import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Line from "../../../Line/Line";
import LoadingButton from "../../../Buttons/LoadingButton";
import { closeIcon } from "../../../../Icons/Icons";

const NewRegularizationRequestSlidingPanel = ({ open, close }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "onSubmit",
  });
  const singleDay = 1;
  const multipleDays = 2;
  const axiosPrivate = useAxiosPrivate();
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const [regularizationTimeType, setRegularizationType] = useState(singleDay);
  const [regularizationReasonList, setRegularizationReasonList] = useState([]);
  const [selectedRegularizationReason, setSelectedRegularizationReason] =
    useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromTime, setSelectedFromTime] = useState("09:00");
  const [selectedToTime, setSelectedToTime] = useState("09:00");
  const [regularizationReasonNotes, setRegularizationReasonNotes] = useState();
  const [timeDifference, setTimeDifference] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegularizationReasonData = async () => {
      try {
        const response = await axiosPrivate.get(
          "attendance/listOfRegularizationReasons?type=1"
        );
        if ( response?.data?.result) {
          const regularizationReason = response.data.result;
          setRegularizationReasonList(regularizationReason);
        } else {
          toast.error("No list found"); 
        }
      } catch (error) {
        toast.error("Error fetching regularization reasons");
      } finally {
        setLoading(false); 
      }
    };

    fetchRegularizationReasonData();
  }, [axiosPrivate]);

  useEffect(() => {
  }, [regularizationTimeType]);

  const clearFormValues = () => {
    setRegularizationReasonList(null)
    setRegularizationType(null)
    setFromDate(null)
    setToDate(null)
    setSelectedFromTime(null)
    setSelectedToTime(null)
       setSelectedFile(null);

  };

  const calculateTimeDifference = () => {
    if (
      regularizationTimeType === singleDay &&
      selectedFromTime &&
      selectedToTime
    ) {
      const fromTime = moment(selectedFromTime, "HH:mm");
      const toTime = moment(selectedToTime, "HH:mm");
      const diffInMinutes = toTime.diff(fromTime, "minutes");
      const diffInHours = diffInMinutes / 60;
      setTimeDifference(diffInHours);
    } else {
      setTimeDifference(null);
    }
  };

  useEffect(() => {
    calculateTimeDifference();
  }, [selectedFromTime, selectedToTime, regularizationTimeType]);

  const validateFile = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const fileSize = file.size;
    if (fileType === "application/pdf") {
      const maxAllowedFileSizeInBytes = 1033414;
      if (fileSize < maxAllowedFileSizeInBytes) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Uploaded file is too large, Try uploading file less than 1MB"
        );
      }
    } else {
      toast.error("Invalid file type, Accepted file type: PDF");
    }
  };

  const onSubmitRegularizationRequest = async (data) => {
    try {
      if (regularizationReasonList && selectedRegularizationReason && selectedRegularizationReason.id) {
        const formData = new FormData();
        formData.append(
          "regularizationReasonId",
          selectedRegularizationReason.id
        );
        formData.append("request_type", regularizationTimeType);
        formData.append("fromDate", dayjs(fromDate).format("YYYY-MM-DD"));

        if (regularizationTimeType === singleDay) {
          formData.append("toDate", dayjs(fromDate).format("YYYY-MM-DD"));
          formData.append("fromTime", selectedFromTime);
          formData.append("toTime", selectedToTime);
        } else if (regularizationTimeType === multipleDays) {
          formData.append("toDate", dayjs(toDate).format("YYYY-MM-DD"));
        }

        formData.append("notes", regularizationReasonNotes);

        if (selectedFile) {
          formData.append("file", selectedFile);
        }

        const response = await axiosPrivate.post(
          `attendance/requestForRegularizationApproval`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          toast.success("Regulization request has been rasied successfully");
          clearFormValues();
          handleClosePanel();
        } else if(response.data && response.data.message){
          toast.error(response.data.message);
        } else {
          toast.error("Failed to submit regularization request");
        }
      } else {
        toast.info(
          "Please select a valid reason to raise a regularization request"
        );
        return;
      }
    } catch (error) {    
      if (error.response) {   
        const responseData = error.response.data;
        if (responseData) {
          const errorMessage = responseData.message;
          if (errorMessage === "No attendace date found.") {
            toast.error("No attendance date found.");
          } else if (errorMessage === "Request has already been raised") {
            toast.error("Regularization request has already been applied.");
          } else {
            toast.error(errorMessage);
          }
        } else {
          toast.error("Failed to submit regularization request");
        }
      } else if (error.request) {
        toast.error("Network error. Please check your internet connection.");
      } else {
        toast.error("Error submitting regularization request");
      }
    } finally {
      setLoaderBtn(false);
    }
  };

  const handleClosePanel = () => {
    clearFormValues();
    reset();
    close();
  };

  const handleTimeChange = (value, fieldName) => {
    try {
      const formattedTime = value ? value.format("HH:mm") : null;

      if (fieldName === "fromTime") {
        setSelectedFromTime(formattedTime);
      } else if (fieldName === "toTime") {
        setSelectedToTime(formattedTime);
      }
    } catch (err) {
    }
  };

  const handleDateChange = (fieldName, value) => {
    try {
      if (fieldName === "fromDate") {
        setFromDate(value);
      } else if (fieldName === "toDate") {
        setToDate(value);
      }
    } catch (err) {
    }
  };

  return (
    <ReactSlidingPane isOpen={open} width="480px" onRequestClose={close}>
      <form onSubmit={handleSubmit(onSubmitRegularizationRequest)}>
        <div>
     
          <div>
            <div className="flex items-center gap-3 text-sm">
              <h2 className="pb-3 pl-5">Request Regularization</h2>
            </div>
          </div>

          <Line />
          <div className="px-5">
            <div className="flex pb-1 font-medium text-gray-800">
              <span className="font-medium">Regularization Request</span>
              <span className="text-red-600 font-medium">*</span>
            </div>

            <div>
              <Select
                options={regularizationReasonList}
                {...register("regularizationReasonId", {
                  required: false,
                })}
                onChange={(selectedOption) => {
                  setSelectedRegularizationReason(selectedOption);
                }}
              />
            </div>
            <div className="bg-[#F9FAFB] mt-3 flex rounded-md"></div>
            <div className="flex my-2 gap-3">
              <div className="flex font-normal text-sm items-center gap-1 btn btn--border bg-gray-100 text-black">
                <input
                  type="radio"
                  id="single-day"
                  name="request_type"
                  value="1"
                  checked={regularizationTimeType === singleDay ? true : false}
                  onChange={() => {
                    setRegularizationType(singleDay);
                  }}
                />
                <label
                  htmlFor="single-day"
                  className="ml-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Single day
                </label>
              </div>
              <div className="flex font-normal text-sm items-center gap-1 btn btn--border bg-gray-100 text-black">
                <input
                  type="radio"
                  id="css"
                  name="request_type"
                  value="2"
                  checked={
                    regularizationTimeType === multipleDays ? true : false
                  }
                  onChange={() => {
                    setRegularizationType(multipleDays);
                  }}
                />
                <label
                  htmlFor="css"
                  className="ml-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Multiple day
                </label>
              </div>
            </div>

            <div className="pt-2 pb-1 font-medium">
              <p className="text-gray-800">
                Date <span className="text-red-600 font-medium">*</span>
              </p>
            </div>
            <div className="flex w-full my-2 gap-4">
              {" "}
              <div
                className={`flex flex-col ${
                  regularizationTimeType === singleDay ? "w-full" : "w-3/5"
                }`}
              >
                <div className="flex items-center gap-2">
                  <input
                    type="date"
                    placeholder="enter the date"
                    {...register("fromDate", {
                      required: true,
                    })}
                    onChange={(e) =>
                      handleDateChange("fromDate", e.target.value)
                    }
                  />
                  {regularizationTimeType === singleDay ? (
                    <>
                      <Controller
                        control={control}
                        name="fromTime"
                        render={({ field }) => (
                          <TimePicker
                            {...field}
                            disabled={!fromDate}
                            allowClear={false}
                            changeOnBlur={true}
                            size="large"
                            format={"hh:mm a"}
                            minuteStep={60}
                            className="min-w-[110px]"
                            defaultValue={dayjs("09:00", "hh:mm")}
                            onChange={(e) => handleTimeChange(e, "fromTime")}
                          />
                        )}
                      />
                      <p className="text-gray-800 font-medium">to</p>

                      <Controller
                        control={control}
                        name="toTime"
                        render={({ field }) => (
                          <TimePicker
                            {...field}
                            allowClear={false}
                            changeOnBlur={true}
                            size="large"
                            format={"hh:mm a"}
                            minuteStep={60}
                            className="min-w-[110px]"
                            defaultValue={dayjs("09:00", "hh:mm")}
                            onChange={(e) => handleTimeChange(e, "toTime")}
                            disabled={!fromDate}
                          />
                        )}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              {regularizationTimeType === multipleDays ? (
                <>
                  <div className="mx-3  flex justify-center font-medium text-gray-800 items-center">
                    to
                  </div>

                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field }) => (
                      <input
                        type="date"
                        placeholder="enter the date"
                        min={fromDate}
                        disabled={!fromDate}
                        {...field}
                        onChange={(e) =>
                          handleDateChange("toDate", e.target.value)
                        }
                      />
                    )}
                  />
                </>
              ) : null}
            </div>

            {/* calculate time */}
            <div className="inline-flex w-full bg-[#ECFDF5] rounded-m">
              <img
                className="w-4 h-4 mt-1.5 ml-2"
                src="/images/Icon.png"
                alt=""
              />
              {timeDifference !== null &&
                regularizationTimeType === singleDay && (
                  <p className="p-1 ml-2">
                    Time Difference: {timeDifference} hours
                  </p>
                )}
            </div>

            <div className="py-3"></div>
            <div>
              <p className="pb-1 font-medium text-gray-800">
                Add note <span className="text-red-600 font-medium"></span>
              </p>
              <div>
                <textarea
                  className="h-[96px]"
                  value={regularizationReasonNotes}
                  {...register("notes", {
                    required: false,
                  })}
                  onChange={(e) => setRegularizationReasonNotes(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div>
              <p className="pt-4 pb-1 font-medium text-gray-800">
                Upload attachment{" "}
              </p>
              <div>
                <input
                  type="file"
                  id="lv-doc"
                  accept="application/pdf"
                  className="hidden"
                  onChange={(e) => {
                    validateFile(e);
                  }}
                ></input>

                <label
                  htmlFor="lv-doc"
                  className="btn btn--border w-full h-[38px] mt-2"
                >
                  {" "}
                  <i className="ico-upload mr-3 text-lg text-gray-900"></i>
                  Upload document
                </label>
              </div>
              <p className="mt-2 text-sm">
                Max file size: 1MB. File format: pdf
              </p>
              {selectedFile && (
                <p className="mt-2 text-sm">
                  Uploaded file: {selectedFile.name}
                </p>
              )}
            </div>
          </div>
          <div></div>
        </div>

        <div className="mt-40">
          <Line />
        </div>

        <div className="flex justify-end items-end px-5">
          <div className="mr-2">
            <button
              type="button"
              className="btn btn--border text-[14px]"
              onClick={handleClosePanel}
            >
              Cancel
            </button>
          </div>
          <div>
            {!isLoaderBtn ? (
              <button
                type="submit"
                className=" btn text-[14px] bg-[#3466E7] pt-[11px]"
              >
                Submit
              </button>
            ) : (
              <LoadingButton />
            )}
          </div>
        </div>
      </form>
    </ReactSlidingPane>
  );
};

export default NewRegularizationRequestSlidingPanel;
