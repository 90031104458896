export const getSettingValue = (settingId, tenantSetting) => {
  try {
    const getSetting =
      Array.isArray(tenantSetting) &&
      tenantSetting?.find(
        (i) => Number(i?.setting_category_id) === Number(settingId)
      );
    if (getSetting) {
      return !!Number(getSetting.setting_value);
    }
    return false;
  } catch (err) {
    throw err;
  }
};

// category id of the setting from setting category table
export const settingCategory = {
    isHrLeaveApprovalRequired: 1,
    isHrLeaveApprovalRequiredForAdditionalLeaves: 2,
    
  }
