import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import auth from './reducers/auth';
import layout from "./reducers/layout";
const rootReducer = combineReducers({
  user: auth,
  layout
});
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

export default store;