import React from "react";
import Avatar from "../../../Avatar/Avatar";
const WhoIsInEmployeeTableData = ({ isClockInOrLeaveCol , employeeList}) => {
    const clockInOrLeaveValue = employeeList?.clock_in_time || employeeList?.leave_name 
  return (
    <>
      <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td className="flex gap-3 items-center px-10 py-4"><Avatar/><div>
            <p className="font-medium text-black">{employeeList?.employee_name}</p>
            <span>{employeeList?.department_name}</span>
            </div></td>
        <td className="px-10 py-4">{employeeList?.designation_title}</td>
        <td className="px-10 py-4">{employeeList?.location_name}</td>

        {isClockInOrLeaveCol ? <td className="px-10 py-4">{clockInOrLeaveValue}</td> : null}
      </tr>
    </>
  );
};

export default WhoIsInEmployeeTableData;
