import React, { useState, useEffect } from "react";
import MyAttendanceTable from "../../../AttendanceList/MyAttendanceTable";
import Avatar from "../../../../Avatar/Avatar";
import Select from "react-select";
import { warningIcon, closeIcon } from "../../../../../Icons/Icons";
import { useLocation } from "react-router-dom";
import MyAttendanceHead from "../../MyAttendanceHead";

//import AttendanceMiniOverview from "../../../../components/Attendance/SelfService/MyAttendance/AttendanceMiniOverview/AttendanceMiniOverview";
import MyAttendanceCalendarView from "../../../AttendanceCalendar/AttendanceCalendar";
import { format, startOfMonth, endOfMonth } from "date-fns";


const TABLE_VIEW = 1;
const CALENDAR_VIEW = 2;
const EmployeeAttendanceDetails = () => {
  const location = useLocation();
  const isProcessAttendance = location?.state?.processAttendance || false;
  const [dataView, setDataView] = useState(TABLE_VIEW);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "1",
    label: "All Status",
  });
  const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
  const [toDate, setToDate] = useState(new Date());

  const handleSetView = (e) => {
    setDataView(e);
  };

  const handleSelectedOptionChange = (selected) => {
    setSelectedOption(selected);
    setSelectedLabel(selected);
  };

  const handleStatusChange = (selected) => {
    setSelectedStatus(selected);
  };

  const handleDateChange = (newFromDate, newToDate) => {
    setFromDate(newFromDate);
    setToDate(newToDate);
  };
  return (
    <div className="w-full relative">
      {isProcessAttendance ? (
        <div className="flex justify-between bg-yellow-500 px-5 py-2 items-center">
          <div className="flex gap-2 text-black items-center">
            {warningIcon({ fill: "black" })} Found 2 pending approval, please
            complete all of them to process this employee attendance
          </div>
          <div>{closeIcon}</div>
        </div>
      ) : null}
      <div className="px-10 py-4">
        <div className="flex text-black gap-3 justify-between items-center py-3">
          <div className="flex gap-3">
            <Avatar />
            <div className="flex flex-col">
              <h1 className=" text-lg font-medium">Employee Name</h1>
              <div className="flex gap-2">
                <span>Marketing</span>
                <span>Full Time</span>
                <span>IV Main Office India</span>
              </div>
            </div>
          </div>
          {/* <div className="flex gap-2">
            <button className="btn btn--border">
              <i className="ico-plus"></i>
            </button>
            <button className="btn btn--border">
              <i className="ico-plus"></i>
            </button>
          </div> */}
        </div>
       
      </div>
      <div className="flex flex-1">
      <div className="flex-1 flex-col">
        <div className="relative mb-20">
          <MyAttendanceHead
            handleSetView={handleSetView}
            dataView={dataView}
            selectedOption={selectedOption}
            onSelectedOptionChange={handleSelectedOptionChange}
            setSelectedOption={setSelectedOption}
            setSelectedLabel={setSelectedLabel}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            selectedStatus={selectedStatus}
            onStatusChange={handleStatusChange}
            onDateChange={handleDateChange}
            showRegularizationPanel={false} 
            showClockData={false}
          />

          {dataView === TABLE_VIEW ? (
            <MyAttendanceTable
              selectedOption={selectedOption}
              label={selectedStatus}
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            <MyAttendanceCalendarView
              selectedOption={selectedOption}
              label={selectedStatus}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
        </div>
        {/* <div className="fixed w-5/6 bottom-0">
          <AttendanceMiniOverview fromDate={fromDate} toDate={toDate} />
        </div> */}
      </div>
    </div>
    </div>
  );
};

export default EmployeeAttendanceDetails;
