import React, { Fragment, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "../../Avatar/Avatar";
import { AccountIcon, SettingsIcon, LogoutIcon } from "../../../Icons/Icons";

const HeaderDropdownMenu = (props) => {
  const navigate = useNavigate();
  const menuBtnRef = useRef();
  const profile = useSelector((state) => state.user.userProfile);
  const { btnFn, openSlide } = props;
  const menuitems = [
    {
      title: "My Profile",
      link: "#",
      menuBtnFn: openSlide,
      icon: AccountIcon,
    },
    {
      title: "Settings",
      link: "/dashboard/employee-settings",
      icon: SettingsIcon,
      menuBtnFn: () => navigate("/dashboard/employee-settings"),
    },
    {
      title: "Log Out",
      link: "#",
      menuBtnFn: btnFn.signout,
      icon: LogoutIcon,
    },
  ];

  const showMenu = () => {
    try {
      menuBtnRef.current.click();
    } catch (e) {}
  };

  return (
    <Menu as="div" className="relative inline-block text-left select-none">
      <div>
        <Menu.Button className="inline-flex w-full justify-center p-2 rounded-full text-sm text-gray-700 hover:bg-gray-100">
          <div
            ref={menuBtnRef}
            aria-hidden="true"
            className=""
            onClick={showMenu}
          >
            <span>
              <Avatar status="available" image={props.image} />
            </span>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 min-w-[240px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none select-none">
          <div className="flex flex-row items-center gap-3 p-3 pt-6 ">
            <Avatar status="available" image={`${profile.photo}`} />
            <div>
              <p className="font-semibold text-black whitespace-nowrap">{`${profile?.first_name} ${profile?.last_name}`}</p>
              <p className="text-blue-600 whitespace-nowrap">{profile?.email}</p>
              {/* <p className="whitespace-nowrap">{profile?.designation_title}</p> */}
            </div>
          </div>
          <div className="py-1">
            {menuitems.map((menu, index) => (
              <Menu.Item key={`${index}_header_profile_menu_item`}>
                <div
                  key={`${index}_header_profile`}
                  onClick={menu.menuBtnFn}
                  className="text-gray-700 flex cursor-pointer hover:font-medium mx-2 py-1 gap-2 hover:bg-blue-100 rounded-md"
                >
                  <span className="flex items-center text-gray-900 hover:text-blue-600 hover:font-medium px-4 py-1 text-sm">
                    <span className="mr-2">{menu.icon}</span>
                    {menu.title}
                  </span>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderDropdownMenu;
