
const statusColor = (totalHours, duration) => {
    const numericValue = parseInt(totalHours);
    if (numericValue < duration) {
      return "red";
    } else if (numericValue > duration) {
      return "green";
    } else {
      return "";
    }
  };
  
  export default statusColor;
  