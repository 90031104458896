import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "antd";

const DeleteWidgetModal = ({ widgetId, handleDeleteClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirmDelete = async () => {
    try {
      if (handleDeleteClick) {
        handleDeleteClick(widgetId)
      } else {
        toast.error("Something went wrong while deleting the widget");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the widget.");
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button>
        <i
          className="ico-delete text-red-600 ml-2 icon-large cursor-pointer"
          style={{
            fontSize: "24px",
            verticalAlign: "middle",
          }}
          onClick={() => setIsModalOpen(true)}
        ></i>
      </button>
      <Modal
        title=" "
        open={isModalOpen}
        footer={false}
        centered
        onCancel={handleCloseModal}
        
      >
        <div className="flex items-center justify-center">
            <div className="">
              <p className="text-gray-800 font-medium">
                <div>
                  <h2>Delete Widget</h2>
                  <p>Are you sure you want to delete widget?</p>
                </div>
                <div className="mt-4 flex justify-end gap-3">
                  <button
                    className="btn btn--border"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn--red"
                    onClick={() => handleConfirmDelete()}
                  >
                    Confirm Delete
                  </button>
                </div>
              </p>
            </div>
          </div>
      </Modal>
    </>
  );
};

export default DeleteWidgetModal;
