import React, { Fragment, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import PageLoader from "../../../components/PageLoader/PageLoader";

const WidgetListTable = () => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();

  const fetchWidgetList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate
      .get("employee/viewWidgets")
      .then((response) => {
        if (response.data.status) {
          setTimeout(() => {
           // console.log("response data reading", response.data.data);
            setWidgets(response.data.data);
            setLoading(false);
          }, 500);
        } else {
         // console.log("no response found");
          setWidgets([]);
          setLoading(false);
        }
      })
      .catch((error) => {
       // console.error("Error fetching data:", error);
        setWidgets([]);
        setLoading(false);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchWidgetList();
  }, []);
  return (
    <div class="relative overflow-x-auto p-4">
      <div className="block text-xl font-medium text-gray-800">
        <h1>Widget List</h1>
      </div>

      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-l font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3 w-full">
              Widget Name
            </th>
            <th scope="col" class="px-10 py-3 w-full">
              Can be disabled
            </th>
          </tr>
        </thead>
        <tbody>
          {widgets.map((widget) => (
            <tr key={widget.id}>
              <td className="px-10 py-2">{widget.widget_name}</td>
              <td className="px-10 py-2">
                {widget.can_disabled ? "No" : "Yes"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WidgetListTable;
