import React from "react";
import WidgetListTable from "./WidgetListTable";
import { Link } from "react-router-dom";
const Settings = () => {
  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className="flex flex-col items-start item-center justify-between pl-4">
          <div className="flex-grow">
            <Link
              to="/settings/assign-widget"
              className="m-4 text-[14px] btn top-4 right-4"
            >
              Assign Widget
            </Link>
          </div>
          <div className="w-full mt-2">
            <WidgetListTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
