import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  // department as departmentOptions,
  // designation as designationOptions,
  // location as locationOptions,
  DropdownIndicator,
} from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import WhoIsInEmployeeTable from "../../../../components/Attendance/SelfService/WhoIsIn/WhoIsInEmployeeTable";
import moment from "moment";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Pagination from "../../../../components/Pagination/Pagination";

const NOT_YET_IN = 1;
const ON_TIME = 2;
const LATE_IN = 3;
const ON_LEAVE = 4;

const FILTER_OPTIONS = {
  TEAM: "team"
}

const WhoIsIn = () => {
  const [toggleState, toggleTab] = useState(NOT_YET_IN);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const axiosPrivate = useAxiosPrivate();
  const [teamList, setTeamList] = useState([]);
  const [filters, setFilters] = useState({});

  const handleSetFilter = (value, source) => {
    setFilters((prev) => ({
      ...prev,
      [source]: value,
    }))
  }

  const fetchTeamList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get("employee/teamlist", {
        signal: controller.signal,
      })
      .then((response) => {
        // console.log(response);
        if (response?.data?.status) {
          const formattedData = response?.data?.teams?.map((val) => ({
            label: val.team_name,
            value: val.id,
          }));
          setTeamList(formattedData);
        }
      })
      .catch((err) => {});

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchTeamList();
  }, []);
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="px-10 py-5">
          <h1 className="font-medium text-black text-lg">Who is in</h1>
          <p>
            You can view employees based on whether they are in for the dat, or
            their In/Out timing
          </p>
        </div>

        <div className="border-b-2 px-10">
          <div className="flex items-center  gap-12 pl-4 pt-6">
            <button
              className={
                toggleState === NOT_YET_IN
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(NOT_YET_IN)}
            >
              Not yet in
            </button>
            <button
              className={
                toggleState === ON_TIME
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(ON_TIME)}
            >
              On Time
            </button>
            <button
              className={
                toggleState === LATE_IN
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(LATE_IN)}
            >
              Late In
            </button>
            <button
              className={
                toggleState === ON_LEAVE
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(ON_LEAVE)}
            >
              On Leave
            </button>
          </div>
        </div>

        <div className="flex justify-between px-10 py-4">
          <div className="flex gap-3">
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <Select
              options={teamList}
              placeholder="Team: All"
              components={{ DropdownIndicator }}
              className="min-w-[150px]"
              isClearable
              onChange={(e) => handleSetFilter(e?.value, FILTER_OPTIONS.TEAM)}
            />
            {/* <Select
              options={designationOptions}
              components={{ DropdownIndicator }}
            />
            <Select
              options={locationOptions}
              components={{ DropdownIndicator }}
            /> */}
          </div>
          <div>
            {/* <button className="btn btn--border">
              <i className="ico-download mr-2"></i>Download
            </button> */}
          </div>
        </div>

        <WhoIsInEmployeeTable filters={filters} toggleState={toggleState} date={date} />
      </div>

    </>
  );
};

export default WhoIsIn;
