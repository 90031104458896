import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../Icons/Icons";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ApproverHistoryDetails from "./LeaveRequestHistory/ApproverHistoryDetails";
import LeaveApplicationDetails from "./LeaveRequestHistory/LeaveApplicationDetails";

const ASC_SORT_ORDER = "ASC";
const DESC_SORT_ORDER = "DESC";

const LeaveRequestHistory = ({ open, close, leave }) => {
  const axiosPrivate = useAxiosPrivate();
  const [sortOrder, setSortOrder] = useState(DESC_SORT_ORDER);
  const leaveApplicationId = leave?.leave_application_id || "";
  const [leaveHistory, setLeaveHistory] = useState();

  const fetchLeaveApplicationHistory = async () => {
    const result = await axiosPrivate
      .get(
        `lms/leave/leaveApplicationHistory?leaveApplicationId=${leaveApplicationId}&sortOrder=${sortOrder}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      setLeaveHistory(result.data.results);
    } else {
      setLeaveHistory("");
    }
  };

  useEffect(() => {
    if (leaveApplicationId) {
      fetchLeaveApplicationHistory();
    }
  }, [open, sortOrder]);

  return (
    <>
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="480px">
        <div className="flex flex-col text-black w-full">
          <div className="flex px-5 pb-3 justify-between w-full border-b">
            <div className="flex gap-3 items-center">
              <i onClick={close} className="ico-left text-xs cursor-pointer"></i>
              <h1 className="font-medium text-lg text-black">History</h1>
            </div>
            <div>
              <button onClick={close}>{closeIcon}</button>
            </div>
          </div>
          <div className="px-5 flex justify-between py-3">
            <p className="text-gray-500">
              Found {leaveHistory?.length || 0} history
            </p>
            <p
              onClick={() =>
                setSortOrder((prev) =>
                  prev === DESC_SORT_ORDER ? ASC_SORT_ORDER : DESC_SORT_ORDER
                )
              }
              className="font-medium flex items-center gap-1 cursor-pointer"
            >
              Sort {sortOrder === DESC_SORT_ORDER ? "Descending" : "Ascending"}
              <i className="ico-down" />
            </p>
          </div>
          {sortOrder === ASC_SORT_ORDER && (
            <LeaveApplicationDetails leave={leave} />
          )}
          {Array.isArray(leaveHistory)
            ? leaveHistory.map((item, index) => {
                return <ApproverHistoryDetails data={item} key={index} />;
              })
            : null}
          {sortOrder === DESC_SORT_ORDER && (
            <LeaveApplicationDetails leave={leave} />
          )}
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default LeaveRequestHistory;
