const formatSecondsToTime = (seconds, method) => {
  if (seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${hours}hr ${minutes}m ${remainingSeconds}s`;
    switch (method){
      case "object":
        return {
          hours,
          minutes,
          remainingSeconds,
        };
      case "hour":
        return `${hours}hr`;
      case "minute":
        return `${minutes}m`;
      case "seconds":
        return `${remainingSeconds}s`;
      default:
        return formattedTime;
    }
  } else {
    switch (method){
      case "object":
        return {
          hours: 0,
          minutes: 0,
          remainingSeconds: 0,
        };
      case "hour":
        return `0hr`;
      case "minute":
        return `0m`;
      case "seconds":
        return `0s`;
      default:
        return "0hr 0m 0s";
    }
  }
};
export default formatSecondsToTime;
