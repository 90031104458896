import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import Line from "../../../components/Line";
import axios from "../../../helpers/axios";
import { toast } from "react-toastify";

const fileTypes = ["JPG", "PNG", "GIF"];

const SupportingFiles = () => {
  const location = useLocation();
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const navigate = useNavigate();

  const submitHandle = () => {
    try {
      axios
        .put(
          "onboarding/candidate/updateCandidate",
          {
            status: 2,
          },
          { headers: { Authorization: `Bearer ${location.state.token}` } }
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            toast.success("Onboarding flow completed", { type: "success" });
            navigate("/offer/registration-completed");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (err) {}
  };
  return (
    <div className="flex-1 justify-center max-w-[740px]">
      <div className="mb-5">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Supporting files
        </h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s.
        </p>
      </div>
      {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} classes={"file_upload"} /> */}
      <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="p-6 text-center rounded-md bg-gray-50 border border-gray-300 mb-6 cursor-pointer">
                <div className="mb-4">
                  <i className="ico-upload text-[25px] text-gray-900"></i>
                </div>
                <p className="text-gray-800 font-medium mb-2">
                  Drag or drop files here
                </p>
                <div className="text-gray-500 mb-2">or</div>
                <span href="" className="btn">
                  Browse Files
                </span>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <div className="mb-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Formal Photo
        </h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
      {/* <Fileuploader /> */}
      <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="p-6 text-center rounded-md bg-gray-50 border border-gray-300 mb-6 cursor-pointer">
                <div className="mb-4">
                  <i className="ico-upload text-[25px] text-gray-900"></i>
                </div>
                <p className="text-gray-800 font-medium mb-2">
                  Drag or drop files here
                </p>
                <div className="text-gray-500 mb-2">or</div>
                <span href="" className="btn">
                  Browse Files
                </span>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <div className="mb-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">ID Card</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
      {/* <Fileuploader /> */}
      <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="p-6 text-center rounded-md bg-gray-50 border border-gray-300 mb-6 cursor-pointer">
                <div className="mb-4">
                  <i className="ico-upload text-[25px] text-gray-900"></i>
                </div>
                <p className="text-gray-800 font-medium mb-2">
                  Drag or drop files here
                </p>
                <div className="text-gray-500 mb-2">or</div>
                <span href="" className="btn">
                  Browse Files
                </span>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <Line />
      <div className="flex">
        <div>
          <button className="btn btn--border" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
        <div className="ml-auto flex">
          <input
            type="submit"
            value="Complete"
            className="btn ml-5"
            onClick={submitHandle}
          />
          {/* <Link to="/offer/registration-completed" className="btn ml-5">
            Complete
          </Link> */}
        </div>
      </div>
    </div>
  );
}

export default SupportingFiles;
