import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";
const {employeeList, employeeHistory, 
  getActionTypesFoeEmployeeHistory,
  locationList, departmentList, desginationList,
  lineManagerList, hrList,
  countryList, stateList, cityList, singleEmployeeDetails ,
  employeeTypeList,employeeJobList, employeeSerialKey} = queryKeys

export const useEmployeeList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("employee/search", {
        params: formatedSearchParams,
      });
     // console.log("data:",data)
      return data;
    },
  });
};

export const useGetEmployeeHistory = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeHistory, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("employee/getEmployeeHistory", {
        params: formatedSearchParams,
      });
      return data;
    },
    enabled: !!params?.employee_id,
  });
};

export const useGetActionTypesForEmployeeHistory = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getActionTypesFoeEmployeeHistory, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getActionTypesForEmployeeHistory",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetLocationList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [locationList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getLocationList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetDepartmentList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [departmentList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getDepartmentList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetDesignationList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [desginationList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getDesignationList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetLineManagerList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [lineManagerList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getLineManagerList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useGetHrList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [hrList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getHRList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};

export const useCreateEmployee = (payload) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: async () => {
      const { data } = axiosPrivate.post("employee/create", payload);
      return data;
    }
  })
};

export const useUpdatePersonalDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
    //  console.log("Data in line 160:",data)
      const response = await axiosPrivate.put("onboarding/candidate/updatePersonalDetails", data);
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([singleEmployeeDetails, variables.id]); 
    },
    onError: (error) => {
//console.log("error in uodating personal data:",error)
    }
  });
};



export const useGetCountryList = () => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [countryList],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("onboarding/services/location");
      return data.country;
    },
  });
};

export const useGetStateList = (selectedCountry) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [stateList, selectedCountry],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(`onboarding/services/location?StatesFrom=${selectedCountry}`);
      return data.state;
    },
    enabled: !!selectedCountry,
  });
};

export const useGetCityList = (selectedCountry, selectedState) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [cityList, selectedCountry, selectedState],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(`onboarding/services/location?StatesFrom=${selectedCountry}&CityFrom=${selectedState}`);
      return data.city;
    },
    enabled: !!selectedCountry && !!selectedState,
  });
};

export const useGetEmployeeDetailsById = (params = {}) => {
  const formattedSearchParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();

  return useQuery({
    queryKey: [singleEmployeeDetails, params.employeeId], 
    queryFn: async () => {
      const { data } = await axiosPrivate.get("employee/search", {
        params: formattedSearchParams,
      });
      return data;
    },
    enabled: !!params.employeeId, 
  });
};

export const useGetEmployeeTypeList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeTypeList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getEmployeeTypeList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};
export const useGetJobTitleList = (params = {}) => {
  const formatedSearchParams = formatSearchParams(params)
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeJobList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/getJobTitleList",
        {
          params: formatedSearchParams,
        }
      );
      return data;
    },
  });
};
export const useUpdateEmployeeInformation = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const { employeeId, ...rest } = data;
      // console.log("updating data:",data)
      const response = await axiosPrivate.put("employee/updateEmployeeInformation", {
        employeeId,
        ...rest,
      });
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([singleEmployeeDetails, variables.employeeId]);
    },
    onError: (error) => {
     // console.log("error in updating employee data:",error)
    },
  });
};
export const useAssignLineManager = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
    
    //  console.log("data into line manager:", data);
      const response = await axiosPrivate.put("employee/assignLineManager", data);
    //  console.log("response from useQuery:", response);
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([singleEmployeeDetails, variables.employee_id]);
    },
    onError: (error) => {
   //   console.log("Error in assigning line manager:", error);
    },
  });
};


export const useUpdateProfileDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (formData) => {
     // console.log("form data:",formData)
      const response = await axiosPrivate.put("employee/updateProfile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
    onSuccess: (data, variables) => {
 
      queryClient.invalidateQueries([singleEmployeeDetails, variables.employeeId]);
    },
    onError: (error) => {
  //  console.log("error in form data:",error)
    },
  });
};

export const useCheckSerialKeyAvailability = (params = {}) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeSerialKey, params.serialKey], 
    queryFn: async () => {
      try {
       // console.log("Params from checking serial key:",params)
        const formattedSearchParams = formatSearchParams(params); 
        const { data } = await axiosPrivate.get("employee/search", {
          params: formattedSearchParams,
        });
   // console.log("respmse from qurey:",data)
    
return data
      } catch (error) {
       // console.error("Error checking serial key availability:", error);
      }
    },
    enabled: !!params.serialKey, 
  });
};

