import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import CardListView from "../../../components/Settings/TenantSettings/CardListView";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../components/PageLoader/SpinnerLoader";
import PageLoader from "../../../components/PageLoader";
import AddTenantDetails from "../../../components/Settings/TenantSettings/AddTenantDetails";

const emptyState = <p>No Data Found...</p>;
const EMPLOYEE_TYPE_LIST = "emploeeTypeList";
const LOCATION_LIST = "locationList";
const DEPARTMENT_LIST = "departmentList";
const DESIGNATION_LIST = "designationList";
const TEAM_LIST = "teamList";
const TENANT_SERIAL_TYPE = "tenantSerialType";
const JOB_TITLE_LIST = "jobTitleList";

const TenantSetting = () => {
  const axiosPrivate = useAxiosPrivate();
  const loaderSpinner = <SpinnerLoader />;
  const [isPageLoader, setIsPageLoader] = useState(true);
  const [isAddTenantDetailsModal, setIsAllTenantDetailsModal] = useState(false);

  const [tenantData, setTenantData] = useState({
    emploeeTypeList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Employee Type",
    },
    locationList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Location",
    },
    departmentList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Department",
    },
    designationList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Designation",
    },
    teamList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Team",
    },
    tenantSerialType: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Serial Types",
    },
    jobTitleList: {
      data: [],
      loaderComponent: loaderSpinner,
      cardTitle: "Job Title",
    },
  });

  const cardItems = Object.keys(tenantData);

  const handleSetTenantData = (data, source, loaderComponent) => {
    setTenantData((prev) => ({
      ...prev,
      [source]: {
        ...prev[source],
        data,
        loaderComponent,
      },
    }));
  };

  const handleSetLoaderComponent = (loaderComponent, source) => {
    setTenantData((prev) => ({
      ...prev,
      [source]: {
        ...prev[source],
        loaderComponent,
      },
    }));
  };

  const fetchDesignationList = async () => {
    handleSetLoaderComponent(loaderSpinner, DESIGNATION_LIST);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          const formattedData = data.result.map((val) => ({
            title: val.designation_title,
            description: val.description,
            id: val.id,
          }));
          handleSetTenantData(formattedData, DESIGNATION_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, DESIGNATION_LIST);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, DESIGNATION_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    handleSetLoaderComponent(loaderSpinner, DEPARTMENT_LIST);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          const formattedData = data.result.map((val) => ({
            title: val.department_name,
            description: null,
            id: val.id,
          }));
          handleSetTenantData(formattedData, DEPARTMENT_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, DEPARTMENT_LIST);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, DEPARTMENT_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchLocationList = async () => {
    handleSetLoaderComponent(loaderSpinner, LOCATION_LIST);

    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          const formattedData = data.result.map((val) => ({
            title: val.location_name,
            description: null,
            id: val.id,
          }));

          handleSetTenantData(formattedData, LOCATION_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, LOCATION_LIST);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, LOCATION_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchEmployeeTypeList = async () => {
    handleSetLoaderComponent(loaderSpinner, EMPLOYEE_TYPE_LIST);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getEmployeeTypeList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          const formattedData = data.result.map((val) => ({
            title: val.type_label,
            description: null,
            id: val.id,
          }));

          handleSetTenantData(formattedData, EMPLOYEE_TYPE_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, EMPLOYEE_TYPE_LIST);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, EMPLOYEE_TYPE_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchTeamList = async () => {
    handleSetLoaderComponent(loaderSpinner, TEAM_LIST);

    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get("employee/teamlist", {
        signal: controller.signal,
      })
      .then((response) => {
        if (response.data.status) {
          const formattedData = response?.data?.teams?.map((val) => ({
            title: val.team_name,
            description: null,
            id: val.id,
          }));
          handleSetTenantData(formattedData || [], TEAM_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, TEAM_LIST);
        }
      })
      .catch((err) => {
        console.error(err);
        handleSetLoaderComponent(emptyState, TEAM_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchTenantSerialTypes = async () => {
    handleSetLoaderComponent(loaderSpinner, TENANT_SERIAL_TYPE);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getTenantSerialList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          const formattedData = data.result.map((val) => ({
            title: `${val.prefix}-${val.serial_start_from}-${val.suffix}`,
            description: val.type,
            id: val?.id || null,
          }));
          handleSetTenantData(formattedData, TENANT_SERIAL_TYPE, false);
        } else {
          handleSetLoaderComponent(emptyState, TENANT_SERIAL_TYPE);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, TENANT_SERIAL_TYPE);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchJobTitle = async () => {
    handleSetLoaderComponent(loaderSpinner, JOB_TITLE_LIST);

    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getJobTitleList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          handleSetTenantData(data.result, JOB_TITLE_LIST, false);
        } else {
          handleSetLoaderComponent(emptyState, JOB_TITLE_LIST);
        }
      })
      .catch((err) => {
        handleSetLoaderComponent(emptyState, JOB_TITLE_LIST);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchAllTenantData = async () => {
    await Promise.all([
      fetchDesignationList(),
      fetchDepartmentList(),
      fetchLocationList(),
      fetchEmployeeTypeList(),
      fetchTeamList(),
      fetchTenantSerialTypes(),
      fetchJobTitle(),
    ])
      .then(() => {
        setIsPageLoader(false);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    fetchAllTenantData();
  }, []);

  if (isPageLoader) {
    return <PageLoader />;
  }

  return (
    <div className="p-10 w-full">
      {isAddTenantDetailsModal ? (
        <AddTenantDetails
          fetchAllTenantData={fetchAllTenantData}
          open={isAddTenantDetailsModal}
          close={() => setIsAllTenantDetailsModal(false)}
        />
      ) : null}
      <div className="flex justify-between items-center">
        <h1 className="text-gray-800 font-medium text-xl py-5">
          Tenant Setting
        </h1>
        <button
          className="btn"
          type="button"
          onClick={() => setIsAllTenantDetailsModal(true)}
        >
          Add Details
        </button>
      </div>
      <div className="flex flex-wrap gap-5 h-[300px]">
        {cardItems.map((item, index) => {
          return (
            <div key={`carf_item_tenant_map_key_${index}`} className="h-full">
              <Card>
                <CardListView value={tenantData[item]} />
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TenantSetting;
