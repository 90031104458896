import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { DatePicker } from "antd";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
Chart.register(...registerables);

const { RangePicker } = DatePicker;

const AttendanceOverviewChart = () => {
  const colors = [
    "#4875E9",
    "#9AB3F3",
    "#C2D1F8",
    "#7d9df0",
    "#f0f4fd",
  ];
  const defaultColor = "#D3D3D3";
  const axiosPrivate = useAxiosPrivate();
  const [attendanceLocationList, setAttendanceLocationList] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const chartRef = useRef(null);
  let chartInstance = useRef(null);

  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  const fetchAttendanceListData = () => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      const fromDateStr = formatDate(dateRange[0].toDate()).date;
      const toDateStr = formatDate(dateRange[1].toDate()).date;
      axiosPrivate
        .get(
          `attendance/attendanceOverView?from_date=${fromDateStr}&to_date=${toDateStr}`
        )
        .then((response) => {
          if (response?.data?.result) {
            const employeeAttendanceLocationResponseData = response?.data?.result;
            setAttendanceLocationList(employeeAttendanceLocationResponseData);
          } else {
            setAttendanceLocationList([]);
          }
        })
        .catch((error) => {
          setAttendanceLocationList([]);
        });
    } else {
      setAttendanceLocationList([]);
    }
  };

  useEffect(() => {
    fetchAttendanceListData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (attendanceLocationList) {
      const ctx = chartRef?.current?.getContext("2d");
      const dataValues = attendanceLocationList.map((item) => item.count_per_location);
      const totalEmployeeLocationList = dataValues.reduce((total, value) => total + value, 0);
      const backgroundColor = totalEmployeeLocationList > 0 ? colors : [defaultColor];

      if (chartInstance?.current) {
        chartInstance.current.data.labels = attendanceLocationList.map(
          (item) => item.leave_status
        );
        chartInstance.current.data.datasets[0].data = totalEmployeeLocationList > 0 ? dataValues : [1];
        chartInstance.current.data.datasets[0].backgroundColor = backgroundColor;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: attendanceLocationList.map((item) => item.location_name),
            datasets: [
              {
                label: "# of Attendance Overview",
                data: totalEmployeeLocationList > 0 ? dataValues : [1],
                backgroundColor: backgroundColor,
              },
            ],
          },
          options: {
            scales: {},
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [attendanceLocationList]);

  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  const totalAttendanceByLocation = attendanceLocationList.reduce((total, item) => total + parseInt(item.count_per_location), 0);

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <>
      <div className="border rounded-md p-3">
        <div className="flex justify-between font-medium text-black">
          <p className="">Attendance</p>
          <p className="flex items-center gap-2">
            {isCalendarOpen ? (
              <RangePicker
                value={dateRange}
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
                disabledDate={disableFutureDates}
              />
            ) : (
              "Today"
            )}
            <i
              className={`ico-${isCalendarOpen ? "up" : "down"}`}
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            />
          </p>
        </div>

        <div className="flex items-center py-2">
          <div className="w-[160px] px-3">
            <canvas
              id="AttendanceByLocation"
              ref={chartRef}
              width="160"
              height="160"
            ></canvas>
          </div>

          <div className="flex flex-wrap w-full">
            {attendanceLocationList?.length === 0 ? (
              <div className="flex gap-2 justify-center py-10 max-h-full">
                <span className="flex items-center py-2 text-gray-800">
                  No data available!
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-2 w-full">
                {attendanceLocationList?.map((item, index) => (
                  <div className="flex pb-3 pr-5" key={index}>
                    <span
                      className={`h-3 w-5 rounded-[20px] mr-2`}
                      style={{ backgroundColor: colors[index % colors.length] }}
                    ></span>
                    <p>{item?.location_name}</p>
                    <h3 className="ml-auto text-gray-800 font-medium">
                      {item?.count_per_location}
                    </h3>
                  </div>
                ))}
                <div className="flex pb-3 pr-5 w-full text-gray-800">
                  <p className="font-medium">Total Attendance </p>
                  <h3 className="ml-auto font-medium">
                    {totalAttendanceByLocation}
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceOverviewChart;
