import React, { useEffect, useState } from "react";
import WhoIsInEmployeeTableData from "./WhoIsInEmployeeTableData";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import moment from "moment";
import Pagination from "../../../Pagination/Pagination";

const NOT_YET_IN = 1;
const ON_TIME = 2;
const LATE_IN = 3;
const ON_LEAVE = 4;

const WhoIsInEmployeeTable = ({ toggleState, date, filters }) => {
  const isClockInOrLeaveCol = toggleState > NOT_YET_IN;
  const clockInOrLeaveColTitle =
    toggleState === LATE_IN ? "Clock-In Time" : "Leave Type";
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const fetchDataForWhoIsIn = async () => {
    setIsLoading(true);
    try {
      const fetchEmployeeData = await axiosPrivate.get(`attendance/whoIsIn?`, {
        params: {
          status: toggleState,
          date: date,
          ...filters
        },
      });
      if (fetchEmployeeData?.data?.employee) {
        setEmployeeList(fetchEmployeeData?.data?.employee);
      } else {
        setEmployeeList([]);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDataForWhoIsIn();
  }, [toggleState, date, filters]);
  return (
    <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500 mb-20">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" className="px-8 py-3">
              Employee Name
            </th>
            <th scope="col" className="px-8 py-3">
              Designation
            </th>
            <th scope="col" className="px-8 py-3">
              Location
            </th>
            {isClockInOrLeaveCol ? (
              <th scope="col" className="px-8 py-3">
                {clockInOrLeaveColTitle}
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {employeeList.map((item) => {
            return (
              <WhoIsInEmployeeTableData
                // key={item.id}
                isClockInOrLeaveCol={isClockInOrLeaveCol}
                employeeList={item}
              />
            );
          })}
        </tbody>
      </table>
      <Pagination/>
    </>
  );
};

export default WhoIsInEmployeeTable;
