import React from "react";

const LoginActivity = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Login Activity
          </p>
        </div>
      </div>
      <div className="flex items-start gap-5 mt-4">
        <div>
          <img src="/images/desktop-icon.svg" alt="" />
        </div>
        <div>
          <p>2018 Macbook Pro 15-inch</p>
          <p>Melbourne, Australia • 22 Jan at 10:40am</p>
        </div>
        <div>
          <p className="text-xs text-[#065F46] font-medium">Active Now</p>
        </div>
      </div>
      <div className="flex items-start gap-5 mt-4">
        <div>
          <img src="/images/mobile-icon.svg" alt="" />
        </div>
        <div>
          <p>Iphone 13</p>
          <p>Melbourne, Australia • 22 Jan at 4:20pm</p>
        </div>
      </div>
    </div>
  );
};

export default LoginActivity;
