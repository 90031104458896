import React from "react";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";
import Aside from "../Dashboard/Aside/Aside";
import { Outlet } from "react-router-dom";

const submenu = [
  {
    text: "Locations",
    link: "/organization/locations",
    roles:[1,2,4,6]

  },
  {
    text: "Departments",
    link: "/organization/departments",
    roles:[1,2,4,6]

  },
  {
    text: "Designations",
    link: "/organization/designations",
    roles:[1,2,4,6]

  }
];

const Organization = () => {
  return (
    <div className="lg:flex ">
      <DashboardSidebar />
      <div className="flex flex-1">
        <Aside submenu={submenu} />
        <Outlet />
      </div>
    </div>
  );
};

export default Organization;
