import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import { closeIcon } from "../../../../Icons/Icons";
import Avatar from "../../../Avatar/Avatar";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const RegularizationRequestActionModal = ({
  open,
  close,
  modalViewState,
  employee_name,
  regularizationId,
  regularization_date,
  total_hours,
  from_time,
  to_time,
  modalView,
  fetchData,
}) => {
  const isApprovalView = modalViewState === modalView.APPROVE_MODAL;
  const isDecliceView = modalViewState === modalView.DECLINE_MODAL;
  const isRevokeView = modalViewState === modalView.REVOKE_MODAL;
  const mainTitle = isApprovalView
    ? "Approve Regularization"
    : isDecliceView
    ? "Decline Regularization"
    : "Revoke Approval";
  const btnClass = isApprovalView
    ? "btn--green"
    : isDecliceView
    ? "btn--red"
    : "";

  const [hrNote, setHrNote] = useState("");
  const axiosPrivate = useAxiosPrivate();

  const handleApproveOrReject = async () => {
    try {
      let apiUrl;
      if (modalViewState === modalView.APPROVE_MODAL) {
        apiUrl = "/attendance/hrRegularizationApproval";
      } else if (modalViewState === modalView.DECLINE_MODAL) {
        apiUrl = "/attendance/hrRegularizationReject";
      } else {
        return;
      }
  
      const requestBody = {
        regularization_application_id: regularizationId,
        hr_note: hrNote,
      };
  
      const response = await axiosPrivate.put(apiUrl, requestBody);
      if (response && response?.data) {
        toast.success("Regulization status has been updated");
        close();
      } else {
        toast.error("Request status has already been updated");
        close();
      }
    } catch (error) {
      toast.error("Something went wrong in getting data");
      close();
    }
  };
  

  const handleModalExited = () => {
    fetchData();
  };

  return (
    <Modal
      open={open}
      onClose={close}
      closeIcon=" "
      center
      onExited={handleModalExited}
    >
      <div className="w-[420px] px-5 text-black">
        <div className="flex justify-between items-center py-4">
          <h1 className="font-medium text-base">{mainTitle}</h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        {isRevokeView ? (
          <div className="py-3">
            This will make the attendance approval status to “Pending”. Are you
            sure ?
          </div>
        ) : null}
        <div className="flex gap-2 items-center bg-gray-100 w-1/2 rounded-3xl p-1">
          <Avatar />
          <p className="font-medium">{employee_name}</p>
        </div>
        <div className="flex justify-between items-center py-2">
          <div className="flex items-center gap-2">
            <i className="ico-calendar"></i>
            <p className="font-medium">{regularization_date}</p>
          </div>
          <p>
            {from_time} AM - {to_time} PM ({total_hours} Hours)
          </p>
        </div>
        <div>
          <label>Add note</label>
          <textarea
            value={hrNote}
            onChange={(e) => setHrNote(e.target.value)}
          ></textarea>
        
        </div>
        <div className="py-4 flex justify-end gap-3">
          <button className="btn btn--border" onClick={close}>
            Cancel
          </button>
          <button className={`btn ${btnClass}`} onClick={() => handleApproveOrReject()}>
  {mainTitle}
</button>



        </div>
      </div>
    </Modal>
  );
};

export default RegularizationRequestActionModal;

