import React from "react";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";
import { toast } from "react-toastify";
import {
  useFetchListOfWidget,
  useUpdateWidgetViewStatus,
} from "../../../../queries/dashboardQuries";

const CustomizeWidgetList = ({ closeCustomizedWidgetList }) => {
  const { data: widgetList, isLoading } = useFetchListOfWidget();
  const updateWidgetMutation = useUpdateWidgetViewStatus();

  const closeSidePanel = () => {
    closeCustomizedWidgetList();
  };

  const toggleWidget = (widget) => {
    const updatedViewStatus = Number(widget.view_status) === 1 ? 0 : 1;

    updateWidgetMutation.mutate(
      {
        widget_id: Number(widget?.widget_id),
        view_status: updatedViewStatus,
      },
      {
        onSuccess: (data) => {
          if (data?.status) {
            toast.success("Widget view status has been updated.");
          } else {
            toast.error("Unable to update widget, please check settings.");
          }
        },
        onError: () => {
          toast.error("Unable to update widget, please check settings.");
        },
      }
    );
  };

  return (
    <div className="relative h-full">
      <div
        onClick={closeSidePanel}
        className="fixed top-20 left-0 w-[70%] h-full bg-black opacity-40"
      ></div>
      <div
        className={`fixed top-20 right-0  w-[60%] sm:w-[50%] lg:w-[40%] xl:w-[30%] h-full bg-white border-l border-gray-400`}
      >
        <div className="flex flex-col h-full p-5 pl-10 text-black">
          <div className="flex justify-between items-center p-4 border-b border-b-gray-300 py-3">
            <h2 className="text-lg font-semibold text-center">
              Dashboard Widgets List
            </h2>
          </div>

          <div className="overflow-y-auto">
            <ul>
              <div className="flex justify-between top-30 items-center border-b border-b-gray-300 py-3">
                <h2 className="text-base font-medium text-center">
                  Customize Dashboard Widgets
                </h2>
              </div>
              {!widgetList?.data || widgetList?.data?.length === 0 ? (
                <li>
                  <span className="flex items-center py-2 text-gray-800">
                    No widget has been assigned!
                  </span>
                </li>
              ) : (
                widgetList?.data?.map((widget) => (
                  <li
                    key={widget.widget_id}
                    className="flex items-center py-1.5 px-3"
                  >
                    <span className="">{widget.widget_name}</span>
                    <div className="ml-auto">
                      <ToggleSwitch
                        checked={Number(widget.view_status) === 1}
                        getToggleState={() => toggleWidget(widget)}
                      />
                    </div>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <div
        className="absolute right-0 h-full w-1/2 bg-white"
        style={{ zIndex: 10 }}
      ></div>
    </div>
  );
};

export default CustomizeWidgetList;
