import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../../components/Avatar";
import { useSelector } from "react-redux";

const EmployeeHeader = ({ toggleCustomizingWidgets }) => {
  const userProfile = useSelector((state) => state.user.userProfile);
  return (
    <div
      className="bg-white px-6 py-4 flex items-center sticky top-[80px] 
    z-10"
    >
      <div className="flex items-center">
        <Avatar classes="mr-2" image={`${userProfile.photo}`} />
        <div>
          <p className="uppercase text-[10px]">Welcome Back</p>
          <h3 className="font-bold text-gray-800">
            <Link to="#">{`${userProfile?.first_name || ""} ${
              userProfile?.middle_name || ""
            } ${userProfile?.last_name || ""}`}</Link>
          </h3>
        </div>
      </div>
      <div className="ml-auto">
        <div className="ml-6">
          <button
            onClick={toggleCustomizingWidgets}
            className="bg-transparent border-none"
          >
            <i className="ico-grid"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHeader;
