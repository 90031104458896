import React, { useEffect, useState, useContext } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import { toast } from "react-toastify";
import LoadingButton from "../../components/Buttons/LoadingButton";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import MultipleLeaveActionPercentageBar from "../../components/Leaves/MultipleLeaveActionPercentageBar/MultipleLeaveActionPercentageBar";
import { closeIcon } from "../../Icons/Icons";
import Avatar from "../../components/Avatar/Avatar";
import formatDate from "../../utils/FormatDate/formatDate";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";
import userRoles from "../../config/userRoles";
import {
  LEAVE_APPROVAL_STATUS_ID,
  LEAVE_APPROVAL_STATUS_LABEL,
  LEAVE_MODAL_ACTIONS,
} from "../../Consts/consts";
import {
  useHrAdditionalLeaveApproval,
  useHrAdditionalLeaveDecline,
  useHrLeaveApproval,
  useHrLeaveDecline,
  useManagerAdditionalLeaveApproval,
  useManagerAdditionalLeaveDecline,
  useManagerLeaveApproval,
  useManagerLeaveDecline,
} from "../../queries/leaveQueries";
import { LeaveApprovalContext } from "./TaskLeaveApplications";
import { Alert } from "antd";
const LeaveApplicationActionModal = ({
  openModal,
  onCloseModal,
  leaveApplications,
  action,
  onSuccess,
}) => {
  const { isLeaveReqTab, isAdditionalLeaveReqTab } =
    useContext(LeaveApprovalContext);
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const isManager = userRoleAuth(user, userRoles.MANAGER);

  const [remark, setRemark] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [forceAction, setForceAction] = useState(false);

  const checkApplicationEligibility = (i) => {
    const hrApprovalStatusId = Number(i?.hr_approval_status_id);
    const hrApprovalStatusLabel = i?.hr_approval_status_label;
    const managerApprovalStatusId = Number(i?.manager_approval_status_id);
    const managerApprovalStatusLabel = i?.manager_approval_status_label;
    const isCanceled = i?.is_canceled;
    const isForceAction = i?.force_action;
    if (isHr) {
      const isHrApprovalPending =
        hrApprovalStatusId === LEAVE_APPROVAL_STATUS_ID.pending ||
        hrApprovalStatusLabel === LEAVE_APPROVAL_STATUS_LABEL.pending;
      const isManagerNotDeclined =
        managerApprovalStatusId !== LEAVE_APPROVAL_STATUS_ID.decline;
      const isEligible =
        !isCanceled && isHrApprovalPending && isManagerNotDeclined;
      
      return !!isEligible;
    } else if (isManager) {
      const isManagerApprovalPending =
        managerApprovalStatusId === LEAVE_APPROVAL_STATUS_ID.pending ||
        managerApprovalStatusLabel === LEAVE_APPROVAL_STATUS_LABEL.pending;
      const isHrNotDeclined =
        hrApprovalStatusId !== LEAVE_APPROVAL_STATUS_ID.decline;
      const isEligible =
        !isCanceled &&
        !isForceAction &&
        isManagerApprovalPending &&
        isHrNotDeclined;
      return !!isEligible;
    }
    return false;
  };

  const filterEligibleLeaveApplications = () => {
    // filter the eligible only
    let data = [];
    if (Array.isArray(leaveApplications)) {
      data = leaveApplications.filter((i) => checkApplicationEligibility(i));
    } else if (typeof leaveApplications === "object") {
      if (checkApplicationEligibility(leaveApplications)) {
        data = [leaveApplications];
      }
    }

    return data;
  };

  const leaveApplicationsData = filterEligibleLeaveApplications();
  const isSubmitBtnDisabled = leaveApplicationsData?.length === 0;
  const isMultipleAction = leaveApplicationsData?.length > 1;
  const isAnyManagerActionPending =
    isMultipleAction &&
    isHr &&
    leaveApplicationsData.find(
      (i) =>
        Number(i?.manager_approval_status_id) ===
        LEAVE_APPROVAL_STATUS_ID.pending
    );

  const isApproval = action === LEAVE_MODAL_ACTIONS.approve;
  const isDecline = action === LEAVE_MODAL_ACTIONS.decline;
  const isRevoke = action === LEAVE_MODAL_ACTIONS.revoke;
  const submitBtnTitle = isApproval
    ? "Approve Leave"
    : isDecline
    ? "Decline Leave"
    : isRevoke
    ? "Revoke Leave"
    : "";

  const handleCloseModal = () => {
    setErrMsg("");
    setRemark("");
    onCloseModal();
  };

  const handleOnSuccess = () => {
    if (typeof onSuccess === "function") onSuccess();
    handleCloseModal();
  };

  // hractions
  const {
    mutateAsync: mutateAsyncHrLeaveApproval,
    isPending: isPendingHrLeaveApproval,
  } = useHrLeaveApproval();
  const {
    mutateAsync: mutateAsyncHrLeaveDecline,
    isPending: isPendingHrLeaveDecline,
  } = useHrLeaveDecline();
  const {
    mutate: mutateHrAdditionalLeaveApproval,
    isPending: isPendingHrAdditionalLeaveApproval,
  } = useHrAdditionalLeaveApproval();
  const {
    mutate: mutateHrAdditionalLeaveDecline,
    isPending: isPendingHrAdditionalLeaveDecline,
  } = useHrAdditionalLeaveDecline();

  // manager actions
  const {
    mutateAsync: mutateAsyncManagerLeaveApproval,
    isPending: isPendingManagerLeaveApproval,
  } = useManagerLeaveApproval();

  const {
    mutateAsync: mutateAsyncManagerLeaveDecline,
    isPending: isPendingManagerLeaveDecline,
  } = useManagerLeaveDecline();
  const {
    mutate: mutateManagerAdditionalLeaveApproval,
    isPending: isPendingManagerAdditionalLeaveApproval,
  } = useManagerAdditionalLeaveApproval();
  const {
    mutate: mutateManagerAdditionalLeaveDecline,
    isPending: isPendingManagerAdditionalLeaveDecline,
  } = useManagerAdditionalLeaveDecline();

  const isPending =
    isPendingHrAdditionalLeaveApproval ||
    isPendingHrLeaveApproval ||
    isPendingHrLeaveDecline ||
    isPendingHrAdditionalLeaveDecline ||
    isPendingManagerAdditionalLeaveApproval ||
    isPendingManagerAdditionalLeaveDecline ||
    isPendingManagerLeaveApproval ||
    isPendingManagerLeaveDecline;

  const hrLeaveApproval = async (payload) => {
    const response = await mutateAsyncHrLeaveApproval(payload);
    if (isMultipleAction) {
      const approvedCount = response.reduce((prev, curr) => {
        if (curr?.status) {
          return prev + 1;
        }
        return prev;
      }, 0);

      toast.info(
        `${approvedCount} out of ${leaveApplicationsData.length} leave applications have been approved`
      );
      handleOnSuccess();
    } else {
      const data = response?.[0];
      if (data?.status) {
        toast.success(data.message);
        handleOnSuccess();
      } else {
        setErrMsg(data?.message);
        if (data?.manager_action_pending) {
          setForceAction(true);
        }
      }
    }
  };
  const hrAdditionalLeaveApproval = (payload) => {
    mutateHrAdditionalLeaveApproval(payload, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data?.message);
          handleOnSuccess();
        } else {
          setErrMsg(
            data?.message ||
              "Something went wrong while approving additional leave application"
          );
        }
      },
    });
  };

  // both leave and addiitonal leave
  const handleHrLeaveAction = async () => {
    let payload;
    const forceApprove = isMultipleAction ? true : forceAction;
    if (isLeaveReqTab) {
      payload = leaveApplicationsData.map((i) => ({
        leave_application_id: i.leave_application_id,
        hr_remarks: remark,
        [isApproval ? "force_approve" : isDecline ? "force_reject" : ""]:
          forceApprove,
      }));
    } else if (isAdditionalLeaveReqTab) {
      payload = {
        additional_leave_id:
          leaveApplicationsData?.[0]?.additional_leave_application_id,
        hr_comments: remark,
        force_approval: forceApprove,
      };
    }
    if (!payload) {
      return;
    }

    if (isApproval) {
      if (isLeaveReqTab) {
        hrLeaveApproval(payload);
      } else if (isAdditionalLeaveReqTab) {
        hrAdditionalLeaveApproval(payload);
      }
    } else if (isDecline) {
      if (isLeaveReqTab) {
        hrLeaveReject(payload);
      } else if (isAdditionalLeaveReqTab) {
        hrAdditionalLeaveReject(payload);
      }
    }
  };

  const hrLeaveReject = async (payload) => {
    const response = await mutateAsyncHrLeaveDecline(payload);
    if (isMultipleAction) {
      const approvedCount = response.reduce((prev, curr) => {
        if (curr?.status) {
          return prev + 1;
        }
        return prev;
      }, 0);

      toast.info(
        `${approvedCount} out of ${leaveApplicationsData.length} leave applications have been declined`
      );
      handleOnSuccess();
    } else {
      const data = response?.[0];
      if (data?.status) {
        toast.success(data?.message);
        handleOnSuccess();
      } else {
        setErrMsg(data?.message);
        if (data?.manager_action_pending) {
          setForceAction(true);
        }
      }
    }
  };

  const hrAdditionalLeaveReject = (payload) => {
    mutateHrAdditionalLeaveDecline(payload, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data?.message);
          handleOnSuccess();
        } else {
          setErrMsg(
            data?.message ||
              "Something went wrong while rejecting additional leave application"
          );
        }
      },
    });
  };

  const managerLeaveApproval = async (payload) => {
    const response = await mutateAsyncManagerLeaveApproval(payload);
    if (isMultipleAction) {
      const approvedCount = response.reduce((prev, curr) => {
        if (curr?.status) {
          return prev + 1;
        }
        return prev;
      }, 0);

      toast.info(
        `${approvedCount} out of ${leaveApplicationsData.length} leave applications have been approved`
      );
      handleOnSuccess();
    } else {
      const data = response?.[0];
      if (data?.status) {
        toast.success(data?.message);
        handleOnSuccess();
      } else {
        setErrMsg(data?.message);
        if (data?.manager_action_pending) {
          setForceAction(true);
        }
      }
    }
  };
  const managerAdditionalLeaveApproval = async (payload) => {
    mutateManagerAdditionalLeaveApproval(payload, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data?.message);
          handleOnSuccess();
        } else {
          setErrMsg(
            data?.message ||
              "Something went wrong while approving additional leave application"
          );
        }
      },
    });
  };

  const managerLeaveDecline = async (payload) => {
    const response = await mutateAsyncManagerLeaveDecline(payload);
    if (isMultipleAction) {
      const approvedCount = response.reduce((prev, curr) => {
        if (curr?.status) {
          return prev + 1;
        }
        return prev;
      }, 0);

      toast.info(
        `${approvedCount} out of ${leaveApplicationsData.length} leave applications have been declined`
      );
      handleOnSuccess();
    } else {
      const data = response?.[0];
      if (data?.status) {
        toast.success(data?.message);
        handleOnSuccess();
      } else {
        setErrMsg(data?.message);
      }
    }
  };
  const managerAdditionalLeaveDecline = (payload) => {
    mutateManagerAdditionalLeaveDecline(payload, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data?.message);
          handleOnSuccess();
        } else {
          setErrMsg(
            data?.message ||
              "Something went wrong while rejecting additional leave application"
          );
        }
      },
    });
  };

  const handleManagerLeaveAction = () => {
    let payload;
    if (isLeaveReqTab) {
      payload = leaveApplicationsData.map((i) => ({
        leave_application_id: i.leave_application_id,
        remark,
      }));
    } else if (isAdditionalLeaveReqTab) {
      payload = {
        additional_leave_id:
          leaveApplicationsData?.[0].additional_leave_application_id,
        manager_comments: remark,
      };
    }
    if (!payload) {
      return;
    }

    if (isApproval) {
      if (isLeaveReqTab) {
        managerLeaveApproval(payload);
      } else if (isAdditionalLeaveReqTab) {
        managerAdditionalLeaveApproval(payload);
      }
    } else if (isDecline) {
      if (isLeaveReqTab) {
        managerLeaveDecline(payload);
      } else if (isAdditionalLeaveReqTab) {
        managerAdditionalLeaveDecline(payload);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg("");
    if (!remark) {
      setErrMsg("Fill out the required field");
      return;
    }
    if (
      !Array.isArray(leaveApplicationsData) &&
      !leaveApplicationsData.length > 0
    ) {
      setErrMsg(
        `The selected leave applications are not eligible for ${action}.`
      );
      return;
    }

    if (isHr) {
      handleHrLeaveAction();
    } else if (isManager) {
      handleManagerLeaveAction();
    } else {
      setErrMsg("You do not have permission to perform this action.");
    }
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal} center closeIcon=" ">
        <div className="p-5 min-w-[520px] text-black">
          <>
            <form onSubmit={handleSubmit}>
              <div className="flex pb-3 justify-between items-center">
                <h2 className="text-lg font-semibold">{submitBtnTitle}</h2>
                <button type="button" onClick={handleCloseModal}>
                  {closeIcon}
                </button>
              </div>
              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2">
                {errMsg}
              </span>

              <div className="max-h-[350px] overflow-y-auto px-5 mb-7">
                {Array.isArray(leaveApplicationsData) &&
                leaveApplicationsData.length > 0 ? (
                  leaveApplicationsData.map((leave, index) => {
                    if (!leave) {
                      return;
                    }
                    const managerApprovalStatusId = Number(
                      leave?.manager_approval_status_id
                    );
                    const managerApprovalStatusLabel =
                      leave?.manager_approval_status_label;
                    const isManagerActionPending =
                      isHr &&
                      (managerApprovalStatusId ===
                        LEAVE_APPROVAL_STATUS_ID.pending ||
                        managerApprovalStatusLabel ===
                          LEAVE_APPROVAL_STATUS_LABEL.pending);

                    const avatarTitle = formatAvatarLetter(
                      `${leave?.first_name} ${leave?.last_name}`
                    );
                    const applicationDate = formatDate(
                      leave?.application_date
                    )?.dateString2;
                    const fromDate = formatDate(leave?.from_date).dateString2;
                    const toDate = formatDate(leave?.to_date).dateString2;
                    const leaveDuration = formatLeaveDuration({
                      durationInDay: leave?.duration_in_day,
                      durationInHour: leave?.duration_in_hour,
                    });
                    const availableBalance = formatLeaveDuration({
                      durationInDay: leave?.leave_balance_days,
                      durationInHour: leave?.leave_balance_hours,
                    });
                    const leaveSerial = leave?.leave_serial;
                    const appliedForDate = formatDate(
                      leave?.applied_for_date
                    ).dateString2;
                    const employeeName = `${leave?.first_name || ""} ${leave?.last_name || ""}`
                    return (
                      <div
                        key={`${index}_employee_leave_application_list`}
                        className="flex items-center justify-between max-h-[110px] gap-x-10 border-b py-3"
                      >
                        <div className="flex flex-col p-2 gap-1">
                          <div className=" flex p-2 h-10 gap-2 items-center rounded-3xl  bg-gray-100">
                            <Avatar
                              title={avatarTitle}
                              width={"6"}
                              height={"6"}
                            />
                            <div>
                              <p className="text-xs font-medium whitespace-nowrap w-full">{employeeName}</p>
                              <p className="text-xs whitespace-nowrap w-full">
                                {leaveSerial}
                              </p>
                            </div>
                          </div>
                          {isManagerActionPending && (
                            <span className="status">
                              Manager action pending
                            </span>
                          )}
                        </div>
                        <div className="flex justify-end">
                          <div>
                            <p className="text-end font-medium">
                              {leave.leave_name}
                            </p>
                            {isAdditionalLeaveReqTab && (
                              <span className="text-xs text-gray-500 whitespace-nowrap">
                                Applied for: {appliedForDate}
                              </span>
                            )}
                            {isLeaveReqTab && (
                              <span className="text-xs text-gray-500 whitespace-nowrap">
                                {fromDate} - {toDate} ({leaveDuration})
                              </span>
                            )}
                            <br />
                            <span className="text-xs text-gray-500 whitespace-nowrap">
                              Leave applied on: {applicationDate}
                            </span>
                            <br />
                            {isLeaveReqTab && (
                              <span className="text-xs text-gray-500 whitespace-nowrap">
                                Available leave balance: {availableBalance}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Alert
                    message={`The selected leave application(s) are not eligible for ${action}.`}
                    type="warning"
                    showIcon
                  />
                )}
              </div>
              {isAnyManagerActionPending && (
                <Alert
                  className="my-3"
                  message={
                    <span>
                      {`Some of the leave applications have pending manager actions.`}{" "}
                      <br /> {`If you proceed with the ${action},`}
                      <br />
                      {`it will be considered as a force ${action} for those applications.`}
                    </span>
                  }
                  type="info"
                  showIcon
                />
              )}
              {leaveApplicationsData.length ? (
                <textarea
                  name="noteContent"
                  value={remark}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\s/.test(value)) {
                      setErrMsg("Note cannot start with a space");
                    } else {
                      setErrMsg("");
                      setRemark(value.trim());
                    }
                  }}
                />
              ) : null}

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>

                {isPending ? (
                  <LoadingButton
                    bg={`${
                      isApproval ? "btn--green" : isDecline ? "btn--red" : ""
                    }`}
                    title={`${
                      isApproval
                        ? "Approving Leave Application"
                        : isDecline
                        ? "Rejecting Leave Application"
                        : isRevoke
                        ? "Revoking Leave Application"
                        : ""
                    }`}
                  />
                ) : submitBtnTitle ? (
                  <button
                    type="submit"
                    className={`btn ${
                      isApproval ? "btn--green" : isDecline ? "btn--red" : ""
                    } text-sm font-medium ${
                      isSubmitBtnDisabled ? "opacity-50" : ""
                    }`}
                    disabled={isSubmitBtnDisabled}
                  >
                    {forceAction ? `Force ${submitBtnTitle}` : submitBtnTitle}
                  </button>
                ) : null}
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default LeaveApplicationActionModal;
