import React from "react";
import Avatar from "../../Avatar/Avatar";

const AssetReturnList = (props) => {
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td class="px-10 py-4">
        <input type="checkbox" />
      </td>
      <td scope="row" class="px-10 py-4 whitespace-nowrap">
        {props.requestdata.id}
      </td>
      <td class="px-10 py-4 flex items-center gap-3 border-0">
        <Avatar image={props.requestdata.image} />
        {props.requestdata.employee_name}
      </td>
      <td class="px-10 py-4">{props.requestdata.assigned_asset}</td>
      <td class="px-10 py-4">{props.requestdata.return}</td>
      <td class="px-10 py-4">{props.requestdata.returned}</td>
      <td class="px-10 py-4 text-right">
        <i className="ico-more text-gray-500 cursor-pointer"></i>
      </td>
    </tr>
  );
};

export default AssetReturnList;
