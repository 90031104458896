import React from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
const NotificationSlidingPanel = ({ open, close }) => {
  return (
    <>
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="480px">
        <div className="flex justify-between border-b pb-3 px-5">
          <h2>Notifications</h2>
          <button>{closeIcon}</button>
        </div>
        <div className="flex justify-between px-5 py-3 items-center">
          <div>
            <Select placeholder="All Notifications" />
          </div>
          <div>
            <p className="font-medium text-blue-600">Mark all as read</p>
          </div>
        </div>
        <div className="px-5 flex">
            No Notification yet... image will be here
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default NotificationSlidingPanel;
