import React from "react";
import SeperationListData from "./SeperationListData";

const seperationListData = [
  {
    id: "798",
    image: "/images/avatar.jpg",
    employee_name: "Jane Cooper",
    reason: "Retire",
    request_date: "27 Apr 2022",
    label: "Pending",
    manager_status: "Pending",
    off_boarding_status: "Incomplete",
    seperation_status: "Pending"
  },
  {
    id: "799",
    image: "/images/avatar.jpg",
    employee_name: "Courtney Henry",
    reason: "Resign",
    request_date: "27 Apr 2022",
    label: "Declined",
    manager_status: "Pending",
    off_boarding_status: "Add to Off-Boarding",
    seperation_status: "Pending"
  },
  {
    id: "898",
    image: "/images/avatar.jpg",
    employee_name: "Jane Cooper",
    reason: "Contract Expiry",
    request_date: "27 Apr 2022",
    label: "Pending",
    manager_status: "Pending",
    off_boarding_status: "Completed",
    seperation_status: "Pending"
  },
  {
    id: "508",
    image: "/images/avatar.jpg",
    employee_name: "Jane Cooper",
    reason: "Termination",
    request_date: "27 Apr 2022",
    label: "Approved",
    manager_status: "Approved",
    off_boarding_status: "Completed",
    seperation_status: "Terminated"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    employee_name: "Jane Cooper",
    reason: "Retire",
    request_date: "27 Apr 2022",
    label: "Pending",
    manager_status: "Pending",
    off_boarding_status: "Incomplete",
    seperation_status: "Pending"
  }
];

const SeperationList = () => {
  return (
    <div class="relative">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <div className="flex items-center">
                <input type="checkbox" className="mr-3" />
                ID
              </div>
            </th>
            <th scope="col" class="px-10 py-3">
              Employee Name
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              REASON
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              REQUEST DATE
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              HR STATUS
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              MANAGER STATUS
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              OFF-BOARDING Status
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              SEPARATION STATUS
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right cursor-pointer">
              <i className="ico-grid text-blue-600"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {seperationListData.map((val, key) => {
            return <SeperationListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SeperationList;
