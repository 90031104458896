import React, { useState } from "react";
import EditHolidayPolicyModal from "./EditHolidayPolicyModal";

const HolidayPolicyListData = ({ policy, makeApiCall }) => {
  const [editHolidayPolicyModal, setEditHolidayPolicyModal] = useState(false);

  return (
    <>
      <EditHolidayPolicyModal
        open={editHolidayPolicyModal}
        close={() => setEditHolidayPolicyModal(false)}
        policy={policy}
        makeApiCall={makeApiCall}
      />
      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td
          scope="row"
          classNameName="px-10 py-4 font-medium text-gray-800 whitespace-nowrap"
        >
          {policy?.policy_name}
        </td>

        <td className="px-2 py-4 font-medium text-gray-800 text-center"></td>

        <td className="px-7 py-4"></td>
        <td className="py-4 px-7 flex  justify-end gap-5 border-none ">
          <i
            className="ico-edit2 text-xl cursor-pointer"
            onClick={() => setEditHolidayPolicyModal(true)}
          ></i>
          {/* <i className="ico-delete text-4xl text-red-600 font-medium cursor-pointer" onClick={() => setIsDeleteLeaveTypeModal(true)}></i> */}
        </td>
      </tr>
    </>
  );
};

export default HolidayPolicyListData;
