import React from "react";

const AddWidgetCard = ({ onAddWigetClick }) => {
  return (
    <div className="border rounded-md p-3 w-full h-full flex justify-center items-center">
      <div className="bg-500 text-gray rounded-md px-4 py-2 mt-4 hover:bg-600 text-center">
        <button onClick={onAddWigetClick}>
          <i className="ico-plus text-xl text-gray-600 "></i>
        </button>
        <p className="">Add widget</p>
      </div>
    </div>
  );
};

export default AddWidgetCard;
