import React, { useEffect, useState } from "react";
import HolidayPolicyListData from "./HolidayPolicyListData";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import EmptyState from "../../../../EmptyState/EmptyState";
import PageLoader from "../../../../PageLoader/PageLoader";

const HolidayPolicyListTable = ({ refreshBtn, fetchHolidayPolicy }) => {
  const axiosPrivate = useAxiosPrivate();
  const [holidayPolicyList, setHolidayPolicyList] = useState(null);
  const [component, setComponent] = useState(<PageLoader />);

  const fetchHolidayPolicyList = async () => {
    setHolidayPolicyList(null);
    setComponent(<PageLoader />);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listHolidayPolicy`)
      .then((response) => {
        // isMounted && console.log(response.data, "holiday policy list");
        const res = response?.data;
        if (res.status) {
          setTimeout(() => {
            setHolidayPolicyList(res.policy_list);
            setComponent(null);
          }, 500);
        } else {
          setHolidayPolicyList(null);
          setComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        console.error(err);
        setHolidayPolicyList(null);
        setComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    fetchHolidayPolicyList();
  }, [refreshBtn, fetchHolidayPolicy]);

  return (
    <>
      <div class="relative">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
          <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              <th scope="col" class="px-10 py-3">
                <div className="flex items-center">
                  Holiday Policy
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </div>
              </th>
              <th scope="col" class="px-10 py-3"></th>
              <th scope="col" class="px-10 py-3"></th>
              <th scope="col" class="px-10 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {holidayPolicyList &&
              holidayPolicyList.map((item) => (
                <HolidayPolicyListData
                  policy={item}
                  makeApiCall={() => fetchHolidayPolicyList()}
                />
              ))}
          </tbody>
        </table>
        {component}
      </div>
    </>
  );
};

export default HolidayPolicyListTable;
