import React from "react";

const StarterHeader = () => {
  return (
    <header className="bg-white p-5 md:px-10 border-b border-gray-200 sticky top-0 z-50">
      <div className="flex items-center">
        <h1>
          <img src="/images/logo.svg" alt="" />
        </h1>
      </div>
    </header>
  );
};

export default StarterHeader;
