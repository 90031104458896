import React, { useState } from "react";
import Select, { components } from "react-select";
import Search from "../../../../components/Header/Search/Search";
import SeperationList from "./SeperationList";
import NewSeperationModal from "./NewSeperationModal"

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const Seperation = () => {
  const [isSeperationModal, setIsSeperationModal] = useState(false);

  return (
    <>
    <NewSeperationModal
    openModal={isSeperationModal}
    onCloseModal={() => setIsSeperationModal(false)}
    />
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Offer Lists</h2>
          <p>Manage all employe on your organization</p>
        </div>
        <div>
          <button type="button" className="btn text-[14px]" onClick={() => setIsSeperationModal(true)}>
            <span>
              <i className="ico-plus mr-1"></i>
            </span>
            New Separation
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center px-10 pb-4">
        <div className="flex gap-3">
          <input
            type="date"
            placeholder="Select a date"
            className="appearance-none bg-transparent border border-gray-300 rounded w-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-600"
          />

          <Select
            options={[
              { value: "Pending", label: "Pending" },
              { value: "Declined", label: "Declined" },
              { value: "Approved", label: "Approved" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "HR Status",
              label: "HR Status"
            }}
          />
          <Select
            options={[
              { value: "Pending", label: "Pending" },
              { value: "Declined", label: "Declined" },
              { value: "Approved", label: "Approved" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Manager Status",
              label: "Manager Status"
            }}
          />
          <Select
            options={[
              { value: "Incomplete", label: "Incomplete" },
              { value: "Add to Off-Boarding", label: "Add to Off-Boarding" },
              { value: "Completed", label: "Completed" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Off-Boarding Status",
              label: "Off-Boarding Status"
            }}
          />
          <Select
            options={[
              { value: "Pending", label: "Pending" },
              { value: "Terminated", label: "Terminated" },
              { value: "Contract Expired", label: "Contract Expired" },
              { value: "Retired", label: "Retired" },
              { value: "Resigned", label: "Resigned" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Separation Status",
              label: "Separation Status"
            }}
          />
        </div>
        <div className="flex">
          <div>
            <Search />
          </div>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <SeperationList />
    </div>
    </>
  );
};

export default Seperation;
