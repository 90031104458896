import React, { useEffect } from "react";
import Birthday from "../../Dashboard/EmployeePortal/Birthday";
import { Chart } from "chart.js";
import NewJoiner from "../../../components/Employee/NewJoiner";
import PendingConfirmation from "../../../components/Employee/PendingConfirmation";

const Overview = () => {
  useEffect(() => {
    const ctx = document.getElementById("pieChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["", "", ""],
        datasets: [
          {
            label: "# of Votes",
            data: [35, 25, 10, 10, 10, 10],
            backgroundColor: [
              "#3466E7",
              "#4875E9",
              "#789BF6",
              "#9AB3F3",
              "#D6E0FA",
              "#EBF0FD"
            ],
            borderWidth: 0
          }
        ]
      },
      options: {
        scales: {},
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
    return () => {
      myChart.destroy();
    };
  });

  useEffect(() => {
    const ctx = document.getElementById("ChartAttendance").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["2001", "2002", "2003", "2004", "2005", "2006"],
        datasets: [
          {
            label: "blue",
            backgroundColor: "#4875E9",
            data: [3, 7, 4, 4, 2, 6, 5]
          },
          {
            label: "red",
            backgroundColor: "#EF4444",
            data: [4, 3, 5, 5, 7, 3, 5]
          }
        ]
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: function (value, index, ticks) {
                return value;
              }
            },
            grid: {
              drawBorder: false,
              lineWidth: 0.5
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
    return () => {
      myChart.destroy();
    };
  });

  return (
    <div className="bg-gray-50 w-full">
      <div className="flex-1 px-10 pt-8 pb-6 border-b border-gray-200 max-w-[1200px] m-auto">
        <div className="mb-6">
          <h2 className="pb-1">Overview</h2>
          <p>
            Get an overview of the day-to-day activities related to your
            employees from the Overview page. You can find information about new
            joiners or updates such as Confirmation, Joining, or Resignation.
          </p>
        </div>
        <div className="flex justify-between items-center">
          <div className="bg-white w-[24%] flex items-center p-4 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6]">
            {/* <i className="ico-people text-blue-800 bg-blue-100 rounded-full p-2"></i> */}
            <div className="pl-4">
              <p className="text-gray-800 font-semibold">560</p>
              <p>Total Headcount</p>
            </div>
          </div>
          <div className="bg-white w-[24%] flex items-center p-4 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6]">
            <div className="pl-4">
              <p className="text-gray-800 font-semibold">560</p>
              <p>Active Employee</p>
            </div>
          </div>
          <div className="bg-white w-[24%] flex items-center p-4 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6]">
            <div className="pl-4">
              <p className="text-gray-800 font-semibold">24</p>
              <p>Onboarding</p>
            </div>
          </div>
          <div className="bg-white w-[24%] flex items-center p-4 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6]">
            <div className="pl-4">
              <p className="text-gray-800 font-semibold">160</p>
              <p>Offboarding</p>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-6 py-6">
          <div className="card w-2/4">
            <div className="px-4 py-2 flex items-center justify-between">
              <h2>Employee Headcount</h2>
              <div className="flex items-center">
                <span className="flex items-center px-3 text-gray-500 text-sm font-medium py-1 border border-gray-200 mr-3 cursor-pointer rounded-md">
                  Yearly <i className="ico-down ml-2 text-[#4875E9]"></i>
                </span>
              </div>
            </div>
            <div className="px-4">
              <div className="mx-auto my-3 h-[270px]">
                <canvas id="ChartAttendance" width="200" height="100"></canvas>
              </div>
              <div className=" flex justify-center">
                <div className="flex items-center mr-3">
                  <span className="h-3 w-5 rounded-[20px] bg-[#4875E9] mr-3"></span>
                  <p>Active</p>
                </div>
                <div className="flex items-center mr-3">
                  <span className="h-3 w-5 rounded-[20px] bg-[#EF4444] mr-3"></span>
                  <p>Exit</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card w-2/4">
            <div className="px-4 py-2 flex items-center justify-between">
              <h2>By Category</h2>
              <div className="flex items-center">
                <span className="flex items-center px-3 text-gray-500 text-sm font-medium py-1 border border-gray-200 mr-3 cursor-pointer rounded-md">
                  Yearly <i className="ico-down ml-2 text-[#4875E9]"></i>
                </span>
              </div>
            </div>
            <div className="flex items-center justify-evenly">
              <div className="pt-9">
                <canvas
                  id="pieChart"
                  className="max-w-[200px] max-h-[200px]"
                ></canvas>
              </div>

              <div className="flex gap-12 items-center">
                <ul className="font-medium text-[#1F2937]">
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#EBF0FD]"></div>
                    <p className="">IT</p>
                  </li>
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#D6E0FA]"></div>
                    <p className="">Marketing</p>
                  </li>
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#C2D1F8]"></div>
                    <p className="">HR</p>
                  </li>
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#9AB3F3]"></div>
                    <p className="">Operations</p>
                  </li>
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#4875E9]"></div>
                    <p className="">Sales</p>
                  </li>
                  <li className="flex items-center gap-3 pb-3">
                    <div className="w-[12px] h-[12px] rounded-full bg-[#3466E7]"></div>
                    <p className="">Finance</p>
                  </li>
                </ul>
                <ul className="text-[#374151] font-normal">
                  <li className="pb-3">10</li>
                  <li className="pb-3">10</li>
                  <li className="pb-3">10</li>
                  <li className="pb-3">10</li>
                  <li className="pb-3">25</li>
                  <li className="pb-3">35</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="md:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 w-full">
          <div>
            <Birthday />
          </div>
          <div>
            <NewJoiner />
          </div>
          <div>
            <PendingConfirmation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
