export const sortIcon = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M3.00156 7.59844C3.00156 8.04027 3.35973 8.39844 3.80156 8.39844C4.24339 8.39844 4.60156 8.04027 4.60156 7.59844L4.60156 3.12981L5.63588 4.16412C5.9483 4.47654 6.45483 4.47654 6.76725 4.16412C7.07967 3.8517 7.07967 3.34517 6.76725 3.03275L4.36725 0.632752C4.21722 0.482723 4.01374 0.398438 3.80156 0.398438C3.58939 0.398438 3.38591 0.482723 3.23588 0.632752L0.835877 3.03275C0.523458 3.34517 0.523458 3.8517 0.835877 4.16412C1.1483 4.47654 1.65483 4.47654 1.96725 4.16412L3.00156 3.12981L3.00156 7.59844Z"
      fill="#6B7280"
    />
    <path
      d="M11.0016 4.39844C11.0016 3.95661 10.6434 3.59844 10.2016 3.59844C9.75974 3.59844 9.40156 3.95661 9.40156 4.39844V8.86707L8.36725 7.83275C8.05483 7.52033 7.5483 7.52033 7.23588 7.83275C6.92346 8.14517 6.92346 8.6517 7.23588 8.96412L9.63588 11.3641C9.78591 11.5142 9.98939 11.5984 10.2016 11.5984C10.4137 11.5984 10.6172 11.5142 10.7672 11.3641L13.1672 8.96412C13.4797 8.6517 13.4797 8.14517 13.1672 7.83275C12.8548 7.52033 12.3483 7.52033 12.0359 7.83275L11.0016 8.86707L11.0016 4.39844Z"
      fill="#6B7280"
    />
  </svg>
);
export const viewIcon = (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M11.4983 7.0013C11.4983 8.38201 10.379 9.5013 8.99833 9.5013C7.61762 9.5013 6.49833 8.38201 6.49833 7.0013C6.49833 5.62059 7.61762 4.5013 8.99833 4.5013C10.379 4.5013 11.4983 5.62059 11.4983 7.0013Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.04688 7.00127C2.10877 3.62037 5.26734 1.16797 8.9987 1.16797C12.7301 1.16797 15.8887 3.6204 16.9505 7.00133C15.8886 10.3822 12.7301 12.8346 8.99872 12.8346C5.26734 12.8346 2.10875 10.3822 1.04688 7.00127Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const selectIcon = (
  <svg
    width="16"
    height="4"
    viewBox="0 0 16 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M2.9987 2.0013C2.9987 2.46154 2.6256 2.83464 2.16536 2.83464C1.70513 2.83464 1.33203 2.46154 1.33203 2.0013C1.33203 1.54106 1.70513 1.16797 2.16536 1.16797C2.6256 1.16797 2.9987 1.54106 2.9987 2.0013Z"
      fill="#6B7280"
    />
    <path
      d="M8.83203 2.0013C8.83203 2.46154 8.45893 2.83464 7.9987 2.83464C7.53846 2.83464 7.16536 2.46154 7.16536 2.0013C7.16536 1.54106 7.53846 1.16797 7.9987 1.16797C8.45893 1.16797 8.83203 1.54106 8.83203 2.0013Z"
      fill="#6B7280"
    />
    <path
      d="M14.6654 2.0013C14.6654 2.46154 14.2923 2.83464 13.832 2.83464C13.3718 2.83464 12.9987 2.46154 12.9987 2.0013C12.9987 1.54106 13.3718 1.16797 13.832 1.16797C14.2923 1.16797 14.6654 1.54106 14.6654 2.0013Z"
      fill="#6B7280"
    />
    <path
      d="M2.16536 2.0013H2.1737M7.9987 2.0013H8.00703M13.832 2.0013H13.8404M2.9987 2.0013C2.9987 2.46154 2.6256 2.83464 2.16536 2.83464C1.70513 2.83464 1.33203 2.46154 1.33203 2.0013C1.33203 1.54106 1.70513 1.16797 2.16536 1.16797C2.6256 1.16797 2.9987 1.54106 2.9987 2.0013ZM8.83203 2.0013C8.83203 2.46154 8.45893 2.83464 7.9987 2.83464C7.53846 2.83464 7.16536 2.46154 7.16536 2.0013C7.16536 1.54106 7.53846 1.16797 7.9987 1.16797C8.45893 1.16797 8.83203 1.54106 8.83203 2.0013ZM14.6654 2.0013C14.6654 2.46154 14.2923 2.83464 13.832 2.83464C13.3718 2.83464 12.9987 2.46154 12.9987 2.0013C12.9987 1.54106 13.3718 1.16797 13.832 1.16797C14.2923 1.16797 14.6654 1.54106 14.6654 2.0013Z"
      stroke="#1F2937"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const titleIcon = (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0H5Z"
      fill="#1F2937"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.89543 0.895431 1 2 1C2 2.65685 3.34315 4 5 4H7C8.65685 4 10 2.65685 10 1C11.1046 1 12 1.89543 12 3V14C12 15.1046 11.1046 16 10 16H2C0.895431 16 0 15.1046 0 14V3ZM3 7C2.44772 7 2 7.44772 2 8C2 8.55229 2.44772 9 3 9H3.01C3.56228 9 4.01 8.55229 4.01 8C4.01 7.44772 3.56228 7 3.01 7H3ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55229 5.44772 9 6 9H9C9.55228 9 10 8.55229 10 8C10 7.44772 9.55228 7 9 7H6ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H3.01C3.56228 13 4.01 12.5523 4.01 12C4.01 11.4477 3.56228 11 3.01 11H3ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H9C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11H6Z"
      fill="#1F2937"
    />
  </svg>
);
export const taskCompleteIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M6.5 9L8.16667 10.6667L11.5 7.33333M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
      stroke="#34D399"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const taskPendingIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" />
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#D1D5DB" />
  </svg>
);
export const addIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9987 1.33203V14.6654M14.6654 7.9987L1.33203 7.9987"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const priorityMediumIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <circle cx="4" cy="4" r="4" fill="#FBBF24" />
  </svg>
);

export const priorityHighIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <circle cx="4" cy="4" r="4" fill="#F87171" />
  </svg>
);
export const priorityLowIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <circle cx="4" cy="4" r="4" fill="#60A5FA" />
  </svg>
);
export const closeIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
      fill="#065F46"
    />
  </svg>
);
export const personRoundedIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M3.12104 15.8037C5.15267 14.6554 7.4998 14 10 14C12.5002 14 14.8473 14.6554 16.879 15.8037M13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="#1E40AF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const personOutlinedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5 inline"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
    />
  </svg>
);
export const bagIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M19 12.2554C16.2207 13.3805 13.1827 14 10 14C6.8173 14 3.7793 13.3805 1 12.2554M14 5V3C14 1.89543 13.1046 1 12 1H8C6.89543 1 6 1.89543 6 3V5M10 11H10.01M3 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const homeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M1 10L3 8M3 8L10 1L17 8M3 8V18C3 18.5523 3.44772 19 4 19H7M17 8L19 10M17 8V18C17 18.5523 16.5523 19 16 19H13M7 19C7.55228 19 8 18.5523 8 18V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V18C12 18.5523 12.4477 19 13 19M7 19H13"
      stroke="#1E40AF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const securityIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M7 10.001L9 12.001L13 8.00099M18.6179 3.98531C18.4132 3.99569 18.2072 4.00095 18 4.00095C14.9265 4.00095 12.123 2.84551 9.99993 0.945312C7.87691 2.84544 5.07339 4.00083 2 4.00083C1.79277 4.00083 1.58678 3.99557 1.38213 3.98519C1.1327 4.94881 1 5.9594 1 7.00099C1 12.5925 4.82432 17.2908 10 18.6229C15.1757 17.2908 19 12.5925 19 7.00099C19 5.95944 18.8673 4.94889 18.6179 3.98531Z"
      stroke="#1E40AF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const officeIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M17 19V3C17 1.89543 16.1046 1 15 1H5C3.89543 1 3 1.89543 3 3V19M17 19L19 19M17 19H12M3 19L1 19M3 19H8M7 4.99998H8M7 8.99998H8M12 4.99998H13M12 8.99998H13M8 19V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V19M8 19H12"
      stroke="#1E40AF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const educationIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M10 11L19 6L10 1L1 6L10 11ZM10 11L16.1591 7.57826C16.7017 8.94659 17 10.4384 17 11.9999C17 12.7013 16.9398 13.3885 16.8244 14.0569C14.2143 14.3106 11.849 15.4006 10 17.0555C8.15096 15.4006 5.78571 14.3106 3.17562 14.0569C3.06017 13.3885 3 12.7012 3 11.9999C3 10.4384 3.29824 8.94656 3.84088 7.57825L10 11ZM6 16.9999V9.5L10 7.27778"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const mailIcon = (
  <svg
    className="inline"
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 3.66797L8.0755 8.05164C8.63533 8.42486 9.36467 8.42486 9.9245 8.05164L16.5 3.66797M3.16667 12.8346H14.8333C15.7538 12.8346 16.5 12.0884 16.5 11.168V2.83464C16.5 1.91416 15.7538 1.16797 14.8333 1.16797H3.16667C2.24619 1.16797 1.5 1.91416 1.5 2.83464V11.168C1.5 12.0884 2.24619 12.8346 3.16667 12.8346Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const dialIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 inline"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);
export const clockIcon = (
  <svg
    className="inline"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.66667V9L11.5 11.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const chatIcon = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      d="M5.66667 7.9987H5.675M9 7.9987H9.00833M12.3333 7.9987H12.3417M16.5 7.9987C16.5 11.6806 13.1421 14.6654 9 14.6654C7.71728 14.6654 6.50976 14.3791 5.45389 13.8746L1.5 14.6654L2.66249 11.5654C1.9263 10.534 1.5 9.31059 1.5 7.9987C1.5 4.3168 4.85786 1.33203 9 1.33203C13.1421 1.33203 16.5 4.3168 16.5 7.9987Z"
      stroke="#4B5563"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const editIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2677 1.73223L18.798 1.2019V1.2019L18.2677 1.73223ZM4.5 19.0355V19.7855C4.69891 19.7855 4.88968 19.7065 5.03033 19.5658L4.5 19.0355ZM1 19.0355H0.25C0.25 19.4497 0.585786 19.7855 1 19.7855L1 19.0355ZM1 15.4644L0.46967 14.9341C0.329018 15.0747 0.25 15.2655 0.25 15.4644H1ZM15.2625 2.26256C15.9459 1.57915 17.054 1.57915 17.7374 2.26256L18.798 1.2019C17.5288 -0.067301 15.4711 -0.067301 14.2018 1.2019L15.2625 2.26256ZM17.7374 2.26256C18.4208 2.94598 18.4208 4.05402 17.7374 4.73744L18.798 5.7981C20.0672 4.52889 20.0672 2.47111 18.798 1.2019L17.7374 2.26256ZM17.7374 4.73744L3.96967 18.5051L5.03033 19.5658L18.798 5.7981L17.7374 4.73744ZM4.5 18.2855H1V19.7855H4.5V18.2855ZM14.2018 1.2019L0.46967 14.9341L1.53033 15.9947L15.2625 2.26256L14.2018 1.2019ZM0.25 15.4644V19.0355H1.75V15.4644H0.25ZM12.7018 3.76256L16.2374 7.2981L17.298 6.23744L13.7625 2.7019L12.7018 3.76256Z"
      fill="#374151"
    />
  </svg>
);
export const deleteIcon = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.832 4.83333L13.1093 14.9521C13.047 15.8243 12.3212 16.5 11.4468 16.5H4.55056C3.67616 16.5 2.95043 15.8243 2.88813 14.9521L2.16536 4.83333M6.33203 8.16667V13.1667M9.66536 8.16667V13.1667M10.4987 4.83333V2.33333C10.4987 1.8731 10.1256 1.5 9.66536 1.5H6.33203C5.87179 1.5 5.4987 1.8731 5.4987 2.33333V4.83333M1.33203 4.83333H14.6654"
      stroke="#F87171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const dropDown = (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893Z"
      fill="#1F2937"
    />
  </svg>
);
export const mobileArrowLeft = (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24696 0.351472C7.71559 0.820101 7.71559 1.5799 7.24696 2.04853L3.29549 6L7.24696 9.95147C7.71559 10.4201 7.71559 11.1799 7.24696 11.6485C6.77834 12.1172 6.01854 12.1172 5.54991 11.6485L0.749909 6.84853C0.28128 6.3799 0.28128 5.6201 0.749909 5.15147L5.54991 0.351472C6.01854 -0.117157 6.77834 -0.117157 7.24696 0.351472Z"
      fill="#3B82F6"
    />
  </svg>
);
export const mobileArrowRight = (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292894 9.70711C-0.0976307 9.31658 -0.0976307 8.68342 0.292894 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683417 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292894 9.70711Z"
      fill="#6B7280"
    />
  </svg>
);
export const calendarIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.80157 0.601562C3.35974 0.601562 3.00157 0.959735 3.00157 1.40156V2.20156H2.20156C1.31791 2.20156 0.601562 2.91791 0.601562 3.80156V11.8016C0.601562 12.6852 1.31791 13.4016 2.20156 13.4016H11.8016C12.6852 13.4016 13.4016 12.6852 13.4016 11.8016V3.80156C13.4016 2.91791 12.6852 2.20156 11.8016 2.20156H11.0016V1.40156C11.0016 0.959735 10.6434 0.601562 10.2016 0.601562C9.75975 0.601562 9.40158 0.959735 9.40158 1.40156V2.20156H4.60157V1.40156C4.60157 0.959735 4.2434 0.601562 3.80157 0.601562ZM3.80157 4.60156C3.35974 4.60156 3.00157 4.95973 3.00157 5.40156C3.00157 5.84339 3.35974 6.20156 3.80157 6.20156H10.2016C10.6434 6.20156 11.0016 5.84339 11.0016 5.40156C11.0016 4.95973 10.6434 4.60156 10.2016 4.60156H3.80157Z"
      fill="#9CA3AF"
    />
  </svg>
);
export const taskIcon = (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.19922 0.601562C3.75739 0.601562 3.39922 0.959735 3.39922 1.40156C3.39922 1.84339 3.75739 2.20156 4.19922 2.20156H5.79922C6.24105 2.20156 6.59922 1.84339 6.59922 1.40156C6.59922 0.959735 6.24105 0.601562 5.79922 0.601562H4.19922Z"
      fill="#9CA3AF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.199219 3.00156C0.199219 2.11791 0.915563 1.40156 1.79922 1.40156C1.79922 2.72705 2.87374 3.80156 4.19922 3.80156H5.79922C7.1247 3.80156 8.19922 2.72705 8.19922 1.40156C9.08287 1.40156 9.79922 2.11791 9.79922 3.00156V11.8016C9.79922 12.6852 9.08287 13.4016 8.19922 13.4016H1.79922C0.915563 13.4016 0.199219 12.6852 0.199219 11.8016V3.00156ZM2.59922 6.20156C2.15739 6.20156 1.79922 6.55973 1.79922 7.00156C1.79922 7.44339 2.15739 7.80156 2.59922 7.80156H2.60722C3.04905 7.80156 3.40722 7.44339 3.40722 7.00156C3.40722 6.55973 3.04905 6.20156 2.60722 6.20156H2.59922ZM4.99922 6.20156C4.55739 6.20156 4.19922 6.55973 4.19922 7.00156C4.19922 7.44339 4.55739 7.80156 4.99922 7.80156H7.39922C7.84105 7.80156 8.19922 7.44339 8.19922 7.00156C8.19922 6.55973 7.84105 6.20156 7.39922 6.20156H4.99922ZM2.59922 9.40156C2.15739 9.40156 1.79922 9.75973 1.79922 10.2016C1.79922 10.6434 2.15739 11.0016 2.59922 11.0016H2.60722C3.04905 11.0016 3.40722 10.6434 3.40722 10.2016C3.40722 9.75973 3.04905 9.40156 2.60722 9.40156H2.59922ZM4.99922 9.40156C4.55739 9.40156 4.19922 9.75973 4.19922 10.2016C4.19922 10.6434 4.55739 11.0016 4.99922 11.0016H7.39922C7.84105 11.0016 8.19922 10.6434 8.19922 10.2016C8.19922 9.75973 7.84105 9.40156 7.39922 9.40156H4.99922Z"
      fill="#9CA3AF"
    />
  </svg>
);
export const dragIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="15"
    viewBox="0 -960 960 960"
    width="15"
  >
    <path d="M349.911-160Q321-160 300.5-180.589q-20.5-20.588-20.5-49.5Q280-259 300.589-279.5q20.588-20.5 49.5-20.5Q379-300 399.5-279.411q20.5 20.588 20.5 49.5Q420-201 399.411-180.5q-20.588 20.5-49.5 20.5Zm260 0Q581-160 560.5-180.589q-20.5-20.588-20.5-49.5Q540-259 560.589-279.5q20.588-20.5 49.5-20.5Q639-300 659.5-279.411q20.5 20.588 20.5 49.5Q680-201 659.411-180.5q-20.588 20.5-49.5 20.5Zm-260-250Q321-410 300.5-430.589q-20.5-20.588-20.5-49.5Q280-509 300.589-529.5q20.588-20.5 49.5-20.5Q379-550 399.5-529.411q20.5 20.588 20.5 49.5Q420-451 399.411-430.5q-20.588 20.5-49.5 20.5Zm260 0Q581-410 560.5-430.589q-20.5-20.588-20.5-49.5Q540-509 560.589-529.5q20.588-20.5 49.5-20.5Q639-550 659.5-529.411q20.5 20.588 20.5 49.5Q680-451 659.411-430.5q-20.588 20.5-49.5 20.5Zm-260-250Q321-660 300.5-680.589q-20.5-20.588-20.5-49.5Q280-759 300.589-779.5q20.588-20.5 49.5-20.5Q379-800 399.5-779.411q20.5 20.588 20.5 49.5Q420-701 399.411-680.5q-20.588 20.5-49.5 20.5Zm260 0Q581-660 560.5-680.589q-20.5-20.588-20.5-49.5Q540-759 560.589-779.5q20.588-20.5 49.5-20.5Q639-800 659.5-779.411q20.5 20.588 20.5 49.5Q680-701 659.411-680.5q-20.588 20.5-49.5 20.5Z" />
  </svg>
);
export const arrowLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="35"
    viewBox="0 -960 960 960"
    width="35"
  >
    <path d="M560-280 360-480l200-200v400Z" />
  </svg>
);
export const arrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="35"
    viewBox="0 -960 960 960"
    width="35"
  >
    <path d="M400-280v-400l200 200-200 200Z" />
  </svg>
);
export const eye = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);
export const closeEye = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
    />
  </svg>
);

export const closeIconRoundRed = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM7.06066 4.93934C6.47487 4.35355 5.52513 4.35355 4.93934 4.93934C4.35355 5.52513 4.35355 6.47487 4.93934 7.06066L5.87868 8L4.93934 8.93934C4.35355 9.52513 4.35355 10.4749 4.93934 11.0607C5.52513 11.6464 6.47487 11.6464 7.06066 11.0607L8 10.1213L8.93934 11.0607C9.52513 11.6464 10.4749 11.6464 11.0607 11.0607C11.6464 10.4749 11.6464 9.52513 11.0607 8.93934L10.1213 8L11.0607 7.06066C11.6464 6.47487 11.6464 5.52513 11.0607 4.93934C10.4749 4.35355 9.52513 4.35355 8.93934 4.93934L8 5.87868L7.06066 4.93934Z"
      fill="#EF4444"
      stroke="#EF4444"
    />
  </svg>
);

export const greenTickRound = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM12.0607 7.06066C12.6464 6.47487 12.6464 5.52513 12.0607 4.93934C11.4749 4.35355 10.5251 4.35355 9.93934 4.93934L7 7.87868L6.06066 6.93934C5.47487 6.35355 4.52513 6.35355 3.93934 6.93934C3.35355 7.52513 3.35355 8.47487 3.93934 9.06066L5.93934 11.0607C6.52513 11.6464 7.47487 11.6464 8.06066 11.0607L12.0607 7.06066Z"
      fill="#10B981"
      stroke="#10B981"
    />
  </svg>
);

export const revokeIcon2 = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70712 0.292893C6.09765 0.683417 6.09765 1.31658 5.70712 1.70711L3.41422 4H9.00003C12.866 4 16 7.13401 16 11V13C16 13.5523 15.5523 14 15 14C14.4478 14 14 13.5523 14 13V11C14 8.23858 11.7615 6 9.00003 6H3.41422L5.70712 8.29289C6.09765 8.68342 6.09765 9.31658 5.70712 9.70711C5.3166 10.0976 4.68343 10.0976 4.29291 9.70711L0.292894 5.70711C-0.0976314 5.31658 -0.0976314 4.68342 0.292894 4.29289L4.29291 0.292893C4.68343 -0.0976311 5.3166 -0.0976311 5.70712 0.292893Z"
      fill={`${props?.fill || "#5D6B98"}`}
    />
  </svg>
);

export const closeIconRoundRed2 = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 10.6667L9 9M9 9L10.6667 7.33333M9 9L7.33333 7.33333M9 9L10.6667 10.6667M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
      stroke="#DC2626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const warningIcon = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25803 1.09882C7.02264 -0.26048 8.97973 -0.260481 9.74434 1.09882L15.3246 11.0194C16.0746 12.3526 15.1111 13.9999 13.5815 13.9999H2.42088C0.891228 13.9999 -0.0722004 12.3526 0.677729 11.0194L6.25803 1.09882ZM9.0011 11C9.0011 11.5523 8.55338 12 8.0011 12C7.44881 12 7.0011 11.5523 7.0011 11C7.0011 10.4477 7.44881 10 8.0011 10C8.55338 10 9.0011 10.4477 9.0011 11ZM8.0011 3C7.44881 3 7.0011 3.44772 7.0011 4V7C7.0011 7.55229 7.44881 8 8.0011 8C8.55338 8 9.0011 7.55229 9.0011 7V4C9.0011 3.44772 8.55338 3 8.0011 3Z"
      fill={`${props?.fill || "#FBBF24"}`}
    />
  </svg>
);

export const filterIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 2.5C1.5 1.94771 1.94772 1.5 2.5 1.5H15.5C16.0523 1.5 16.5 1.94772 16.5 2.5V4.41912C16.5 4.68434 16.3946 4.93869 16.2071 5.12623L10.9595 10.3738C10.772 10.5613 10.6667 10.8157 10.6667 11.0809V13.1667L7.33332 16.5V11.0809C7.33332 10.8157 7.22797 10.5613 7.04043 10.3738L1.79289 5.12623C1.60536 4.93869 1.5 4.68434 1.5 4.41912V2.5Z"
      stroke={props?.stroke || "#3466E7"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const infoIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66666 9.66667H6.99999V7H6.33332M6.99999 4.33333H7.00666M13 7C13 10.3137 10.3137 13 6.99999 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 6.99999 1C10.3137 1 13 3.68629 13 7Z"
      stroke="#7D89B0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const warningIcon2 = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0015 7V9M10.0015 13H10.0115M3.0733 17H16.9297C18.4693 17 19.4316 15.3333 18.6618 14L11.7336 2C10.9637 0.666667 9.03925 0.666667 8.26945 2L1.34125 14C0.571445 15.3333 1.5337 17 3.0733 17Z"
      stroke="#DC2626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const paySlipNotAvaible = (
  <svg
    width="151"
    height="120"
    viewBox="0 0 151 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.933594" width="149.63" height="120" fill="#FCFCFD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6412 80.3984H93.1064C93.4146 80.3984 93.715 80.3651 94.0042 80.3018C94.2934 80.3651 94.5938 80.3984 94.902 80.3984H126.025C128.339 80.3984 130.215 78.518 130.215 76.1984C130.215 73.8788 128.339 71.9984 126.025 71.9984H122.434C120.12 71.9984 118.244 70.118 118.244 67.7984C118.244 65.4788 120.12 63.5984 122.434 63.5984H133.806C136.12 63.5984 137.995 61.718 137.995 59.3984C137.995 57.0788 136.12 55.1984 133.806 55.1984H120.638C122.952 55.1984 124.828 53.318 124.828 50.9984C124.828 48.6788 122.952 46.7984 120.638 46.7984H82.3331C84.6469 46.7984 86.5227 44.918 86.5227 42.5984C86.5227 40.2788 84.6469 38.3984 82.3331 38.3984H48.2175C45.9037 38.3984 44.0279 40.2788 44.0279 42.5984C44.0279 44.918 45.9037 46.7984 48.2175 46.7984H24.2768C21.9629 46.7984 20.0872 48.6788 20.0872 50.9984C20.0872 53.318 21.9629 55.1984 24.2768 55.1984H39.2397C41.5536 55.1984 43.4294 57.0788 43.4294 59.3984C43.4294 61.718 41.5536 63.5984 39.2397 63.5984H15.299C12.9851 63.5984 11.1094 65.4788 11.1094 67.7984C11.1094 70.118 12.9851 71.9984 15.299 71.9984H38.6412C36.3274 71.9984 34.4516 73.8788 34.4516 76.1984C34.4516 78.518 36.3274 80.3984 38.6412 80.3984ZM136.2 80.3984C138.514 80.3984 140.389 78.518 140.389 76.1984C140.389 73.8788 138.514 71.9984 136.2 71.9984C133.886 71.9984 132.01 73.8788 132.01 76.1984C132.01 78.518 133.886 80.3984 136.2 80.3984Z"
      fill="#EBF0FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9955 84.0031C48.7234 84.0031 42.8281 78.2276 42.8281 71.1031C42.8281 63.9787 48.7234 58.2031 55.9955 58.2031C56.3048 58.2031 56.6115 58.2136 56.9154 58.2341C56.7043 57.0558 56.5941 55.8424 56.5941 54.6031C56.5941 43.3365 65.7049 34.2031 76.9437 34.2031C85.9161 34.2031 93.5323 40.0243 96.2391 48.1048C96.8804 48.0376 97.5317 48.0031 98.1911 48.0031C108.273 48.0031 116.446 56.062 116.446 66.0031C116.446 75.4475 109.069 83.2395 99.6874 83.99V84.0031H65.8758H55.9955ZM63.1753 84.0031H59.0069H63.1753Z"
      fill="#FCFCFD"
    />
    <path
      d="M63.1753 84.0031H59.0069M55.9955 84.0031C48.7234 84.0031 42.8281 78.2276 42.8281 71.1031C42.8281 63.9787 48.7234 58.2031 55.9955 58.2031C56.3048 58.2031 56.6115 58.2136 56.9154 58.2341C56.7043 57.0558 56.5941 55.8424 56.5941 54.6031C56.5941 43.3365 65.7049 34.2031 76.9437 34.2031C85.9161 34.2031 93.5323 40.0243 96.2391 48.1048C96.8804 48.0376 97.5317 48.0031 98.1911 48.0031C108.273 48.0031 116.446 56.062 116.446 66.0031C116.446 75.4475 109.069 83.2395 99.6874 83.99V84.0031H65.8758H55.9955Z"
      stroke="#789BF7"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.7269 38.6025C70.7269 57.9363 88.0962 73.8677 110.461 76.0186C107.658 79.4735 103.33 81.8174 98.3992 82.1856V82.1969H58.2363C53.3633 82.1969 45.2227 80.1387 45.2227 71.1259C45.2227 62.1131 51.5515 60.0549 58.2363 60.0549C58.5205 60.0549 58.8025 60.0638 59.0819 60.0815C58.8878 59.0702 58.8537 58.0279 58.7865 56.9653C58.3325 49.7874 61.4562 40.7411 70.7377 37.7969C70.7303 38.0653 70.7269 38.3335 70.7269 38.6025ZM77.0138 68.8516C75.195 68.8516 73.7207 70.3122 73.7207 72.1138C73.7207 73.9155 75.195 75.376 77.0138 75.376C78.8325 75.376 80.3069 73.9155 80.3069 72.1138C80.3069 70.3122 78.8325 68.8516 77.0138 68.8516Z"
      fill="#D6E0FA"
    />
    <path
      d="M77.245 75.6C79.063 75.6 80.5368 74.1225 80.5368 72.3C80.5368 70.4775 79.063 69 77.245 69C75.4269 69 73.9531 70.4775 73.9531 72.3C73.9531 74.1225 75.4269 75.6 77.245 75.6Z"
      stroke="#789BF7"
      strokeWidth="2.5"
    />
    <path
      d="M67.9688 65.4022L72.1584 62.1063L67.9688 58.9688"
      stroke="#789BF7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.5234 65.4022L82.3338 62.1063L86.5234 58.9688"
      stroke="#789BF7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.9323 40.2031C86.828 41.2367 89.904 44.2908 90.9766 48.179"
      stroke="#C2D1F8"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M95.4987 29.9984C96.4903 29.9984 97.2942 29.1926 97.2942 28.1984C97.2942 27.2043 96.4903 26.3984 95.4987 26.3984C94.507 26.3984 93.7031 27.2043 93.7031 28.1984C93.7031 29.1926 94.507 29.9984 95.4987 29.9984Z"
      stroke="#C2D1F8"
      strokeWidth="2"
    />
    <path
      d="M114.053 39.6C115.045 39.6 115.849 38.7941 115.849 37.8C115.849 36.8059 115.045 36 114.053 36C113.062 36 112.258 36.8059 112.258 37.8C112.258 38.7941 113.062 39.6 114.053 39.6Z"
      fill="#C2D1F8"
    />
    <path
      d="M100.141 34.6562L105.143 39.6712M105.219 34.6562L100.217 39.6712L105.219 34.6562Z"
      stroke="#C2D1F8"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.6719 45.3594L47.263 48.9594M47.263 45.3594L43.6719 48.9594L47.263 45.3594Z"
      stroke="#C2D1F8"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.8073 41.3969C52.7989 41.3969 53.6028 40.591 53.6028 39.5969C53.6028 38.6028 52.7989 37.7969 51.8073 37.7969C50.8156 37.7969 50.0117 38.6028 50.0117 39.5969C50.0117 40.591 50.8156 41.3969 51.8073 41.3969Z"
      fill="#C2D1F8"
    />
  </svg>
);
export const AccountIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>

);

export const SettingsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>

);

export const LogoutIcon = (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
</svg>
);
