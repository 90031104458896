export const formatTimeDifferece = (time1, time2) => {
  if (time1 && time2) {
    try {
      // Split the time strings to get hours, minutes, and seconds
      var time1Parts = time1.split(":");
      var time2Parts = time2.split(":");

      // Create Date objects with a common date (e.g., today's date)
      var date1 = new Date();
      date1.setHours(Number(time1Parts[0]), Number(time1Parts[1]), "00");

      var date2 = new Date();
      date2.setHours(Number(time2Parts[0]), Number(time2Parts[1]), "00");

      // Calculate the time difference in milliseconds
      var timeDifferenceMs = date2 - date1;

      // Convert milliseconds to hours, minutes, and seconds
      var hours = Math.floor(timeDifferenceMs / 3600000); // 1 hour = 3600000 milliseconds
      var minutes = Math.floor((timeDifferenceMs % 3600000) / 60000); // 1 minute = 60000 milliseconds
      var seconds = Math.floor((timeDifferenceMs % 60000) / 1000); // 1 second = 1000 milliseconds
      
      const result = {
        hours,
        minutes,
        seconds,
      };
      return result;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }
  return false;
};
