import React from "react";
import RegularizationRequestOverviewSwiper from "../../../../components/Attendance/SelfService/MyRegularizationRequest/RegularizationRequestOverviewSwiper";
import AttendanceAnalyticsOverviewChart from "../../../../components/Attendance/ManageAttendance/Overview/AttendanceAnalyticsOverviewChart";
import AttendanceOverviewChart from "../../../../components/Attendance/ManageAttendance/Overview/AttendanceOverviewChart";
import RegularizationApprovalOverviewChart from "../../../../components/Attendance/ManageAttendance/Overview/RegularizationApprovalOverviewChart";
import LeaveApprovalOverviewChart from "../../../../components/Attendance/ManageAttendance/Overview/LeaveApprovalOverviewChart"

const data = [
  {
    title: "Total Employee",
    count: 100,
  },
  {
    title: "Absent Days",
    count: 20,
  },
  {
    title: "Total Overtime",
    count: "120hr",
  },
  {
    title: "Pending Regularization",
    count: 12,
  },
];

const Overview = () => {
  return (
    <div className="p-10 w-full">
      <div className="flex justify-between items-center py-3">
        <div>
          <h1 className="font-semibold text-lg text-black">
            {" "}
            Attendance Overview
          </h1>
          <p>Get an overview of your employee attendance information</p>
        </div>
        <button className="btn">
          <i className="ico-download mr-2"></i>
          Download
        </button>
      </div>
      <div className="flex gap-4 py-3">
        {data.map((item) => {
          return <RegularizationRequestOverviewSwiper data={item} />;
        })}
      </div>
      <div className="text-black text-lg">
        <AttendanceAnalyticsOverviewChart />
      </div>

     <div className="flex justify-between py-3">
     <AttendanceOverviewChart />
      <RegularizationApprovalOverviewChart />
      <LeaveApprovalOverviewChart/>
     </div>
    </div>
  );
};

export default Overview;
