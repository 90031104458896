import React from "react";
import CompletedFinalizeAttendaceData from "./CompletedFinalizeAttendaceData";

const data = [
  {
    attendanceDate: "20 Jan 2022 - 19 Feb 2023",
    finalizationDate: "20 Jan 2022 05:00 PM by: Ashley Ported",
  },
  {
    attendanceDate: "20 Jan 2022 - 19 Feb 2023",
    finalizationDate: "20 Jan 2022 05:00 PM by: Ashley Ported",
  },
  {
    attendanceDate: "20 Jan 2022 - 19 Feb 2023",
    finalizationDate: "20 Jan 2022 05:00 PM by: Ashley Ported",
  },
  {
    attendanceDate: "20 Jan 2022 - 19 Feb 2023",
    finalizationDate: "20 Jan 2022 05:00 PM by: Ashley Ported",
  },
  {
    attendanceDate: "20 Jan 2022 - 19 Feb 2023",
    finalizationDate: "20 Jan 2022 05:00 PM by: Ashley Ported",
  },
];

const CompletedFinalizeAttendance = () => {
  const cols = [
    { colName: "Attendance Date", status: true },
    { colName: "Finalization Date", status: true },
  ];
  return (
    <div className="p-10">
      <div className="rounded-xl border overflow-hidden">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] dark:border-gray-200 tracking-wider">
          <tr>
            {cols.map((item) => {
              if (item.status) {
                return (
                  <th scope="col" className="px-10 py-3">
                    {item.colName}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return <CompletedFinalizeAttendaceData data={item} />;
          })}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default CompletedFinalizeAttendance;
