import React, { useState } from "react";
import PolicyApprovalTable from "../../../../components/Task/Approval/Policy/PolicyApprovalTable";
import { useGetSystemPolicyList } from "../../../../queries/systemPolicyQueries";
import Pagination from "../../../../components/Pagination/Pagination";
import PageLoader from "../../../../components/PageLoader";
import { Empty } from "antd";
import Search from "../../../../components/Header/Search";
import Select from "react-select";
import {
  systemPolicyApprovalStatusOptions,
} from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";

const PolicyApproval = () => {
  const loggedInUser = useSelector((state) => state.user.auth);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [approvalStatus, setApprovalStatus] = useState();
  const [searchValue, setSearchValue] = useState("")

  const { data: getSystemPolicyListData, isLoading } = useGetSystemPolicyList({
    page_number: pageNo,
    page_limit: pageSize,
    approval_status: approvalStatus,
    filter: searchValue
  });

  const systemPolicyData = Array.isArray(getSystemPolicyListData?.data)
    ? getSystemPolicyListData.data
    : [];
  const totalRecords = getSystemPolicyListData?.totalRecords || 0;

  return (
    <div className="w-full overflow-x-auto">
      <div className="px-10 py-6">
        <div className="my-2">
          <h2 className="text-lg font-semibold">Policy Approval</h2>
          <p>Manage all policy requests</p>
        </div>
      </div>
      <div className="py-3 flex justify-between gap-3 px-10">
        <div className="flex gap-3">
          <Select
            isClearable
            placeholder="Approval status"
            options={systemPolicyApprovalStatusOptions}
            onChange={(e) => setApprovalStatus(e?.value)}
          /> 
        </div>
        <div>
          <Search mx="0" onChange={(e) => setSearchValue(e)} />
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : !!systemPolicyData.length && isAdmin ? (
        <PolicyApprovalTable data={systemPolicyData} />
      ) : (
        <Empty />
      )}
      {!!systemPolicyData.length && (
        <Pagination
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      )}
    </div>
  );
};

export default PolicyApproval;
