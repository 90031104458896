import React from "react";

const ManageAssetData = (props) => {
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td class="px-10 py-4">
        <input type="checkbox" />
      </td>
      <td scope="row" class="px-10 py-4 whitespace-nowrap">
        {props.requestdata.id}
      </td>
      <td class="px-10 py-4 text-blue-700 font-medium">
        {props.requestdata.asset_name}
      </td>
      <td class="px-10 py-4 text-gray-800 font-medium">
        {props.requestdata.added_by}
      </td>
      <td class="px-10 py-4">{props.requestdata.added_date}</td>
      <td class="px-10 py-4">{props.requestdata.category}</td>
      <td class="px-10 py-4">{props.requestdata.total_asset}</td>
      <td class="px-10 py-4">{props.requestdata.assigned}</td>
      <td class="px-10 py-4">
        <span className="status status--red py-1 px-2.5">
          {props.requestdata.status}
        </span>
      </td>
      <td class="px-10 py-4 flex justify-between items-center border-none">
        <img
          src="/images/edit-black-icon.svg"
          alt=""
          className="cursor-pointer"
        />
        <i className="ico-more text-gray-500 cursor-pointer pl-5"></i>
      </td>
    </tr>
  );
};

export default ManageAssetData;
