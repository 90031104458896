import React from "react";

const PayrollOverview = () => {
  return (
    <div className="w-full max-w-[1084px] mx-auto">
      <div className="px-10 pb-4 mt-8 flex flex-col">
        <div>
          <h2>Overview</h2>
          <p>Manage all employe regularization</p>
        </div>
        <div className="bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg my-6">
          <div className="flex justify-between items-start">
            <div className="p-6">
              <h3 className="text-base text-gray-800 font-semibold pb-1.5">
                Procees Pay Run
              </h3>
              <div className="flex items-center gap-4">
                <p>01/01/2021 to 31/01/2021</p>
                <p className="px-3 py-1 bg-[#F3F4F6] rounded-full font-medium text-[#1F2937]">
                  Draft
                </p>
              </div>
            </div>
            <div className="p-6">
              <a href="" className="btn text-sm font-medium">
                View Details
              </a>
            </div>
          </div>
          <div className="flex justify-start items-center px-6 gap-40 pb-6">
            <div>
              <p>Employee net pays</p>
              <p className="text-[#1F2937] font-semibold pt-1 text-center">
                ₹4,52,512.00
              </p>
            </div>
            <div>
              <p>Payment date</p>
              <p className="text-[#1F2937] font-semibold pt-1 text-center">
                01/02/2019
              </p>
            </div>
            <div>
              <p>No Of Employee</p>
              <p className="text-[#1F2937] font-semibold pt-1 text-center">
                12
              </p>
            </div>
          </div>
          <div className="px-6 mb-6 bg-[#FFFBEB] mx-6 flex gap-3">
            <img
              src="/images/info-icon.svg"
              alt=""
              className="cursor-pointer"
            />
            <p className="text-[#92400E] py-2">
              This payment is overdue by 1 day(s)
            </p>
          </div>
        </div>
        <div className="bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg my-6 pb-6">
          <div className="p-6">
            <h3 className="text-base text-gray-800 font-semibold pb-1.5">
              Summary
            </h3>
          </div>
          <div className="flex items-center justify-around">
            <div className="text-center flex flex-col items-center">
              <img src="/images/payroll-salary-icon.svg" alt="" />
              <p className="text-[#10B981] text-base font-semibold mt-2 mb-1">
                Salary
              </p>
              <p className="text-base font-semibold text-[#1F2937]">
                ₹4,52,512.00
              </p>
              <a href="" className="text-[#3466E7] text-sm font-medium pt-4">
                view details
              </a>
            </div>
            <div className="text-center flex flex-col items-center">
              <img src="/images/payroll-netpay-icon.svg" alt="" />
              <p className="text-[#10B981] text-base font-semibold mt-2 mb-1">
                Net Pay
              </p>
              <p className="text-base font-semibold text-[#1F2937]">
                ₹4,52,512.00
              </p>
              <a href="" className="text-[#3466E7] text-sm font-medium pt-4">
                view details
              </a>
            </div>
            <div className="text-center flex flex-col items-center">
              <img src="/images/payroll-epf-icon.svg" alt="" />
              <p className="text-[#F97316] text-base font-semibold mt-2 mb-1">
                EPF
              </p>
              <p className="text-base font-semibold text-[#1F2937]">
                ₹4,52,512.00
              </p>
              <a href="" className="text-[#3466E7] text-sm font-medium pt-4">
                view details
              </a>
            </div>
            <div className="text-center flex flex-col items-center">
              <img src="/images/payroll-esi-icon.svg" alt="" />
              <p className="text-[#F97316] text-base font-semibold mt-2 mb-1">
                ESI
              </p>
              <p className="text-base font-semibold text-[#1F2937]">
                ₹4,52,512.00
              </p>
              <a href="" className="text-[#3466E7] text-sm font-medium pt-4">
                view details
              </a>
            </div>
            <div className="text-center flex flex-col items-center">
              <img src="/images/payroll-tds-icon.svg" alt="" />
              <p className="text-[#EF4444] text-base font-semibold mt-2 mb-1">
                TDS DEDUCTION
              </p>
              <p className="text-base font-semibold text-[#1F2937]">
                ₹4,52,512.00
              </p>
              <a href="" className="text-[#3466E7] text-sm font-medium pt-4">
                view details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollOverview;
