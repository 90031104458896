import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import Line from "../../../components/Line/Line";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useGetCountryList,
  useGetStateList,
  useGetCityList,
  useUpdatePersonalDetails,
} from "../../../queries/employeeQueries";
import { getFormErrMsg } from "../../../utils/helperFunctions";
import {
  cannotStartWithSpace,
  nonZeroValues,
} from "../../../utils/regex/regex";

const EditOrAddAdressModal = ({ openModal, onCloseModal, employee }) => {
  const { data: countryList } = useGetCountryList();
  const [selectedCountry, setSelectedCountry] = useState(employee?.nationality);
  const { data: stateList } = useGetStateList(selectedCountry);
  const [selectedState, setSelectedState] = useState(
    employee?.present_state_id
  );
  const { data: cityList } = useGetCityList(selectedCountry, selectedState);
  const [selectedCity, setSelectedCity] = useState(employee?.present_city_id);
  const [isSameAsCurrentAdress, setIsSameAsCurrentAdress] = useState(
    employee?.present_address === employee?.permanent_address
  );
  const updatePersonalDetailsMutation = useUpdatePersonalDetails();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      nationality: employee?.nationality || "",
      present_address: employee?.present_address || "",
      present_city_id: employee?.present_city_id || "",
      permanent_address: employee?.permanent_address || "",
      permanent_city_id: employee?.permanent_city_id || "",
      present_state_id: employee?.present_state_id || "",
      present_postal_code: employee?.present_postal_code || "",
      permanent_state_id: employee?.permanent_state_id || "",
      permanent_postal_code: employee?.permanent_postal_code || "",
      present_district_id: employee?.present_district_id || "",
      permanent_district_id: employee?.permanent_district_id || "",
    },
  });

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      employeeId: employee?.employee_id,
    };

    if (isSameAsCurrentAdress) {
      formData.permanent_district_id = data.present_district_id;
      formData.permanent_postal_code = data.present_postal_code;
      formData.permanent_state_id = data.present_state_id;
      formData.permanent_city_id = data.present_city_id;
      formData.permanent_address = data.present_address;
    }

    try {
      const response = await updatePersonalDetailsMutation.mutateAsync(
        formData
      );
      if (response?.status) {
        toast.success("Address updated successfully");
        onCloseModal();
      } else {
        toast.error(response?.message || "Failed to update address");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to update address");
    }
  };

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit/Add Address
            </h2>
            <p className="text-sm font-semibold text-black mb-4">
              Current Address
            </p>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Address
                </label>
                <input
                  {...register("present_address", {
                    validate: {
                      noLeadingSpace: (value) =>
                        cannotStartWithSpace.test(value) ||
                        "Address cannot start with a space",
                    },
                  })}
                  type="text"
                  className="h-[38px]"
                />
                {getFormErrMsg("present_address", errors)}
              </div>

              <div className="flex gap-5 justify-between">
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Country
                  </label>
                  <Controller
                    name="nationality"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className=""
                          placeholder={
                            countryList?.find(
                              (i) => Number(i?.id) === Number(value)
                            )?.flag || "Select"
                          }
                          onChange={(e) => {
                            onChange(Number(e.id));
                            setSelectedCountry(e.id);
                          }}
                          options={countryList}
                          getOptionLabel={(option) => option.flag}
                          getOptionValue={(option) => option.id}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("nationality", errors)}
                </div>
                <div className="w-full">
                  <div>
                    <label className="text-sm font-medium text-black mr-4">
                      State
                    </label>
                    <Controller
                      name="present_state_id"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            className=""
                            placeholder={
                              stateList?.find(
                                (i) => Number(i?.id) === Number(value)
                              )?.state_name || "Select"
                            }
                            onChange={(e) => {
                              onChange(Number(e.id));
                              setSelectedState(e.id);
                            }}
                            options={stateList}
                            getOptionLabel={(option) => option.state_name}
                            getOptionValue={(option) => option.id}
                          />
                        );
                      }}
                    />
                    {getFormErrMsg("present_state_id", errors)}
                  </div>
                </div>
              </div>

              <div className="flex gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    District
                  </label>
                  <Controller
                    name="present_district_id"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className=""
                          placeholder={
                            cityList?.find(
                              (i) => Number(i?.id) === Number(value)
                            )?.city_name || "Select"
                          }
                          onChange={(e) => {
                            onChange(Number(e.id));
                            setSelectedCity(e.id);
                          }}
                          options={cityList}
                          getOptionLabel={(option) => option.city_name}
                          getOptionValue={(option) => option.id}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("present_district_id", errors)}
                </div>
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    City
                  </label>
                  <Controller
                    name="present_city_id"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className=""
                          placeholder={
                            cityList?.find(
                              (i) => Number(i?.id) === Number(value)
                            )?.city_name || "Select"
                          }
                          onChange={(e) => {
                            onChange(Number(e.id));
                            setSelectedCity(e.id);
                          }}
                          options={cityList}
                          getOptionLabel={(option) => option.city_name}
                          getOptionValue={(option) => option.id}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("present_city_id", errors)}
                </div>
              </div>
              <div>
              <div className="w-full">
                      <label className="text-sm font-medium text-black mr-4">
                        Zip Code
                      </label>
                      <input
                        {...register("present_postal_code", {
                          pattern: {
                            value: nonZeroValues,
                            message: "Zip code cannot be all zeros",
                          },
                        })}
                        type="number"
                      />
                      {getFormErrMsg("present_postal_code", errors)}
                    </div>
              </div>

              <Line />
              <div className="">
                <p className="text-sm font-semibold text-black mb-4">
                  Permanent Address
                </p>
                <div className="flex items-center">
                  {" "}
                  <input
                    checked={isSameAsCurrentAdress}
                    onChange={(e) => setIsSameAsCurrentAdress(e.target.checked)}
                    type="checkbox"
                    className="mr-2"
                  />
                  <span className="text-sm font-normal text-black">
                    Same as current address
                  </span>
                </div>
              </div>
              {!isSameAsCurrentAdress ? (
                <>
                  <div className="flex flex-col">
                    <label className="text-sm font-medium text-black">
                      Address
                    </label>
                    <input
                      {...register("permanent_address", {
                        validate: {
                          noLeadingSpace: (value) =>
                            cannotStartWithSpace.test(value) ||
                            "Address cannot start with a space",
                        },
                      })}
                      type="text"
                      className="h-[38px]"
                    />
                    {getFormErrMsg("permanent_address", errors)}
                  </div>

                  <div className="flex gap-5 justify-between">
                    <div className="w-full">
                      <div>
                        <label className="text-sm font-medium text-black mr-4">
                          State
                        </label>
                        <Controller
                          name="permanent_state_id"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                className=""
                                placeholder={
                                  stateList?.find(
                                    (i) => Number(i?.id) === Number(value)
                                  )?.state_name || "Select"
                                }
                                onChange={(e) => {
                                  onChange(Number(e.id));
                                  setSelectedState(e.id);
                                }}
                                options={stateList}
                                getOptionLabel={(option) => option.state_name}
                                getOptionValue={(option) => option.id}
                              />
                            );
                          }}
                        />
                        {getFormErrMsg("permanent_state_id", errors)}
                      </div>
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-medium text-black mr-4">
                        District
                      </label>
                      <Controller
                        name="permanent_district_id"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              className=""
                              placeholder={
                                cityList?.find(
                                  (i) => Number(i?.id) === Number(value)
                                )?.city_name || "Select"
                              }
                              onChange={(e) => {
                                onChange(Number(e.id));
                                setSelectedCity(e.id);
                              }}
                              options={cityList}
                              getOptionLabel={(option) => option.city_name}
                              getOptionValue={(option) => option.id}
                            />
                          );
                        }}
                      />
                      {getFormErrMsg("permanent_district_id", errors)}
                    </div>
                  </div>

                  <div className="flex gap-5">
                    <div className="w-full">
                      <label className="text-sm font-medium text-black mr-4">
                        City
                      </label>
                      <Controller
                        name="permanent_city_id"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              className=""
                              placeholder={
                                cityList?.find(
                                  (i) => Number(i?.id) === Number(value)
                                )?.city_name || "Select"
                              }
                              onChange={(e) => {
                                onChange(Number(e.id));
                                setSelectedCity(e.id);
                              }}
                              options={cityList}
                              getOptionLabel={(option) => option.city_name}
                              getOptionValue={(option) => option.id}
                            />
                          );
                        }}
                      />
                      {getFormErrMsg("permanent_city_id", errors)}
                    </div>
                    <div className="w-full">
                      <label className="text-sm font-medium text-black mr-4">
                        Zip Code
                      </label>
                      <input
                        {...register("permanent_postal_code", {
                          pattern: {
                            value: nonZeroValues,
                            message: "Zip code cannot be all zeros",
                          },
                        })}
                        type="number"
                      />
                      {getFormErrMsg("permanent_postal_code", errors)}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditOrAddAdressModal;
