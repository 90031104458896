import React, { useState } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { closeIcon } from "../../Icons/Icons";
import Avatar from "../Avatar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const roleOptions = [
  { label: "HR", value: 2 },
  { label: "Manager", value: 4 },
  { label: "Employee", value: 6 },
];

const ManageEmployeeRole = ({ open, close, employee }) => {
  const {
    role_id,
    role_name,
    first_name,
    last_name,
    employee_id,
    department_name,
    employee_serial,
  } = employee;
  const axiosPrivate = useAxiosPrivate();

  const [selectedRole, setSelectedRole] = useState(role_id);
  const [errMsg, setErrMsg] = useState("");
  const handleSelectRole = (e) => {
    const roleId = Number(e.value);
    setSelectedRole(roleId);
  };
  const isUpdateDisabled = Number(role_id) === Number(selectedRole);

  const onSubmit = async () => {
    if (isUpdateDisabled) {
      return;
    }
    setErrMsg("");
    const result = await axiosPrivate
      .post("employee/assignRole", {
        employeeId: employee_id,
        roleId: selectedRole,
      })
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      toast.success(result.data.message);
      close()
    } else {
      setErrMsg("Failed to assign selected role to this employee");
    }
  };
  return (
    <Modal open={open} onClose={close} center showCloseIcon={false}>
      <div className="flex flex-col justify-between gap-3 w-[400px] min-h-[350px]">
        <div>
          <div className="flex justify-between items-center gap-5">
            <h1 className="text-gray-800 font-medium text-lg">
              Manage Employee Role
            </h1>
            <button onClick={close}>{closeIcon}</button>
          </div>
          <p className="text-red-600">{errMsg}</p>
          <div className="flex flex-col border rounded-md shadow-sm p-2 my-3">
            <div className="flex items-center gap-3 mb-2">
              <Avatar title={`${first_name?.[0] || ""}${last_name?.[0] || ""}`} />{" "}
              <div className="flex flex-col">
                {" "}
                <span>
                  {first_name} {last_name}
                </span>
                <span className="text-gray-800">{employee_serial}</span>
              </div>
            </div>
            <span>Department: {department_name}</span>
            <span>Role: {role_name}</span>
          </div>
          <div>
            <label>Select Role</label>
            <Select
              defaultValue={
                roleOptions?.find(
                  (i) => Number(i?.value) === Number(role_id)
                ) || {}
              }
              options={roleOptions}
              onChange={handleSelectRole}
            />
          </div>
          <p className="py-3">
            Selected role will be applied to{" "}
            <span>
              {first_name} {last_name}
            </span>
          </p>
        </div>
        <div className="flex justify-end gap-3">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button
            onClick={onSubmit}
            className={`btn ${isUpdateDisabled && "opacity-50"}`}
            disabled={isUpdateDisabled}
          >
            Update Role
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageEmployeeRole;
