import React from "react";
import OfferListData from "./OfferListData";

const offerList = [
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Savannah Nguyenr",
    recruiter: "Kristin Watson",
    offer_date: "27 Apr 2022 10:30am",
    status: "Approved",
    hr_status: "Declined",
    manager_status: "Pending",
    candidate_status: "Pending"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Savannah Nguyenr",
    recruiter: "Kristin Watson",
    offer_date: "27 Apr 2022 10:30am",
    status: "Approved",
    hr_status: "Declined",
    manager_status: "Pending",
    candidate_status: "Pending"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Savannah Nguyenr",
    recruiter: "Kristin Watson",
    offer_date: "27 Apr 2022 10:30am",
    status: "Approved",
    hr_status: "Declined",
    manager_status: "Pending",
    candidate_status: "Pending"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Savannah Nguyenr",
    recruiter: "Kristin Watson",
    offer_date: "27 Apr 2022 10:30am",
    status: "Approved",
    hr_status: "Declined",
    manager_status: "Pending",
    candidate_status: "Pending"
  }
];

const OfferList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <div className="flex items-center">
                <input type="checkbox" className="mr-3" />
                ID
              </div>
            </th>
            <th scope="col" class="px-10 py-3">
              CANDIDATE NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              RECRUITER
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              OFFER DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              STATUS
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              HR STATUS
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              MANAGER STATUS
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CANDIDATE STATUS
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {offerList.map((val, key) => {
            return <OfferListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OfferList;
