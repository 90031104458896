import React from "react";
import Avatar from "../../../../components/Avatar/Avatar";

const OrganizationTreeCard = () => {
  return (
    <div className="border border-gray-100 bg-white p-6 rounded-md shadow-md shadow-[#F3F4F6]">
      <div className="flex flex-col items-center">
        <Avatar />
        <p className="font-semibold pt-6 pb-1 text-[#1F2937]">
          Marvin McKinney
        </p>
        <p className="text-sm font-normal pb-1 text-[#1F2937]">
          Finance Manager
        </p>
        <p className="text-sm text-[#6B7280]">Finance</p>
      </div>
    </div>
  );
};

export default OrganizationTreeCard;
