import React, { useEffect, useState } from "react";
import Switch from "react-switch";

const ToggleSwitch = (props) => {
  const [isChecked, setIsChecked] = useState(!!props?.checked || false);

  useEffect(() => {
    setIsChecked(!!props?.checked);
  }, [props.checked]);

  const handleChange = (e) => {
    setIsChecked(e);
    props?.getToggleState && props.getToggleState(e);
  };

  return (
    <Switch
      checked={isChecked}
      onChange={handleChange}
      onColor="#3466E7"
      onHandleColor="#ffffff"
      handleDiameter={20}
      uncheckedIcon={false}
      checkedIcon={false}
       disabled={props?.disabled || false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={16}
      width={36}
    />
  );
};

export default ToggleSwitch;
