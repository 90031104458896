import React, { useContext, useState } from "react";
import LeaveDetailsModal from "../../LeaveDetailsModal/LeaveDetailsModal";
import formatDate from "../../../utils/FormatDate/formatDate";
import formatLeaveApplicationStatus from "../../../utils/FormatLeaveApplicationStatus/formatLeaveApplicationStatus";
import { closeIcon } from "../../../Icons/Icons";
import CancelLeaveModal from "../../CancelLeaveModal/CancelLeaveModal";
import formatLeaveDuration from "../../../utils/FormatLeaveDuration/formatLeaveDuration";
import { MyLeaveContext } from "../../../containers/Leaves/MyLeave/MyLeave";

const APPROVED_LABEL = "Approved";
const PENDING_LABEL = "Pending";

const LeaveRequestData = ({ leave }) => {
  const { isAdditionalLeaveTab, isLeaveTabView } = useContext(MyLeaveContext);

  const durationInDay = leave?.duration_in_day || 0;
  const durationInHour = leave?.duration_in_hour || 0;

  const duration = formatLeaveDuration({ durationInDay, durationInHour });
  const fromDate = formatDate(leave.from_date || leave.applied_for_date);
  const toDate = formatDate(leave.to_date);
  const hrStatus = leave.hr_approval_status_label;
  const managerStatus = leave.manager_approval_status_label;
  const leaveName = leave.leave_name;
  const isCanceled = leave.is_canceled;
  const status = isCanceled
    ? formatLeaveApplicationStatus("canceled")
    : formatLeaveApplicationStatus(hrStatus, managerStatus);
  const {
    leaveStatus,
    leaveStatusClass,
    // hrStatusClass,
    // managerStatusClass,
    // managerStatusLabel,
    // hrStatusLabel,
  } = status;
  const [slidingPanel, setSlidingPanel] = useState(false);
  const [isCancelLeaveModal, setCancelLeaveModal] = useState(false);

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isApprovedOrPending =
    leaveStatus === APPROVED_LABEL || leaveStatus === PENDING_LABEL;
  const isLeaveApplicationApprovedOrPendingAndDateOver =
    isApprovedOrPending &&
    (new Date() <= new Date(leave.from_date) ||
      isToday(new Date(leave.from_date)));

  const isCancelBtnEnabled =
    !isAdditionalLeaveTab &&
    !isCanceled &&
    isLeaveApplicationApprovedOrPendingAndDateOver;

  const handleSlidingPanel = (e) => {
    setSlidingPanel(e);
  };

  const handleCancelLeaveModal = () => {
    setCancelLeaveModal((prev) => !prev);
  };

  return (
    <>
      {slidingPanel && (
        <LeaveDetailsModal
          open={slidingPanel}
          close={() => handleSlidingPanel(false)}
          leaveApplicationId={
            isLeaveTabView
              ? leave?.leave_application_id
              : isAdditionalLeaveTab
              ? leave?.id
              : ""
          }
        />
      )}
      <CancelLeaveModal
        open={isCancelLeaveModal}
        close={() => setCancelLeaveModal(false)}
        leave={leave}
      />
      <tr className="px-5 text-black">
        <td
          className="text-blue-800 font-medium cursor-pointer px-10"
          onClick={() => handleSlidingPanel(true)}
        >
          {leave.leave_serial}
        </td>
        <td className="px-10">{leaveName}</td>

        <td className="px-10">{fromDate.localDate2}</td>
        {!isAdditionalLeaveTab ? (
          <td className="px-10">{toDate.localDate2}</td>
        ) : null}

        {!isAdditionalLeaveTab && <td>{duration}</td>}

        <td className="px-10">
          <span className={` ${leaveStatusClass} pr-2 pl-2`}>
            {leaveStatus}
          </span>
        </td>
        {!isAdditionalLeaveTab && (
          <td className="cursor-pointer px-10">
            {" "}
            {isCancelBtnEnabled ? (
              <button onClick={handleCancelLeaveModal}>{closeIcon}</button>
            ) : null}
          </td>
        )}
      </tr>
    </>
  );
};

export default LeaveRequestData;
