import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
import Avatar from "../../Avatar";

import { toast } from "react-toastify";
import formatAvatarLetter from "../../../utils/FormatAvatarLetter/formatAvatarLetters";
import {
  useEmployeeList,
  useAssignLineManager,
} from "../../../queries/employeeQueries";
import userRoles from "../../../config/userRoles";
const AssignLineManagerModal = ({ open, close, selectedEmployees }) => {



  const [selectedLineManager, setSelectedLineManager] = useState(null);

  const { data: managerData } = useEmployeeList({
    pageNo: 1,
    pageSize: 100,
    sortOrder: "DESC",
    roleId: userRoles.MANAGER,
  });

  const managerList = Array.isArray(managerData?.employee)
    ? managerData?.employee
    : [];


  useEffect(() => {
 
    if (
      selectedEmployees.length === 1 &&
      selectedEmployees[0]?.line_manager_name
    ) {
      const currentManager = {
        employee_id: selectedEmployees[0].line_manager_id, // line manager id
        first_name: selectedEmployees[0].line_manager_name.split(" ")[0],
        last_name: selectedEmployees[0].line_manager_name.split(" ")[1] || "", // line manaer name
      };
     
      setSelectedLineManager(currentManager);
    } else {
      setSelectedLineManager(null);
    }
  }, [selectedEmployees]);

  const assignLineManager = useAssignLineManager();

  const onSubmit = () => {
    if (!selectedLineManager) {
      return toast.info("Select a line manager from the list");
    }

    const reqObj = selectedEmployees.map((employee) => ({
      employeeId: employee.employee_id,
      lineManagerId: selectedLineManager.employee_id,
    }));

    assignLineManager.mutate(
      { employeeList: reqObj },
      {
        onSuccess: (data) => {
         if(data.status){
          toast.success("Line manager updated for the selected employee(s)");
          close();
         } else {
          toast.success("Failed to assign line manager for the selected employee(s)");
         }
        },
        onError: (error) => {
          toast.error(
            error?.response?.data?.error || "Failed to assign line manager(s)"
          );
        },
      }
    );
  };
  const managerOptions =
    selectedEmployees.length === 1
      ? managerList.filter(
          (manager) => manager.employee_id !== selectedEmployees[0]?.employee_id
        )
      : managerList;

     // console.log("Manger op:",managerOptions)
  // const isFlag = selectedEmployees.employee_id === selectedLineManager.employee_id
  return (
    <Modal open={open} onClose={close} center showCloseIcon={false}>
      <div className="w-[500px] flex flex-col">
        <div className="flex justify-between items-center gap-5">
          <h1 className="text-xl font-medium text-gray-800">
            Assign Line Manager
          </h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        <div className="py-2">
          <label>Select line manager</label>

          <Select
            options={managerOptions}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={(option) => option.employee_id}
            value={selectedLineManager}
            onChange={(selectedOption) =>
              setSelectedLineManager(selectedOption)
            }
            placeholder="Select Line Manager"
          />
        </div>

        <div className="max-h-[400px] overflow-y-auto px-1">
          {selectedEmployees.map((i) => {
            const empShortName = `${i?.first_name} ${i?.last_name}`;
            const avatarLetter = formatAvatarLetter(empShortName);
            const empName = `${i.first_name || ""} ${i.last_name || ""}`;
            const isFlag = i.employee_id === selectedLineManager?.employee_id

            return (
              <div className="border rounded-3xl px-2 py-1 my-2 flex items-center gap-2 bg-gray-50">
                <Avatar width="8" height="8" title={avatarLetter} />
                <span className="text-gray-800">{empName}</span>

                {isFlag && (
                  <span className="text-red-600 ml-2">
                    Line manager and employee cannot be the same
                  </span>
                )}
              </div>
            );
          })}
        </div>

        <div className="flex gap-3 justify-end mt-3">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn" onClick={onSubmit}>
            Assign Line Manager
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignLineManagerModal;
