import React, { useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import Line from "../../../Line/Line";
import ViewAttendanceHistorySlidingPanel from "./ViewAttendanceHistorySlidingPanel";
import AddManulTimeSlidingPanel from "../../ManageAttendance/FinalizeAttendance/AddManulTimeSlidingPanel";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const AttendanceDetailsSlidingPanel = ({
  open,
  close,
  attendanceId,
  handleOpenAttendanceDetailsSlidingPanel,
  isProcessAttendance,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [attendanceIdResponse, setAttendanceIdResponse] = useState([]);

  const fetchAttendanceDataForId = async () => {
    try {
      const response = await axiosPrivate.get(`attendance/getAttendanceDetails?attendance_id=${attendanceId}`);
      if (response?.data?.result) {
        setAttendanceIdResponse(response.data.result);
      } else {
        setAttendanceIdResponse([]);
      }
    } catch (error) {
      setAttendanceIdResponse([]);
    }
  };

  useEffect(() => {
    if (attendanceId && open) {
      fetchAttendanceDataForId();
    }
  }, [attendanceId, open]);

  const [viewHistorySlidingPanelOpen, setViewHistorySlidingPanelOpen] = useState(false);
  const [viewAddManualTimeSlidingPanel, setAddManualTimeSlidingPanel] = useState(false);

  const handleCloseViewHistorySlidingPanel = () => {
    setViewHistorySlidingPanelOpen(false);
  };

  const handleOpenViewHistorySlidingPanel = () => {
    close();
    setViewHistorySlidingPanelOpen(true);
  };

  const handleOpenAddManualTimeSlidingPanel = () => {
    close();
    setAddManualTimeSlidingPanel(true);
  };

  const handleCloseAddManualTimeSlidingPanel = () => {
    setAddManualTimeSlidingPanel(false);
  };
 
  return (
    <>
      <AddManulTimeSlidingPanel
        open={viewAddManualTimeSlidingPanel}
        close={handleCloseAddManualTimeSlidingPanel}
        handleOpenAttendanceDetailsSlidingPanel={handleOpenAttendanceDetailsSlidingPanel}
      />
      <ViewAttendanceHistorySlidingPanel
        handleOpenAttendanceDetailsSlidingPanel={handleOpenAttendanceDetailsSlidingPanel}
        attendanceId={attendanceId}
        open={viewHistorySlidingPanelOpen}
        close={handleCloseViewHistorySlidingPanel}
      />
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="480px">
        <div className="text-black">
          <div className="flex justify-between px-5 py-3">
            <span className="font-medium text-base">Attendance Details</span>
            <span className="inline-flex items-center gap-3">
              <p className="text-blue-600 cursor-pointer" onClick={handleOpenViewHistorySlidingPanel}>
                View History
              </p>
              <button onClick={close}>{closeIcon}</button>
            </span>
          </div>
          <Line />
          <div className="px-5 flex flex-col gap-3">
            <div>
              <h1 className="font-medium text-base">{attendanceIdResponse[0]?.attendance_date || "-"}</h1>
              <span>{attendanceIdResponse[0]?.shift_name || "-"}</span>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-1 text-gray-600">
                <ol>Attendance Status</ol>
                {isProcessAttendance ? <ol>Attendance Processing</ol> : null}
                <ol>Work schedule</ol>
                <ol>First Clock-In</ol>
                <ol>Last Clock-Out</ol>
                <ol>Deviation/Overtime</ol>
                <ol>Total Hours</ol>
                <ol>Payable Hours</ol>
              </div>
              <div className="flex flex-col gap-1">
                <ol>
                  <span className="status status--green">
                    {attendanceIdResponse[0]?.attendance_status_label || "-"}
                  </span>
                </ol>
                {isProcessAttendance ? (
                  <ol>
                    <span className="status status--gray">{"Open"}</span>
                  </ol>
                ) : null}
                <ol>{attendanceIdResponse[0]?.duration || "-"}</ol>
                <ol>{attendanceIdResponse[0]?.clock_in_time || "-"}</ol>
                <ol>{attendanceIdResponse[0]?.clock_out_time || "-"}</ol>
                <ol>{attendanceIdResponse[0]?.remaining_hours || "-"}</ol>
                <ol>{attendanceIdResponse[0]?.total_hours || "-"}</ol>
                <ol>{attendanceIdResponse[0]?.overtime_rounded_hours || "-"}</ol>
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <h1 className="text-base font-medium">Activity</h1>
                {isProcessAttendance ? (
                  <button
                    className="text-blue-600 font-medium"
                    onClick={handleOpenAddManualTimeSlidingPanel}
                  >
                    Add Manual Time
                  </button>
                ) : null}
              </div>
              <div className="flex justify-between text-gray-600 py-2">
                <span className="flex flex-col">
                  <span>Clock-In</span>
                  {attendanceIdResponse.map((item, index) => (
                    <span key={index} className="text-gray-600">
                      {item.clock_in_time ? `${item.clock_in_time} ${item.location_name}` : "-"}
                    </span>
                  ))}
                </span>
                <span className="flex flex-col">
                  <span>Clock-Out</span>
                  {attendanceIdResponse.map((item, index) => (
                    <span key={index} className="text-gray-600">
                      {item.clock_out_time ? `${item.clock_out_time}  ${item.location_name}` : "-"}
                    </span>
                  ))}
                </span>
              </div>
              <div className="text-gray-600 py-2">
                <span>Break </span>
                {attendanceIdResponse.map((item, index) => (
                  <span key={index} className={`${item.break_from ? "text-blue-600" : "text-gray-400"}`}>
                    {item.break_from ? `${item.break_from} - ${item.break_to}` : "-"}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default AttendanceDetailsSlidingPanel;
