import React from "react";

const OrganizationLocation = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 mt-8">
        <div>
          <h2>Locations</h2>
          <p className="pt-1">
            Create multiple locations for your organization and manage location
            details
          </p>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            <span>
              <i className="ico-plus text-white mr-3"></i>
            </span>
            New Location
          </a>
        </div>
      </div>
      <div className="mx-10 bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg max-w-[490px] max-h-[204px] p-6 mt-8">
        <div className="flex items-center justify-between pb-4">
          <p className="text-base font-semibold text-[#1F2937]">
            IV Main Office
          </p>
          <img src="/images/editicon.svg" alt="" className="cursor-pointer" />
        </div>
        <div className="flex gap-6">
          <ul className="flex flex-col gap-3 text-sm leading-5 font-normal">
            <li>Number of Employees</li>
            <li>Country</li>
            <li>State/Province</li>
            <li>Timezone</li>
          </ul>
          <ul className="flex flex-col gap-3 text-sm leading-5 font-medium text-[#1F2937]">
            <li>12</li>
            <li>India</li>
            <li>Kerala</li>
            <li>GMT-12:00 (Ec.GMT+12)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrganizationLocation;
