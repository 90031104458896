import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

const basePath = "/attendance";
const selfServicePath = `${basePath}/self-service`;
const manageAttendancePath = `${basePath}/manage-attendance`;
const manageShiftPath = `${basePath}/manage-shifts`;

const menu = [
  {
    text: "Self Service",
    link: `${selfServicePath}/my-attendance`,
    icon: "ico-down",
    menu: [
      {
        text: "My Attendance",
        link: `${selfServicePath}/my-attendance`,
        roles: [1, 2, 4, 6],
      },
      {
        text: "My Regularization Requests",
        roles: [1, 2, 4, 6],
        link: `${selfServicePath}/my-regulization-request`,
      },
      {
        text: "Who is in",
        link: `${selfServicePath}/who-is-in`,
        roles: [1, 2, 4],
      },
      {
        text: "My Shifts",
        link: `${selfServicePath}/my-shift`,
        roles: [1, 2, 4, 6],
      },
    ],
    roles: [],
    status: true,
  },
  {
    text: "Manage Attendance",
    link: `${manageAttendancePath}/overview`,
    icon: "ico-down",
    menu: [
      // {
      //   text: "Overview",
      //   link: `${manageAttendancePath}/overview`,
      //   roles: [1, 2, 4],
      // },
      {
        text: "Employee Attendance",
        link: `${manageAttendancePath}/employee-attendance`,
        roles: [1, 2, 4],
      },
      {
        text: "Regularization Approval",
        link: `${manageAttendancePath}/regularization-approval`,
        roles: [],
      },
      {
        text: "Finalize Attendance",
        link: `${manageAttendancePath}/finalize-attendance`,
        roles: [1, 2, 4],
      },
    ],
    roles: [1, 2],
  },
  {
    text: "Manage Shifts",
    link: `${manageShiftPath}/shift-lists`,
    icon: "ico-down",
    menu: [
      {
        text: "Shift Lists",
        link: `${manageShiftPath}/shift-lists`,
        roles: [1, 2],
      },
      {
        text: "Shift Calendar",
        link: `${manageShiftPath}/shift-calendar`,
        roles: [1, 2],
      },
      {
        text: "Assign Shift",
        link: `${manageShiftPath}/assign-shift`,
        roles: [1, 2],
      },
    ],
    roles: [1, 2],
  },
];


const AttendanceModule = () => {

  return (
    <DashboardLayout asideBarMenu={menu}/>
  );
};

export default AttendanceModule;
