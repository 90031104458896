import React, { useEffect, useState } from "react";
import EmployeeAttendanceListData from "./EmployeeAttendanceListData";
import formatDate from "../../../utils/FormatDate/formatDate";
import { generateDates } from "../../../utils/FormatDate/generateDates";

const EmployeeAttendanceList = ({ empAttendanceList, dateRange }) => {
  const cols = [
    { colName: "Employee Name" },
    { colName: "Emp. Type" },
    { colName: "Payable Hours/Work Shedule" },
  ];
  const [datesCol, setDatesCol] = useState([]);

  useEffect(() => {
    const getDate = dateRange.find((i) => i.status === true);
    setDatesCol(generateDates(getDate.fromDate, getDate.toDate));
  }, [dateRange]);

  return (
    <div className="flex">
      {/* EMP DETAIL TABLE */}
      <div>
        <table className="w-[700px] shadow-md text-sm text-left text-gray-500 dark:text-gray-500">
          <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              {cols.map((item, index) => {
                return (
                  <>
                    <th
                      scope="col"
                      className={`px-${
                        index > 2 ? "2" : "10"
                      } py-3 whitespace-nowrap`}
                      key={index}
                    >
                      {item.colName}
                      {index < 3 ? (
                        <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
                      ) : null}
                    </th>
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {empAttendanceList.map((val, index) => {
              return (
                <EmployeeAttendanceListData
                  data={val}
                  cols={cols}
                  key={index}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {/* DATES TABLE */}
      <div
        className="table-container select-none"
        style={{ overflowX: "auto", maxWidth: "100%" }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
          <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              {datesCol.map((item, index) => {
                return (
                  <>
                    <th
                      scope="col"
                      className={`px-2 py-3 whitespace-nowrap`}
                      key={index}
                    >
                      {formatDate(item).dateString3}
                    </th>
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {empAttendanceList.map((val, index) => {
              return (
                <EmployeeAttendanceListData
                  data={val}
                  datesCol={datesCol}
                  key={index}
                  isDate
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeAttendanceList;
