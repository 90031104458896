import React from "react";
import * as XLSX from "xlsx";

const ExcelExport = (props) => {
  const data = props.data;
  const fileName = props.fileName;
  const downloadExcel = () => {
    if (!data) {
      return;
    }
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <button onClick={downloadExcel} className="btn btn--border text-[14px]">
        <span>
          <i className="ico-download mr-1"></i>
        </span>
        Download CSV
      </button>
    </>
  );
};

export default ExcelExport;
