import React, { useState, useEffect } from "react";
import Avatar from "../../../Avatar/Avatar";
import {
  closeIconRoundRed,
  greenTickRound,
  revokeIcon2,
} from "../../../../Icons/Icons";
import RegularizationRequestDetailsSlidingPanel from "../../SelfService/MyRegularizationRequest/MyRegularizationRequestTable/RegularizationRequestDetailsSlidingPanel";
import RegularizationRequestActionModal from "./RegularizationRequestActionModal";
import { MODAL_VIEW, TAB } from "../../../../containers/Attendance/consts";
import axiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const RegularizationApprovalData = ({ data, toggleState, MakeAPICall }) => {
  const isCancelTab = toggleState === TAB.CANCELED_TAB;
  const isPendingTab =
    toggleState === TAB.ALL_TAB || toggleState === TAB.PENDING_TAB;
  const isApproveOrDeclineTab =
    toggleState === TAB.ALL_TAB ||
    toggleState === TAB.CANCELED_TAB ||
    toggleState === TAB.PENDING_TAB ||
    toggleState === TAB.APPROVED_TAB ||
    toggleState === TAB.DECLINED_TAB;
  const {
    employee_name,
    department,
    requested_date,
    type,
    regularization_date,
    reason,
    total_hours,
    regularization_request_id,
    from_time,
    to_time,
    approval_status,
  } = data;
  const approvalStatusLabel = "Approve";
  const rejectStatusLabel = "Reject";
  const pendingStatusLabel = "Pending";
  const cancelStatusLabel = "Cancel";

  const statusLabel =
    approval_status === approvalStatusLabel
      ? "Approved"
      : approval_status === rejectStatusLabel
      ? "Rejected"
      : approval_status === cancelStatusLabel
      ? "Canceled"
      : "";

  const statusClass =
    approval_status === approvalStatusLabel
      ? "status--green"
      : approval_status === rejectStatusLabel
      ? "status--red"
      : approval_status === cancelStatusLabel
      ? "status--gray"
      : "";

  const [
    openRegularizationRequestDetailsSlidingPanel,
    setRegularizationRequestDetailsSlidingPanel,
  ] = useState(false);
  const [openRegularizationActionModal, setRegularizationActionModalState] =
    useState(false);
  const [modalViewState, setModalViewState] = useState(null);
  const [hrNote, setHrNote] = useState("");
  const [isRegularizationIdClicked, setIsRegularizationIdClicked] = useState(false);


  const handleOpenRegularizationRequestSlidingPanel = () => {
    if (!openRegularizationRequestDetailsSlidingPanel) {
      setIsRegularizationIdClicked(true); 
      setRegularizationRequestDetailsSlidingPanel(true);
    }
  };

  const handleCloseRegularizationRequestSlidingPanel = () => {
    setIsRegularizationIdClicked(false); 
    setRegularizationRequestDetailsSlidingPanel(false);
  };

  const handleOpenRegularizationRequestActionModal = (modalType) => {
    setModalViewState(modalType);

    setRegularizationActionModalState(true);
  };

  const handleCloseRegularizationRequestActionModal = () => {
    setRegularizationActionModalState(false);
    MakeAPICall();
  };

  return (
    <>
      <RegularizationRequestDetailsSlidingPanel
        open={openRegularizationRequestDetailsSlidingPanel}
        close={handleCloseRegularizationRequestSlidingPanel}
        regularizationId={regularization_request_id}
        isViewFromRegularizationApprovalPage={true}
        isRegularizationIdClicked={true}
        handleOpenRegularizationDetailsSlidingPanel={
          handleOpenRegularizationRequestSlidingPanel
        }
      />

      <RegularizationRequestActionModal
        open={openRegularizationActionModal}
        close={handleCloseRegularizationRequestActionModal}
        modalViewState={modalViewState}
        regularizationId={regularization_request_id} 
        employee_name={employee_name}
        regularization_date={regularization_date}
        from_time={from_time}
        to_time={to_time}
        total_hours={total_hours}
        modalView={MODAL_VIEW}
        MakeAPICall={MakeAPICall}
      />
      <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200">
        {isCancelTab ? null : (
          <td className="px-10 py-4">
            <input type="checkbox" />
          </td>
        )}
        <td
          className="flex gap-3 px-10 py-4 cursor-pointer hover:text-blue-600"
          onClick={handleOpenRegularizationRequestSlidingPanel}
        >
          <Avatar />
          <div>
            <h1 className="font-medium">{employee_name}</h1>
            <p>{department}</p>
          </div>
        </td>
        <td className="px-10 py-4">
          <div>
            <h1 className="font-medium"> {type}</h1>
            <p>{requested_date}</p>
          </div>
        </td>
        <td className="px-10 py-4 font-medium">{regularization_date}</td>
        <td className="px-10 py-4 font-medium">{reason}</td>
        <td className="px-10 py-4 font-medium">{total_hours}</td>
        <td className="px-10 py-4">
          <div className="flex gap-3 items-center">
            {isApproveOrDeclineTab ? (
              <>
                {approval_status !== pendingStatusLabel && (
                  <span
                    className={`rounded-[10px] py-0.5 px-2.5 text-xs bg-yellow-500 text-black status ${statusClass}`}
                  >
                    {statusLabel}
                  </span>
                )}
                {approval_status === pendingStatusLabel ? (
                  <>
                    <button
                      onClick={() =>
                        handleOpenRegularizationRequestActionModal(
                          MODAL_VIEW.DECLINE_MODAL
                        )
                      }
                    >
                      {closeIconRoundRed}
                    </button>
                    <button
                      onClick={() =>
                        handleOpenRegularizationRequestActionModal(
                          MODAL_VIEW.APPROVE_MODAL
                        )
                      }
                    >
                      {greenTickRound}
                    </button>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

export default RegularizationApprovalData;
