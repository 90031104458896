import React from "react";
import formatDate from "../../../../utils/FormatDate/formatDate";
import Avatar from "../../../Avatar";
import formatLeaveDuration from "../../../../utils/FormatLeaveDuration/formatLeaveDuration";

const LeaveApplicationDetails = ({ leave }) => {
  const employeeName = `${leave?.first_name || ""} ${leave?.last_name || ""}`;
  const applicationDate = leave?.application_date
    ? formatDate(leave?.application_date).dateString2
    : "";
  const leaveSerial = leave?.leave_serial || "";
  const leaveName = leave?.leave_name || "";
  const leaveDate =
    leave?.from_date && leave?.to_date
      ? `${formatDate(leave.from_date).dateString2} - ${
          formatDate(leave.to_date).dateString2
        }`
      : "";
  const leaveTime =
    leave?.from_time && leave?.to_time
      ? `${leave?.from_time} - ${leave?.to_time}`
      : "";
  const leaveDuration = formatLeaveDuration({
    durationInDay: leave?.duration_in_date || 0,
    durationInHour: leave?.duration_in_hour || 0,
  });
  const leaveApplicationTime = leave?.application_date
    ? formatDate(leave.application_date).localString2
    : "";

  return (
    <div className="px-5">
      <p className="font-medium py-2">{applicationDate}</p>
      <div className=" flex gap-2">
        <div>
          <Avatar
            title={`${leave?.first_name?.[0] || ""}${
              leave?.last_name?.[0] || ""
            }`}
          />
        </div>
        <div className="w-full">
          <div className="flex justify-between ">
            {" "}
            <p className="font-medium">{employeeName}</p>
            <p>{leaveApplicationTime}</p>
          </div>
          <p>Request Leave #{leaveSerial}</p>
          <div className="rounded-md flex gap-5 text-gray-600 text-xs bg-gray-100 p-2 mt-1">
            <ul className="flex gap-1 flex-col">
              <li>Leave Type</li>
              <li>Date</li>
              <li>Time</li>
              <li>Total</li>
            </ul>
            <ul className="flex gap-1 flex-col">
              <li>: {leaveName}</li>
              <li>: {leaveDate}</li>
              <li>: {leaveTime}</li>
              <li>: {leaveDuration}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveApplicationDetails;
