import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import Avatar from "../../Avatar/Avatar";
import Line from "../../Line/Line";
import SpinnerLoader from "../../PageLoader/SpinnerLoader";
import { toast } from "react-toastify";
import formatAvatarLetter from "../../../utils/FormatAvatarLetter/formatAvatarLetters";
import {
  useAssignLeavePolicy,
  useListLeavePolicyMapping,
} from "../../../queries/leaveQueries";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const BulkLeavePolicyModal = ({
  open,
  close,
  selectedEmployees,
  leavePolicy,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const assignLeavePolicyMutation = useAssignLeavePolicy();

  const {
    data: policyDetails,
    isLoading: isLoadingPolicyDetails,
    error: policyDetailsError,
  } = useListLeavePolicyMapping(
    { policy_id: selectedPolicy },
    !!selectedPolicy
  );

  const handleDropdownChange = (selectedOption) => {
    setSelectedPolicy(selectedOption.value);
  };

  const disableUpdateButton =
    assignLeavePolicyMutation.isLoading ||
    policyDetails?.policy_list?.length > 0
      ? false
      : true;

  const handleSubmit = () => {
    if (selectedPolicy) {
      setErrMsg("");
      const formattedLeaveTypes = policyDetails.policy_list.map((item) => ({
        leaveTypeId: item.leave_type_id,
        leaveBalance: item.number_days_allowed,
      }));

      assignLeavePolicyMutation.mutate(
        {
          employee_id: selectedEmployees.map((emp) => emp.employee_id),
          leaveTypes: formattedLeaveTypes,
          leavePolicy: selectedPolicy,
        },
        {
          onSuccess: (data) => {
            toast.success(
              data?.message || "Leave policy assigned successfully!"
            );
            handleCloseModal();
          },
          onError: (error) => {
            const errorMessage =
              error?.response?.data?.message ||
              "Something went wrong while assigning leave policy";
            toast.error(errorMessage);
          },
        }
      );
    } else {
      toast.error("Select a leave policy");
    }
  };

  const handleCloseModal = () => {
    close();
  };

  return (
    <Modal open={open} onClose={handleCloseModal} center>
      <div className="p-5 min-w-[420px] text-black">
        <h1 className="text-base font-medium">Manage Bulk Leave Policy</h1>
        <Line />

        <div className="flex flex-col gap-3">
          {errMsg && <span className="text-red-600 font-medium">{errMsg}</span>}

          <div>
            {leavePolicy && leavePolicy.length ? (
              <>
                <p className="py-4 text-sm font-medium">Select leave policy:</p>
                <Select
                  components={{ DropdownIndicator }}
                  options={leavePolicy.map((item) => ({
                    label: item.policy_name,
                    value: item.id,
                  }))}
                  onChange={handleDropdownChange}
                  formatOptionLabel={(option) => (
                    <div className="flex items-center justify-between">
                      {option.label}
                    </div>
                  )}
                />

                {isLoadingPolicyDetails ? (
                  <SpinnerLoader />
                ) : policyDetailsError ? (
                  <span className="text-red-600 font-medium">
                    Failed to load policy details
                  </span>
                ) : policyDetails?.policy_list?.length ? (
                  <div className="border rounded-md p-2 shadow-md mt-4">
                    <label>Leave Policy Details:</label>
                    {policyDetails?.policy_list?.map((detail) => (
                      <ul
                        className="flex justify-between"
                        key={detail?.leave_type_id}
                      >
                        <li>{detail?.leave_name}</li>
                        <li>{detail?.number_days_allowed} day(s)</li>
                      </ul>
                    ))}
                  </div>
                ) : selectedPolicy ? (
                  <span className="text-red-600 font-medium">
                    Selected policy have no active leave types
                  </span>
                ) : null}
              </>
            ) : (
              <span className="text-red-600 font-medium">
                Create leave policy to assign
              </span>
            )}
          </div>

          <div className="max-h-[400px] overflow-y-auto px-1">
            {selectedEmployees.map((i) => {
              const empShortName = `${i?.first_name} ${i?.last_name}`;
              const avatarLetter = formatAvatarLetter(empShortName);
              const empName = `${i.first_name || ""} ${i.last_name || ""}`;
              return (
                <div
                  className="border rounded-3xl px-2 py-1 my-2 flex items-center gap-2 bg-gray-50"
                  key={i.employee_id}
                >
                  <Avatar width="8" height="8" title={avatarLetter} />
                  <span className="text-gray-800">{empName}</span>
                </div>
              );
            })}
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              className="btn btn--border"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              className={`btn text-[14px] pt-[11px] ${
                disableUpdateButton
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleSubmit}
              disabled={disableUpdateButton}
            >
              {assignLeavePolicyMutation.isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BulkLeavePolicyModal;
