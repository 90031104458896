import React, { useState, useEffect } from "react";
import Line from "../../../../../components/Line";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import axios from "../../../../../helpers/axios";
import {
  bloodGroup,
  genderOptions,
  residentialStatusOptions,
} from "../../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { useForm, Controller } from "react-hook-form";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const EmployeePersonalDetails = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const getDataFromLocalStorage =
    JSON?.parse?.(localStorage?.getItem("employeePersonalData")) || {};
  const [errMsg, setErrMsg] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: getDataFromLocalStorage,
  });

  useEffect(() => {
    axios.get("onboarding/services/location").then((response) => {
      setCountry(response.data.country);
    });
  }, []);

  const storeEmployeePersonalDetails = (data) => {
    localStorage.setItem("employeePersonalData", JSON.stringify(data));
    navigate("/employee/manage-employee/employee-contact-details");
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex-1 justify-center max-w-[900px] text-[14px]">
        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Personal Details{" "}
          </h2>
          <p>Fill all employee personal basic information</p>
        </div>
        <form
          onSubmit={handleSubmit(storeEmployeePersonalDetails)}
          className="min-h-screen"
        >
          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1">
            {errMsg}
          </span>
          <div className="lg:flex gap-4">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Date Of Birth{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <div className="relative">
                <input
                  type="date"
                  {...register("dateOfBirth", {
                    required: "This field is required",
                  })}
                />
                {errors?.dateOfBirth && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.dateOfBirth.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Father's name{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("fatherName", {
                  required: "This field is required",
                })}
              />
              {errors?.fatherName && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.fatherName.message}
                </span>
              )}
            </div>
          </div>
          <div className="lg:flex gap-4">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Gender <span className="text-red-600 font-medium">*</span>{" "}
              </label>

              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      genderOptions.find((i) => i?.value === value)?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={genderOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.gender && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.gender.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">Blood Group </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="bloodGroup"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      bloodGroup.find((i) => i?.value === value)?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => onChange(e.value)}
                    options={bloodGroup}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.bloodGroup && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.bloodGroup.message}
                </span>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4">
            <div className="flex-1 mb-4">
              <label htmlFor="">Marital Status</label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="martialStatus"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      [
                        { value: "Single", label: "Single" },
                        { value: "Married", label: "Married" },
                      ].find((i) => i?.value === value)?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.value);
                      setIsInputDisabled(e.value === "Single");
                    }}
                    options={[
                      { value: "Single", label: "Single" },
                      { value: "Married", label: "Married" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.martialStatus && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.martialStatus.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">Spouse Name </label>
              <input
                type="text"
                disabled={isInputDisabled}
                {...register("spouseName", {
                  required: isInputDisabled ? false : "This fied is required",
                })}
              />
              {errors?.spouseName && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.spouseName.message}
                </span>
              )}
            </div>
          </div>
          <div className="lg:flex gap-4">
            <div className="flex-1 mb-4">
              <label htmlFor="">Residential Status</label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="residentialStatus"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      residentialStatusOptions.find((i) => i?.value === value)
                        ?.label
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.value);
                    }}
                    options={residentialStatusOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                )}
              />
              {errors?.residentialStatus && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.residentialStatus.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">Nationality </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      Array.isArray(country)
                        ? country.find((i) => Number(i?.id) === Number(value))
                            ?.flag
                        : ""
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.id);
                    }}
                    options={Array.isArray(country) ? country : []}
                    getOptionLabel={(option) => option.flag}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.nationality && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.nationality.message}
                </span>
              )}
            </div>
          </div>

          <div className=" mb-4">
            <label
              htmlFor=""
              className="text-sm leading-5 font-medium text-gray-500"
            >
              <input type="checkbox" {...register("physicallyChallenged")} />{" "}
              Physically challenged{" "}
            </label>
          </div>
          <Line />

          <div className="flex justify-end pt-6">
            <Link
              to="/employee-module/create-employee"
              className="btn btn--border ml-3 text-[14px]"
            >
              Back
            </Link>
            <button type="submit" className="btn ml-3 text-[14px]">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeePersonalDetails;
