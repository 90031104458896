import React from "react";
import Avatar from "../../../Avatar/Avatar";
import moment from "moment";

const ShiftCalendarData = ({ data, isDates, datesCol }) => {
  const employeeName = `${data?.first_name || ""} ${data?.last_name || ""}`;
  const departmentTitle = data?.department_name;
  const employeePhoto = data?.photo;


  return (
    <>
      {isDates && (
        <>
          {data.map((employee, index) => {
            return (
              <tr className="h-[90px]" key={`${index + 1}_dates_col_t_row`}>
                {datesCol.map((date, index) => {
                  const shift = employee?.shiftDetails?.find(
                    (i) => moment(i?.date).format("YYYY-MM-DD") === date
                  );
                  const shiftName = shift?.shift_name?.slice(0, 3);
                  const color = shift?.color_code || "";
                  const isWorkingDay = shift?.isWorkingDay;
                  return (
                    <td
                      style={{
                        backgroundColor: isWorkingDay ? color : "#EFF1F5",
                        color: "black",
                      }}
                      className={`border px-1 font-medium text-center border-white w-[70px] h-[64px]`}
                      key={`shift_dates_col_${index}`}
                    >
                      {!shift ? "Unallocated" : isWorkingDay ? shiftName : "-"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </>
      )}

      {!isDates ? (
        <tr>
          <td className="w-[200px] h-[90px]">
            <div className="flex gap-2">
              <Avatar image={employeePhoto} />
              <span>
                <p className="font-medium text-black">{employeeName}</p>
                <p>{departmentTitle}</p>
              </span>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default ShiftCalendarData;
