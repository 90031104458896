import React from "react";
import Avatar from "../../../../Avatar/Avatar";

const ApplicableForSelectList = ({
  handleChangeApplicableFor,
  isExist,
  referenceId,
  title,
  photo,
  tag,
  uniqueId,
  isEmployeeList
}) => {
  const handleSelect = (status) => {
    handleChangeApplicableFor({
      status,
      referenceId,
      tag,
      title,
      photo,
      uniqueId,
    });
  };
  return (
    <div className="flex items-center gap-3">
      <input
        type="checkbox"
        checked={isExist}
        onChange={(e) => handleSelect(e.target.checked)}
      />
      <div className="flex items-center gap-2">
        {isEmployeeList ? <Avatar image={photo} width={"6"} height={"6"} /> : null}{" "}
        <span className="font-medium">{title}</span>
      </div>
    </div>
  );
};

export default ApplicableForSelectList;
