import React from "react";

import DepartmentList from "../../components/Organizations/DepartmentList/DepartmentList";

const OrganizationDepartment = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Departments</h2>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            Add Component
            <span>
              <i className="ico-down text-white ml-3"></i>
            </span>
          </a>
        </div>
      </div>
      <DepartmentList />
    </div>
  );
};

export default OrganizationDepartment;
