import React, { useState } from "react";
import PreferenceTab from "../../components/Assets/AssetsTab/PreferenceTab";
import TabSelector from "../../components/TabSelector/TabSelector";
import StatutoryCard from "./StatutoryCard";

const PayrollStatutoryComponents = () => {
  const [index, setIndex] = useState(0);

  const getTabIndex = (e) => {
    setIndex(e);
  };
  const tabs = ["Profesional Tax", "EPF", "ESI", "Labour Welfare Fund"];
  const components = [<StatutoryCard />];
  return (
    <div className="px-10 py-6 w-full">
      <div className="px-10">
        <h2 className="text-xl font-semibold">Statutory Components</h2>
      </div>
      <PreferenceTab tabs={tabs} getTabIndex={getTabIndex} />
      <TabSelector tabIndex={index} components={components} />
    </div>
  );
};

export default PayrollStatutoryComponents;
