import React, { useState } from "react";
import Select from "react-select";
import Search from "../../../components/Header/Search/Search";
import ShiftCard from "../../../components/ShiftCard/ShiftCard";
import AddNewShiftSlidingPanel from "../../../components/Attendance/ManageShift/ShiftList/AddNewShiftSlidingPanel";
import { DropdownIndicator, shiftTypes, statusOptions } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader";
import { useShiftList } from "../../../queries/attendanceQuries";

const ShiftSchedule = () => {
  const [openAddNewShiftSlidingPanel, setOpenAddNewShiftSlidingPanel] = useState(false);
  const [shiftType, setSelectedShiftType] = useState(null);
  const [statusOption, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const handleOpenAddNewShiftSlidingPanel = () => {
    setOpenAddNewShiftSlidingPanel(true);
  };
  const handleCloseAddNewShiftSlidingPanel = () => {
    setOpenAddNewShiftSlidingPanel(false);
  };

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  const clearAllFilter = () => {
    setSelectedShiftType(null);
    setSearchValue(null);
    setStatus(null);
  };

  const queryParams = {
    shift_type_id: shiftType || undefined,
    status: statusOption || undefined,
    shift_name: searchValue || undefined,
  };

  const { data, isLoading, isError, refetch } = useShiftList(queryParams);
  const isFilterApplied = shiftType || statusOption || searchValue

  return (
    <>
      {openAddNewShiftSlidingPanel ? (
        <AddNewShiftSlidingPanel
          open={openAddNewShiftSlidingPanel}
          close={handleCloseAddNewShiftSlidingPanel}
          // fetchShiftList={refetch} 
        />
      ) : null}

      <div className="w-full px-10">
        <div className="flex items-center justify-between pb-4 mt-8">
          <div>
            <h2>Shift Lists</h2>
            <p>Manage all employee regularization</p>
          </div>
          <div>
            <button className="btn text-[14px]" onClick={handleOpenAddNewShiftSlidingPanel}>
              <span>
                <i className="ico-plus text-white mr-1"></i>
              </span>
              Add new shift
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center pb-4">
          <div className="flex gap-3">
            <Select
              options={shiftTypes}
              components={{ DropdownIndicator }}
              className="custom-select-container text-gray-500"
              defaultValue={{ value: "Choose Shift Type", label: "All Shift Type" }}
              onChange={(e) => setSelectedShiftType(e.value)}
            />
            <Select
              options={statusOptions}
              components={{ DropdownIndicator }}
              className="custom-select-container text-gray-500"
              defaultValue={{ value: "Status: All", label: "Status: All" }}
              onChange={(e) => setStatus(e.value)}
            />
            {isFilterApplied ? (
              <p className="text-blue-600 font-medium pt-2 cursor-pointer" onClick={clearAllFilter}>
                Clear filter
              </p>
            ) : null}
          </div>

          <div className="flex">
            <div>
              <Search onChange={getSearchValue} />
            </div>
            <a href="" className="btn btn--border text-[14px]">
              <span>
                <i className="ico-download mr-1"></i>
              </span>
              Download
            </a>
          </div>
        </div>

        {isLoading ? (
          <PageLoader />
        ) : isError ? (
          <EmptyState />
        ) :  Array.isArray(data?.result) && data.result.length > 0 ? (
          <ShiftCard shiftList={data.result} />
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

export default ShiftSchedule;
