import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import formatDate from "../../utils/FormatDate/formatDate";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import Avatar from "../../components/Avatar";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";
import { closeIcon } from "../../Icons/Icons";

const ADDITIONAL_LEAVE_APPLIED_DAYS = 1; // additional leave can be only applied for one day



const ViewEmployeeLeaveWithDateRange = ({ openModal, onCloseModal, data }) => {

  const NoEmployeeAppliedMessage = (
    <div
      className="p-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:text-blue-400"
      role="alert"
    >
      <span className="font-medium">Info: </span>
      No other employee applied for the same date
    </div>
  );

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center closeIcon=" ">
        <div className="px-2 max-w-auto">
          <div
            className="flex justify-end py-3 cursor-pointer"
            onClick={onCloseModal}
          >
            {closeIcon}
          </div>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((val, index) => {
              const employeeName = `${val?.first_name || ""} ${
                val?.last_name || ""
              }`;
              const avatarTitle = formatAvatarLetter(employeeName);
              const fromDate = formatDate(
                val?.applied_for_date || val?.from_date
              );
              const toDate = formatDate(val?.applied_for_date || val?.to_date);
              const leaveName = val?.leave_name || "";
              const fromTime = val?.from_time || "";
              const toTime = val?.to_time || "";
              const durationInDay = val?.duration_in_day || 0;
              const durationInHour = val?.duration_in_hour || 0;
              const duration = val?.applied_for_date
                ? ADDITIONAL_LEAVE_APPLIED_DAYS
                : formatLeaveDuration({
                    durationInDay,
                    durationInHour,
                  });
              const durationLabel =
                fromTime && toTime
                  ? `${fromDate.dateString2}, ${fromTime} - ${toTime} (${duration})`
                  : `${fromDate.dateString2} - ${toDate.dateString2} (${duration})`;

              return (
                <div
                  className="flex items-center justify-between py-1 gap-x-10"
                  key={`view_employee_leave_on_same_date_key_${index}`}
                >
                  <div className="p-2 flex h-10 gap-2 items-center rounded-3xl w-1/2 bg-gray-100">
                    <Avatar title={avatarTitle} width={"6"} height={"6"} />
                    <p className="font-medium whitespace-nowrap w-full text-gray-800">
                      {employeeName}
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <div>
                      <p className="text-end font-medium text-black">
                        {leaveName}
                      </p>
                      <span className="text-xs text-gray-500 whitespace-nowrap">
                        {durationLabel}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            NoEmployeeAppliedMessage
          )
          
          }
        </div>
      </Modal>
    </>
  );
};

export default ViewEmployeeLeaveWithDateRange;
