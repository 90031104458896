import React from "react";
import { SYSTEM_POLICY_APPROVAL_STATUS } from "../../../../Consts/consts";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";

const PolicyApprovalDetails = ({ systemPolicy }) => {
  const loggedInUser = useSelector((state) => state.user.auth);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const {
    is_creator,
    approval_remark,
    approved_date,
    approval_status_id,
    is_depricated,
  } = systemPolicy;
  const approvalStatusId = Number(approval_status_id);
  const isDepricated = Number(is_depricated);
  const approvedStatusLabel = isDepricated
    ? "Depricated"
    : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.approved
    ? "Approved"
    : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.declined
    ? "Declined"
    : "Pending";
  const approvedStatusLabelClass = isDepricated
    ? "status--gray"
    : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.approved
    ? "status--green"
    : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.declined
    ? "status--red"
    : "";

  const formattedApprovedDate = approved_date
    ? formatDate(approved_date).dateString2
    : "N/A";
  if (is_creator || isAdmin) {
    return (
      <div className="border shadow-md rounded-md p-2 flex flex-col">
        <div className="text-center font-medium text-gray-800 mb-3">
          Approval status
        </div>
        <div className="flex justify-between">
        <ul className="flex flex-col gap-1">
          <li>Status</li>
          <li>Remark</li>
          <li>Approved date</li>
        </ul>
        <ul className="text-gray-800 font-medium flex flex-col gap-1 text-end">
          <li>
            <span className={`status ${approvedStatusLabelClass}`}>
              {approvedStatusLabel}
            </span>
          </li>
          <li>{approval_remark || "N/A"}</li>
          <li>{formattedApprovedDate}</li>
        </ul>
        </div>
      </div>
    );
  }
};

export default PolicyApprovalDetails;
