import React, { useState } from "react";
import UploadFileIcon from "../../../../../Icons/UploadFileIcon";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const ONBOARDING_EMP_ACCOUNT_STATUS = 2;
const ACTIVE_EMP_ACCOUNT_STATUS = 1;

const BulkUploadEmployeeModal = ({ close, fetchEmployeeData, open }) => {
  const [errMsg, setErrMsg] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [empAccountStatus, setEmpAccountStatus] = useState(
    ONBOARDING_EMP_ACCOUNT_STATUS
  );

  const validateUploadedCsvFile = (file) => {
    if (
      file?.type === "text/csv" ||
      file?.type === "application/vnd.ms-excel"
    ) {
      setErrMsg("");
      if (file?.size < 1033414) {
        setErrMsg("");
        setUploadedFile(file);
      } else {
        setErrMsg(
          "Uploaded CSV file is too large, try uploading a file less than 1 MB"
        );
      }
    } else {
      setErrMsg("Upload a valid CSV file");
    }
  };

  const processUplodedFile = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/processFile?activateEmployeeAccount=${empAccountStatus}`, {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data);
        fetchEmployeeData();
      })
      .catch((err) => {
        toast.error("No server response");
        console.error(err);
      });
  };

  const uploadBulkEmployee = async () => {
    if (uploadedFile !== null) {
      let formData = new FormData();
      formData.append("file", uploadedFile);

      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post("employee/addBulkEmployee", formData, {
          signal: controller.signal,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // isMounted && console.log(response.data);
          if (response.data.statusCode == 200) {
            setUploadedFile(null);
            toast.success(
              "We have received the file and it will be processed in the background"
            );
            processUplodedFile();
            close();
          } else if (response.data.message == "KEY_EXPIRED") {
            toast.error("Session expired login again");
          } else {
            toast.error("Failed to upload");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "No server response");
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      toast.info("Upload a CSV file");
    }
  };

  return (
    <>
      <Modal open={open} onClose={close} center>
        <div className="text-center items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t min-w-[500px] ">
          <h3 className="text-xl text-black font-semibold">
            Bulk Upload Employee
          </h3>
        </div>
        <div className="flex justify-center">
          <div className=" max-w-[350px] mb-5 font-semibold">
            <br />
            <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2">
              {errMsg}
            </span>

            <div className="block h-[100px] max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100  dark:border-gray-700 dark:hover:bg-gray-100 mb-4">
              <input
                id="upload-csv"
                type="file"
                className="hidden"
                accept="text/csv"
                onChange={(e) => validateUploadedCsvFile(e.target.files[0])}
              />
              <label
                for="upload-csv"
                className="flex justify-center items-center h-[50px]"
              >
                <span>
                  <UploadFileIcon />
                </span>
              </label>
            </div>
            <label htmlFor="">Upload CSV file with employee data</label>
            <br />
            <div className="font-normal mt-2">
              {uploadedFile ? (
                <div>
                  <div
                    className="p-2 text-sm text-blue-800 rounded-lg bg-blue-50"
                    role="alert"
                  >
                    <span className="font-medium">Uploaded file: </span>
                    &nbsp; {uploadedFile.name}
                  </div>
                  <div className=" pt-2">
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setEmpAccountStatus(ACTIVE_EMP_ACCOUNT_STATUS);
                          } else {
                            setEmpAccountStatus(ONBOARDING_EMP_ACCOUNT_STATUS);
                          }
                        }}
                      />
                      <span className="text-gray-800 font-medium">
                        Employee account status:
                      </span>
                    </div>{" "}

                   <div className="ml-6">
                   {empAccountStatus === ONBOARDING_EMP_ACCOUNT_STATUS
                      ? "Account activation will be initiated from the employee side at the time of account setup."
                      : "Account will be activated while processing the file"}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
          <button
            className="btn btn--border mr-2"
            type="button"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            className="btn  mr-2"
            type="button"
            onClick={() => uploadBulkEmployee()}
          >
            Upload
          </button>
        </div>
      </Modal>
    </>
  );
};

export default BulkUploadEmployeeModal;
