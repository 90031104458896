import React from "react";
import Avatar from "../Avatar";

const OfferListData = (props) => {
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td scope="row" class="px-10 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <input type="checkbox" className="mr-3" />
          {props.requestdata.id}
        </div>
      </td>
      <td class="px-10 py-4 flex items-center gap-3 border-0">
        <Avatar image={props.requestdata.image} />
        {props.requestdata.candidate_name}
      </td>
      <td class="px-10 py-4">{props.requestdata.recruiter}</td>
      <td class="px-10 py-4">{props.requestdata.offer_date}</td>
      <td class="px-10 py-4">
        <span className="status status--green py-1 px-2.5">
          {props.requestdata.status}
        </span>
      </td>
      <td class="px-10 py-4">
        <span className="status status--red py-1 px-2.5">
          {props.requestdata.hr_status}
        </span>
      </td>
      <td class="px-10 py-4">
        <span className="status status--yellow py-1 px-2.5">
          {props.requestdata.manager_status}
        </span>
      </td>
      <td class="px-10 py-4">
        <span className="status status--yellow py-1 px-2.5">
          {props.requestdata.candidate_status}
        </span>
      </td>
      <td class="px-10 py-4 flex justify-between items-center border-none">
        <i className="ico-chat text-gray-500 cursor-pointer pl-5"></i>
        <i className="ico-more text-gray-500 cursor-pointer pl-5"></i>
      </td>
    </tr>
  );
};

export default OfferListData;
