import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ToggleSwitch from "../../ToggleSwitch";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { settingCategory } from "../../../utils/Setting/setting";
import { ActionCreators } from "../../../redux/actions/profile";

const LeaveSetting = () => {
  const { tenantSetting } = useSelector((state) => state.user);
 // console.log("teanant settings",tenantSetting)
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const [col, setCol] = useState([
    { colName: "Setting", icoDown: true, action: null, status: true },
    { colName: "Status", icoDown: true, action: null, status: true },
  ]);

  const HrLeaveApprovalRequiredSetting = tenantSetting?.find(
    (i) =>
      Number(i?.setting_category_id) ===
      Number(settingCategory?.isHrLeaveApprovalRequired)
  );
  const HrApprovalRequiredForAdditionalRequestsSetting = tenantSetting?.find(
    (i) =>
      Number(i?.setting_category_id) ===
      Number(settingCategory?.isHrLeaveApprovalRequiredForAdditionalLeaves)
  );

  const updateSettingValue = async (req) => {
    try {
      const response = await axiosPrivate.put("auth/updateSetting", req);
      if (response?.data?.status) {
        const updatedSetting = tenantSetting?.map((item) => {
          if (Number(item.setting_category_id) === Number(req.categoryId)) {
            return {
              ...item,
              setting_value: Number(req.settingValue),
            };
          }
          return item;
        });
        dispatch(ActionCreators.setTenantSetting(updatedSetting || []));
        toast.success("Setting value updated");
      } else {
        toast.error("Something went wrong, Try again later");
      }
    } catch (err) {
      toast.error("Something went wrong, Try again later");
    }
  };

  const getToggleState = (e) => {
   // console.log("getToggleState", e);
    const reqObj = {
      categoryId: settingCategory.isHrLeaveApprovalRequired,
      settingValue: Number(e),
    };
  //  console.log("reObject for settings", reqObj);
    updateSettingValue(reqObj);
  };
  
  const getAddtionalSettingsToggleState = (e) => {
   // console.log("getAddtionalSettingsToggleState", e);
    const reqObj = {
      categoryId: settingCategory.isHrLeaveApprovalRequiredForAdditionalLeaves,
      settingValue: Number(e),
    };
   // console.log("reObject for additional settings", reqObj);
    updateSettingValue(reqObj);
  };

  return (
    <div className="border">
      <table className="">
        <thead>
          <tr className="px-5">
            {col.map((item, index) =>
              item.status ? (
                <td className="items-center" key={`leave_req_list_col_${index}`}>
                  <span>{item.colName}</span>
                </td>
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>HR Leave Approval Required</td>
            <td>
              <ToggleSwitch
                checked={Number(HrLeaveApprovalRequiredSetting?.setting_value || 0)}
                getToggleState={getToggleState}
              />
            </td>
          </tr>
          <tr>
            <td>HR Approval Required For Additional Requests</td>
            <td>
              <ToggleSwitch
                checked={Number(HrApprovalRequiredForAdditionalRequestsSetting?.setting_value || 0)}
                getToggleState={getAddtionalSettingsToggleState}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LeaveSetting;
