import React, { useState } from "react";
import Avatar from "../../Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import MenuButtonForOfferListPage from "../CandidateOfferListMenuButton/MenuButtonForOfferListPage";

const OfferListData = (props) => {
  const candidate = props.requestdata;
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [isSendingBtn, setIsSendingBtn] = useState(false);
  var offerSendDate = candidate.offer_send_date.split("T")[0];
  var getofferDueDate = new Date(offerSendDate);
  getofferDueDate.setDate(getofferDueDate.getDate() + 7);
  var day = getofferDueDate.getDate();
  var month = getofferDueDate.getMonth() + 1;
  var year = getofferDueDate.getFullYear();
  const offerDueDate = day + "-" + month + "-" + year;

  const optionBtnfunctions = {
    sendPasswordLinkBtn: async () => {
      if (candidate.hr_approved_status == 0) {
        try {
          toast.info("Sending password setup link");
          setIsSendingBtn(true);
          let isMounted = true;
          const controller = new AbortController();
          await axiosPrivate
            .post(
              "employee/sendPasswordSetupLink",
              {
                reference_id: candidate.reference_id,
              },
              {
                signal: controller.signal,
              }
            )
            .then((response) => {
              if (response.data.status) {
                setIsSendingBtn(false);
                toast.success("Password setup link sent successfully!", {
                  type: "success",
                });
                setTimeout(() => {
                  setOpen(!open);
                  candidate.hr_approved_status = 1;
                }, 500);
              } else if (
                !response.data.status &&
                response.data.statusCode == 200
              ) {
                setIsSendingBtn(false);
              } else {
                toast.error("Passoword setup link sending failed!");
              }
            })
            .catch((err) => {
              console.error(err);
            });

          return () => {
            isMounted = false;
            controller.abort();
          };
        } catch (err) {
        }
      }
    },
    waitForApproval: () => {
      toast.warn(
        `Wait for ${candidate.status != 2 ? "candidate" : ""} ${
          candidate.status != 2 && candidate.manager_approved_status != 1
            ? "and"
            : ""
        }  ${
          candidate.manager_approved_status != 1 ? "manager" : ""
        } approval before sending mail`
      );
    },
  };

  return (
    <>
      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td
          scope="row"
          className="px-10 py-4 font-medium text-gray-800 whitespace-nowrap"
        >
          {candidate.candidate_serial}
        </td>
        <td className="px-10 py-4 flex items-center gap-3 border-0">
          <Avatar image={candidate.photo} />
          {candidate.first_name}
        </td>
        <td className="px-10 py-4">
          {offerSendDate.split("-").reverse().join("-")}
        </td>
        <td className="px-10 py-4">{offerDueDate}</td>
        <td className="px-10 py-4">{candidate.employee_desgination}</td>
        <td
          className={
            candidate.status == 2
              ? "px-10 py-4 text-green-600"
              : "px-10 py-4 text-yellow-600"
          }
        >
          {candidate.status == 2 ? "Approved" : "Pending"}
        </td>
        <td
          className={
            candidate.manager_approved_status == 1
              ? "px-10 py-4 text-green-600"
              : "px-10 py-4 text-yellow-600"
          }
        >
          {candidate.manager_approved_status == 1 ? "Approved" : "Pending"}
        </td>
        <td
          className={
            candidate.hr_approved_status == 1
              ? "px-10 py-4 text-green-600"
              : "px-10 py-4 text-yellow-600"
          }
        >
          {candidate.hr_approved_status == 1 ? "Approved" : "Pending"}
        </td>
        <td
          className={
            candidate.hr_approved_status == 1
              ? "px-10 py-4 text-green-600"
              : "px-10 py-4 text-yellow-600"
          }
        >
          {candidate.hr_approved_status == 1
            ? "Password setup mail disbursed"
            : "Pending"}
        </td>
        <td className="px-10 py-4 text-right">
          <MenuButtonForOfferListPage
            btnFunction={optionBtnfunctions}
            title={
              candidate.hr_approved_status == 1
                ? "Password setup link sent successfully"
                : isSendingBtn
                ? "Sending password setup link"
                : candidate.hr_approved_status == 1
                ? "Password setup link sent successfully"
                : "Send password setup link"
            }
            btnStatus={
              candidate.hr_approved_status == 1
                ? "SendSuccess"
                : isSendingBtn
                ? "Sending"
                : candidate.hr_approved_status == 1
                ? "SendSuccess"
                : ""
            }
            isBtnEnabled={
              candidate.status == 2 && candidate.manager_approved_status == 1
                ? true
                : false
            }
          />
        </td>
      </tr>
    </>
  );
};

export default OfferListData;
