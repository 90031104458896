import React from "react";
import { Modal } from "react-responsive-modal";
import { closeIcon } from "../../../../Icons/Icons";
import Avatar from "../../../Avatar/Avatar";
import { MODAL_VIEW } from "../../../../containers/Attendance/consts";

const data = [
    {name: "Ruben Mathews", date: "19 Jan 2023 (8 hr)"},
    {name: "Sreeram S", date: "19 Jan 2023 (8 hr)"},
    {name: "jazeem J", date: "19 Jan 2023 (8 hr)"},
    {name: "Ananthu S", date: "19 Jan 2023 (8 hr)"},
]

const RegularizationRequestMultipleActionModal = ({
  open,
  close,
  modalViewState,
}) => {
  const isApprovalView = modalViewState === MODAL_VIEW.APPROVE_MODAL;
  const isDecliceView = modalViewState === MODAL_VIEW.DECLINE_MODAL;
  const isRevokeView = modalViewState === MODAL_VIEW.REVOKE_MODAL;
  const mainTitle = isApprovalView
    ? "Approve Regularization"
    : isDecliceView
    ? "Decline Regularization"
    : "Revoke Approval";
  const btnClass = isApprovalView
    ? "btn--green"
    : isDecliceView
    ? "btn--red"
    : "";
  return (
    <Modal open={open} onClose={close} closeIcon=" " center>
      <div className="w-[420px] px-5 text-black">
        <div className="flex justify-between items-center py-4">
          <h1 className="font-medium text-base">{mainTitle}</h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        {isRevokeView ? (
          <div className="py-3">
            This will make the attendance approval status to “Pending”. Are you
            sure ?
          </div>
        ) : null}
        {data.map((item) => {
            return (
                <div className="flex justify-between py-1">
          <div className="flex gap-2 items-center bg-gray-100 w-1/2 rounded-3xl p-1">
            <Avatar width={"6"} height={"6"} />
            <p className="font-medium">{item.name}</p>
          </div>
          <div className="flex justify-between items-center py-2">
            <p>19 Jan 2023 (8 hr)</p>
          </div>
        </div>
            )
        })}
        

        <div>
          <label>Add note</label>
          <textarea></textarea>
        </div>
        <div className="py-4 flex justify-end gap-3">
          <button className="btn btn--border" onClick={close}>
            Cancel
          </button>
          <button className={`btn ${btnClass}`}>{mainTitle}</button>
        </div>
      </div>
    </Modal>
  );
};

export default RegularizationRequestMultipleActionModal;
