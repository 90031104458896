import React, { useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon, filterIcon } from "../../../../Icons/Icons";
import Avatar from "../../../Avatar/Avatar";
import Select from "react-select";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ApplicableForSelectComp from "./ApplicableForSelectComp/ApplicableForSelectComp";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingButton from "../../../Buttons/LoadingButton";
import {
  useShiftList,
  useAssignEmployeeShift,
} from "../../../../queries/attendanceQuries";

const requiredErrMsg = (
  <p className="text-red-600 font-medium text-sm py-1">
    This field is required
  </p>
);

const requiredField = <span className="text-red-600">*</span>;

const AssignShiftSlidingPanel = ({ open, close, fetchEmployeeShiftList }) => {
  const [applicableForModal, setApplicableForModal] = useState(false);
  const [applicableForData, setApplicapleForData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [fromDateForValidate, setFromDate] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur" });

  const { data: shiftListData, isLoading: isShiftListLoading } = useShiftList();
  const shiftList = Array.isArray(shiftListData?.result) ? shiftListData.result : [];

  const assignShiftMutation = useAssignEmployeeShift();

  const handleChangeApplicableFor = (data) => {
    setErrMsg("");
    const { status, referenceId, tag, title, photo, uniqueId } = data;
    if (status) {
      const isExist = applicableForData.find((i) => i.uniqueId === uniqueId);
      if (isExist) {
        return;
      }
      setApplicapleForData((prev) => [
        ...prev,
        { referenceId, tag, title, photo, uniqueId },
      ]);
    } else {
      setApplicapleForData((prev) =>
        prev.filter((item) => item.uniqueId !== uniqueId)
      );
    }
  };

  const handleCloseSlidingPanel = () => {
    setApplicapleForData([]);
    close();
  };

  const handleFilter = (e) => {
    setApplicableForModal(e);
  };

  const onSubmit = (data) => {
    if (applicableForData.length) {
      const { shiftId, fromDate, toDate, note } = data;
      const reqObj = {
        shiftId: Number(shiftId),
        fromDate,
        toDate,
        note,
        applicableFor: applicableForData.map((data) => ({
          referenceId: data.referenceId,
          tag: data.tag,
        })),
      };

      assignShiftMutation.mutate(reqObj, {
        
        onSuccess: (responseData) => {
          if(responseData?.status){
            toast.success(responseData?.message);
            close();
          }
          else{
            toast.error(responseData?.message || "Failed to assign shift");
          }
          
        },
        onError: (error) => {
          toast.error(
            error?.response?.data?.message || "Failed to assign shift"
          );
        },
      });
    } else {
      setErrMsg("Minimum one tag is required");
    }
  };

  const handleRemoveItemFromApplicableFor = (item) => {
    setApplicapleForData((prev) =>
      prev.filter((data) => data.uniqueId !== item.uniqueId)
    );
  };

  return (
    <ReactSlidingPane
      isOpen={open}
      onRequestClose={handleCloseSlidingPanel}
      width="560px"
      closeIcon=" "
    >
      <div className="flex justify-between flex-col h-full">
        <div>
          <div className="flex justify-between items-center border-b py-3 px-5">
            <h1 className="text-black font-medium">Assign Shift</h1>
            <button onClick={handleCloseSlidingPanel}>{closeIcon}</button>
          </div>

          <div className="px-5 flex flex-col gap-3 text-black py-4">
            <div className="flex flex-col">
              {errMsg ? (
                <span className="text-red-600 font-medium">{errMsg}</span>
              ) : null}
              <label>Applicable For {requiredField}</label>
              <div className="border min-h-[35px] p-2 rounded-lg border-gray-300 shadow flex justify-start flex-wrap gap-2 font-normal relative hover:bg-white">
                <button
                  className="absolute z-50 top-2 right-2 ml-2"
                  onClick={() => handleFilter((prev) => !prev)}
                >
                  {filterIcon()}
                </button>
                {applicableForData.map((item, index) => (
                  <div
                    className="bg-gray-100 rounded-3xl flex items-center p-1"
                    key={`applcablefor_uniq_${index + 120}`}
                  >
                    {item.photo ? (
                      <Avatar image={item.photo} width={"6"} height={"6"} />
                    ) : null}
                    <p className="text-sm font-medium ml-2 mr-4">
                      {item.title}
                    </p>
                    <button
                      className="mx-1"
                      onClick={() => handleRemoveItemFromApplicableFor(item)}
                    >
                      {closeIcon}
                    </button>
                  </div>
                ))}

                {applicableForModal ? (
                  <ApplicableForSelectComp
                    applicableForData={applicableForData}
                    handleChangeApplicableFor={handleChangeApplicableFor}
                  />
                ) : null}
              </div>
              {errors?.applicableFor && requiredErrMsg}
            </div>
            <div>
              <label>Date {requiredField}</label>
              <div className="flex justify-between items-center gap-3">
                <input
                  type="date"
                  {...register("fromDate", { required: true })}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <p>to</p>
                <input
                  type="date"
                  disabled={!fromDateForValidate}
                  min={fromDateForValidate ? fromDateForValidate : ""}
                  {...register("toDate", { required: true })}
                />
              </div>
              {errors?.fromDate || errors?.toDate ? requiredErrMsg : null}
            </div>
            <div>
              <label>Select Shift {requiredField}</label>
              <Controller
                control={control}
                name="shiftId"
                render={({ field }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    options={shiftList.map((item) => ({
                      label: item.shift.shift_name,
                      value: item.shift.id,
                    }))}
                    onChange={(e) => field.onChange(e.value)}
                    isLoading={isShiftListLoading}
                  />
                )}
                rules={{
                  required: true,
                }}
              />
              {errors?.ShiftId && requiredErrMsg}
            </div>
            <div>
              <label>Add note {requiredField}</label>
              <textarea {...register("note", {
                  required: "This field is required", 
                  maxLength: {
                    value: 100,
                    message: "Max length cannot be more than 100 characters",
                  }
                })}></textarea>
              {errors.note && (
                  <span className="text-red-600 font-medium text-sm py-1">
                    {errors?.note?.message}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="border-t px-5 py-3 flex justify-end gap-3">
          <button className="btn btn--border" onClick={handleCloseSlidingPanel}>
            Close
          </button>
          {assignShiftMutation.isLoading ? (
            <LoadingButton title="Assigning Shift" />
          ) : (
            <button className="btn" onClick={handleSubmit(onSubmit)}>
              Assign Shift
            </button>
          )}
        </div>
      </div>
    </ReactSlidingPane>
  );
};

export default AssignShiftSlidingPanel;
