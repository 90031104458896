import React from "react";
import SalaryEarningListData from "./SalaryEarningListData";

const salaryEarningData = [
  {
    id: "883",
    name: "CEO Salary",
    descriptions: "-"
  },
  {
    id: "883",
    name: "General Officer Salary",
    descriptions: "-"
  },
  {
    id: "883",
    name: "Marketing Department Salary",
    descriptions: "-"
  },
  {
    id: "883",
    name: "Security Salary",
    descriptions: "-"
  }
];

const SalaryTemplateEarningList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-600 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3 flex items-center gap-3">
              <input type="checkbox" />
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Descriptions
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              IS ACTIVE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {salaryEarningData.map((val, key) => {
            return <SalaryEarningListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SalaryTemplateEarningList;
