import React from 'react'

const Card = (props) => {
  return (
    <div style={{ width: props.width || "300px"}} className={`border rounded-md p-4 h-full overflow-y-auto`}>
      {props.children}
    </div>
  )
}

export default Card