import React from "react";
import SettingsInfo from "./SettingsInfo";
import LoginActivity from "./LoginActivity";

const EmployeeSettingsTab = () => {
  return (
    <div>
      <SettingsInfo />
      <LoginActivity />
    </div>
  );
};

export default EmployeeSettingsTab;
