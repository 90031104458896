import React, { useRef } from "react";

const OrganizationTax = () => {
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const input = e.target;
    const maxLength = parseInt(input.getAttribute("maxlength"), 10);
    const inputValue = input.value;

    if (
      inputValue.length >= maxLength &&
      index < inputRefs.current.length - 1
    ) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    const prevInput = inputRefs.current[index - 1];
    const nextInput = inputRefs.current[index + 1];

    if (
      e.key === "Backspace" &&
      index > 0 &&
      prevInput &&
      prevInput.value === ""
    ) {
      prevInput.focus();
    }

    if (e.key === "Delete" && nextInput && nextInput.value === "") {
      nextInput.focus();
    }
  };

  return (
    <div className="w-full px-10 pb-4 mt-8">
      <div>
        <h2 className="text-xl">Tax</h2>
        <p className="text-base font-semibold text-[#1F2937] mt-6 mb-4">
          Organisation Tax Details
        </p>
        <div className="flex gap-4 mb-4">
          <label className="text-sm font-medium text-black w-[220px]">
            PAN <span className="text-red-700">*</span>
            <input type="text" placeholder="AAAPZ1234C" />
          </label>
          <label className="text-sm font-medium text-black w-[220px]">
            TAN <span className="text-red-700">*</span>
            <input type="text" placeholder="PDES03028F" />
          </label>
        </div>

        <div className="flex gap-4 mb-4 items-center">
          <label className="text-sm font-medium text-black ">
            TDS circle / AO code <span className="text-red-700">*</span>
            <div className="flex tax-auto-moving-input">
              {[...Array(4)].map((_, index) => (
                <>
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength={2}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{ marginRight: "5px" }}
                    className=""
                  />
                  {index < 3 ? <p className="mx-2 mt-2">/</p> : null}
                </>
              ))}
            </div>
          </label>
          <label className="text-sm font-medium text-black w-[220px]">
            Tax Payment Frequency <span className="text-red-700">*</span>
            <input
              type="text"
              placeholder="Montlhy"
              className="bg-gray-100 mt-1 tax-input"
            />
          </label>
        </div>
      </div>
      <div>
        <p className="text-base font-semibold text-[#1F2937] mt-6 mb-4">
          Tax Deductor Details
        </p>
        <div className="flex items-center mb-4">
          <div className="flex items-center">
            <input
              type="radio"
              id="employee"
              name="employee"
              value="Employee"
            />
             {" "}
            <label for="employee" className="pr-4">
              Employee
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="non-employee"
              name="nonemployee"
              value="NonEmployee"
            />
              <label for="employee">Non-Employee</label>
          </div>
        </div>

        <div className="flex gap-4 mb-4">
          <label className="text-sm font-medium text-black w-[220px]">
            Deductor Name <span className="text-red-700">*</span>
            <input type="text" placeholder="Michele Morrison" />
          </label>
          <label className="text-sm font-medium text-black w-[220px]">
            Deductor Father Name <span className="text-red-700">*</span>
            <input
              type="text"
              placeholder="Montlhy"
              className="bg-gray-100 mt-1 tax-input"
            />
          </label>
        </div>
      </div>
      <div className="flex items-center gap-5">
        <button type="submit" className="btn btn--border text-sm font-medium">
          Cancel
        </button>
        <button type="submit" className="btn text-sm font-medium">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default OrganizationTax;
