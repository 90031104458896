import React from 'react'
import PolicyEmployeeViewStatusTData from './PolicyEmployeeViewStatusTData'

const PolicyEmployeeViewStatusTable = ({ data }) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
      <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
        <tr>
          <th scope="col" className=" py-3  px-5">
           Employee name
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
        
          <th scope="col" className="text-center py-3  px-5">
            View status
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((i, index) => (<PolicyEmployeeViewStatusTData data={i} key={`${index}_p_emp_view_status_data`}/>))
        }
        
      </tbody>
    </table>
  )
}

export default PolicyEmployeeViewStatusTable