import React from "react";
import Avatar from "../../Avatar/Avatar";

const PendingConfirmationData = (props) => {
  return (
    <div className="py-2 px-4 cursor-pointer">
      <div className="flex justify-between items-center border-b border-b-[#E5E7EB] py-3">
        <div className="flex justify-between items-center gap-3">
          <Avatar image={props.requestdata.image} status="available" />
          <div>
            <p className="text-sm leading-5 font-medium text-gray-800">
              {props.requestdata.Name}
            </p>
            <p className="text-xs leading-4 font-normal text-gray-500">
              {props.requestdata.date}
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <i className="ico-more text-gray-600"></i>
        </div>
      </div>
    </div>
  );
};

export default PendingConfirmationData;
