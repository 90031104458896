import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../helpers/axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState([]);
  const { token } = useParams();
  const [candidate, setCandidate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [doneButton, setDoneButton] = useState(false);
  const [pageComponent, setPageComponent] = useState("");

  const getUserDetails = async () => {
    setPageComponent("Loading...");
    await axios
      .get("auth/get-user", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response?.data?.userData) {
          setCandidate(response.data.userData);
          setPageComponent("");
        } else {
          toast.info("Link has expired");
          setPageComponent("Link has expired");
        }
      })
      .catch(() => {
        setPageComponent("Something went wrong try again...");
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);
    setErrMsg("");
    const newPassword = data.password;
    try {
      await axios
        .post(
          "auth/resetPassword",
          {
            newPassword: newPassword,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const statusCode = response.data.statusCode;
          if (statusCode == 200) {
            setTimeout(() => {
              toast.success("Password reset successfully!");
              setLoader(false);
              setDoneButton(true);
            }, 2000);
            setTimeout(() => {
              navigate("/offer/login");
            }, 3000);
          } else if (!statusCode) {
            setErrMsg("No Server Response!");
          } else if (statusCode == 400) {
            setErrMsg("Please provide a valid password!");
          } else if (statusCode == 401) {
            setErrMsg("Unauthorized!");
          } else {
            setErrMsg("Password Creation Failed!");
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Password is not added in request");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Password Creation Failed");
      }

      errRef.current.focus();
    }
  };

  if (pageComponent) {
    return (
      <div className="flex justify-center items-center h-screen">
        {pageComponent}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <main className="lg:flex items-start  overflow-hidden pt-[77px] -mt-[77px]">
        <div className="flex-1 justify-center set-middle max-w-[380px]">
          <div className="mb-6 text-center">
            <div>
              <img
                src="/images/logo-large.svg"
                className="inline-block mb-6 w-20 h-20"
                alt=""
              />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Reset Passsword
            </h2>
            <p>
              Hi {candidate && candidate.firstName}, to change or reset your
              password please create new password below.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <span
              className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
              ref={errRef}
            >
              {errMsg}
            </span>
            <div className=" mb-4">
              <label htmlFor="">Passsword</label>
              <input
                type="password"
                {...register("password", {
                  required: "Password is required!",
                  minLength: {
                    value: 8,
                    message: "Minimum 8 characters required for passsword",
                  },
                  validate: (value) => {
                    return (
                      [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every(
                        (pattern) => pattern.test(value)
                      ) ||
                      "Passwords must contain a minimum of 1 lower case,upper case,number and special character"
                    );
                  },
                })}
              />
              {errors.password && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1">
                  {errors.password.message}
                </span>
              )}
            </div>

            <div className=" mb-4">
              <label htmlFor="">Confirm Passsword</label>
              <input
                type="password"
                {...register("passwordConfirmation", {
                  required: "Password is required",
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords should match!";
                    },
                  },
                })}
              />
              {errors.passwordConfirmation && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1">
                  {errors.passwordConfirmation.message}
                </span>
              )}
            </div>
            <div className=" mb-4">
              {doneButton ? (
                <button className="btn btn--full text-[14px]" disabled>
                  <svg
                    class="w-6 h-6 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  Done
                </button>
              ) : loader ? (
                <div role="status">
                  <button className="btn btn--full text-[14px]" disabled>
                    <svg
                      aria-hidden="true"
                      className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    Changing password
                  </button>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <input
                  type="submit"
                  className="btn btn--full text-[14px]"
                  value="Reset Password"
                />
              )}
            </div>
            <div className=" mb-4">
              <Link to="/" className="btn btn--full btn--border">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword;
