import React from "react";
import SalaryTemplateEarningList from "./SalaryTemplateEarningList";

const SalaryTemplates = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Salary Templates</h2>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            Add Component
          </a>
        </div>
      </div>
      <SalaryTemplateEarningList />
    </div>
  );
};

export default SalaryTemplates;
