import React, { useState } from "react";
import EditLeaveTypesInPolicyModal from "./EditLeaveTypesInPolicyModal";

const LeaveTypesInPolicyList = ({
  leave,
  policyId,
  fetchListLeavePolicyMapping,
  setPolicyData,
  policyData,
}) => {
  const isDraftData = policyData.find(
    (i) => Number(i.leave_type_id) === Number(leave.leave_type_id)
  );
  const [editLeaveTypeModal, setEditLeaveTypeModal] = useState(false);

  const leaveName = leave?.leave_name;
  const numberOfDaysAllowed =
    isDraftData?.number_days_allowed ?? leave?.number_days_allowed;

  const isLopLeave = leave?.is_lop;

  const status = Number(isDraftData?.status ?? leave?.status);

  return (
    <>
      {editLeaveTypeModal && (
        <EditLeaveTypesInPolicyModal
          leave={leave}
          openModal={editLeaveTypeModal}
          onCloseModal={() => setEditLeaveTypeModal(false)}
          policyId={policyId}
          fetchListLeavePolicyMapping={fetchListLeavePolicyMapping}
          setPolicyData={setPolicyData}
          policyData={policyData}
        />
      )}
      <tr className="text-black">
        <td className="px-4 py-4 w-[220px]">
          <div className="flex justify-between items-center gap-2">
            <span>{leaveName}</span>
            {isDraftData ? (
              <span className="border status">Drafted</span>
            ) : null}
          </div>
        </td>

        <td className="px-4 py-4 w-[220px] text-center">
          {isLopLeave ? "Not applicable" : numberOfDaysAllowed}
        </td>
        <td className="px-4 py">
          <span
            className={`${
              status ? "status status--green" : "status status--red"
            }`}
          >
            {status ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="px-4 py-4">
          {" "}
          <i
            className="ico-edit2 text-xl text-gray-600 cursor-pointer"
            onClick={() => setEditLeaveTypeModal(true)}
          ></i>
        </td>
      </tr>
    </>
  );
};

export default LeaveTypesInPolicyList;
