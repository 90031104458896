import React from "react";
import JobOpeningListData from "./JobOpeningListData";

const jobList = [
  {
    id: "798",
    offboarding_name: "Programme Management Officer",
    recruiter: "Kristin Watson",
    created_date: "27 Apr 2022 10:30am",
    hiring_target: "12",
    candidates: "98",
    hired: "0"
  },
  {
    id: "798",
    offboarding_name: "IT Manager ",
    recruiter: "Kristin Watson",
    created_date: "27 Apr 2022 10:30am",
    hiring_target: "12",
    candidates: "98",
    hired: "0"
  },
  {
    id: "798",
    offboarding_name: "Administrator",
    recruiter: "Kristin Watson",
    created_date: "27 Apr 2022 10:30am",
    hiring_target: "12",
    candidates: "98",
    hired: "0"
  },
  {
    id: "798",
    offboarding_name: "Programme Management Officer",
    recruiter: "Kristin Watson",
    created_date: "27 Apr 2022 10:30am",
    hiring_target: "12",
    candidates: "98",
    hired: "0"
  }
];

const JobOpeningList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              OFFBOARDING NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              RECRUITER
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CREATED DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              HIRING TARGET
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CANDIDATES
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              HIRED
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {jobList.map((val, key) => {
            return <JobOpeningListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default JobOpeningList;
