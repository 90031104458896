import React, { useEffect, useState } from "react";
import Avatar from "../../Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import MenuButtonForCandidateListPage from "../CandidateListMenuButton/MenuButtonForCandidateListPage";

const CandidateListData = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const [offerBtnLoader, setOfferBtnLoader] = useState(false);
  const candidate = props.requestdata;

  const optionBtnFunctions = {
    sendOfferBtn: async () => {
      if (candidate.reference_id == null) {
        toast.success(`Sending offer to ${candidate.first_name}`, {
          type: "info",
        });

        const candidateId = candidate.candidate_id;
        try {
          setOfferBtnLoader(true);
          let isMounted = true;
          const controller = new AbortController();
          await axiosPrivate
            .post(
              "onboarding/candidate/sendOfferMail",
              {
                candidate_id: candidateId,
              },
              {
                signal: controller.signal,
              }
            )
            .then((response) => {
              if (response.data.status) {
                candidate.reference_id = "";
                setOfferBtnLoader(false);
                toast.success("Offer sent successfully!");
                setTimeout(() => {
                  setOpen(!open);
                }, 500);
              } else if (
                !response.data.status &&
                response.data.statusCode == 200
              ) {
                setOfferBtnLoader(false);
              } else {
              }
            })
            .catch((err) => {
              console.error(err);
            });

          return () => {
            isMounted = false;
            controller.abort();
          };
        } catch (err) {
          toast.success(`Sending offer to ${candidate.first_name} failed!`, {
            type: "error",
          });
        }
      }
    },
  };

  return (
    <>
      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td
          scope="row"
          className="px-10 py-4 font-medium text-gray-800 whitespace-nowrap"
        >
          {candidate.candidate_serial}
        </td>
        <td className="px-10 py-4 flex items-center gap-3 border-0">
          <Avatar image={candidate.photo} />
          {candidate.first_name}
        </td>
        <td className="px-10 py-4">{candidate.added_date.split("T")[0]}</td>
        <td className="px-10 py-4">{candidate.employee_desgination}</td>
        <td className="px-10 py-4">{candidate.added_by}</td>
        <td className="px-10 py-4">{candidate.source_name}</td>

        <td
          className={
            candidate.reference_id != null
              ? "px-10 py-4 text-green-600"
              : "px-10 py-4 text-yellow-600"
          }
        >
          {candidate.reference_id != null ? "Offer disbursed" : "Pending"}
        </td>
        <td className="px-10 py-4 text-right">
          <div>
            <MenuButtonForCandidateListPage
              btnFunction={optionBtnFunctions}
              title={
                candidate.reference_id != null
                  ? "Offer sent successfully"
                  : offerBtnLoader
                  ? "Sending Offer"
                  : candidate.reference_id != null
                  ? "Offer sent successfully"
                  : "Send Offer"
              }
              btnStatus={
                candidate.reference_id != null
                  ? "SendSuccess"
                  : offerBtnLoader
                  ? "Sending"
                  : candidate.reference_id != null
                  ? "SendSuccess"
                  : ""
              }
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default CandidateListData;
