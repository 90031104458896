import React, { useEffect, useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
import AdjustLeaveBalanceSlidingPanelTable from "./AdjustLeaveBalanceSlidingPanelTable";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LeaveBalanceHistorySlidingPanel from "../../Leaves/SlidingPanels/LeaveRequestHistory/LeaveBalanceHistorySlidingPanel";
import {
  useAdjustLeaveBalance,
  useGetEmployeeLeaveBalanceById,
} from "../../../queries/leaveQueries";
import { useEmployeeList } from "../../../queries/employeeQueries";

import PageLoader from "../../PageLoader/PageLoader";

const AdjustLeaveBalanceSlidingPanel = ({
  open,
  close,
  // employeeList,
  leaveTypes,
  employee,
  // getSearchValue,
}) => {
  // console.log("selected employee list:",employeeList)
  const axiosPrivate = useAxiosPrivate();
  const [selectedEmployee, setSelectedEmployee] = useState(employee || null);
  const [leaveType, setLeaveType] = useState([]);
  const [errMsg, setErrMsg] = useState({
    balanceErrValue: { error: false, message: "New balance is required" },
    noteErrValue: { error: false, message: "Note is required" },
  });
  const [isLeaveHistorySlidingPanel, setLeaveHistorySlidingPanel] =
    useState(false);
  const { data: employeeData } = useEmployeeList({
    selectbox: 1,
    sortOrder: "DESC",
  });
  const employeeList = Array.isArray(employeeData?.employee)
    ? employeeData.employee
    : [];



  const { data: getEmployeeLeaveBalanceData, isLoading } =
    useGetEmployeeLeaveBalanceById({
      employee_serial: selectedEmployee?.employee_serial,
    });
  const employeeLeaveBalanceData = getEmployeeLeaveBalanceData?.employee?.[0];

  // console.log("emp :",employeeLeaveBalanceData)
  const handleSetLeaveTypeValue = (e = {}) => {
    const isExists = leaveType.find(
      (i) => Number(i.leaveTypeId) === Number(e.leaveTypeId)
    );
    if (isExists) {
      setLeaveType((prev) => {
        return prev.map((item) => {
          if (Number(isExists.leaveTypeId) === Number(item.leaveTypeId)) {
            return { ...item, ...e };
          } else {
            return item;
          }
        });
      });
    } else {
      setLeaveType((prev) => [...prev, e]);
    }
  };
  const { mutate: adjustLeaveBalance, isPending } = useAdjustLeaveBalance();

  const onSubmit = () => {
    const balanceErrValue = {
      error: false,
      message: "",
    };
    let noteErrValue = false;
    leaveType.forEach((item) => {
      const { newLeaveBalanceDays, newLeaveBalanceHours, note } = item;
      if (!newLeaveBalanceDays) {
        balanceErrValue.error = true;
        balanceErrValue.message = "New balance is required";
      } else if (
        !Number.isInteger(Number(newLeaveBalanceDays)) ||
        (newLeaveBalanceHours &&
          !Number.isInteger(Number(newLeaveBalanceHours)))
      ) {
        balanceErrValue.error = true;
        balanceErrValue.message =
          "Enter a valid number, Decimal inputs are not allowed";
      }
    });
    setErrMsg((prev) => ({
      ...prev,
      balanceErrValue: { ...prev.balanceErrValue, ...balanceErrValue },
      noteErrValue: { ...prev.noteErrValue, error: noteErrValue },
    }));
    if (balanceErrValue.error || noteErrValue) {
      return;
    }
    try {
      adjustLeaveBalance(
        {
          employeeId: selectedEmployee.employee_id,
          leaveType,
        },
        {
          onSuccess: (data) => {
            if (data?.status) {
              close();
              toast.success("Leave balance adjusted successfully!");
            } else {
              toast.error("Failed to update leave balance");
            }
          },
          onError: (error) => {
            // console.error("Error adjusting leave balance:", error);
            toast.error("Failed to update leave balance");
          },
        }
      );
    } catch (error) {
      //  console.error("Error calling adjustLeaveBalance:", error);
      toast.error("Failed to update leave balance");
    }
  };

  const handleLeaveHistorySlidingPanel = () => {
    setLeaveHistorySlidingPanel((prev) => !prev);
  };

  const formattingLeaveBalanceData = () => {
    if (selectedEmployee) {
      let baseUrl = `lms/leave/exportEmployeeLeaveBalance?employeeId=${selectedEmployee.employee_id}`;
      return baseUrl;
    }
    return "";
  };

  const handleDownloadForLeaveBalance = async () => {
    const url = formattingLeaveBalanceData();
    try {
      const response = await axiosPrivate.get(url, { responseType: "blob" });
      if (response?.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        const contentDisposition = response.headers["content-disposition"];
        const filename = "employee_leave_balance.csv";

        link.setAttribute("download", filename);

        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("File downloaded");
      } else {
        toast.error("Error while downloading the file!");
      }
    } catch (error) {
      if (error?.message === "Request failed with status code 404") {
        toast.error("No data found for the selected employee!");
      } else {
        toast.error("Internal server error" || error?.message);
        // console.error("Error downloading the file:", error.message);
      }
    }
  };

  return (
    <ReactSlidingPane
      className="overflow-x-auto"
      isOpen={open}
      onRequestClose={close}
      width="940px"
    >
      <LeaveBalanceHistorySlidingPanel
        open={isLeaveHistorySlidingPanel}
        close={handleLeaveHistorySlidingPanel}
        employee_id={selectedEmployee?.employee_id}
      />
      <div className="flex justify-between flex-col h-full">
        <div>
          <div className="flex justify-between px-5 pb-3 border-b">
            <p className="text-black text-lg font-medium">
              Adjust Leave Balance
            </p>
            <div className="flex items-center">
              <span
                className="text-blue-600 cursor-pointer mr-2"
                onClick={handleLeaveHistorySlidingPanel}
              >
                View History
              </span>
              <button onClick={close}>{closeIcon}</button>
            </div>
          </div>

          <div className="px-5">
            <div className="py-3">
              <label className="text-black">Employee</label>

              <div className="flex items-center justify-between pt-2">
                <Select
                  options={employeeList}
                  getOptionLabel={(option) =>
                    `${option.first_name} ${option.middle_name} ${option.last_name}`
                  }
                  getOptionValue={(option) => option.employee_id}
                  className="w-1/2"
                  onChange={(e) => setSelectedEmployee(e)}
                  defaultValue={
                    employee || {
                      first_name: "Select",
                      middle_name: "",
                      last_name: "",
                    }
                  }
                />
                <btn
                  onClick={handleDownloadForLeaveBalance}
                  className="btn btn--border text-[14px] ml-4"
                >
                  <span>
                    <i className="ico-download mr-1"></i>
                  </span>
                  Download
                </btn>
              </div>
              {!selectedEmployee && (
                <p className="py-1 text-red-600">
                  Select an employee from the list
                </p>
              )}
              {errMsg?.balanceErrValue?.error && (
                <p className="py-1 text-red-600">
                  {errMsg?.balanceErrValue?.message}
                </p>
              )}
              {errMsg?.noteErrValue?.error && (
                <p className="py-1 text-red-600">
                  {errMsg?.noteErrValue?.message}
                </p>
              )}
            </div>

            {isLoading ? (
              <PageLoader />
            ) : selectedEmployee &&
              employeeLeaveBalanceData?.leaveBalances?.length ? (
              <AdjustLeaveBalanceSlidingPanelTable
                leaveTypes={leaveTypes}
                selectedEmployee={employeeLeaveBalanceData}
                handleSetLeaveTypeValue={handleSetLeaveTypeValue}
              />
            ) : (
              <p className="ml-1">No leave types assigned for this employee</p>
            )}
          </div>
        </div>

        <div className="flex justify-end px-5 gap-3">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button
            className={`btn ${!leaveType.length ? "bg-blue-400" : ""}`}
            onClick={onSubmit}
            disabled={!leaveType.length}
          >
            Save Adjustment
          </button>
        </div>
      </div>
    </ReactSlidingPane>
  );
};

export default AdjustLeaveBalanceSlidingPanel;
