import React, { useEffect, useState } from "react";
import SingleProfile from "../../../components/SingleProfile";
import Line from "../../../components/Line";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const MeetTeam = () => {
  const navigate = useNavigate();
  const [teamList, setTeamList] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const fetchTeam = async () => {
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get("employee/teams", {
        signal: controller.signal,
      })
      .then((response) => {
        setTeamList(response.data);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }

  useEffect(() => {
    fetchTeam()
  }, []);

  const managerDetails =
    teamList && teamList.hasOwnProperty("managerDetails")
      ? teamList.managerDetails
      : null;
  const teamDetails =
    teamList && teamList.hasOwnProperty("teamMembers")
      ? teamList.teamMembers
      : null;
  const hrDetails =
    teamList && teamList.hasOwnProperty("hrDetails")
      ? teamList.hrDetails
      : null;
  return (
    <div className="flex-1 justify-center max-w-[660px]">
      <div>
        <div>
          <h2 className="text-2xl leading-8 font-bold text-gray-800">
            Offer Location & Schedule
          </h2>
          <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s,
          </p>
          <h3 className="text-lg leading-8 font-semibold text-gray-800">
            Location
          </h3>
          <div className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
            {teamList ? (
              <div className="font-semibold">{teamList.location}</div>
            ) : null}
          </div>
        </div>
        <div>
          <div>
            <h2 className="text-2xl leading-8 font-bold text-gray-800">
              Offer Location & Schedule
            </h2>
            <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <h3 className="text-lg leading-8 font-semibold text-gray-800">
              Location
            </h3>
            <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
          <div>
            <h2 className="text-2xl leading-8 font-bold text-gray-800">
              Let’s meet everyone
            </h2>
            <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries.
            </p>
          </div>
          {hrDetails ? (
            <>
              <div>
                <h3 className="text-lg leading-8 font-semibold text-gray-800 pt-4">
                  HR
                </h3>
                <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
              <div className="lg:grid grid-cols-2 gap-6">
                {hrDetails &&
                  hrDetails.map((val, key) => {
                    return <SingleProfile memberdata={val} key={key} />;
                  })}
              </div>
            </>
          ) : null}
          {managerDetails ? (
            <>
              <div>
                <h3 className="text-lg leading-8 font-semibold text-gray-800">
                  Manager
                </h3>
                <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </div>
              <div className="lg:grid grid-cols-2 gap-6">
                {managerDetails &&
                  managerDetails.map((val, key) => {
                    return <SingleProfile memberdata={val} key={key} />;
                  })}
              </div>
            </>
          ) : null}

          
        </div>

        {teamDetails ? (
          <>
            <div className="pt-6 pb-12">
              <h3 className="text-lg leading-8 font-semibold text-gray-800">
                Your teammates
              </h3>
              <p className="text-sm leading-5 font-normal text-gray-500 pt-2 pb-6">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
              <div className="lg:grid grid-cols-2 gap-6">
                {teamDetails &&
                  teamDetails.map((val, key) => {
                    return <SingleProfile memberdata={val} key={key} />;
                  })}
              </div>
            </div>
          </>
        ) : null}
        <Line />

        <div className="flex justify-between pt-6">
          <button className="btn btn--border" onClick={() => navigate(-1)}>
            Back
          </button>
          <Link to="/onboarding/greet-team" className="btn">
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MeetTeam;
