import React, { Fragment, useEffect, useState } from "react";
import ApplyLeaveModal from "../../../components/ApplyLeaveModal/ApplyLeaveModal";
import LeaveItem from "./LeaveItem";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader/PageLoader";

const LeaveBalance = () => {
  const user = useSelector((profile) => profile.user.auth);
  const isHr = userRoleAuth(user, 2);
  const isManager = userRoleAuth(user, 4);
  const hrOrManager = isHr || isManager;
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [leaveBalances, setLeaveBalances] = useState(null);
  const [component, setComponent] = useState(<PageLoader />);

  const fetchLeaveBalances = async () => {
    setComponent(<PageLoader />);
    setLeaveBalances(null);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveBalances`)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          setTimeout(() => {
            setLeaveBalances(response.data.results);
            setComponent(null);
          }, 500);
        } else {
          setComponent(<EmptyState />);
          setLeaveBalances(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setComponent(<EmptyState />);
        setLeaveBalances(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeaveBalances();
  }, []);

  const applyleave = () => {
    setIsPaneOpen(true);
  };

  return (
    <Fragment>
      <ApplyLeaveModal
        open={isPaneOpen}
        close={() => setIsPaneOpen(false)}
        leaveTypes={leaveBalances}
      />
      <div className="flex-1 px-10 pt-8 pb-6 border-b border-gray-200">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2>Leave Balance</h2>
            {/* <p className="text-black flex items-center gap-1">
              <i className="ico-left text-blue-600 text-xs"/>
              01 Jan 2023 - 31 Dec 2023
              <i className="ico-right text-blue-600 text-xs"/>
            </p> */}
          </div>
          <div className="flex gap-4 items-center">
            {/* <div className="">
             {hrOrManager ?
               <input type="date"/>
              : null}
            </div> */}
            <div>
              <button
                className="btn bg-[#3466E7] max-h-[38px] text-sm"
                onClick={applyleave}
              >
                <span>
                  <i className="ico-plus text-white mr-1 h-3.5 w-3.5"></i>
                </span>
                New Leave Request
              </button>
            </div>
          </div>
        </div>
        {component}
        <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
          {leaveBalances &&
            leaveBalances.map((leave, key) => {
              return <LeaveItem leavedata={leave} key={key} />;
            })}
        </div>
      </div>
    </Fragment>
  );
};

export default LeaveBalance;
