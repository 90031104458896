import React, { useState, useRef } from "react";
import Select, { components } from "react-select";
import ShiftCalenderTable from "../../../components/Attendance/ManageShift/ShiftCalendar/ShiftCalenderTable";
import MiniShiftOverviewBar from "../../../components/Attendance/ManageShift/ShiftCalendar/MiniShiftOverviewBar";
import formatDate from "../../../utils/FormatDate/formatDate";
import moment from "moment";
import EmptyState from "../../../components/EmptyState";
import PageLoader from "../../../components/PageLoader";
import Search from "../../../components/Header/Search/Search";
import {
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
} from "../../../queries/employeeQueries";
import {
  useShiftList,
  useEmployeeShiftList,
  useDownloadEmployeesShift,
} from "../../../queries/attendanceQuries";
import { generateDates } from "../../../utils/FormatDate/generateDates";
import { toast } from "react-toastify";

const DEPARTMENT = "department";
const DESIGNATION = "designation";
const OFFICE = "office";
const FILTER = "filter";
const PAGENO = "pageNo";
const PAGESIZE = "pageSize";
const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <span className="ico-down text-blue-600"></span>
  </components.DropdownIndicator>
);
const ShiftCalendar = () => {
  const [firstDate, setFirstDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [lastDate, setLastDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [pageNoAndSize, setPageNoAndSize] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  const [empFilters, setEmpFilters] = useState({
    filter: "",
    office: null,
    designation: null,
    department: null
  });
  const searchRef = useRef();


  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();
  const { data: shiftListData } = useShiftList();

  const {
    data: employeeShiftData,
    isLoading,
  } = useEmployeeShiftList({
    ...empFilters,
    office: empFilters.office?.value || "",
    department: empFilters.department?.value || "",
    designation: empFilters.designation?.value || "",
    fromDate: firstDate,
    toDate: lastDate,
    pageNo: pageNoAndSize.pageNo,
    pageSize: pageNoAndSize.pageSize,
  });

  const formatedFirstDay = formatDate(firstDate).dateString2;
  const formatedLastDay = formatDate(lastDate).dateString2;
  const defaultLocationOption = { location_name: "Office: All", id: null };
  const defaultDesignationOption = { designation_title: "Designation: All", id: null};
  const defaultDepartmentOption = { department_name: "Department: All", id: null };

  const departmentList = Array.isArray(getDepartmentList?.result)
    ? [defaultDepartmentOption, ...getDepartmentList?.result]
    : [];

  const designationList = Array.isArray(getDesignationList?.result)
    ? [defaultDesignationOption, ...getDesignationList?.result]
    : [];

  const locationList = Array.isArray(getLocationList?.result)
    ? [defaultLocationOption, ...getLocationList?.result]
    : [];
  const shiftList = Array.isArray(shiftListData?.result)
    ? shiftListData.result
    : [];

  const handleChangeDate = (type) => {
    if (type === "nextMonth") {
      const nextMonth = moment(firstDate).add(1, "month").format("YYYY-MM-DD");
      setFirstDate(nextMonth);
      setLastDate(moment(nextMonth).endOf("month").format("YYYY-MM-DD"));
    } else {
      const prevMonth = moment(firstDate)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
      setFirstDate(prevMonth);
      setLastDate(moment(prevMonth).endOf("month").format("YYYY-MM-DD"));
    }
  };
  const handleClearFilters = () => {
    setEmpFilters((prev) => ({
      ...prev,
      filter: "",
      department: "",
      designation: "",
      office: "",
    }));

    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  const isFilterApplied = Object.values(empFilters).some((value) => value);

  const datesCol = generateDates(firstDate, lastDate);

  const screenWidth = window.innerWidth - 326;
  const totalRecords = employeeShiftData?.totalRecords || 0;
  const totalPages = Math.ceil(totalRecords / pageNoAndSize.pageSize) || 1;

  const nextPage = () => {
    if (pageNoAndSize.pageNo < totalPages) {
      setPageNoAndSize((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
    }
  };

  const previousPage = () => {
    if (pageNoAndSize.pageNo > 1) {
      setPageNoAndSize((prev) => ({ ...prev, pageNo: prev.pageNo - 1 }));
    }
  };
  // mutation ?
const {mutateAsync, isPending} = useDownloadEmployeesShift()


  const handleDownload = async () => {
   
   
    try {
      const response = await  mutateAsync({
        ...empFilters,
        office: empFilters.office?.value || "",
        department: empFilters.department?.value || "",
        designation: empFilters.designation?.value || "",
        fromDate: firstDate,
        toDate: lastDate,
        pageNo: pageNoAndSize.pageNo,
        pageSize: pageNoAndSize.pageSize,
      });


      if (response.data) {
        const csvData = response.data;

        const csvString =
          typeof csvData === "string" ? csvData : JSON.stringify(csvData);

        const blob = new Blob([csvString], { type: "text/csv" });

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `employee_shift_data_${Date.now()}.csv`);

        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!");
      } else {
        toast.error("No data available for download.");
        throw new Error("No data available for download.");
      }
    } catch (error) {
   
      const errorMessage =
        error?.response?.status === 400
          ? "No shift data found for the selected employee!"
          : error.message || "Internal server error";
      toast.error(errorMessage);
    } 
  };

  const handleSetFilter = (value, source) => {
    setEmpFilters((prev) => ({
      ...prev,
      [source]: value,
    }));
  };
  return (
    <div className={`flex flex-col relative`} style={{ width: screenWidth }}>
      <div className="p-10 flex flex-col gap-5">
        <div>
          <h1 className="text-black font-semibold text-base">Shift Calendar</h1>
          <p>Manage and view shifts</p>
        </div>

        <div className="flex justify-between">
          <div className="flex gap-3">
          <Select
              placeholder="Designation: All"
              options={designationList.map((i) => ({
                label: i.designation_title,
                value: i.id,
              }))}
              value={empFilters.designation}
              onChange={(e) => handleSetFilter(e, DESIGNATION)}
            />

            <Select
              placeholder="Department: All"
              options={departmentList.map((i) => ({
                label: i.department_name,
                value: i.id,
              }))}
              value={empFilters.department}
              onChange={(e) => handleSetFilter(e, DEPARTMENT)}
            />

            <Select
              placeholder="Office : All"
              options={locationList.map((i) => ({
                label: i.location_name,
                value: i.id,
              }))}
              value={empFilters.office}
              onChange={(e) => handleSetFilter(e, OFFICE)}
            />
            <Search /// refernce seracj ref
              eleRef={searchRef}
              onChange={(e) => handleSetFilter(e, FILTER)}
              value={empFilters.filter}
            />

            {isFilterApplied && (
              <p
                className="text-blue-600 font-medium pt-2 cursor-pointer"
                onClick={handleClearFilters}
              >
                Clear filter
              </p>
            )}
          </div>

          <div className="flex gap-4">
            <div className="flex gap-3 items-center">
              <button onClick={() => handleChangeDate("prevMonth")}>
                <i className="ico-left"></i>
              </button>
              <p className="text-black font-medium">
                {formatedFirstDay} - {formatedLastDay}
              </p>
              <button onClick={() => handleChangeDate("nextMonth")}>
                <i className="ico-right"></i>
              </button>
            </div>
            <div>
              <button className="btn btn--border" onClick={handleDownload}>
                <i className="ico-download mr-2"></i>Download
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 items-center">
          <button className="btn btn--border" onClick={previousPage}>
            Previous
          </button>
          <span className="text-gray-800 font-medium">
            {pageNoAndSize.pageNo} / {totalPages} pages
          </span>
          <button className="btn btn--border" onClick={nextPage}>
            Next
          </button>
        </div>
      </div>

      <div>
        {isLoading ? (
          <PageLoader />
        ) : employeeShiftData?.result?.length ? (
          <ShiftCalenderTable
            data={employeeShiftData.result}
            datesCol={datesCol}
          />
        ) : (
          <EmptyState />
        )}
      </div>

      <div className="fixed bottom-0 w-full mt-5 bg-white">
        <MiniShiftOverviewBar shiftList={shiftList} />
      </div>
    </div>
  );
};

export default ShiftCalendar;
