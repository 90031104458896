import React, { useState, useEffect, useRef } from "react";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../../../Line/Line";
import { closeIcon } from "../../../../Icons/Icons";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { holidayTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { toast } from "react-toastify";
import useFetchFile from "../../../../hooks/useFetchFile";
import { LEAVE_EXCEL_TEMPLATES } from "../../../../Consts/filePathConst";
import { id } from "date-fns/locale";

const AddorEditHoliday = ({
  open,
  close,
  handleMakeApiCallToFetchHolidayList,
  existingHolidayData = {},
}) => {
  const axiosPrivate = useAxiosPrivate();
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
 // console.log("existingHolidayData:",existingHolidayData)
  // Ensure default values are provided
  const formattedDate = existingHolidayData.date
    ? existingHolidayData.date.split("T")[0]
    : "";

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      holiday_name: existingHolidayData.holiday_name || "",
      date: formattedDate || "",
      country_code: existingHolidayData.country_id || "",
      state_code: existingHolidayData.state_id || "",
      holiday_type: existingHolidayData.holiday_type_id || "",
      year_id: existingHolidayData.holiday_year || 1,
    },
  });

  const uploadFileErrorList = useRef();
  const fileInputRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountry] = useState([]);
  const [stateList, setStateList] = useState([]);

  const fetchCountry = async () => {
    try {
      const response = await axiosPrivate.get("onboarding/services/location");
      setCountry(response.data.country || []);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchState = async (countryCode) => {
    if (countryCode) {
      try {
        const response = await axiosPrivate.get(
          `onboarding/services/location?StatesFrom=${countryCode}`
        );
        setStateList(response.data.state || []);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
  };

  useEffect(() => {
  
    if (existingHolidayData.country_id) {
      setSelectedCountry(existingHolidayData.country_id);
      fetchState(existingHolidayData.country_id);
    }
  }, [existingHolidayData.country_id]);
  
  useEffect(() => {
    if (selectedCountry) {
      fetchState(selectedCountry);
    } else {
      setStateList([]);
    }
  }, [selectedCountry]);
  

  const addNewHoliday = async (data) => {
    try {
      const result = await axiosPrivate.post("lms/holiday/addHoliday", data);
      if (result?.data?.status) {
        toast.success(result.data.message);
        handleMakeApiCallToFetchHolidayList();
        close();
      } else {
        toast.error(
          result?.response?.data?.error || "Failed to add holiday details"
        );
      }
    } catch (error) {
      console.error("Error adding new holiday:", error);
    }
  };

  const editHoliday = async (data) => {
    try {
      const result = await axiosPrivate.put("lms/holiday/editHoliday", data);
      if (result?.data?.status) {
        toast.success(result.data.message);
        handleMakeApiCallToFetchHolidayList();
        close();
      } else {
        toast.error(
          result?.response?.data?.error || "Failed to edit holiday details"
        );
      }
    } catch (error) {
      console.error("Error editing holiday:", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      if (existingHolidayData.holiday_id) {
        await editHoliday({
          id: existingHolidayData.holiday_id,
          ...data,
        });
      } else {
        await addNewHoliday(data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const formatUploadFileErrorMessages = (err) => {
    let errmsg = "";
    if (Array.isArray(err)) {
      const ul = document.createElement("ul");
      ul.style.maxHeight = "160px";
      ul.style.overflowY = "scroll";
      ul.style.border = "1px solid #00000040";
      ul.style.padding = "4px";
      ul.style.borderRadius = "5px";
      err.forEach((item) => {
        const li = document.createElement("li");
        li.style.paddingTop = "4px";
        li.style.paddingBottom = "4px";
        li.style.display = "flex";
        const span = document.createElement("span");
        span.style.marginRight = "4px";
        span.innerHTML = "&#8226;";
        li.appendChild(span);
        const p = document.createElement("p");
        p.innerText = item;
        li.appendChild(p);
        ul.appendChild(li);
      });

      errmsg = ul.outerHTML;
    } else if (typeof err === "string") {
      errmsg = `<ul><li className="py-1"><span className="mr-1">&#8226;</span>${err}</li></ul>`;
    }
    fileInputRef.current.value = null;
    uploadFileErrorList.current.innerHTML = errmsg;
  };

  const uploadHolidayListExcelFile = async (uploadedFile) => {
    const formData = new FormData();
    formData.append("holiday-list", uploadedFile);

    const response = await axiosPrivate
      .post("lms/holiday/upload/holiday-list", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res)
      .catch((err) => err);
    if (response?.status) {
      toast.success(response?.data?.message || "Uploaded successfully");
      handleMakeApiCallToFetchHolidayList();
      close();
      return;
    } else {
      const errResponseMsg = response?.response?.data?.message;
      formatUploadFileErrorMessages(errResponseMsg);
    }
  };

  const validateUploadedExcelFile = (e) => {
    uploadFileErrorList.current.innerHTML = "";
    const uploadedFile = e?.target?.files[0];
    if (!uploadedFile) {
      return formatUploadFileErrorMessages("No file uploaded");
    }
    const fileType = uploadedFile.type;
    const allowedFileTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    ];
    if (!allowedFileTypes.includes(fileType)) {
      return formatUploadFileErrorMessages(
        "Invalid file uploaded. Please upload a file with one of the following extensions: .csv, .xls, or .xlsx."
      );
    }
    uploadHolidayListExcelFile(uploadedFile);
  };

  const fetchFile = useFetchFile();

  const downloadUploadHolidayListTemplate = async () => {
    try {
      const file = await fetchFile(
        "upload_holiday_list_template.csv",
        LEAVE_EXCEL_TEMPLATES
      );
      const link = document.createElement("a");
      link.href = file;
      link.download = "upload_holiday_list_template.csv";
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    } catch (ex) {
      console.error("Error downloading file:", ex);
    }
  };

  return (
    <ReactSlidingPane
      className="add-new-holiday-modal"
      isOpen={open}
      width="480px"
      onRequestClose={close}
    >
      <div className="px-6 py-3 text-black flex justify-between items-center">
        <p className="font-semibold text-base">
          {existingHolidayData.holiday_id ? "Edit Holiday" : "Add Holiday"}
        </p>
        <span className="cursor-pointer" onClick={close}>
          {closeIcon}
        </span>
      </div>
      <Line />

      {!existingHolidayData.holiday_id && (
        <>
          <div className="px-6 py-3 flex justify-between gap-4">
            <label htmlFor="upload-holiay-list" className="btn btn--border">
              <span>
                <i className="ico-upload mr-1"></i>
              </span>
              Upload holiday list
              <input
                id="upload-holiay-list"
                hidden
                type="file"
                onChange={validateUploadedExcelFile}
                accept=".csv, .xlsx"
                ref={fileInputRef}
              />
            </label>

            <button
              onClick={downloadUploadHolidayListTemplate}
              className="btn btn--border"
            >
              <span>
                <i className="ico-download mr-1"></i>
              </span>
              Download template
            </button>
          </div>
          <div
            className="text-red-600 px-6 py-3"
            ref={uploadFileErrorList}
            id="upload-file-errors"
          ></div>
          <div className="text-center relative justify-center py-4">
            <span className="text-black absolute z-10 top-1 left-[220px] font-medium bg-white px-3">
              OR
            </span>
            <Line />
          </div>
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="px-6 flex flex-col justify-between">
            <div>
              <div className="mb-4">
                <label>
                  Name<span className="text-red-700">*</span>
                </label>
                <input
                  {...register("holiday_name", {
                    required: "This field is required",
                  })}
                  type="text"
                  className="mt-1"
                />
                {errors?.holiday_name && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.holiday_name.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label>Date</label>
                <input
                  {...register("date", {
                    required: "This field is required",
                  })}
                  type="date"
                />
                {errors?.date && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.date.message}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label>Holiday Type</label>
                <Controller
                  control={control}
                  name="holiday_type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(selectedOption) => {
                        onChange(selectedOption ? selectedOption.value : null);
                      }}
                      value={
                        holidayTypeOptions.find(
                          (option) => option.value === value
                        ) || null
                      }
                      options={holidayTypeOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  )}
                />
                {errors?.holiday_type && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.holiday_type.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label>Country</label>
                <Controller
                  control={control}
                  name="country_code"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(selectedOption) => {
                        onChange(selectedOption ? selectedOption.id : null);
                        setSelectedCountry(
                          selectedOption ? selectedOption.id : null
                        );
                      }}
                      value={
                        countryList.find((country) => country.id === value) ||
                        null
                      }
                      options={countryList}
                      getOptionLabel={(option) => option.flag}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
                {errors?.country_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.country_code.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label>State</label>

                <Controller
                  control={control}
                  name="state_code"
                  render={({ field: { onChange, value } }) => {
                  
                    const options = Array.isArray(stateList) ? stateList : [];

                 
                    const defaultState = options.find(
                      (state) => Number(state.id) === Number(value)
                    );

                    return (
                      <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(selectedOption) => {
                        onChange(selectedOption ? selectedOption.id : null);
                       
                      }}
                      value={defaultState || null}
                      options={options}
                      getOptionLabel={(option) => option.state_name}
                      getOptionValue={(option) => option.id}
                    />
                    
                    );
                  }}
                />
                {errors?.state_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.state_code.message}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label>Year</label>
                <Controller
                  rules={{
                    required: "This field is required",
                  }}
                  control={control}
                  name="year_id"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(Number(e.value));
                      }}
                      options={[{ value: 1, label: "2024" }]}
                      defaultValue={{ value: 1, label: "2024" }}
                    />
                  )}
                />
                {errors?.year_id && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.year_id.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer text-right mt-4 pr-6">
          <button type="submit" className="btn btn--blue font-medium">
            {existingHolidayData.holiday_id ? "Edit" : "Add"} Holiday
          </button>
        </div>
      </form>
    </ReactSlidingPane>
  );
};

export default AddorEditHoliday;
