import React from "react";
import { infoIcon } from "../../Icons/Icons";
import { Tooltip } from "antd";

const LeavePercentage = ({ leavedata }) => {
  const leaveName = leavedata.leave_name;
  const leaveused = leavedata.number_days_allowed - leavedata.leave_taken;
  const leaveBalPercentage = (leaveused / leavedata.number_days_allowed) * 100;
  const percentage = leaveBalPercentage > 100 ? 100 : leaveBalPercentage;
  const isLopLeaveType = leavedata.is_lop;
  const description = leavedata?.description || "";

  let color =
    percentage > 75
      ? "bg-[#9AB3F3]"
      : percentage > 50
      ? "bg-[#C4B5FD]"
      : percentage > 25
      ? "bg-[#FDBA74]"
      : percentage > 0
      ? "bg-[#FCA5A5]"
      : "";
      
  const leaveBalanceHours = leavedata?.leave_balance_hours || 0;
  const leaveBalanceDays = leavedata?.leave_balance_days || 0;
  const availableBalance = `${leaveBalanceDays} days ${
    leaveBalanceHours ? `${leaveBalanceHours} hours` : ""
  }`;
  const numberOfDaysAllowed = leavedata.number_days_allowed;
  const bookedDays = leavedata?.booked_day || 0;
  const bookedHours = leavedata?.booked_hours || 0;
  const booked = `${bookedDays} days ${
    bookedHours ? `${bookedHours} hours` : ""
  }`;
  return (
    <div className="">
      <div className="flex relative justify-between items-center mb-4">
        <h3 className="font-semibold text-gray-800">{leaveName}</h3>

        <Tooltip
          title={<div dangerouslySetInnerHTML={{ __html: description }}></div>}
          color="geekblue"
          trigger={"click"}
        >
          <span className="cursor-pointer">{infoIcon}</span>
        </Tooltip>
      </div>
      {!isLopLeaveType ? (
        <div className="text-sm justify-between flex gap-2 text-gray-500">
          <span>Available: </span>
          <span className="text-gray-800 font-medium">
            {availableBalance} / {numberOfDaysAllowed} days
          </span>
        </div>
      ) : null}
      <div className="h-2 rounded-xl bg-gray-100 mb-2">
        <div
          className={`h-2 rounded-xl ${color}`}
          style={{ width: percentage + "%" }}
        ></div>
      </div>
      <div className="flex gap-2">
        <span>Booked:</span>
        <span className="text-gray-900">{booked}</span>
      </div>
    </div>
  );
};

export default LeavePercentage;
