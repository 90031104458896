import React, { Fragment, useEffect, useState } from "react";
import SlidingPanel from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./Slidingpane.scss";
import Line from "../Line/Line";
import { useSelector } from "react-redux";
import IdleStateValidator from "../../components/IdleStateValidator";
import EditProfileForm from "./EditProfileForm"; 
import ProfileData from "./ProfileDataComponent"; 
import PageLoader from "../../components/PageLoader/PageLoader";

const UserProfile = ({ state, setState }) => {
  const profile = useSelector((state) => state.user.userProfile);
  const { isPaneOpen } = state;
  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    if (isPaneOpen) {
      setEditProfile(false); 
    }
  }, [isPaneOpen]);

  const handleEditClick = () => {
    setLoading(true); 
    setTimeout(() => {
      setEditProfile(true);
      setLoading(false); 
    }, 1000); 
  };

  return (
    <Fragment>
      <IdleStateValidator />
      <div>
        <div className="w-3/4">
          <SlidingPanel
            isOpen={isPaneOpen}
            width="480px"
            onRequestClose={() => {
              setState(false);
            }}
          >
            <div>
              <h2 className="p-3">Profile</h2>
            </div>
            <Line />
            <div className="w-full px-4 ">
              {loading ? (
                <PageLoader />
              ) : editProfile ? (
                <EditProfileForm
                  profile={profile}
                  setEditProfile={setEditProfile}
                 
                />
              ) : (
                <ProfileData
                  profile={profile}
                  setEditProfile={handleEditClick}
                />
              )}
            </div>
          </SlidingPanel>
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfile;
