import React, { useState } from "react";
import EditOrAddPaymentInformation from "../EditOrAddPaymentInformationModal/EditOrAddPaymentInformation";

const BankAccountDetails = ({ bank_list, count, makeApiCall, getBankDetails, employee }) => {
  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
 // console.log("data employee", employee);
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between">
        {bank_list.is_default ? (
          <p className="text-base text-[#1F2937] font-semibold">
            Bank Account {count} (Default bank account)
          </p>
        ) : (
          <p className="text-base text-[#1F2937] font-semibold">
            Bank Account {count}
          </p>
        )}
        <div className="cursor-pointer">
          <img
            src="/images/editicon.svg"
            alt=""
            onClick={() => onOpenModal()}
          />
          {openModal ? (
            <EditOrAddPaymentInformation
              openModal={openModal}
              onCloseModal={onCloseModal}
              bankList={bank_list}
              employee={employee}
              makeApiCall={makeApiCall}
              handleMakeApiCall={getBankDetails}
            />
          ) : null}
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Payment Mode </td>
              <td className="data">#1211 </td>
            </tr>
            <tr>
              <td className="title">Account Number </td>
              <td className="data">{bank_list.account_number}</td>
            </tr>
            <tr>
              <td className="title">Account holder Name</td>
              <td className="data">{bank_list.account_holder_name} </td>
            </tr>
            <tr>
              <td className="title">Bank Name </td>
              <td className="data">{bank_list.bank_name} </td>
            </tr>
            <tr>
              <td className="title">IFSC </td>
              <td className="data">{bank_list.ifsc_code} </td>
            </tr>
            <tr>
              <td className="title">Account Type </td>
              <td className="data">{bank_list.account_type} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BankAccountDetails;
