import React, { useRef } from "react";

const NEWBALANCEINDAYS = "newLeaveBalanceDays";
const ADDNOTE = "note";
const NEWBALANCEINHOURS = "newLeaveBalanceHours";

const AdjustLeaveBalanceSlidingPanelData = ({
  data,
  handleSetLeaveTypeValue,
}) => {
  const leaveTypeName = data?.leave_name || "";
  const paidOrNot = !!Number(data?.paid) ? "Paid" : "Un Paid";
  const availableBalance = data?.availableBalance || 0;
  const { availableBalanceInDays, availableBalanceInHours } = data;
  const inputFieldRef = useRef({
    newLeaveBalanceDays: "",
    newLeaveBalanceHours: "",
    note: "",
  });

  const handleInputChange = (e, source) => {
    handleSetLeaveTypeValue({
      source,
      leaveTypeId: data.id,
      newLeaveBalanceDays:
        inputFieldRef?.current?.newLeaveBalanceDays?.value || 0,
      newLeaveBalanceHours:
        inputFieldRef?.current?.newLeaveBalanceHours?.value || 0,
      note: inputFieldRef?.current?.note?.value || "",
    });
  };

  return (
    <>
      <tr className="text-black whitespace-nowrap">
        <td className="font-medium">{leaveTypeName}</td>
        <td>{paidOrNot}</td>
        {/* <td>
          <input type="date"/>
        </td> */}
        <td>{availableBalance}</td>
        <td>
          <input
            ref={(ele) => (inputFieldRef.current.newLeaveBalanceDays = ele)}
            onChange={(e) =>
              handleInputChange(e.target.value, NEWBALANCEINDAYS)
            }
            type="number"
            className=""
            defaultValue={availableBalanceInDays}
          />
        </td>
        <td>
          <input
            ref={(ele) => (inputFieldRef.current.newLeaveBalanceHours = ele)}
            onChange={(e) =>
              handleInputChange(e.target.value, NEWBALANCEINHOURS)
            }
            type="number"
            className=""
            defaultValue={availableBalanceInHours}
          />
        </td>
        <td>
          <input
            ref={(ele) => (inputFieldRef.current.note = ele)}
            onChange={(e) => handleInputChange(e.target.value, ADDNOTE)}
            type="text"
            placeholder="Add note"
          />
        </td>
      </tr>
    </>
  );
};

export default AdjustLeaveBalanceSlidingPanelData;
