import React, { useState } from "react";
import { mobileArrowLeft } from "../../../Icons/Icons";
import Search from "../../../components/Header/Search/Search";
import Line from "../../../components/Line/Line";
import Avatar from "../../../components/Avatar/Avatar";
import { useNavigate, useLocation, Link } from "react-router-dom";

const EmployeeListAside = (items) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmenu, setIsSubmenu] = useState(false);
  const {pageNo, totalPages, nextPage, previousPage, totalRecords, getSearchValue} = items;
  const setSubMenu = (item) => {
    item.status = !item.status;
    setIsSubmenu(!isSubmenu);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const isProcessAttendance = location?.state?.processAttendance || false;
  const mainTitle = isProcessAttendance
    ? "Process attendance"
    : "Employee Lists";

  return (
    <div className="px-6 py-5 border-r border-gray-200 w-[320px] flex-shrink-0">
      <div className="">
        <button
          className="text-blue-500 font-semibold text-base not-italic mb-2"
          onClick={handleGoBack}
        >
          <span className="flex items-center gap-2">
            {mobileArrowLeft} Back
          </span>
        </button>

        <p className="text-lg text-[#1F2937] font-semibold mb-4">{mainTitle}</p>
        <div className="flex mb-4 gap-3">
          <Search onChange={(e) => getSearchValue(e)} mx={"mx-0"} />
          <a href="" className="btn btn--border py-3 px-3">
            <img src="/images/filter-icon.svg" alt="" />
          </a>
        </div>
        <Line />
      </div>
      <div>
        <div className="flex items-center justify-between py-4">
          <p className="text-sm">{totalRecords} employee</p>
          <div className="flex items-center justify-between gap-2">
            <p className="text-[#1F2937] text-sm">Sort by name</p>
            <i className="ico-down text-[#1F2937]"></i>
          </div>
        </div>
        <ul className="text-gray-600">
          {items &&
            items.submenu.map((item, index) => {
              const {
                photo,
                first_name,
                last_name,
                designation_title,
                employee_id,
              } = item;
              return (
                <li className="mb-4 text-sm" key={index}>
                  <li
                    className="cursor-pointer select-none flex items-center justify-between"
                    onClick={() => {
                      setSubMenu(item);
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <Avatar image={photo} status="available" />
                      <div>
                        <p className="text-sm text-[#1F2937] font-medium">
                          {first_name} {last_name}
                        </p>
                        <p className="text-sm pt-0.5">
                          {isProcessAttendance ? (
                            <span className="status status--gray">Open</span>
                          ) : (
                            designation_title
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <i
                        className="ico-right text-[#3466E7] cursor-pointer hover:bg-blue-100 p-2 rounded-full"
                        onClick={() =>
                          navigate(
                            `/employee/employee-details/employee-information/${employee_id}`
                          )
                        }
                      ></i>
                    </div>
                  </li>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="flex items-center justify-end gap-5">
        <button onClick={previousPage}>
          <i className="ico-left" />
        </button>
        <span>{pageNo}/{totalPages}</span>
        <button onClick={nextPage}>
          <i className="ico-right" />
        </button>
      </div>

    </div>
  );
};

export default EmployeeListAside;
