import React, { useState } from "react";
import Modal from "react-responsive-modal";
import {
  closeIcon,
  personOutlinedIcon,
  personRoundedIcon,
} from "../../../../Icons/Icons";
import PolicyPreviewData from "./PolicyPreviewData";
import { SYSTEM_POLICY_APPROVAL_STATUS } from "../../../../Consts/consts";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";
import PolicyApprovalDetails from "./PolicyApprovalDetails";
import PolicyManageEmployeeView from "./PolicyManageEmployeeView";

const PolicyPreview = ({
  isModalOpen,
  handleCloseModal,
  systemPolicy,
  handleClosePreviewAndOpenEdit,
}) => {
  const { approval_status_id, is_creator, is_depricated } = systemPolicy;
  const isDepricated = Number(is_depricated);
  const approvalStatusId = Number(approval_status_id);
  const isPending = approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.pending;
  const isApproved = approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.approved;
  const loggedInUser = useSelector((state) => state.user.auth);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdminOrHr = isAdmin || isHr;
  const isEditPolicyEligible = isPending && (isAdmin || is_creator) && !isDepricated;
  const [isEmpViewListModalOpen, setEmpViewListModalOpen] = useState(false);
  const showEmpViewList = isAdminOrHr && isApproved && !isDepricated;

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      center
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <div className="flex flex-col w-[400px] gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">Policy preview</h1>
          <div className="flex gap-2 items-center">
            {showEmpViewList && (
              <button onClick={() => setEmpViewListModalOpen(true)}>
                {personRoundedIcon}
              </button>
            )}
            {isEditPolicyEligible && (
              <button
                className="text-sm"
                onClick={
                  typeof handleClosePreviewAndOpenEdit === "function"
                    ? handleClosePreviewAndOpenEdit
                    : null
                }
              >
                {" "}
                <img src="/images/editicon.svg" alt="" />
              </button>
            )}
            <button onClick={handleCloseModal}>{closeIcon}</button>
          </div>
        </div>
        <PolicyPreviewData systemPolicy={systemPolicy} />
        <PolicyApprovalDetails systemPolicy={systemPolicy} />
        <PolicyManageEmployeeView
          isModalOpen={isEmpViewListModalOpen}
          handleCloseModal={() => setEmpViewListModalOpen(false)}
          systemPolicy={systemPolicy}
        />
        <div className="flex items-center justify-end gap-3">
          <button onClick={handleCloseModal} className="btn btn--border">
            Cancel
          </button>
          {/** For the admin view we need to add the employees who have view for this policy also include option to enable of disable the status
           *
           */}
        </div>
      </div>
    </Modal>
  );
};

export default PolicyPreview;
