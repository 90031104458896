import React, { useState, useRef,useEffect } from "react";
import Avatar from "../../Avatar";
import MenuButtonForEmployeeListPage from "../EmployeeListMenuButton/MenuButtonForEmployeeListPage";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
import EmployementStatus from "../../Employee/EmployementStatus";
import { Link, useNavigate } from "react-router-dom";
import EmployeeTeamAllocaitonModal from "../EmployeeTeamAllocationModal/EmployeeTeamAllocaitonModal";
import AssignLeaveModal from "../AssignLeaveModal/AssignLeaveModal";
import AssignLineManagerModal from "../AssignLineManagerModal/AssignLineManagerModal";
import SendPasswordSetupLinkConfirmationModal from "../SendPasswordSetupLinkConfirmationModal";
import ManageEmployeeRole from "../ManageEmployeeRole";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../config/userRoles";
import AssignHRModal from "../AssignHRModal/AssignHRModal"
import { useSelector } from "react-redux";

const EmployeeModuleListData = (props) => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const isAdmin = userRoleAuth(user, userRoles.TENANTADMIN);
  const isAdminOrHr = isAdmin || isHr;
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [showModal, setShowModal] = useState(false);
  const [teamListData, setTeamListData] = useState(null);
  const [selectedTeam, setSetectedTeam] = useState();
  const [isAllocatingButton, setIsAllocatingButton] = useState(false);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState([]);
  const employee = props.requestdata;
  const [isAssignLeaveModal, setIsAssignLeaveModal] = useState(false);
  const [isPortalAccess, setIsPortalAccess] = useState(false);
  const { selectedEmployees, handleSelectEmployee } = props;

  const isSelectedEmployee = selectedEmployees.find(
    (i) => i.employee_id === employee.employee_id
  );
  const [isAssignLineManagerModalOpen, setAssignLineManagerModalOpen] =
    useState(false);
    const [isAssignHRModalOpen, setAssignHRModalOpen] =
    useState(false);
  const [
    isSendPasswordSetupLinkConfirmationModal,
    setSendPasswordSetupConfirmationModal,
  ] = useState(false);
  const [isManageEmpRoleModal, setManageEmpRoleModal] = useState(false);

  const handleOpenManageRoleModal = () => {
    setManageEmpRoleModal(true);
  };

  const functions = {
    handleOpenManageRoleModal,
    editProfile: () =>
      navigate(
        `/employee/employee-details/employee-information/${employee.employee_id}`
      ),
    lineManagerAllocation: () => setAssignLineManagerModalOpen(true),
    hrAllocation: () => setAssignHRModalOpen(true),
    handleOpenSendPasswordSetupLinkConfirmationModal: () =>
      setSendPasswordSetupConfirmationModal(true),
    sendPasswordLinkBtn: async () => {
      try {
        toast.info(`Sending password setup link to ${employee.first_name}`);
        // setIsSendingBtn(true);
        let isMounted = true;
        const controller = new AbortController();
        await axiosPrivate
          .post(
            "employee/sendPasswordSetupLink",
            {
              reference_id: employee.employee_id,
            },
            {
              signal: controller.signal,
            }
          )
          .then((response) => {
            if (response.data.status) {
              toast.success("Password setup link sent successfully!", {
                type: "success",
              });
            } else if (
              !response.data.status &&
              response.data.statusCode == 200
            ) {
              toast.error("Passoword setup link sending failed!");
            } else {
              toast.error("Passoword setup link sending failed!");
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error("Passoword setup link sending failed!");
          });

        return () => {
          isMounted = false;
          controller.abort();
        };
      } catch (err) {
        toast.error("Passoword setup link sending failed!");
      }
    },
    employeeTeamAllocation: async () => {
      setShowModal(true);
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();

      await axiosPrivate
        .get("employee/teamlist", {
          signal: controller.signal,
        })
        .then((response) => {
          setTeamListData(response.data.teams);
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    },
    closeModal: () => {
      setShowModal(false);
      setSetectedTeam(undefined);
      setErrMsg("");
    },
    selectTeam: (e) => {
      setSetectedTeam(e);
      setErrMsg("");
    },
    assignLeave: () => {
      setIsAssignLeaveModal(true);
    },
  };
  useEffect(() => {
    if (employee) {
      setIsPortalAccess(employee.portal_access);
    }
  }, [employee]);
  const updatePortalAccess = async (status) => {
    try {
      const response = await axiosPrivate.put("employee/updatePortalAccess", {
        employee_id: employee.employee_id,
        status: status.toString(),
      });
      if (response?.data?.status) {
        setIsPortalAccess(status);
        toast.success("Portal access updated successfully!");
      } else {
     
        toast.error("Failed to update portal access");
      }
    } catch (err) {
      
      toast.error(err?.response?.data?.message || "Something went wrong!!");
    }
  };

  const allocateToTeam = async () => {
    setErrMsg("");
    if (selectedTeam === undefined) {
      setErrMsg("Select a team to allocate the employee");
    } else {
      setErrMsg("");
      setIsAllocatingButton(true);
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post(
          "employee/allocateTeam",
          {
            employee_id: employee.employee_id,
            team_id: selectedTeam.value,
          },
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          // isMounted && console.log(response.data);
          if (response.data.status) {
            setTimeout(() => {
              toast.success(
                `${selectedTeam.label} team is allocated to ${employee.first_name}`,
                { type: "success" }
              );
              setSetectedTeam(undefined);
              setShowModal(false);
              setIsAllocatingButton(false);
            }, 1000);
          } else {
            setIsAllocatingButton(false);
            setErrMsg("Employee is already allocated to this team");
          }
        })
        .catch((err) => {
          toast.error(`Team allocation failed!`, { type: "error" });
          setErrMsg("No server response!");
          setIsAllocatingButton(false);
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };

  return (
    <>
      {isManageEmpRoleModal && (
        <ManageEmployeeRole
          employee={employee}
          open={isManageEmpRoleModal}
          close={() => setManageEmpRoleModal(false)}
        />
      )}
      {isSendPasswordSetupLinkConfirmationModal && (
        <SendPasswordSetupLinkConfirmationModal
          open={isSendPasswordSetupLinkConfirmationModal}
          close={() => setSendPasswordSetupConfirmationModal(false)}
          functions={functions}
          employeeName={`${employee?.first_name || "this employee"}`}
        />
      )}
      {isAssignLineManagerModalOpen && (
        <AssignLineManagerModal
          open={isAssignLineManagerModalOpen}
          close={() => setAssignLineManagerModalOpen(false)}
          selectedEmployees={[employee]}
        />
      )}
         {isAssignHRModalOpen && (
        <AssignHRModal
          open={isAssignHRModalOpen}
          close={() => setAssignHRModalOpen(false)}
          selectedEmployees={[employee]}
        />
      )}
      {showModal && (
        <EmployeeTeamAllocaitonModal
          open={showModal}
          close={() => setShowModal(false)}
          employee={employee}
          functions={functions}
          teamListData={teamListData}
          allocateToTeam={allocateToTeam}
          isAllocatingButton={isAllocatingButton}
          errMsg={errMsg}
          errRef={errRef}
        />
      )}
      {isAssignLeaveModal && (
        <AssignLeaveModal
          open={isAssignLeaveModal}
          close={() => setIsAssignLeaveModal(false)}
          employee={employee}
          leaveTypes={props.leaveTypes}
          leavePolicy={props.leavePolicy}
        />
      )}
      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td
          scope="row"
          className="px-10 py-4 font-medium text-gray-800 whitespace-nowrap"
        >
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isSelectedEmployee}
              onChange={(e) => handleSelectEmployee(employee, e.target.checked)}
              className="mr-3"
            />
            <Link
              to={
                isAdminOrHr
                  ? `/employee/employee-details/employee-information/${employee.employee_id}`
                  : "#"
              }
            >
              {employee.employee_serial}
            </Link>
          </div>
        </td>
        <td className="px-10 py-4 flex items-center gap-3 border-0">
          <Avatar image={employee.photo} />
          <span className="whitespace-nowrap">
            {employee.first_name} &nbsp;
            {employee.middle_name} &nbsp;
            {employee.last_name} &nbsp;
          </span>
        </td>
        <td className="px-10 py-4">@{employee.line_manager_name}</td>
        <td className="px-10 py-4">@{employee.hr_name}</td>
        <td className="px-10 py-4">{employee.designation_title}</td>

        <td className="px-10 py-4">{employee.department_name}</td>

        <td className="px-10 py-4">{employee.office_location}</td>
        <td className="px-10 py-4">
          <span className="py-1 px-2.5">
          <ToggleSwitch 
  checked={isPortalAccess}
  getToggleState={isAdminOrHr ? updatePortalAccess : undefined}
  disabled={!isAdminOrHr} 
/>


          </span>
        </td>
        <td className="px-10 py-4">
          <EmployementStatus status={employee.account_status} />
        </td>

        {isAdminOrHr && (
          <td className="px-8 py-4">
            <div className="flex items-center gap-2.5">
              <i className="ico-chat text-gray-500 cursor-pointer"></i>
              <MenuButtonForEmployeeListPage
                btnFunction={functions}
                title={"Send password setup link"}
              />
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default EmployeeModuleListData;
