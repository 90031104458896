import React, { useContext, useEffect, useState } from "react";
import LeaveRequestData from "../LeaveRequestData/LeaveRequestData";
import { MyLeaveContext } from "../../../containers/Leaves/MyLeave/MyLeave";

const LeaveRequestList = ({ leaveData }) => {
  const { isAdditionalLeaveTab, isLeaveTabView } = useContext(MyLeaveContext);

  const tableCols = [
    { colName: "ID", icoDown: true, action: null, status: true },
    { colName: "Leave Type", icoDown: true, action: null, status: true },
    {
      colName: "From",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "To",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "Total",
      icoDown: true,
      action: null,
      status: !isAdditionalLeaveTab,
    },
    {
      colName: "Leave Date",
      icoDown: true,
      action: null,
      status: isAdditionalLeaveTab,
    },
    { colName: "Status", icoDown: true, action: null, status: true },
    {
      colName: " ",
      icoDown: false,
      action: null,
      status: !isAdditionalLeaveTab,
    },
  ];
  const [col, setCol] = useState(tableCols);

  useEffect(() => {
    setCol(tableCols);
  }, [isLeaveTabView, isAdditionalLeaveTab]);

  return (
    <div className="mx-10 border rounded-lg overflow-x-auto mb-20">
      <table className="">
        <thead>
          <tr className="px-5">
            {Array.isArray(col)
              ? col.map((item, index) => {
                  if (item.status) {
                    return (
                      <td
                        className="items-center"
                        key={`leave_req_list_col_${index}`}
                      >
                        <span>{item.colName}</span>
                        {item.icoDown ? (
                          <i className="ico-down pl-2 text-blue-600"></i>
                        ) : null}
                      </td>
                    );
                  }
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {leaveData &&
            leaveData?.map?.((leave, index) => (
              <LeaveRequestData leave={leave} key={`leave_rew_data_${index}`} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveRequestList;
