import React from "react";
import Avatar from "../../Avatar/Avatar";

const DirectoryCardData = (props) => {
  return (
    <div className="border border-gray-300 bg-white p-6 rounded-md">
      <div className="flex flex-col items-center">
        <Avatar image={props.requestdata.image} status="available" />
        <p className="font-semibold text-gray-800 pt-4 pb-1">
          {props.requestdata.Name}
        </p>
        <p className="text-sm font-normal pb-3">
          {props.requestdata.Designation}
        </p>
        <p className="border border-green-600 py-0.5 px-2.5 rounded text-[#059669]">
          {props.requestdata.Status}
        </p>
      </div>
      <div>
        <div className="pt-4 pb-2 flex justify-between">
          <p>phone:</p>
          <p>{props.requestdata.Phone}</p>
        </div>
        <div className="pb-2 flex justify-between">
          <p>Extension:</p>
          <p>{props.requestdata.Extension}</p>
        </div>
        <div className="pb-2 flex justify-between">
          <p>Email:</p>
          <p>{props.requestdata.Email}</p>
        </div>
        {/* <div className="pb-2 flex justify-between">
          <p>Line Manager:</p>
          <p>{props.requestdata.Line_Manager}</p>
        </div> */}
        <div className="text-center mt-4">
          <span className="pr-5 cursor-pointer">
            <i className="ico-more"></i>
          </span>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-chat mr-2.5"></i>
            </span>
            Send Message
          </a>
        </div>
      </div>
    </div>
  );
};

export default DirectoryCardData;
