import React, { useState } from "react";
import Select from "react-select";
import AssignShiftTable from "../../../components/Attendance/ManageShift/AssignShift/AssignShiftTable";
import AssignShiftSlidingPanel from "../../../components/Attendance/ManageShift/AssignShift/AssignShiftSlidingPanel";
import moment from "moment";
import formatDate from "../../../utils/FormatDate/formatDate";
import EmptyState from "../../../components/EmptyState";
import PageLoader from "../../../components/PageLoader";
import Pagination from "../../../components/Pagination/Pagination";
import Search from "../../../components/Header/Search/Search";
import {
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
} from "../../../queries/employeeQueries";
import {
  useEmployeeShiftList,
} from "../../../queries/attendanceQuries";

const AssignShift = () => {
  const [filters, setFilters] = useState({
    filter: "",
    location: "",
    designation: "",
    department: "",
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    pageNo: 1,
    pageSize: 10,
  });

  const [openAssignShiftSlidingPanel, setAssignShiftSlidingPanel] =
    useState(false);

  const formatedFirstDay = formatDate(filters.fromDate).dateString2;
  const formatedLastDay = formatDate(filters.toDate).dateString2;

  const { data: locationListData } = useGetLocationList();
  const { data: departmentListData } = useGetDepartmentList();
  const { data: designationListData } = useGetDesignationList();

  const {
    data: employeeShiftData,
    isLoading,
    refetch,
  } = useEmployeeShiftList(filters);

  const locationList = locationListData?.result || [];
  const departmentList = departmentListData?.result || [];
  const designationList = designationListData?.result || [];

  const handleSelectChange = (value, source) => {
    setFilters((prev) => ({
      ...prev,
      [source]: value?.value || "",
    }));
  };

  const handleChangeDate = (type) => {
    const updatedFirstDate =
      type === "nextMonth"
        ? moment(filters.fromDate).add(1, "month").format("YYYY-MM-DD")
        : moment(filters.fromDate).subtract(1, "month").format("YYYY-MM-DD");

    setFilters((prev) => ({
      ...prev,
      fromDate: updatedFirstDate,
      toDate: moment(updatedFirstDate).endOf("month").format("YYYY-MM-DD"),
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      filter: "", 
      location: "",
      designation: "",
      department: "",
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().endOf("month").format("YYYY-MM-DD"),
      pageNo: 1,
      pageSize: 10,
    });
  };

  const handleSetPageNoAndSize = (value, type) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const isFilterApplied = filters.filter || filters.location || filters.designation || filters.department;

  return (
    <>
      {openAssignShiftSlidingPanel && (
        <AssignShiftSlidingPanel
          open={openAssignShiftSlidingPanel}
          close={() => setAssignShiftSlidingPanel(false)}
          fetchEmployeeShiftList={refetch}
        />
      )}

      <div className="flex flex-col w-full">
        <div className="p-10 flex flex-col gap-3">
          <div className="flex justify-between">
            <div>
              <h1 className="text-black font-semibold text-xl">Assign Shift</h1>
              <p>Manage and view all shifts</p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setAssignShiftSlidingPanel(true)}
              >
                <i className="ico-plus mr-1.5"></i> Assign Shift
              </button>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex gap-3">
              <Select
                value={filters.designation
                  ? { 
                      label: designationList.find((i) => i.id === filters.designation)?.designation_title,
                      value: filters.designation 
                    }
                  : null}
                placeholder="Designation: All"
                options={designationList.map((i) => ({
                  label: i.designation_title,
                  value: i.id,
                }))}
                onChange={(e) => handleSelectChange(e, "designation")}
              />

              <Select
                value={filters.department
                  ? { 
                      label: departmentList.find((i) => i.id === filters.department)?.department_name,
                      value: filters.department 
                    }
                  : null}
                placeholder="Department: All"
                options={departmentList.map((i) => ({
                  label: i.department_name,
                  value: i.id,
                }))}
                onChange={(e) => handleSelectChange(e, "department")}
              />

              <Select
                value={filters.location
                  ? { 
                      label: locationList.find((i) => i.id === filters.location)?.location_name,
                      value: filters.location 
                    }
                  : null}
                placeholder="Office: All"
                options={locationList.map((i) => ({
                  label: i.location_name,
                  value: i.id,
                }))}
                onChange={(e) => handleSelectChange(e, "location")}
              />

              <Search
                value={filters.filter}
                onChange={(e) => setFilters((prev) => ({ ...prev, filter: e || "" }))}
              />

              {isFilterApplied && (
                <p
                  className="text-blue-600 font-medium pt-2 cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Clear filter
                </p>
              )}
            </div>

            <div className="flex gap-3 items-center">
              <button onClick={() => handleChangeDate("prevMonth")}>
                <i className="ico-left text-blue-600"></i>
              </button>
              <p>{`${formatedFirstDay} - ${formatedLastDay}`}</p>
              <button onClick={() => handleChangeDate("nextMonth")}>
                <i className="ico-right text-blue-600"></i>
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <PageLoader />
        ) : 
          Array.isArray(employeeShiftData?.result) && employeeShiftData?.result ? (
          <>
            <AssignShiftTable data={employeeShiftData?.result} />
            {Array.isArray(employeeShiftData?.result) && (
              <Pagination
                page={{
                  pageNo: filters.pageNo,
                  setPageNo: (e) => handleSetPageNoAndSize(e, 'pageNo'),
                  pageSize: filters.pageSize,
                  setPageSize: (e) => handleSetPageNoAndSize(e, 'pageSize'),
                  totalRecords: employeeShiftData?.totalRecords || 0,
                }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

export default AssignShift;
