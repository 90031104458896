import React, { useState } from "react";
import SeperationStatus from "./SeperationStatus";
import Avatar from "../../../../components/Avatar/Avatar";

const SeperationListData = (props) => {
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td
        scope="row"
        className="px-10 py-4 font-medium text-gray-800 whitespace-nowrap"
      >
        <div className="flex items-center">
          <input type="checkbox" className="mr-3" />
          {props.requestdata.id}
        </div>
      </td>
      <td className="px-10 py-4 flex items-center gap-3 border-0 text-[#1F2937] text-sm font-medium">
        <Avatar image={props.requestdata.image} status="available" />
        {props.requestdata.employee_name}
      </td>
      <td className="px-10 py-4 text-[#1F2937] text-sm font-medium">
        {props.requestdata.reason}
      </td>
      <td className="px-10 py-4 text-[#1F2937] text-sm font-medium">
        {props.requestdata.request_date}
      </td>
      {/* <td className="px-10 py-4">{props.requestdata.hr_status}</td> */}
      <td class="px-10 py-4">
        <SeperationStatus
          selectedStatus={selectedStatus}
          onChange={handleStatusChange}
          bg={props.requestdata.selectBg}
          label={props.requestdata.label}
        />
      </td>
      <td class="px-10 py-4">
        <SeperationStatus
          selectedStatus={selectedStatus}
          onChange={handleStatusChange}
          bg={props.requestdata.selectBg}
          label={props.requestdata.label}
        />
      </td>
      <td className="px-10 py-4">
        <span className="status status--pending">
          {props.requestdata.off_boarding_status}
        </span>
      </td>
      <td className="px-10 py-4">
        <span className="status status--green">
          {props.requestdata.seperation_status}
        </span>
      </td>
      <td className="px-10 py-4">
        <i className="ico-more text-gray-500 cursor-pointer"></i>
      </td>
    </tr>
  );
};

export default SeperationListData;
