import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";

const menu = [
  {
    text: "Widgets",
    link: `/settings/widget-list`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
  {
    text: "Tenant Setting",
    link: `/settings/tenant-setting`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
  {
    text: "App Setting",
    link: `/settings/app-setting`,
    icon: "ico-down",
    roles: [1, 2],
    status: true,
  },
];

const SettingsModule = () => {
  return (
    <DashboardLayout asideBarMenu={menu} />
  );
};

export default SettingsModule;
