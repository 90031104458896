import React from "react";

const SalaryInfo = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Salary</p>
        </div>
        <div>
          <img src="/images/editicon.svg" alt="" />
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Annual CTC </td>
              <td className="data">Rs 6,62,916.00 </td>
              <td className="title">Effective Date </td>
              <td className="data">01 Oct 2020</td>
            </tr>
            <tr>
              <td className="title">Last Revision </td>
              <td className="data">2 year ago </td>
              <td className="title">Payout Month </td>
              <td className="data">Oct 2020 </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-base font-semibold text-gray-800">Earning</p>
        </div>
        <div>
          <p>Ammount (in INR)</p>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Basic</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">House Rent Allowance</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Special Allowance</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Bonus/ WFH Allowances</p>
        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Gross Earning</p>
        <div className="flex-1 text-right">
          <span>1,66,667.00</span>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-base font-semibold text-gray-800">Deductions</p>
        </div>
        <div>
          <p>Ammount (in INR)</p>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Provident Fund</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">KS&CEWF/LWF</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">TDS/Income Tax</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Profesional Tax</p>
        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Total Deduction</p>
        <div className="flex-1 text-right">
          <span>1,66,667.00</span>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-base font-semibold text-gray-800">Reimbursement</p>
        </div>
        <div>
          <p>Ammount (in INR)</p>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Fuel Reimbursement</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>
      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Travel Ticket</p>

        <div className="flex-1 text-right">
          <span>0</span>
        </div>
      </div>

      <div className="flex items-center gap-4 mt-6 mb-3">
        <p className="text-sm font-medium">Total Deduction</p>
        <div className="flex-1 text-right">
          <span>1,66,667.00</span>
        </div>
      </div>
      <div className="mb-4 p-4 bg-red-50 flex items-center justify-between gap-4 rounded-md">
        <div className="text-red-800 w-3/5">
          <p className="text-sm font-medium">
            System Calculated Components' Total
          </p>
          <p className="text-xs leading-4 font-normal">
            Amount must be greater than zero. Adjust the CTC or any of the
            component's amount.
          </p>
        </div>
        <div className="text-red-600 text-sm font-medium w-1/5 text-center">
          <p>₹ 10,666,00 </p>
        </div>
        <div className="text-red-600 text-sm font-medium w-1/5 text-right">
          <p>₹ 10,666,00 </p>
        </div>
      </div>
      <div className="mb-10 p-4 bg-green-50 flex items-center justify-between gap-4 rounded-md">
        <div className="text-gray-800 w-3/5">
          <p className="text-sm font-medium">Cost to company</p>
        </div>
        <div className="text-green-800 text-sm font-medium w-1/5 text-center">
          <p>₹ 416,666,00 </p>
        </div>
        <div className="text-green-800 text-sm font-medium w-1/5 text-right">
          <p>₹ 416,666,00 </p>
        </div>
      </div>
    </div>
  );
};

export default SalaryInfo;
