import React, { useState, useRef, useEffect } from "react";
import { formatHourAndMinitueDuration } from "../../../../utils/FormatDate/formatTimeHourDuration";

const DurationBasedTimeSelectComp = ({ item, getTimeRef, work }) => {
  const [edit, setEdit] = useState(false);
  const hourRef = useRef();
  const minituRef = useRef();
  const duration = formatHourAndMinitueDuration(work[item?.id]?.duration);

  const handleChangeDurationView = () => {
    getTimeRef({ hourRef, minituRef, item });
  };

  const handleEdit = () => {
    setEdit(!edit);
  };


  return (
    <div className="relative">
      <button
        className="btn btn--border font-normal text-sm text-black"
        onClick={handleEdit}
      >
        {duration?.result || "00h 00m"}
      </button>

      {edit ? (
        <div className="shadow z-50 border-gray-200 hover:bg-gray-50 bg-white rounded-md p-2 w-[160px] absolute left-[50%] top-20  translate-x-[-50%] translate-y-[-50%]">
          <div className="flex items-center gap-2">
            <input
              ref={hourRef}
              type="number"
              defaultValue={duration?.hour || 8}
              onChange={(e) =>
                handleChangeDurationView(
                  Number(e.target.value),
                  "hour",
                  item?.id
                )
              }
            />
            <span>:</span>
            <input
              ref={minituRef}
              type="number"
              defaultValue={duration?.minitue || 0}
              onChange={(e) =>
                handleChangeDurationView(
                  Number(e.target.value),
                  "minitues",
                  item?.id
                )
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DurationBasedTimeSelectComp;
