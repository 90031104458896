import React from "react";

const EmptyState = (props) => {
  return (
    <div className="flex w-full justify-center bg-[#F9FAFB] h-screen">
      <div className="mt-28">
        <div className="max-w-[500px] max-h-[500px]">
          <img src="/images/task-img.png" className="w-full h-auto" alt="" />
        </div>

        <div className="flex justify-center mt-10 mb-4">
          <p className="text-md text-black font-semibold">
            No Result Found !!!
          </p>
        </div>
        <p className="flex justify-center">{props.message}</p>
      </div>
    </div>
  );
};

export default EmptyState;
