import React from "react";
import Avatar from "../Avatar/Avatar";
const ProfileData = ({ profile, setEditProfile }) => {
  const {
    first_name,
    middle_name,
    last_name,
    department_name,
    designation_title,
    line_manager_name,
    location_name,
    phone,
    email,
  } = profile;

  return (
    <div className="">
      <div className="relative flex justify-center item-center ">
        <Avatar
          height={"20"}
          width={"20"}
          image={profile?.photo}
          isProfileSideBar={true}
        />
      </div>
      <h2 className="text-lg text-center font-semibold leading-6 text-gray-800 not-italic pt-3">
        {first_name + " " + middle_name + " " + last_name}
      </h2>
      <p className="text-sm text-center font-normal leading-5 text-gray-500 not-italic pt-1">
        {designation_title}
      </p>
      <div className="md:text-left py-4 border border-solid border-r-0 border-l-0 border-gray-200 mt-7">
        <p className="pb-3 flex items-center justify-between">
          <div className="text-sm flex items-center font-medium leading-5 text-gray-800 pl-1">
            <span className="mr-1 ico-email"></span>
            {email}
          </div>
          <button
            className="ico-edit inline-block mr-[10px] float-right"
            onClick={() => setEditProfile(true)}
          ></button>
        </p>
        <p className="pb-3 flex items-center">
          <span className="ico-phone ml-1"></span>
          <span className="text-sm font-medium leading-5 text-gray-800 inline pl-1">
            {phone}
          </span>
        </p>
      </div>
      <div className="md:text-left py-4 border border-solid border-r-0 border-l-0 border-t-0 border-gray-200">
        <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
          Department
        </p>
        <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
          {department_name}
        </p>
        <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
          Office
        </p>
        <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
          {location_name}
        </p>
        <p className="text-sm font-medium leading-5 text-gray-500 pb-1">
          Line Manager
        </p>
        <p className="text-sm font-normal leading-5 text-gray-800 pb-3">
          {line_manager_name}
        </p>
      </div>
    </div>
  );
};

export default ProfileData;
