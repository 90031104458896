import React from "react";
import formatDate from "../../../../utils/FormatDate/formatDate";
import Avatar from "../../../Avatar";

const ApproverHistoryDetails = ({ data }) => {
  const { approver_name, leave_serial, approval_status_label, remark, time } =
    data;
  const logDate = formatDate(time).dateString2;
  const logTime = formatDate(time).localString2;
  return (
    <div className="px-5">
      <p className="font-medium py-2">{logDate}</p>
      <div className=" flex gap-2">
        <div>
          <Avatar title={approver_name[0]} />
        </div>
        <div className="w-full">
          <div className="flex justify-between ">
            {" "}
            <p className="font-medium">{approver_name}</p>
            <p>{logTime}</p>
          </div>
          <p>
            <span className="font-medium">{approval_status_label}</span> Leave{" "}
            <span className="text-blue-600">#{leave_serial}</span>
          </p>
          <div className="rounded-md flex gap-5 text-gray-600 text-xs bg-gray-100 p-2 mt-1">
            {remark}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproverHistoryDetails;
