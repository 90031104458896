import React, { useEffect, useState } from "react";
import AttendanceDetailsSlidingPanel from "../SelfService/MyAttendance/AttendanceDetailsSlidingPanel";
import statusColor from "../../../utils/Attendance/statusColor";

const MyAttendanceListData = (props) => {
  const [
    isAttendanceDetailsSlidingPanelOpen,
    setAttendanceDetailsSlidingPanelOpen,
  ] = useState(false);

  const handleOpenAttendanceDetailsSlidingPanel = () => {
    setAttendanceDetailsSlidingPanelOpen(true);
  };

  const handleCloseAttendanceDetailsSlidingPanel = () => {
    setAttendanceDetailsSlidingPanelOpen(false);
  };
  const [clockInClockOutList, setClockInClockOutList] = useState([]);

  const {
    requestdata: {
      attendance_date,
      clock_in_time,
      clock_out_time,
      remaining_hours,
      total_hours,
      attendance_status_label,
      attendance_id,
      duration,
    },
    isProcessAttendance,
  } = props;
  
  return (
    <>
      <AttendanceDetailsSlidingPanel
        open={isAttendanceDetailsSlidingPanelOpen}
        close={handleCloseAttendanceDetailsSlidingPanel}
        attendanceId={attendance_id}
        handleOpenAttendanceDetailsSlidingPanel={
          handleOpenAttendanceDetailsSlidingPanel
        }
        isProcessAttendance={isProcessAttendance}
      />
      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
        <td
          scope="row"
          className="px-10 py-4 font-medium text-gray-800 whitespace-nowrap cursor-pointer hover:text-blue-600"
          onClick={handleOpenAttendanceDetailsSlidingPanel}
        >
          {attendance_date || "-"}
        </td>
        <td className="px-10 py-4">{clock_in_time || "-"}</td>
        <td className="px-10 py-4">{clock_out_time || "-"}</td>
        <td
        className="px-10 py-4"
        style={{ color: statusColor(total_hours, duration) }}
      >
        {remaining_hours || "-"}
      </td>
        <td className="px-10 py-4">{total_hours || "- "}</td>

        <td
          className="px-10 py-4"
        
        >
          <span
            style={{
              backgroundColor:
                statusColor(total_hours) === "red"
                  ? "lightcoral"
                  : "lightgreen",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              display: "inline-block",
              marginRight: "5px"
            }}
          ></span>
          <span>{attendance_status_label || "-"}</span>
        </td>
      </tr>
    </>
  );
};

export default MyAttendanceListData;
