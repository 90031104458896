import React from "react";
import Avatar from "../../Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import formatSecondsToTime from "../../../utils/FormatDate/formatSecondsToTime";

const EmployeeAttendanceListData = ({ data, isDate, datesCol }) => {

  const navigate = useNavigate();
  const employeeName = `${data?.first_name || ""} ${data?.last_name || ""}`;
  const image = data?.photo || "";
  const employeeType = data?.employee_type_label || "Full Time";
  const paidType = "Salaried";
  const designationTitle = data?.designation_title || "";
  const attendaceDetails = data?.attendaceDetails || [];
  const handleViewEmployeeAttendanceDetails = () => {
    navigate("/attendance/employee-attendance-details");
  };

  const totalWorkHour = Array.isArray(attendaceDetails)
    ? attendaceDetails.reduce((prev, curr) => {
        return (prev += Number(curr.duration));
      }, 0)
    : 0;
  const payableHours = Array.isArray(attendaceDetails)
    ? formatSecondsToTime(
        attendaceDetails.reduce((prev, curr) => {
          return (prev += Number(curr.lastActivitiyDurationInSecond));
        }, 0),
        "object"
      )
    : "0hr";
  const formatPayableHours = `${payableHours?.hours || 0}hr ${
    payableHours.minutes ? `${payableHours.minutes}m` : ""
  }`;

  const workShedulePercentage = Math.round(
    (Number(payableHours?.hours || 0) * totalWorkHour) / 100
  );

  const percentageBar =
    workShedulePercentage < 0
      ? 0
      : workShedulePercentage > 100
      ? 100
      : workShedulePercentage;

  if (isDate) {
    return (
      <tr>
        {datesCol.map((date, index) => {
          const getDetails = attendaceDetails.find((i) =>
            moment(i.created_at).isSame(moment(date), "day")
          );
          const isWorkingDay = getDetails?.isWorkingDay || null;
          const isShiftAllocated = getDetails?.isShiftAllocated || null;
          const isShiftDetailsFound = getDetails?.shiftDetailsFound || null;
          const attendanceHour = formatSecondsToTime(
            getDetails?.lastActivitiyDurationInSecond || 0,
            "object"
          );
          const formatAttendanceHour = `${attendanceHour?.hours || 0}hr ${
            attendanceHour.minutes ? `${attendanceHour.minutes}m` : ""
          }`;
          const colTitle =
            !getDetails || !isShiftAllocated || !isShiftDetailsFound
              ? "-"
              : isWorkingDay
              ? formatAttendanceHour
              : "OFF";

          return (
            <td
              style={{
                backgroundImage: !isWorkingDay ? "url('/images/cell.jpg')" : "",
              }}
              key={`${index}_table_data_attendance_list-emp_key`}
              className={`text-black  whitespace-nowrap font-medium px-2 text-center py-[26px] ${
                !isWorkingDay && "bg-gray-100"
              }`}
            >
              {colTitle}
            </td>
          );
        })}
      </tr>
    );
  }

  return (
    <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td className="px-10 py-4  cursor-pointer hover:bg-gray-100   border-0 whitespace-nowrap">
        <div
          className="flex items-center gap-3"
          onClick={handleViewEmployeeAttendanceDetails}
        >
          <Avatar status="available" image={image} />
          <div className="flex flex-col">
            <span className="text-black hover:text-blue-600 font-medium ">
              {employeeName}
            </span>

            <span>{designationTitle}</span>
          </div>
        </div>
      </td>
      <td className="px-10 py-4">
        <div className="flex flex-col">
          <span className="text-gray-800">{employeeType}</span>
          <span className="">{paidType}</span>
        </div>
      </td>
      <td className="px-10 py-4 ">
        <div className="bg-gray-100 rounded-md relative">
          <div
            style={{
              width: `${percentageBar}%`,
            }}
            className={`bg-gray-300  h-7  ${
              percentageBar === 100 ? "rounded-md" : "rounded-l-md"
            }`}
          ></div>
          <span className="absolute z-50 left-[10px] bottom-1 text-black font-medium">
            {formatPayableHours} / {totalWorkHour} hr
          </span>
        </div>
      </td>
    </tr>
  );
};

export default EmployeeAttendanceListData;
