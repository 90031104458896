import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import LeaveBalanceAutoIncrementList from "./LeaveBalanceAutoIncrementList";
import PreferenceTab from "../../../Assets/AssetsTab/PreferenceTab";
import PageLoader from "../../../PageLoader/PageLoader";
import { Link } from "react-router-dom";

const LeaveBalanceAutoIncrement = () => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedLeaveType, setSelectedLeaveType] = useState({
    id: null,
    name: null,
  });
  const [leaveTypesList, setLeaveTypesList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const selectedYear = new Date().getFullYear();

  const fetchAutoIncrementLeaveTypeList = async () => {
    try {
      const response = await axiosPrivate.get(
        `lms/leave/leaveBalanceAutoIncrementList`
      );
      const res = response?.data;
      if (res?.status) {
        setLeaveTypesList(res?.leaveTypeList);
        if (res.leaveTypeList.length > 0) {
          setSelectedLeaveType({
            id: res?.leaveTypeList?.[0]?.id,
            name: res?.leaveTypeList?.[0]?.leave_name,
          });
        }
      } else {
        setLeaveTypesList([]);
      }
    } catch (err) {
      setLeaveTypesList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAutoIncrementLeaveTypeList();
  }, []);

  const handleTabChange = (index) => {
    const selectedLeaveType = leaveTypesList[index];
    setSelectedLeaveType({
      id: selectedLeaveType.id,
      name: selectedLeaveType.leave_name,
    });
  };

  const leaveTypeTabContainerStyle = {
    overflowX: "auto",
    whiteSpace: "nowrap",
  };
  const centerMessageStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100vh",
    textAlign: "center",
    paddingTop: "20vh",
  };

  return (
    <>
      <div className="w-full overflow-x-scroll">
        <div className="px-10 py-6">
          <div className="my-2">
            <h2 className="text-lg font-semibold">
              Auto Increment Leave Balance
            </h2>
            <p>Customize all auto increment leave balances</p>
          </div>
          {!isLoading && leaveTypesList && leaveTypesList.length > 0 && (
            <div style={leaveTypeTabContainerStyle}>
              <PreferenceTab
                tabs={leaveTypesList.map((leaveType) => leaveType.leave_name)}
                getTabIndex={handleTabChange}
              />
              <div className="flex gap-3 px-8 py-6"></div>
            </div>
          )}
          {isLoading ? (
            <PageLoader />
          ) : leaveTypesList && leaveTypesList.length > 0 ? (
            <LeaveBalanceAutoIncrementList
              selectedLeaveType={selectedLeaveType}
              selectedYear={selectedYear}
            />
          ) : (
            <div style={centerMessageStyle}>
              <div>
                There are currently no leave types with auto increment enabled.{" "}
                <br />
                To enable it please follow the{" "}
                <Link
                  to="/leave/settings/leave-type"
                  className="text-blue-600 font-medium underline cursor-pointer"
                >
                  Leave Type and Policy
                </Link>{" "}
                link
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LeaveBalanceAutoIncrement;
