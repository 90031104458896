import React from "react";
import Avatar from "../../../Avatar/Avatar";
import formatDate from "../../../../utils/FormatDate/formatDate";
import moment from "moment";

const AssignShiftData = ({ data }) => {
  const getShiftLastModifiedDate = (empShiftData) => {
    let time = empShiftData?.[0]?.created_at;
    empShiftData.forEach((i) => {
      if (moment(i?.created_at) > moment(time)) {
        time = i.created_at;
      }
    });
    return time || "";
  };
  const shiftDetails = data?.shiftDetails || [];
  const employeeName = `${data?.first_name || ""} ${data?.middle_name || ""} ${
    data?.last_name || ""
  }`;
  const departmentTitle = data?.department_name;
  const employeePhoto = data?.photo;
  const getLastModifiedDate = getShiftLastModifiedDate(shiftDetails);
  const lastModified = getLastModifiedDate
    ? `${formatDate(getLastModifiedDate).dateString2} ${
        formatDate(getLastModifiedDate).localString2
      }`
    : "-";
  const lastUpdatedShift =
  shiftDetails?.find((i) => i?.created_at === getLastModifiedDate);
  const fromDate = lastUpdatedShift ? formatDate(lastUpdatedShift?.from_date)?.dateString2 : "";
  const toDate = lastUpdatedShift ? formatDate(lastUpdatedShift?.to_date)?.dateString2 : "";
  const shiftName = lastUpdatedShift?.shift_name || "Shift not allocated";

  return (
    <>
      <tr>
        <td>
          <div className="flex gap-2">
            <Avatar image={employeePhoto} />
            <div className="flex flex-col">
              {" "}
              <p className="text-black font-medium">{employeeName}</p>
              {departmentTitle}
            </div>
          </div>
        </td>
        <td>
          <div>
            <p className="text-black font-medium">{shiftName}</p>
            {`${fromDate} - ${toDate}`}
          </div>
        </td>
        <td className="text-black font-medium">{lastModified}</td>
      </tr>
    </>
  );
};

export default AssignShiftData;
