import React from 'react'

const RegularizationRequestOverviewSwiper = ({ data }) => {
  return (
    <div className=' flex flex-col w-[200px] h-[62px] rounded-md border py-2 px-4'>
        
        <span className='font-medium text-base text-black'>{data?.count}</span>
        <p>{data?.status_name}</p>
    </div>
  )
}

export default RegularizationRequestOverviewSwiper