const formatAvatarLetter = (name) => {
  let letters = "";
  if (name) {
    try {
      letters += `${name.split(" ")[0].charAt(0).toUpperCase()}`;
      letters += `${name.split(" ")[1].charAt(0).toUpperCase()}`;
      letters += `${name.split(" ")[2].charAt(0).toUpperCase()}`;
    } catch (err) {}
    return letters;
  } else {
    return "Please provide a name";
  }
};
export default formatAvatarLetter;
