import React from "react";
import Select from "react-select";

const SeperationStatus = ({ selectedStatus, onChange, bg, label }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#D1FAE5",
      background: state.isFocused ? "white" : bg,
      // borderColor: state.isFocused ? "#80bdff" : "#ced4da",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : null,
      "&:hover": {
        borderColor: state.isFocused ? "#80bdff" : "#ced4da"
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? state.data.color : null,
      color: state.isSelected ? "black" : "black"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: selectedStatus ? selectedStatus.color : "black"
    })
  };

  return (
    <Select
      className="text-sm leading-5 font-medium"
      options={[
        { label: "Pending" },
        { label: "Declined" },
        { label: "Approved" }
      ]}
      defaultValue={{
        label: label
      }}
      onChange={onChange}
      styles={customStyles}
    />
  );
};

export default SeperationStatus;
