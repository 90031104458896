import React, { useEffect, useState } from "react";
import Select from "react-select";
import Search from "../../../../components/Header/Search/Search";
import EmployeeAttendanceList from "../../../../components/Attendance/AttendanceList/EmployeeAttendanceList";
import { DatePicker } from "antd";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import dayjs from "dayjs";
import PageLoader from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import formatDate from "../../../../utils/FormatDate/formatDate";
import Pagination from "../../../../components/Pagination/Pagination";
import { toast } from "react-toastify";
const PAGE_STATUS = {
  loading: "loading",
  empty: "empty",
  clear: "",
};

const FILTER = {
  designation: "designation",
  department: "department",
  office: "office",
  filter: "filter",
  clearAllFilter: "clearAllFilter",
  pageNo: "pageNo",
  pageSize: "pageSize",
};

const EmployeeAttendance = () => {
  const axiosPrivate = useAxiosPrivate();
  const [empAttendanceList, setEmpAttendanceList] = useState(null);
  const [pageComponent, setPageComponent] = useState(<PageLoader />);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateRange, setDateRange] = useState({
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [dateRangeLabels, setDateRangeLabels] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    designation: null,
    department: null,
    office: null,
    filter: "",
    pageNo: 1,
    pageSize: 10,
  });

  const [downloadFilters, setDownloadFilters] = useState({});

  const isFilterApplied =
    searchFilters.department ||
    searchFilters.designation ||
    searchFilters.office ||
    searchFilters.filter;

  const handleSetFilterValue = (source, value) => {
    if (source === FILTER.clearAllFilter) {
      setSearchFilters((prev) => ({
        ...prev,
        designation: null,
        department: null,
        office: null,
        filter: "",
      }));
    } else {
      setSearchFilters((prev) => ({ ...prev, [source]: value }));
    }
  };

  const handleChangeDateRange = (dateMonth) => {
    setDateRange({
      fromDate: dayjs(dateMonth).startOf("month").format("YYYY-MM-DD"),
      toDate: dayjs(dateMonth).endOf("month").format("YYYY-MM-DD"),
    });
    setDatePickerOpen(false);
  };

  const handleSetPageComponent = (status) => {
    if (status === PAGE_STATUS.loading) {
      setPageComponent(<PageLoader />);
    } else if (status === PAGE_STATUS.empty) {
      setPageComponent(<EmptyState />);
    } else {
      setPageComponent(null);
    }
  };

  const handleSetDateRangeLabels = () => {
    const lastDaySplit = dateRange.toDate.split("-");
    const halfMonthDate = `${lastDaySplit[0]}-${lastDaySplit[1]}-15`;
    const lastMonthStartDate = dayjs(halfMonthDate)
      .add(1, "day")
      .format("YYYY-MM-DD");

    const firstHalfMonthView = `${
      formatDate(dateRange.fromDate).dateString2
    } - ${formatDate(halfMonthDate).dateString2}`;
    const lastHalfMonthView = `${
      formatDate(lastMonthStartDate).dateString2
    } - ${formatDate(dateRange.toDate).dateString2}`;
    const fullMonthView = `${formatDate(dateRange.fromDate).dateString2} - ${
      formatDate(dateRange.toDate).dateString2
    }`;
    const today = dayjs().format("YYYY-MM-DD");
    setDateRangeLabels([
      {
        label: firstHalfMonthView,
        status: dayjs(today) < dayjs(halfMonthDate),
        fromDate: dateRange.fromDate,
        toDate: halfMonthDate,
        id: 1,
      },
      {
        label: lastHalfMonthView,
        status: dayjs(today) > dayjs(halfMonthDate),
        fromDate: lastMonthStartDate,
        toDate: dateRange.toDate,
        id: 2,
      },
      {
        label: fullMonthView,
        status: false,
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        id: 3,
      },
    ]);
  };

  const handleSetDateRangeStatus = (id) => {
    setDateRangeLabels((prev) =>
      prev.map((item) => {
        if (Number(item.id) === Number(id)) {
          return {
            ...item,
            status: true,
          };
        } else {
          return {
            ...item,
            status: false,
          };
        }
      })
    );
    setDatePickerOpen(false);
  };

  const fetchEmpAttendanceList = async (url) => {
    if (!url) {
      return;
    }
    setEmpAttendanceList(null);
    handleSetPageComponent(PAGE_STATUS.loading);
    await axiosPrivate
      .get(url)
      .then((res) => {
        if (res?.data?.status) {
          setEmpAttendanceList(res.data.employee);
          setTotalRecords(res?.data?.totalRecords || 0);
          handleSetPageComponent(PAGE_STATUS.clear);
        } else {
          handleSetPageComponent(PAGE_STATUS.empty);
        }
      })
      .catch((err) => {
        // console.log(err);
        handleSetPageComponent(PAGE_STATUS.empty);
      });
  };

  useEffect(() => {
    const getDates = dateRangeLabels.find((i) => i.status === true);
    if (!getDates) {
      return;
    }
    let baseUrl = `attendance/employeeAttendanceList?fromDate=${getDates?.fromDate}&toDate=${getDates?.toDate}`;
    const filterKeys = Object.keys(searchFilters);
    filterKeys.forEach((filter) => {
      let value = searchFilters[filter];
      if (value) {
        baseUrl += `&${filter}=${value}`;
      }
    });

    let delayTimeOut = searchFilters.filter ? 400 : 0;
    const delayTimer = setTimeout(() => {
      fetchEmpAttendanceList(baseUrl);
    }, delayTimeOut);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [dateRangeLabels, searchFilters]);

  useEffect(() => {
    handleSetDateRangeLabels();
  }, [dateRange]);

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  const screenWidth = window.innerWidth - 326; //362 is the width of aside bar and side bar

const handleDownloadAttendanceReport = async () => {
  try {
    let url = `attendance/generateEmployeeAttendanceReport?fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`;
    const filterKeys = Object.keys(searchFilters);
    filterKeys.forEach((filter) => {
      let value = searchFilters[filter];
      if (value) {
        url += `&${filter}=${value}`;
      }
    });
    const response = await axiosPrivate.get(url, { responseType: 'blob' });
    if (response.status === 200) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      const url = window.URL.createObjectURL(blob);


      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'employee_attendance_report.csv');
      document.body.appendChild(link);


      link.click();

  
      document.body.removeChild(link);
    } else {
 
      console.error('Failed to download attendance report:', response);
    }
  } catch (error) {

    console.error("Error downloading attendance report:", error);
  }
};

  

  return (
    <div className="w-full" style={{ width: screenWidth }}>
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Employee Attendance</h2>
          <p>
            Keeps track of employees’ working hours, clock in and out time, and
            which task <br /> they were doing. Time Attendance can be used with
            Payroll to calculate salary <br />
            automatically.
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center px-10 pb-4">
        <div className="flex gap-3">
          <DatePicker
            onClick={() => setDatePickerOpen(!isDatePickerOpen)}
            open={isDatePickerOpen}
            allowClear={false}
            value={dayjs(dateRange.fromDate, "YYYY-MM")}
            onChange={handleChangeDateRange}
            picker="month"
            renderExtraFooter={() => (
              <div className="rounded-sm my-2 ">
                <ul>
                  {dateRangeLabels.map((i) => (
                    <li
                      className="hover:bg-gray-50 cursor-pointer flex justify-between gap-2 px-2"
                      onClick={() => handleSetDateRangeStatus(i.id)}
                    >
                      <span>{i.label}</span>
                      {i.status && <img src="/images/tick-icon-blue.svg" />}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          />

          <Select
            options={designationList.map((i) => ({
              label: i.designation_title,
              value: i.id,
            }))}
            components={{ DropdownIndicator }}
            className="custom-select-container"
            placeholder="Designation"
            onChange={(e) => handleSetFilterValue(FILTER.designation, e.value)}
          />
          <Select
            options={departmentList.map((i) => ({
              label: i.department_name,
              value: i.id,
            }))}
            components={{ DropdownIndicator }}
            className="custom-select-container"
            placeholder="Department"
            onChange={(e) => handleSetFilterValue(FILTER.department, e.value)}
          />
          <Select
            options={locationList.map((i) => ({
              label: i.location_name,
              value: i.id,
            }))}
            components={{ DropdownIndicator }}
            className="custom-select-container"
            placeholder="Location"
            onChange={(e) => handleSetFilterValue(FILTER.office, e.value)}
          />
          {isFilterApplied && (
            <span
              onClick={() => handleSetFilterValue(FILTER.clearAllFilter)}
              className="text-blue-800 font-medium cursor-pointer"
            >
              Clear filters
            </span>
          )}
        </div>
        <div className="flex">
          <div>
            <Search onChange={(e) => handleSetFilterValue(FILTER.filter, e)} />
          </div>
          <button
  className="btn btn--border text-[14px]"
  onClick={() => handleDownloadAttendanceReport(dateRange.fromDate, dateRange.toDate)}
>
  <span>
    <i className="ico-download mr-1"></i>
  </span>
  Download
</button>
        </div>
      </div>
      {/* className="flex overflow-x-auto" */}
      {empAttendanceList && Array.isArray(empAttendanceList) ? (
        <div>
          <EmployeeAttendanceList
            dateRange={dateRangeLabels}
            empAttendanceList={empAttendanceList}
          />
        </div>
      ) : (
        <div>{pageComponent}</div>
      )}
      <div className="mt-[70px]">
        <Pagination
          page={{
            pageNo: searchFilters.pageNo,
            setPageNo: (e) => handleSetFilterValue(FILTER.pageNo, e),
            pageSize: searchFilters.pageSize,
            setPageSize: (e) => handleSetFilterValue(FILTER.pageSize, e),
            totalRecords,
          }}
        />
      </div>
    </div>
  );
};

export default EmployeeAttendance;

