import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import EmployeeSidebar from "../../containers/Employee/EmployeeSideBar";
import EmployeeListAside from "../../components/Employee/EmployeeListAside/EmployeeListAside";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const EmployeeListLayout = () => {
  const user = useSelector((state) => state.user.auth);
  const isEmployee = userRoleAuth(user, 6);
  const axiosPrivate = useAxiosPrivate();
  const [employee, setEmployee] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState({});

  const nextPage = () => {
    if (pageNo < page.totalPages) {
      setPageNo(pageNo + 1);
    }
  };

  const previousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const fetchEmployeeData = async (baseUrl) => {
    if (!baseUrl) {
      return;
    }
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        const res = response?.data || {};
        if (Number(res.statusCode) === 200) {
          setPage({
            totalPages: res.totalPages,
            totalRecords: res.totalRecords,
          });
          setEmployee(res.employee);
        } else if (Number(res.statusCode) === 400) {
          setEmployee([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setEmployee([]);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const getSearchValue = (e) => {
    setSearchValue(e);
  };

  useEffect(() => {
    let baseUrl = `employee/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;
    if (searchValue) {
      baseUrl += `&filter=${searchValue}`;
      const delayTimer = setTimeout(() => {
        fetchEmployeeData(baseUrl);
      }, 500);
      return () => {
        clearTimeout(delayTimer);
      };
    } else {
      fetchEmployeeData(baseUrl);
    }
  }, [pageNo, searchValue]);

  return (
    <div className="lg:flex ">
      <EmployeeSidebar />
      <div className="flex flex-1">
        {!isEmployee ? (
          <EmployeeListAside
            nextPage={nextPage}
            previousPage={previousPage}
            submenu={employee}
            pageNo={pageNo}
            totalPages={page.totalPages}
            totalRecords={page.totalRecords}
            getSearchValue={getSearchValue}
          />
        ) : null}
        <Outlet />
      </div>
    </div>
  );
};

export default EmployeeListLayout;
