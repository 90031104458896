import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { Chart, registerables } from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
Chart.register(...registerables);

const { RangePicker } = DatePicker;

const RegularizationApprovalOverviewChart = () => {
  const colors = [
    "#4875E9",
    "#9AB3F3",
    "#C2D1F8",
    "#7d9df0",
    "#f0f4fd",
  ];
  const defaultColor = "#D3D3D3";
  const axiosPrivate = useAxiosPrivate();
  const [regularizationList, setRegularizationList] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [fromDate, toDate] = dateRange;
  const fetchRegularizationListData = () => {
    const fromDateStr = formatDate(fromDate.toDate()).date;
      const toDateStr = formatDate(toDate.toDate()).date;
    axiosPrivate
      .get(
        `attendance/regularizationApprovalOverView?from_date=${fromDateStr}&to_date=${toDateStr}`
      )
      .then((response) => {
        if (response?.data?.result) {
          const regularizationResponseData = response.data.result;
          setRegularizationList(regularizationResponseData);
        } else {
          setRegularizationList([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching regularization data:", error);
        setRegularizationList([]);
      });
  };

  useEffect(() => {
    fetchRegularizationListData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (regularizationList && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const dataValues = regularizationList.map((item) => item.balance);
      const totalRegularizationList = dataValues.reduce((total, value) => total + value, 0);
      const backgroundColor = totalRegularizationList > 0 ? colors : [defaultColor];

      if (chartInstance.current) {
        chartInstance.current.data.labels = regularizationList.map((item) => item.leave_status);
        chartInstance.current.data.datasets[0].data = totalRegularizationList > 0 ? dataValues : [1];
        chartInstance.current.data.datasets[0].backgroundColor = backgroundColor;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: regularizationList.map((item) => item.location_name),
            datasets: [
              {
                label: "# of Regularization Overview",
                data: totalRegularizationList > 0 ? dataValues : [1],
                backgroundColor: backgroundColor,
              },
            ],
          },
          options: {
            scales: {},
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [regularizationList]);

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

 
  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const totalRegularizarionOverview = regularizationList.reduce((total, item) => total + parseInt(item.balance), 0);

  return (
    <>
      <div className="border rounded-md p-3">
        <div className="flex justify-between font-medium text-black">
          <p className="">Regularization Approval</p>
          <p className="flex items-center gap-2">
            {isCalendarOpen ? (
              <RangePicker
                value={dateRange}
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
                disabledDate={disableFutureDates}
              />
            ) : (
              "Today"
            )}
            <i
              className={`ico-${isCalendarOpen ? "up" : "down"}`}
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            />
          </p>
        </div>

        <div className="flex items-center py-2">
          <div className="w-[160px] px-3">
            <canvas
              id="RegularizationOverview"
              ref={chartRef}
              width="160"
              height="160"
            ></canvas>
          </div>

          <div className="flex flex-wrap w-full">
            {regularizationList?.length === 0 ? (
              <div className="flex gap-2 justify-center py-10 max-h-full">
                <span className="flex items-center py-2 text-gray-800">
                  No data available!
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-2 w-full">
                {regularizationList?.map((item, index) => (
                  <div className="flex pb-3 pr-5" key={index}>
                    <span
                      className={`h-3 w-5 rounded-[20px] mr-2`}
                      style={{ backgroundColor: colors[index % colors.length] }}
                    ></span>
                    <p>{item?.regularizationstatus}</p>
                    <h3 className="ml-auto text-gray-800 font-medium">
                      {item?.balance}
                    </h3>
                  </div>
                ))}
                <div className="flex pb-3 pr-5 w-full text-gray-800">
                  <p className="font-medium">Total Regularization Approvals</p>
                  <h3 className="ml-auto font-medium">
                    {totalRegularizarionOverview}
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegularizationApprovalOverviewChart;
