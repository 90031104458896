import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../../redux/actions/profile";
import axios from "../../../helpers/axios";
import "./Start.scss";

const Start = () => {
  const [candidate, setCandidate] = useState("");
  const [personaldetail, setPersonaldetail] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageComponent, setPageComponent] = useState("");


  const getCandidateById = async () => {
    setPageComponent("Loading...")
    await axios
      .get(
        "onboarding/candidate/getCandidateById?personal_details=1&include_expiry=1&include_hrDetails=1",
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (Number(res?.data?.candidate?.status) === 2) {
          navigate("/offer/registration-completed", {
            state: { token: token },
          });
        } else if (res?.data?.candidate) {
          setCandidate(res.data.candidate);
          setPersonaldetail(res.data.personal_details);
          dispatch(
            ActionCreators.updateProfile({
              connectedAthority: res.data.hr_details,
              recentNotice: res.data.expiry_message,
            })
          );
          setPageComponent("")
        } else {
          setPageComponent("Link has expired...")
        }
      })
      .catch((err) => {
        // console.log(err);
        setPageComponent("Something went wrong try again...")
      });
  };

  useEffect(() => {
    getCandidateById();
  }, []);

  if (pageComponent) {
    return (
      <div className="flex justify-center items-center h-screen">
        {pageComponent}
      </div>
    );
  }


  return (
    <div className="flex min-h-screen">
      <div className="p-24 text-center">
        <figure className="mb-6">
          <img className="m-auto" src="/images/illustration2.1.svg" alt="" />
        </figure>
        <h2 className="text-2xl font-bold mb-2 text-gray-800 pt-5 pb-5">
          Nice to meet you, <br />
          {candidate ? candidate.first_name : " "}
        </h2>
        <p className="mb-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries
        </p>
        <div className="inline-flex gap-4">
          <Link
            to={"/offer/create-account?token=" + token}
            state={{
              candidate: candidate,
              token,
              personaldetail: personaldetail,
            }}
            className="btn"
          >
            Get Started
          </Link>
        </div>
      </div>

      <div className="bg-gray-100 p-24">
        <div className="">
          <h3 className="text-gray-700 text-xl font-bold mb-6">
            Company Details
          </h3>
          <p className="mb-6">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries
          </p>
          <p className="mb-6">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries
          </p>
        </div>
        <div className="">
          <h3 className="text-gray-700 text-xl font-bold mb-6 mt-12">
            Employee Benefits
          </h3>
          <ul className="checklist">
            <li>Lorem Ipsum is simply dummy text of the printing </li>
            <li>There are many variations of passages of Lorem Ipsum</li>
            <li>All the Lorem Ipsum generators on the Internet tend to </li>
            <li>
              It was popularised in the 1960s with the release of Letraset{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Start;
