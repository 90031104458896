const formatDate = (date) => {
  const formatDate = {};
  const weekDays = [
    { short: "Sun", long: "Sunday"},
    { short: "Mon", long: "Monday"},
    { short: "Tue", long: "Tuesday"},
    { short: "Wed", long: "Wednesday"},
    { short: "Thu", long: "Thursday"},
    { short: "Fri", long: "Friday"},
    { short: "Sat", long: "Saturday"},
  ]
  
  try {
    const inputDate = new Date(date);
    const dateStringSplit = inputDate.toDateString().split(" ")
    formatDate.weekDay = weekDays[inputDate.getDay()];
    formatDate.dateString = inputDate.toDateString();
    formatDate.dateString2 = inputDate.toDateString().split(" ").splice(1,3).join(" ");
    formatDate.dateString3 = `${inputDate.toDateString().split(" ")[2]} ${inputDate.toDateString().split(" ")[1]}`;
    formatDate.isoString = inputDate.toISOString();
    formatDate.localDateString = inputDate.toLocaleDateString();
    formatDate.localString = inputDate.toLocaleString();
    formatDate.localTimeString = inputDate.toLocaleTimeString();
    formatDate.date = inputDate.toISOString().split("T")[0];
    formatDate.month = inputDate.getMonth() + 1;
    formatDate.fullYear = inputDate.getFullYear();
    formatDate.day = inputDate.getDate();
    formatDate.localDate1 = inputDate.toISOString().split("T")[0].split("-").reverse().join("-")
    formatDate.localDate2 = inputDate.toISOString().split("T")[0].split("-").reverse().join("/")
    formatDate.localString2 = inputDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return formatDate;
  } catch (err) {
    return "invalid date format";
  }
};
export default formatDate;