import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { toast } from "react-toastify";
import Select from "react-select";
import { roleOptions } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import userRoles from "../../../config/userRoles";
import DeleteWidgetModal from "./DeleteWidgetModal"; 

const AssignWidget = () => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(userRoles.EMPLOYEE);
  const [isAssigning, setIsAssigning] = useState(false);
  const [viewRoleBasedWidget, setViewRoleBasedWidget] = useState([]);



  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const handleRoleChange = (e) => {
    setSelectedRole(e.value);
  };

  useEffect(() => {
    fetchWidgetList();
  }, []);

  useEffect(() => {
    if(widgets.length !== 0){
     
    fetchWidgetListForUserRole();
    }
  }, [selectedRole]);

  const fetchWidgetList = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get("employee/viewWidgets");
      if (response?.data?.status) {
        setWidgets(
          response.data.data.map((widget) => ({
            ...widget,
            checked: false,
          }))
          
        );
        if(viewRoleBasedWidget.length === 0){
        fetchWidgetListForUserRole()
        }
      } else {
        setWidgets([]);
      }
    } catch (error) {
      setWidgets([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchWidgetListForUserRole = async () => {
    if (!selectedRole) return;
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `employee/viewWidgetsForUserRoles?employee_role_id=${selectedRole}`
      );
      if (response?.data?.status) {
        const assignedWidgets = response.data.data.map((assignedWidget) =>
          Number(assignedWidget.widget_id)
        );
        setViewRoleBasedWidget(response?.data?.data);
        setWidgets((prevWidgets) =>
          prevWidgets.map((widget) => ({
            ...widget,
            checked: assignedWidgets.includes(Number(widget.id)),
          }))
        );
      } else {
        setViewRoleBasedWidget([]);
        setWidgets((prevWidgets) =>
          prevWidgets.map((widget) => ({
            ...widget,
            checked: false,
          }))
        );
      }
    } catch (error) {
      setViewRoleBasedWidget([]);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  };

  const handleCheckboxChange = (widgetId) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        Number(widget.id) === Number(widgetId)
          ? { ...widget, checked: !widget.checked }
          : widget
      )
    );
  };

  const handleSelectAllWidgets = (status) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) => ({ ...widget, checked: status }))
    );
  };

  const assignWidgets = async () => {
    setIsAssigning(true);
    try {
      const selectedWidgets = widgets.filter((widget) => widget.checked);
      const deselectedWidgets = widgets.filter((widget) => !widget.checked);

      const newlySelectedWidgetIds = selectedWidgets
        .filter(
          (widget) =>
            !viewRoleBasedWidget.some((w) => Number(w.widget_id) === Number(widget.id))
        )
        .map((widget) => Number(widget.id));

      const disableWidgets = deselectedWidgets
        .filter((widget) =>
          viewRoleBasedWidget.some((w) => Number(w.widget_id) === Number(widget.id))
        )
        .map((widget) => {
          const id = viewRoleBasedWidget.find(
            (i) => Number(i.widget_id) === Number(widget.id)
          )?.id;
          return Number(id);
        })
        .filter(Boolean);

        const widgetAPICall = []

      if (disableWidgets.length) {
        widgetAPICall.push(editAssignedWidgets({
          employee_role_id: selectedRole,
          assigned_widget_id: disableWidgets,
        }))
       
      }

      if (newlySelectedWidgetIds.length) {
        widgetAPICall.push(assignNewWidgets({
          employee_role_id: selectedRole,
          widget_id: newlySelectedWidgetIds,
        }))
     
      }
      setLoading(true);
      Promise.all(widgetAPICall).finally(() => {
        
        fetchWidgetListForUserRole();
       
      
        
      })

     
    } catch (error) {
      toast.error("An error occurred while assigning widgets.");
    } finally {
      
      setTimeout(() => {
        setIsAssigning(false)
        setLoading(false);

      }, 1000)
    }
  };

  const assignNewWidgets = async (payload) => {
    try {
      const response = await axiosPrivate.post(
        "employee/assignWidgetCard",
        payload
      );
      if (response?.data?.status) {
        toast.success("Widgets have been assigned successfully.");
      } else {
        toast.error(response?.data?.message || "Failed to assign widgets.");
      }
    } catch (error) {
      toast.error("An error occurred while assigning widgets.");
    }
  };

  const editAssignedWidgets = async ({ employee_role_id, assigned_widget_id }) => {

    try {
      const payload = {
        employee_role_id: Number(employee_role_id),
        assigned_widget_id: Array.isArray(assigned_widget_id)
          ? assigned_widget_id
          : [Number(assigned_widget_id)],
      };
  
      const response = await axiosPrivate.put(
        "employee/editAssignWidgets",
        payload
      );
  
      if (response?.data?.status) {
        toast.success("Successfully updated the assigned widgets.");
      
         if(!Array.isArray(assigned_widget_id)){
          fetchWidgetListForUserRole();
         }
        
     
      } else {
        toast.error(response?.data?.message || "Failed to edit widgets.");
      }
    } catch (error) {
      toast.error("An error occurred while editing widgets.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  };
  

  const handleAssignClick = () => {
    assignWidgets();
  };

  const handleCancelClick = () => {
    navigate("/settings/widget-list");
  };

 

  const handleDeleteClick = (widgetId) => {
    const matchedWidget = viewRoleBasedWidget.find(
      (widget) => Number(widget.widget_id) === Number(widgetId)
    );
    if (matchedWidget) {
      editAssignedWidgets({
        employee_role_id: selectedRole,
        assigned_widget_id: matchedWidget.id,
      });
    } else {
      toast.error("No matched widget found");
    }
  };

 
  const isAllWidgetsSelected = widgets.every((i) => i.checked)
 

  return (
    <div className="flex relative w-[500px]">
      <div className="flex-1 bg-white p-8 rounded-lg">
        <div className="flex border-b">
          <h1 className="block text-base font-medium text-gray-800">
            Select Employee Role
          </h1>
        </div>
        <div className="mb-4">
          <label
            htmlFor="roleDropdown"
            className="block text-[14px] font-semibold"
          ></label>
          <Select
            options={roleOptions}
            defaultValue={roleOptions.find((option) => option.value === selectedRole)}
            onChange={handleRoleChange}
          />
        </div>
        {selectedRole && (
          <>
            <div className="flex items-center pb-2 justify-between border-b ">
              <h1 className="text-base font-medium text-gray-800">
                Select Widgets
              </h1>
              <div className="flex items-center gap-1 border p-2 rounded-md">
                <input
                  checked={isAllWidgetsSelected}
                  type="checkbox"
                  onChange={(e) => handleSelectAllWidgets(e.target.checked)}
                />{" "}
                <span className="font-medium">Select all</span>
              </div>
            </div>

            <ul className="space-y-2 my-4">
              {loading ? (
                <PageLoader />
              ) : (
                widgets.map((widget, index) => (
                  <li
                    key={`${index}_widget_listing_key`}
                    className="mb-4 relative"
                  >
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={widget.checked}
                        onChange={() =>
                          handleCheckboxChange(Number(widget.id))
                        }
                        className="m-2 test-[14px] custom-checkbox"
                      />
                      <div className="flex justify-between w-full">
                        <span className="text-gray-800">
                          {widget.widget_name}
                        </span>
                        
                          {widget.checked && (
                            <DeleteWidgetModal
                            widgetId={Number(widget.id)}
                            handleDeleteClick={handleDeleteClick}
                            selectedRole={selectedRole}
                            />
                            
                          )}
                        
                      </div>
                    </label>
                  </li>
                ))
              )}
            </ul>

            <div className="flex justify-end gap-3">
              <button
                onClick={handleCancelClick}
                className="text-[14px] btn btn--border"
              >
                Cancel
              </button>
              <button
                onClick={handleAssignClick}
                className="text-[14px] btn"
                disabled={isAssigning}
              >
                {isAssigning ? "Updating..." : "Update Widget Status"}
              </button>
            </div>
          </>
        )}
      </div>

      
    </div>
  );
};

export default AssignWidget;
