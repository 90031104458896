import React, { useEffect, useState } from "react";
import Select from "react-select";
import employeeAccountStatusFormat from "../../../utils/FormatEmployeeAccountStatus/employeeAccountStatusFormat";

const EmployementStatus = ({ status }) => {
  const [selectedStatus, setSelectedStatus] = useState(status || 0);

  useEffect(() => {
    setSelectedStatus(status)
  }, [status])
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };
  const accountStatus = employeeAccountStatusFormat(selectedStatus);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: accountStatus.borderColor,
      background: accountStatus.bgColor,
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : null,
      "&:hover": {
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? state.data.color : null,
      color: state.isSelected ? "black" : "black"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: accountStatus.fontColor
    })
  };
  return (
    <Select
      className="text-sm leading-5 font-medium"
      options={[
        { label: "Onboarding", value: 2 },
        { label: "On leave", value: 3 },
        { label: "Active", value: 1 },
        { label: "Off-boarding", value: 5 },
        { label: "Probationary", value: 4 }
      ]}
      value={{
        label: accountStatus.label
      }}
      onChange={handleStatusChange}
      styles={customStyles}
    />
  );
};

export default EmployementStatus;
