import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { closeIcon } from "../../../../Icons/Icons";
import Select from "react-select";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const CancelRegularizationRequestModal = ({
  open,
  close,
  regularizationId,
  fetchDataAgain 
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [regularizationReasonList, setRegularizationReasonList] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [cancelNote, setCancelNote] = useState("");
  const [cancelRequestSuccess, setCancelRequestSuccess] = useState(false);
  const [cancelRequestError, setCancelRequestError] = useState(false);

  const fetchRegularizationReason = async () => {
    try {
      const response = await axiosPrivate.get(
        "attendance/listOfRegularizationReasons?type=2"
      );
      if (response && response.status === 200 && response.data.result) {
        const regularizationReason = response.data.result;
        setRegularizationReasonList(regularizationReason);
      }
    } catch (error) {
      toast.error("Error in loading regularization list");
    }
  };

  const cancelRegularizationApprovalRequest = () => {
    const requestBody = {
      regularization_application_id: regularizationId,
      cancel_reason_id: selectedReason.id,
      cancel_note: cancelNote,
    };
    const apiUrl = "/attendance/cancelRegularizationRequest";
  
    axiosPrivate.put(apiUrl, requestBody)
      .then(response => {
        if (response && response.data && response.data.status === true) {
          toast.success("Regularization request has been cancelled successfully");
          close();
          setCancelRequestSuccess(true);
          setCancelRequestError(false);
            fetchDataAgain();

        } else {
          toast.error("Failed to cancel regularization request. Please try again later.");
          close();
          fetchDataAgain();
          setCancelRequestSuccess(false);
          setCancelRequestError(true);
        }
      })
      .catch(error => {
        console.error("Error cancelling regularization request:", error);
        toast.error("Regularization request has already been updated");
        close();
        setCancelRequestSuccess(false);
        setCancelRequestError(true);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchRegularizationReason();
    };

    fetchData();

    return () => {};
  }, []);

  return (
    <div>
      <Modal open={open} onClose={close} center closeIcon={" "}>
        <div className="px-5 text-black w-[420px] h-[350px] flex flex-col justify-between">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <h1 className="text-base font-medium">Cancel Request</h1>
              <button onClick={close}>{closeIcon}</button>
            </div>
            <div className="">
              <p className="font-medium">
                Are you sure you want to cancel the request?
              </p>
              <p className="text-gray-500">
                19 Jan 2023, 09:00 AM - 05:00 PM (8 hours)
              </p>
            </div>
            <div>
              <label>
                Cancellation Reason<span className="text-red-600">*</span>
                <Select
                  options={regularizationReasonList}
                  value={selectedReason}
                  onChange={(selectedOption) =>
                    setSelectedReason(selectedOption)
                  }
                />
              </label>
            </div>
            <div>
              <label>Add note</label>
              <textarea
                value={cancelNote}
                onChange={(e) => setCancelNote(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button className="btn btn--border" onClick={close}>
              Cancel
            </button>
            <button
              className="btn btn--red"
              onClick={cancelRegularizationApprovalRequest}
            >
              Cancel Request
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelRegularizationRequestModal;
