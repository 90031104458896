import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { message } from "antd";

const AddAutoIncrementLeaveBalance = ({
  open,
  close,
  leaveTypeId,
  updatedMonth,
  refreshDetails,
  leaveTypeName,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post(
        "/lms/leave/autoIncrementLeaveBalance",
        {
          leaveTyeId: leaveTypeId,
          updated_month: updatedMonth,
          autoIncrementValue: inputValue,
        }
      );
      if (Number(response?.data?.statusCode) === 200) {
        toast.success("Successfully updated the auto increment leave balance");
        refreshDetails();
        close();
      } else {
        toast.error(
          response?.data?.message || "Leave balance is already incremented "
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong with the serve");
    }
  };

  return (
    <Modal open={open} onClose={close} center>
      <div className="p-5 min-w-[450px] text-black flex flex-col gap-3">
        <h1 className="text-lg font-medium border-b border-gray-300 pb-4">
          Auto Increment Value
        </h1>
        <div>
          <h3>Leave Type : {leaveTypeName}</h3>
          <label>
            Do you want to proceed with Increment of Employee leave balance
            manually? <span className="text-red-600">*</span>
          </label>
        </div>
        <div className="flex justify-end gap-3">
          <button type="button" onClick={close} className="btn btn--border">
            Close
          </button>
          <button type="button" onClick={handleSubmit} className="btn">
            Increment
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddAutoIncrementLeaveBalance;
