import React, { useRef, useState } from "react";
import Select, { components } from "react-select";
import Search from "../../../../components/Header/Search/Search";
import CustomizeBalanceTable from "./CustomizeBalanceTable";
import AdjustLeaveBalanceSlidingPanel from "../../../../components/Leaves/SlidingPanels/AdjustLeaveBalanceSlidingPanel";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Pagination from "../../../../components/Pagination/Pagination";
import EmptyState from "../../../../components/EmptyState";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetLocationList, useGetDepartmentList, useGetDesignationList } from "../../../../queries/employeeQueries";
import { useLeaveTypeList, useEmployeeLeaveBalanceList } from "../../../../queries/leaveQueries";
import PageLoader from "../../../../components/PageLoader";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <span className="ico-down text-blue-600"></span>
  </components.DropdownIndicator>
);
const DEPARTMENT = "department";
const DESIGNATION = "designation";
const OFFICE = "office";
const FILTER = "filter";
const PAGENO = "pageNo";
const PAGESIZE = "pageSize";

const CustomizeBalance = () => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const [isAdjustBalanceSlidingPanelOpen, setAdjustBalanceSlidingPanel] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const searchRef = useRef();
  const [searchFilter, setFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    filter: "",
    department: "",
    designation: "",
    office: "",
  });


  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  
  // Determine if any filter is applied
  const isFiltered =
    searchFilter.filter ||
    searchFilter.department ||
    searchFilter.designation ||
    searchFilter.office;

  const { data: leaveTypesData } = useLeaveTypeList({ status: 1, fullDetails: 1 });
  const leaveTypes = leaveTypesData?.data || [];
  
  const { data: employeeLeaveBalanceData , isLoading} = useEmployeeLeaveBalanceList(searchFilter);

  
  const employeeList = employeeLeaveBalanceData?.employee || [];
  const totalRecords = employeeLeaveBalanceData?.totalRecords || 0;

  const defaultLocationOption = { location_name: "Office: All", id: null };
  const defaultDesignationOption = {
    designation_title: "Designation: All",
    id: null,
  };
  const defaultDepartmentOption = {
    department_name: "Department: All",
    id: null,
  };
 
  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();

  const departmentList = Array.isArray(getDepartmentList?.result)
  ? [defaultDepartmentOption, ...getDepartmentList?.result]
  : [];

  const designationList = Array.isArray(getDesignationList?.result)
  ? [defaultDesignationOption, ...getDesignationList?.result]
  : [];
  
  const locationList = Array.isArray(getLocationList?.result)
  ? [defaultLocationOption, ...getLocationList?.result]
  : [];
  


  const handleSetFilter = (value, source) => {
    
    setFilter((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilter((prev) => ({
      ...prev,
      filter: "",
      department: "",
      designation: "",
      office: "",
    }));

    setSelectedDesignation(null);
    setSelectedDepartment(null);
    setSelectedOffice(null);

    if (searchRef.current) {
      searchRef.current.value = "";  
    }
  
  };

  const formattingLeaveBalanceData = () => {
    const filterKeys = Object.keys(searchFilter);
    let baseUrl = `lms/leave/exportEmployeeLeaveBalance?`;
  
    filterKeys.forEach((filterKey) => {
      const value = searchFilter[filterKey];
      if (value) {
        baseUrl += `${filterKey}=${value}&`;
      }
    });
  
    return baseUrl.slice(0, -1);
  };

  const handleDownloadForLeaveBalance = async () => {
    const url = formattingLeaveBalanceData();
    try {
      const response = await axiosPrivate.get(url, { responseType: "blob" });
      if (response?.status === 200) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        const filename = "employee_leave_balance.csv";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("File downloaded");
      } else {
        toast.error("Error while downloading the file!");
      }
    } catch (error) {
      const errorMessage = error?.message === "Request failed with status code 404"
        ? "No data found for the selected employee!"
        : "Internal server error" || error?.message;
      toast.error(errorMessage);
    }
  };
/// fetching useEmployeeLeaveBalanceList without fikters
// const { data: unfilteredEmployeeList } = useEmployeeLeaveBalanceList({ searchFilter});
// console.log("unfilteredEmployeeList:",unfilteredEmployeeList)




const handleAdjustBalanceSlidingPanel = () => {
  setAdjustBalanceSlidingPanel(true);
};
  return (
    <>
      {isAdjustBalanceSlidingPanelOpen && (
        <AdjustLeaveBalanceSlidingPanel
          open={isAdjustBalanceSlidingPanelOpen}
          close={() => setAdjustBalanceSlidingPanel(false)}
          employeeList={employeeList}
          leaveTypes={leaveTypes}
        />
      )}
      <div className="w-full overflow-x-auto">
        <div className="px-10 py-6">
          <div className="flex justify-between items-center pb-4">
            <div>
              <h2>{isHr ? "Adjust" : "Employee"} Leave Balance</h2>
              <p className="pb-4">
                {isHr ? "Customize and adjust all" : "View"} employee leave balance
              </p>
            </div>
            {isHr && (
              <button className="btn" onClick={handleAdjustBalanceSlidingPanel}>
                <i className="ico-plus mr-2" />
                New Adjustment
              </button>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
              <Select
                value={selectedDesignation}
                onChange={(selected) => {
                  setSelectedDesignation(selected); 
                  handleSetFilter(selected?.value, DESIGNATION);
                }}
                placeholder={"Designation: All"}
                options={designationList.map((i) => ({
                  label: i.designation_title,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedDepartment} 
                onChange={(selected) => {
                  setSelectedDepartment(selected); 
                  handleSetFilter(selected?.value, DEPARTMENT);
                }}
                placeholder={"Department: All"}
                options={departmentList.map((i) => ({
                  label: i.department_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedOffice} 
                onChange={(selected) => {
                  setSelectedOffice(selected); 
                  handleSetFilter(selected?.value, OFFICE);
                }}
                placeholder={"Office: All"}
                options={locationList.map((i) => ({
                  label: i.location_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {isFiltered && (
                <span className="text-blue-800 font-medium cursor-pointer" onClick={handleClearFilters}>
                  Clear Filters
                </span>
              )}
            </div>
            <div>
            <Search /// refernce seracj ref
         eleRef={searchRef}   

         onChange={(e) => handleSetFilter(e, FILTER)}
         value={searchFilter.filter}
       
/>
            </div>
            <div className="flex">
              {isHr && (
                <button onClick={handleDownloadForLeaveBalance} className="btn btn--border text-[14px]">
                  <span>
                    <i className="ico-download mr-1"></i>
                  </span>
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
        {isLoading ? 

        (
          <PageLoader/>

        ) : Array.isArray(employeeList) && employeeList?.length ? (
          <>
            <CustomizeBalanceTable
              leaveTypes={leaveTypes}
              employeeList={employeeList}
            />
            <div className="mt-[65px]">
              <Pagination
                page={{
                  pageNo: searchFilter.pageNo,
                  setPageNo: (e) => handleSetFilter(e, PAGENO),
                  pageSize: searchFilter.pageSize,
                  setPageSize: (e) => handleSetFilter(e, PAGESIZE),
                  totalRecords: totalRecords,
                }}
              />
            </div>
          </>
        ) : (
         <EmptyState/>
        )}
      </div>
    </>
  );
};

export default CustomizeBalance;
