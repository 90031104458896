import React from "react";

const PersonalDocument = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Personal Document
          </p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn--border text-sm w-[85px] h-[38px] bg-indigo-50 text-[#2A52B9]"
          >
            <span className="text-[#2A52B9] ico-upload mr-1 w-3 h-3"></span>{" "}
            Upload
          </button>
        </div>
      </div>
      <div className="flex justify-center employee-info-card">
        <div className="text-center">
          <p>No documents yet</p>
          <p>Keep your info up to date by uploading new documents here.</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalDocument;
