import React from "react";
import AssetReturnList from "./AssetReturnList";

const assetList = [
  {
    id: "798",
    image: "/images/avatar.jpg",
    employee_name: "Leslie Alexander Joseph",
    assigned_asset: "1",
    return: "-",
    returned: "-"
  },
  {
    id: "698",
    image: "/images/avatar.jpg",
    employee_name: "Leslie Alexander",
    assigned_asset: "1",
    return: "-",
    returned: "-"
  },
  {
    id: "598",
    image: "/images/avatar.jpg",
    employee_name: "Leslie Alexander",
    assigned_asset: "2",
    return: "-",
    returned: "-"
  },
  {
    id: "498",
    image: "/images/avatar.jpg",
    employee_name: "Leslie Alexander",
    assigned_asset: "2",
    return: "-",
    returned: "-"
  },
  {
    id: "898",
    image: "/images/avatar.jpg",
    employee_name: "Leslie Alexander",
    assigned_asset: "1",
    return: "-",
    returned: "-"
  },
  {
    id: "750",
    image: "/images/avatar.jpg",
    employee_name: "Annie Alexander",
    assigned_asset: "2",
    return: "-",
    returned: "-"
  }
];

const EmployeeAssetWithImage = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              EMPLOYEE NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ASSIGNED ASSET
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              RETURNED
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              RETURNED
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {assetList.map((val, key) => {
            return <AssetReturnList requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeAssetWithImage;
