import React, { useState } from "react";
import PolicyListTable from "../../components/Policy/PolicyListTable";
import CreatePolicy from "../../components/Policy/CreateOrEditPolicy";
import { useGetSystemPolicyList } from "../../queries/systemPolicyQueries";
import PageLoader from "../../components/PageLoader";
import { Empty } from "antd";
import Pagination from "../../components/Pagination/Pagination";
import Select from "react-select";
import Search from "../../components/Header/Search";
import { systemPolicyApprovalStatusOptions } from "../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";

const PolicyList = () => {
  const loggedInUser = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isHrOrAdmin = isHr || isAdmin;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [approvalStatus, setApprovalStatus] = useState();
  const [searchValue, setSearchValue] = useState("");
  const { data: getSystemPolicyListData, isLoading } = useGetSystemPolicyList({
    page_number: pageNo,
    page_limit: pageSize,
    approval_status: approvalStatus,
    filter: searchValue,
  });

  const systemPolicyData = Array.isArray(getSystemPolicyListData?.data)
    ? getSystemPolicyListData.data
    : [];
  const totalRecords = getSystemPolicyListData?.totalRecords || 0;

  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);
  return (
    <div className="w-full overflow-x-auto">
      {isHrOrAdmin && isCreatePolicyModalOpen && (
        <CreatePolicy
          isModalOpen={isCreatePolicyModalOpen}
          handleCloseModal={() => setIsCreatePolicyModalOpen(false)}
        />
      )}

      <div className="px-10 py-6">
        <div className="my-2 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold">Policy</h2>
            <p>view all policy</p>
          </div>
          {isHrOrAdmin && (
            <button
              className="btn"
              onClick={() => setIsCreatePolicyModalOpen(true)}
            >
              Create policy
            </button>
          )}
        </div>
      </div>
      <div className="py-3 flex justify-between gap-3 px-10">
        <div className="flex gap-3">
          {isHrOrAdmin && (
            <Select
              isClearable
              placeholder="Approval status"
              options={systemPolicyApprovalStatusOptions}
              onChange={(e) => setApprovalStatus(e?.value)}
            />
          )}
        </div>
        <div>
          <Search mx="0" onChange={(e) => setSearchValue(e)} />
        </div>
      </div>
      {isLoading ? (
        <PageLoader />
      ) : !!systemPolicyData.length ? (
        <PolicyListTable data={systemPolicyData} />
      ) : (
        <Empty />
      )}
      {!!systemPolicyData.length && (
        <Pagination
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      )}
    </div>
  );
};

export default PolicyList;
