import React, { useState } from "react";
import Avatar from "../../Avatar";
import HeaderDropdownMenu from "../HeaderDropdownMenu/HeaderDropdownMenu";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { ActionCreators } from "../../../redux/actions/profile";
import NotificationSlidingPanel from "./NotificationSlidingPanel";

const Notification = ({ openSlide, showMenu, setShowMenu }) => {
  const profile = useSelector((state) => state.user.userProfile);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [openNotificationPanel, setNotificationPanel] = useState(false)

  const handleOpenNotificationPanel = () => {
    setNotificationPanel(prev => !prev)
  }

  // const openMenu = () => {
  //   setShowMenu(!showMenu);
  // };
  const menuBtnFn = {
    signout: async () => {
      let isMounted = true;
      const controller = new AbortController();

      await axiosPrivate
        .post("auth/logout", {
          signal: controller.signal,
        })
        .then((response) => {
          // isMounted && console.log(response.data);
          const { status } = response.data;
          if (status) {
            dispatch(ActionCreators.logout());
            setShowMenu(false);
            navigate("/login", { replace: true });
          } else {
            toast.info("Failed to logout, try again.");
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    },
  };

  return (
    <>
    <NotificationSlidingPanel open={openNotificationPanel} close={() => setNotificationPanel(false)}/>
    <div className="flex items-center">
      {/* <div>
        <div className="rounded-full w-[36px] h-[36px] grid place-items-center bg-blue-600 cursor-pointer mr-7">
          <i className="ico-plus text-white"></i>
        </div>
      </div> */}
      {/* <div className="relative cursor-pointer mr-7">
        <i className="ico-chat text-lg text-gray-600"></i>
        <span className="absolute block w-[10px] h-[10px] bg-blue-600 rounded-full right-0 top-0 border-[2px] border-white"></span>
      </div> */}
      {/* <div className="relative cursor-pointer mr-12">
        <i className="ico-bell text-xl text-gray-600" onClick={handleOpenNotificationPanel}></i>
        <span className="absolute block w-[10px] h-[10px] bg-blue-600 rounded-full right-0 top-0 border-[2px] border-white"></span>
      </div> */}
      <div className="flex items-center gap-3">
        <div className="text-right">
          <p className="text-sm leading-5 font-medium text-[#111827]">
            {`${profile?.first_name || ""} ${profile?.middle_name || ""} ${profile?.last_name || ""}`}
          </p>
          <span className="text-xs leading-4 font-normal text-[#6B7280]">
            {profile.designation_title}
          </span>
        </div>
        <div>
        <HeaderDropdownMenu image={`${profile.photo}`} btnFn={menuBtnFn} openSlide={openSlide} />
      </div>
      </div>
    </div>
    </>
  );

};

export default Notification;
