import React, { useState } from "react";
import PendingFinalizeAttendance from "../../../../components/Attendance/ManageAttendance/FinalizeAttendance/PendingFinalizeAttendance";
import CompletedFinalizeAttendance from "../../../../components/Attendance/ManageAttendance/FinalizeAttendance/CompletedFinalizeAttendance";

const TAB = {
  PENDING_TAB: 1,
  COMPLETED_TAB: 2,
};

const FinalizeAttendance = () => {
  const [toggleState, toggleTab] = useState(TAB.PENDING_TAB);
  return (
    <div className="w-full">
      <div className="px-10 py-4">
        <h1 className="font-semibold text-lg text-black">
          Finalize Attendance
        </h1>
        <p>Process and finalize montly attendance</p>
      </div>
      <div className="flex items-center justify-between  border-b-2 px-5">
        <div className="flex items-center gap-5 pl-4 pt-6">
          <button
            className={
              toggleState === TAB.PENDING_TAB
                ? "tabs active-tabsnew text-blue-800 font-medium"
                : "tabs"
            }
            onClick={() => toggleTab(TAB.PENDING_TAB)}
          >
            Pending
          </button>
          <button
            className={
              toggleState === TAB.COMPLETED_TAB
                ? "tabs active-tabsnew text-blue-800 font-medium"
                : "tabs"
            }
            onClick={() => toggleTab(TAB.COMPLETED_TAB)}
          >
            Completed
          </button>
        </div>
      </div>
      {
        toggleState === TAB.PENDING_TAB ? <PendingFinalizeAttendance/> : <CompletedFinalizeAttendance/>
      }
    </div>
  );
};

export default FinalizeAttendance;
