import React, { useState } from "react";
import Select, { components } from "react-select";
import Search from "../../../components/Header/Search/Search";
import Talentpool from "../../../components/Recruitment/Talentpool";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const TalentPool = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Talent Pool</h2>
          <p>Manage all employe on your organization</p>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            <span>
              <i className="ico-plus text-white mr-1"></i>
            </span>
            Add new candidate
          </a>
        </div>
      </div>
      <div className="flex justify-between items-center px-10 pb-4">
        <div className="flex gap-3">
          <input
            type="date"
            placeholder="Select a date"
            className="appearance-none bg-transparent border border-gray-300 rounded w-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-600"
          />

          <Select
            options={[
              { value: "All", label: "All" },
              { value: "India", label: "India" },
              { value: "USA", label: "USA" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Source: All",
              label: "Source: All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              {
                value: "Available",
                label: "Available"
              },
              { value: "Out Of Stock", label: "Out Of Stock" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Added By: All",
              label: "Added By: All"
            }}
          />
        </div>
        <div className="flex">
          <div>
            <Search />
          </div>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <Talentpool />
    </div>
  );
};

export default TalentPool;
