import React from "react";
import HashLoader from "react-spinners/HashLoader";

const Loader = () => {
  return (
    <div className="flex justify-center items-center min-w-full min-h-screen">
      <HashLoader
        loading={true}
        size={70}
        color={"#307af2"}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
