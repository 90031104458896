const UploadFileIcon = () => {

return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50.000000pt" height="100.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M685 5108 c-27 -5 -87 -27 -133 -49 -150 -72 -259 -208 -302 -374
-20 -77 -20 -112 -20 -1770 0 -1658 0 -1693 20 -1770 49 -193 183 -336 380
-407 l65 -23 735 -3 735 -3 52 -78 c151 -224 399 -421 661 -525 611 -244 1316
-46 1712 482 389 519 395 1221 13 1743 -196 270 -515 484 -830 559 l-53 12 0
491 c-1 361 -4 499 -13 519 -17 38 -1113 1187 -1144 1199 -32 12 -1811 10
-1878 -3z m1735 -588 c6 -413 7 -422 62 -517 56 -99 143 -170 264 -215 55 -20
81 -22 412 -26 l352 -4 0 -413 0 -413 -115 -1 c-318 -2 -655 -123 -896 -322
l-46 -38 -702 -3 -703 -3 -29 -33 c-57 -64 -29 -153 56 -172 22 -5 293 -10
603 -10 309 0 562 -2 562 -5 0 -2 -26 -44 -57 -92 -31 -48 -72 -121 -90 -161
l-34 -72 -505 0 -506 0 -29 -29 c-41 -41 -41 -98 0 -143 l29 -33 472 -3 472
-3 -5 -22 c-23 -94 -31 -174 -31 -312 1 -201 19 -310 91 -528 5 -17 -27 -17
-668 -15 l-674 3 -55 27 c-109 54 -180 151 -200 275 -13 78 -13 3278 0 3356
26 160 141 281 292 306 29 5 418 8 863 7 l810 -1 5 -385z m621 -205 l313 -330
-257 -3 c-244 -2 -260 -1 -307 19 -27 12 -67 41 -89 64 -65 70 -71 107 -71
414 l0 266 49 -50 c27 -28 190 -198 362 -380z m530 -1606 c294 -38 550 -167
754 -380 457 -478 461 -1230 9 -1719 -56 -60 -177 -159 -259 -212 -304 -194
-735 -237 -1087 -107 -373 137 -661 447 -772 830 -38 134 -49 234 -43 398 12
317 124 582 342 810 275 289 669 430 1056 380z"/>
<path d="M1021 3069 c-55 -55 -37 -142 36 -170 14 -5 258 -9 579 -9 611 0 597
-1 630 61 22 43 11 91 -27 123 l-31 26 -578 0 -578 0 -31 -31z"/>
<path d="M3373 2273 c-29 -16 -628 -649 -667 -705 -9 -12 -16 -40 -16 -61 0
-77 83 -122 157 -85 12 6 120 116 240 245 l218 234 5 -593 c4 -456 8 -598 18
-615 15 -26 61 -53 92 -53 31 0 77 27 92 53 10 17 14 159 18 616 l5 593 220
-237 c134 -146 232 -243 252 -251 85 -36 175 62 130 143 -12 21 -587 642 -644
694 -38 36 -80 44 -120 22z"/>
</g>
</svg>

)
}

export default UploadFileIcon;