import React from "react";
import Avatar from "../../../Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const ViewEmployeeFinalizeAttendaceData = ({ data }) => {
  const navigate = useNavigate();
  const handleOpenEmployeeAttendanceDetailView = () => {
    navigate("/attendance/employee-attendance-details", {
      state: { processAttendance: true },
    });
  };
  return (
    <tr class="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200">
      <td className="px-10 py-4 font-medium">
        <input type="checkbox" />
      </td>
      <td
        className="px-10 py-4 font-medium flex gap-2 cursor-pointer hover:text-blue-600"
        onClick={handleOpenEmployeeAttendanceDetailView}
      >
        <Avatar />
        <div>
          <p>{data?.employee_name}</p> <span> Marketing</span>
        </div>
      </td>
      <td className="px-10 py-4 font-medium">{data?.type_label}</td>
      <td className="px-10 py-4 font-medium">{data?.location_name}</td>
      <td className="px-10 py-4 font-medium">{data?.work_schedule}</td>
      <td className="px-10 py-4 font-medium">{data?.request_count}</td>
      <td className="px-10 py-4 font-medium">
        <span className="status status--gray">{data?.status}</span>
      </td>
   
    </tr>

  
  );
};

export default ViewEmployeeFinalizeAttendaceData;
