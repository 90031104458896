import React from "react";
import LeavePercentage from "../../../../components/LeavePercentage";

const LeaveItem = ({ leavedata }) => {

  return (
    <div className="p-4 border border-gray-300 rounded-md">
      <LeavePercentage leavedata={leavedata} />
    </div>
  );
};

export default LeaveItem;
