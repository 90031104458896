import React, { useState } from "react";
import PreferenceTab from "../../components/Assets/AssetsTab/PreferenceTab";
import TabSelector from "../../components/TabSelector/TabSelector";
import SalaryComponentTable from "./SalaryComponentTable";

const PaySalaryComponent = () => {
  const [index, setIndex] = useState(0);

  const getTabIndex = (e) => {
    setIndex(e);
  };
  const tabs = ["Earning", "Deductions", "Reinbusements"];
  const components = [<SalaryComponentTable />];
  return (
    <div className="px-10 py-6 w-full">
      <div className="px-10 flex justify-between items-center">
        <h2 className="text-xl font-semibold">Salary Components</h2>
        <button type="submit" className="btn text-sm font-medium">
          Add Component
        </button>
      </div>
      <PreferenceTab tabs={tabs} getTabIndex={getTabIndex} />
      <TabSelector tabIndex={index} components={components} />
    </div>
  );
};

export default PaySalaryComponent;
