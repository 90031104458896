import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Line from "../../../Line";
import { FaDownload } from "react-icons/fa";
import useFetchFile from "../../../../hooks/useFetchFile";
import { SYSTEM_POLICY_FILES } from "../../../../Consts/filePathConst";

const ViewPolicyAttachment = ({
  isModalOpen,
  handleCloseModal,
  attachment,
}) => {
  const { file_name, src } = attachment;
  const isPdf = file_name.split(".").pop() === "pdf";
  const getFile = useFetchFile();
  const [fileSrc, setFileSrc] = useState();

  const fetchSystemPolicyFile = async () => {
    const getFileSrc = await getFile(src, SYSTEM_POLICY_FILES);
    setFileSrc(getFileSrc);
  };
  useEffect(() => {
    if (isModalOpen && !fileSrc) {
      fetchSystemPolicyFile();
    }
  }, [attachment, isModalOpen]);

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = fileSrc;
    link.download = file_name;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  return (
    <Modal onClose={handleCloseModal} open={isModalOpen}>
      <div className="flex flex-col w-[500px]">
        <div className="text-left flex items-center gap-3">
          {file_name}
          <FaDownload className="cursor-pointer" onClick={downloadFile} />
        </div>
        <Line className="my-3" />
        {isPdf ? (
          <iframe src={fileSrc} width="500" height="700"></iframe>
        ) : (
          <img src={fileSrc} className="object-cover" />
        )}
      </div>
    </Modal>
  );
};

export default ViewPolicyAttachment;
