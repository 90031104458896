import React, { useState } from "react";
import LeaveSetting from "./LeaveSetting";

const AppSetting = () => {
  const [toggleState, setToggleState] = useState(0);
  const tabs = ["LEAVE"];
  const components = [<LeaveSetting />];
  return (
    <div className="p-5 w-full">
      <div className="py-3">
        <h1 className="text-xl font-medium text-gray-800">App Setting</h1>
      </div>
      <ul className="tab-list tab-head">
        {tabs.map((item, i) => {
          return (
            <li
              key={i}
              className={`tab-li ${toggleState === i && `active-tab`}`}
              onClick={() => {
                setToggleState(i);
              }}
            >
              {item}
            </li>
          );
        })}
      </ul>
      <div className="current-tab-div py-3">{components[toggleState]}</div>
    </div>
  );
};

export default AppSetting;
