import React from "react";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";

const SalaryEarningListData = (props) => {
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td
        scope="row"
        class="px-10 py-4 whitespace-nowrap flex items-center gap-3 border-b-0"
      >
        <input type="checkbox" />
        {props.requestdata.id}
      </td>
      <td class="px-10 py-4 text-blue-700 font-medium">
        {props.requestdata.name}
      </td>
      <td class="px-10 py-4">{props.requestdata.descriptions}</td>
      <td class="px-10 py-4">
        <span className="py-1 px-2.5">
          <ToggleSwitch />
        </span>
      </td>
      <td class="py-4 flex items-center justify-center gap-5 border-none">
        <img
          src="/images/edit-black-icon.svg"
          alt=""
          className="cursor-pointer"
        />
        <i className="ico-more text-gray-500 cursor-pointer"></i>
      </td>
    </tr>
  );
};

export default SalaryEarningListData;
