import React, { useState } from "react";
import Line from "../../../components/Line";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const OfferDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [checkBoxState, setCheckboxState] = useState({
    terms2: { checked: false, error: false },
    terms1: { checked: false, error: false },
  });

  const onButtonClick = () => {
    fetch("/Rest Client.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Employment Contract.pdf";
        alink.click();
      });
    });
  };
  const onButtonClick2 = () => {
    fetch("/Electrical-Designer.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Company Policies.pdf";
        alink.click();
      });
    });
  };

  const handleCheck = (e, checkbox) => {
    setCheckboxState((prev) => {
      return {
        ...prev,
        [checkbox]: { ...prev[checkbox], checked: e.target.checked },
      };
    });
  };

  const validateCheckbox = () => {
    let result = false;
    const methods = ["terms1", "terms2"];
    if (checkBoxState.terms1.checked && checkBoxState.terms2.checked) {
      result = true;
    }
    methods.forEach((item) => {
      setCheckboxState((prev) => {
        return {
          ...prev,
          [item]: { ...prev[item], error: !prev[item].checked },
        };
      });
    });

    return result;
  };

  const continueBtn = () => {
    const isAllAccepted = validateCheckbox();
    if (isAllAccepted) {
      navigate("/offer/supporting-files", {
        state: { token: location.state.token },
      });
    }
  };

  return (
    <div className="flex-1 justify-center max-w-[740px]">
      <div className="mb-5">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Offer Details</h2>
        <p>
          Hi, diane, here your employment offer from our company. Please read it
          carefully and approve it once you download and read it.
        </p>
      </div>
      <form action="">
        <div className="mb-5">
          <h2 className="text-lg font-semibold text-gray-900 mb-2">
            Employmeent Contract
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>
        <div className="mb-8">
          <div className="flex">
            <figure className="border border-gray-300 rounded-md overflow-hidden mr-3">
              <img src="/images/pdf1.jpg" alt="" />
            </figure>
            <div className="flex flex-1 flex-col justify-end">
              <div className="mb-4 flex">
                <i className="ico-doc text-[20px] pr-3"></i>
                <p>Employment Contract.pdf</p>
              </div>
              <div className="mb-4">
                <button onClick={onButtonClick} className="btn">
                  <i className="ico-download mr-4 text-xl"></i>
                  Download to accept
                </button>
              </div>
              <div>
                <div key="terms1">
                  <label
                    htmlFor="terms1"
                    className="relative cursor-pointer inline-flex items-center"
                  >
                    <input
                      id="terms1"
                      type="checkbox"
                      className="mr-2 w-4 h-4 rounded border-gray-300"
                      checked={checkBoxState.terms1.checked}
                      onChange={(e) => handleCheck(e, "terms1")}
                    />
                    <span className={"offer-check"}>
                      {"I agree to the terms of the employment contract"}
                    </span>
                  </label>
                </div>
                {checkBoxState.terms1.error && (
                  <span className="text-red-600">
                    Accept the terms of the employment contract
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <h2 className="text-lg font-semibold text-gray-900 mb-2">
            Company Policies
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>
        <div className="mb-10">
          <div className="flex">
            <figure className="border border-gray-300 rounded-md overflow-hidden mr-3">
              <img src="/images/pdf1.jpg" alt="" />
            </figure>
            <div className="flex flex-1 flex-col justify-end">
              <div className="mb-4 flex">
                <i className="ico-doc text-[20px] pr-3"></i>
                <p>Company Policies.pdf</p>
              </div>
              <div className="mb-4">
                <button onClick={onButtonClick2} className="btn">
                  <i className="ico-download mr-4 text-xl"></i>
                  Download to accept
                </button>
              </div>
              <div>
                <div key={"terms2"}>
                  <label
                    htmlFor={"terms2"}
                    className="relative cursor-pointer inline-flex items-center"
                  >
                    <input
                      id={"terms2"}
                      type="checkbox"
                      className="mr-2 w-4 h-4 rounded border-gray-300"
                      checked={checkBoxState.terms2.checked}
                      onChange={(e) => handleCheck(e, "terms2")}
                    />
                    <span className={"font-medium text-gray-700"}>
                      {"I agree to the terms of the company policies"}
                    </span>
                  </label>
                </div>
                {checkBoxState.terms2.error && (
                  <span className="text-red-600">
                    Accept the terms of the company policies
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <Line />
        <div className="flex">
          <div>
            <button
              type="button"
              className="btn btn--full"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className="ml-auto flex">
            <button
              type="button"
              className="btn btn--border"
              onClick={() => navigate(-1)}
            >
              Decline Offer
            </button>
            <button className="btn ml-5" type="button" onClick={continueBtn}>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OfferDetails;
