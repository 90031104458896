import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Stepper from "../../components/Stepper";
import "./Onboarding.scss";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import ContactHR from "../../components/ContactHR";
import { useSelector } from "react-redux";
import { Alert } from "antd";

function Onboarding(props) {
  const [sidebar, showSidebar] = useState(true);
  const [bluesidebar, setBlueSidebar] = useState(false);
  const pathname = window.location.pathname;
  const activationPagePath = pathname.substr(7, 10);
  const loginPagePath = pathname.substr(7, 5);
  const profile = useSelector((state) => state.user.auth);
  const user = useSelector((state) => state.user.userProfile);

  useEffect(() => {
    if (loginPagePath === "login" || activationPagePath === "activation") {
      setBlueSidebar(true);
    }
  }, [pathname]);

  return (
    <main className="lg:flex items-start lg:h-screen overflow-hidden pt-[77px] -mt-[77px]">
      {sidebar && !bluesidebar && (
        <div className="sidebar border-r border-r-gray-300">
          <SimpleBar className="p-10">
            <div className="mb-6">
              {pathname === "/offer/create-account" ? (
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  {" "}
                  Welcome to our company
                </h2>
              ) : (
                <h2 className="text-xl font-semibold mb-2 text-gray-800">
                  {" "}
                  Nice to meet you, {`${user.first_name} ${user.last_name}`} 👋{" "}
                </h2>
              )}

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <Stepper />
            {profile?.recentNotice ? (
              <Alert className="mb-5" showIcon message={profile.recentNotice} type="warning" />
            ) : null}

            <ContactHR />
          </SimpleBar>
        </div>
      )}
      {sidebar && bluesidebar && (
        <div className="sidebar flex flex-col bg-blue-800  p-10 text-white min-h-screen pt-[117px] -mt-[77px]">
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2 text-white">
              Your success with us <br />
              start here
            </h2>
            <br />
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.{" "}
            </p>
          </div>
          <div className="flex items-center gap-2 mt-auto">
            <div className="rounded-full overflow-hidden">
              <img src="/images/thumb1.png" className="w-[42px] h-[42px]" />
            </div>
            <div>
              <h3 className="font-semibold mb-1">Need any help ?</h3>
              <h4 className="text-xs">Contact Support</h4>
            </div>
          </div>
        </div>
      )}
      <div className="flex-1 lg:max-h-full overflow-hidden">
        <SimpleBar className="p-10" style={{ maxHeight: "calc(100vh - 77px)" }}>
          <div className="grid justify-items-center ">
            <Outlet />
          </div>
        </SimpleBar>
      </div>
    </main>
  );
}
export default Onboarding;
