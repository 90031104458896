import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import Line from "../../../components/Line";

const Welcome = () => {
  const [toggler, setToggler] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onButtonClick = () => {
    fetch("/Rest Client.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "handbook.pdf";
        alink.click();
      });
    });
  };

  const onButtonClick2 = () => {
    fetch("/Electrical-Designer.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "wellness program.pdf";
        alink.click();
      });
    });
  };

  return (
    <div className="flex-1 justify-center max-w-[740px]">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Welcome to our company
        </h2>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
      </div>
      <figure
        className="relative mb-8 rounded-md overflow-hidden"
        onClick={() => setToggler(!toggler)}
      >
        <img src="/images/video1.jpg" alt="" className="w-full" />
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ring-6px ring-white/[.25] rounded-full group">
          <svg
            className="fill-white group-hover:fill-green-400 transition duration-[.3s]"
            width="46"
            height="46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23 45.514c12.371 0 22.4-10.029 22.4-22.4 0-12.371-10.029-22.4-22.4-22.4C10.629.714.6 10.743.6 23.114c0 12.371 10.029 22.4 22.4 22.4Zm-1.247-30.33a2.8 2.8 0 0 0-4.353 2.33v11.2a2.8 2.8 0 0 0 4.353 2.33l8.4-5.6a2.8 2.8 0 0 0 0-4.66l-8.4-5.6Z"
            />
          </svg>
        </span>
      </figure>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=bzFLYp-gOv4"]}
      />
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Why Work for Us?{" "}
        </h2>
        <p className="mb-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
        <ul className="list-disc ml-6">
          <li>Are cost-effective.</li>
          <li>Go the extra mile.</li>
          <li>Do the job properly, first time.</li>
          <li>Deliver the results our clients want.</li>
          <li>Are pleasant to work with.</li>
        </ul>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Know our company benefits
        </h2>
        <p className="mb-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="mb-8 grid grid-cols-2 gap-8">
        <div className="flex">
          <figure className="border border-gray-300 rounded-md overflow-hidden mr-3">
            <img src="/images/pdf1.jpg" alt="" />
          </figure>
          <div className="flex flex-1 flex-col justify-end">
            <div className="mb-4 flex">
              <i className="ico-doc text-[20px] pr-3"></i>
              <p>handbook.pdf</p>
            </div>
            <div>
              <button onClick={onButtonClick} className="btn">
                {" "}
                Download{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="flex">
          <figure className="border border-gray-300 rounded-md overflow-hidden mr-3">
            <img src="/images/pdf2.jpg" alt="" />
          </figure>
          <div className="flex flex-1 flex-col justify-end">
            <div className="mb-4 flex">
              <i className="ico-doc text-[20px] pr-3"></i>
              <p>wellness program.pdf</p>
            </div>
            <div>
              <button onClick={onButtonClick2} className="btn">
                {" "}
                Download{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 p-4 bg-blue-50 flex">
        <span className="mr-4">
          <svg
            className="fill-blue-400"
            width="16"
            height="17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 8.259a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1-9a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1Z"
            />
          </svg>
        </span>
        <div>
          <h3 className="text-blue-800 font-medium leading-tight mb-2">
            Ready to proceed ?
          </h3>
          <p className="text-blue-700">
            Please press continue button to view our offer & contract you get
            with us.
          </p>
        </div>
      </div>
      <Line />
      <div className="flex">
        <div>
          <button className="btn btn--full" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
        <div className="ml-auto flex">
          <button className="btn btn--border" onClick={() => navigate(-1)}>
            Decline Offer
          </button>
          <Link
            to="/offer/personal-details"
            state={{
              candidate: location.state.candidate,
              token: location.state.token,
            }}
            className="btn ml-5"
          >
            Continue
          </Link>
        </div>
      </div>

      {/* <div class="flex items-center">
            <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full m-12" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div> */}
    </div>
  );
};

export default Welcome;
