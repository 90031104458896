import React from "react";
import AssignShiftData from "./AssignShiftData";

const AssignShiftTable = ({ data }) => {
  const cols = [
    { colName: "Employee Name" },
    { colName: "Shift" },
    { colName: "Last Modified" },
  ];
  return (
    <>
      <table>
        <thead className="text-left">
          <tr>
            {cols.map((item, index) => {
              return <th key={`assign_shift_tbl_head_${index}`} className="py-3 pl-10">{item.colName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return <AssignShiftData key={`assign_shift_tbl_data_${index}`} data={item} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default AssignShiftTable;
