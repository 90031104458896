import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Line from "../../../components/Line";
import { useForm } from "react-hook-form";
import axios from "../../../helpers/axios";
import { toast } from "react-toastify";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const PersonalDetails = () => {
  const location = useLocation();
  const candidateData = location.state.candidate;
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", defaultValues: location.state.candidate });
  const navigate = useNavigate();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState();
  const [gender, setGender] = useState(null);
  const [nationality, setNationality] = useState(candidateData.nationality);
  const [presentState, setPresentState] = useState(
    candidateData.present_state_id
  );
  const [presentCity, setPresentCity] = useState(candidateData.present_city_id);
  const [martialStatus, setMartialStatus] = useState(
    candidateData.martial_status
  );
  const dateOfBirth = candidateData.data_of_birth.split("T")[0];

  useEffect(() => {
    axios.get("onboarding/services/location").then((response) => {
      setCountry(response.data.country);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`onboarding/services/location?StatesFrom=${nationality}`)
      .then((response) => {
        setState(response.data.state);
      });
  }, [nationality]);

  useEffect(() => {
    axios
      .get(
        `onboarding/services/location?StatesFrom=${nationality}&CityFrom=${presentState}`
      )
      .then((response) => {
        setCity(response.data.city);
      });
  }, [presentState]);

  const onSubmit = async (data) => {
    try {
      const response = await axios
        .put(
          "onboarding/candidate/updatePersonalDetails",

          {
            father_name: data.father_name,
            gender: gender ? gender : candidateData.gender,
            martial_status: martialStatus,
            spouse_name: data.spouse_name,
            present_address: data.present_address,
            permanent_address: data.permanent_address,
            emergency_contact_name: data.emergency_contact_name,
            data_of_birth: data.date_of_birth,
            nationality: nationality,
            present_city_id: presentCity,
            permanent_city_id: candidateData.permanent_city_id,
            present_state_id: presentState,
            permanent_state_id: candidateData.permanent_state_id,
            place_of_birth: data.place_of_birth,
            residential_status: data.residential_status,
            emergency_phone_1: data.emergency_phone_1,
            emergency_phone_2: data.emergency_phone_1,
            present_postal_code: data.permanent_postal_code,
            permanent_postal_code: candidateData.permanent_postal_code,
          },
          { headers: { Authorization: `Bearer ${location.state.token}` } }
        )
        .then((response) => {
          const statusCode = response.data.statusCode;
          if (statusCode == 200) {
            toast.success("personal Details updated successfully!", {
              type: "success",
            });
            navigate("/offer/offer-details", {
              state: { token: location.state.token },
            });
          } else if (!statusCode) {
            setErrMsg("No Server Response!");
          } else if (statusCode == 400) {
            setErrMsg("Details are not added in Request!");
          } else if (statusCode == 401) {
            setErrMsg("Unauthorized Attempt!");
          } else {
            setErrMsg("Personal Details Updation Creation Failed!");
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Details is not added in request");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Personal Details Updation Creation Failed");
      }

      errRef.current.focus();
    }
  };

  return (
    <>
      <div className="flex-1 justify-center max-w-[740px]">
        <div className="mb-5">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            Personal Details
          </h2>
          <p>
            In order to accept your offer you need to enter some details about
            yourself.
          </p>
        </div>
        <h3 className="flex items-center font-semibold text-lg text-gray-800 mb-4">
          <i className="ico-user mr-4 grid place-items-center w-[36px] h-[36px] bg-green-50 rounded-md text-green-500"></i>
          Basic Informations
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <span
            className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
            ref={errRef}
          >
            {errMsg}
          </span>

          <div className="mb-6 md:flex gap-4">
            <div className="mb-6 md:mb-0">
              <label htmlFor="">
                First Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("first_name", {
                  required: "This field is required",
                })}
              />
              {errors?.first_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.first_name.message}
                </span>
              )}
            </div>
            <div className="mb-6 md:mb-0">
              <label htmlFor="">Middle Name</label>
              <input type="text" {...register("middle_name")} />
            </div>
            <div>
              <label htmlFor="">
                Last Name <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("last_name", {
                  required: "This field is required",
                })}
              />
              {errors?.last_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.last_name.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 lg:mb-0">
              <label htmlFor="">
                Gender <span className="text-red-600 font-medium">*</span>
              </label>
              <Select
                placeholder={candidateData.gender}
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                ]}
                onChange={(e) => {
                  setGender(e.value);
                  setErrMsg("");
                }}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="">
                Father's Name{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("father_name", {
                  required: "This field is required",
                })}
              />
              {errors?.father_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.father_name.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-6 md:flex gap-4 ">
            <div className="flex-1 mb-6 md:mb-0 ">
              <label htmlFor="">
                Date Of Birth{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <div className="relative">
                <input
                  type="date"
                  defaultValue={dateOfBirth}
                  {...register("date_of_birth")}
                />
              </div>
              {errors?.date_of_birth && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.date_of_birth.message}
                </span>
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="">Place Of Birth</label>
              <input type="text" {...register("place_of_birth")} />
            </div>
          </div>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">Martial Status</label>
              <Select
                placeholder={candidateData.martial_status}
                options={[
                  { value: "Married", label: "Married" },
                  { value: "Single", label: "Single" },
                ]}
                onChange={(e) => {
                  setMartialStatus(e.value);
                  setErrMsg("");
                }}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="">Spouse Name</label>
              <input type="text" {...register("spouse_name")} />
            </div>
          </div>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">Residential Status</label>
              <input type="text" {...register("residential_status")} />
            </div>
            <div className="flex-1">
              <label htmlFor="">
                Nationality<span className="text-red-600 font-medium">*</span>
              </label>
              <Select
                placeholder={
                  country &&
                  country.map((val) => {
                    return candidateData.nationality === val.id ? val.flag : "";
                  })
                }
                options={
                  country &&
                  country.map((val) => ({ value: val.id, label: val.flag }))
                }
                onChange={(e) => {
                  setNationality(e.value);
                  setErrMsg("");
                }}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {errors?.city && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.city.message}
                </span>
              )}
            </div>
          </div>
          <Line />
          <h3 className="flex items-center font-semibold text-lg text-gray-800 mb-4">
            <i className="ico-home mr-4 grid place-items-center w-[36px] h-[36px] bg-blue-50 rounded-md text-blue-500"></i>
            Address
          </h3>
          <div className="mb-6">
            <label htmlFor="">Address Line 1</label>
            <input type="text" {...register("present_address")} />
          </div>
          <div className="mb-6">
            <label htmlFor="">Address Line 2</label>
            <input type="text" {...register("permanent_address")} />
          </div>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">
                State<span className="text-red-600 font-medium">*</span>
              </label>
              <Select
                placeholder={
                  state &&
                  state.map((val) => {
                    return candidateData.present_state_id === val.id
                      ? val.state_name
                      : "";
                  })
                }
                options={
                  state &&
                  state.map((val) => ({ value: val.id, label: val.state_name }))
                }
                onChange={(e) => {
                  setPresentState(e.value);
                  setErrMsg("");
                }}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />

              {errors?.state && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.state.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">
                City<span className="text-red-600 font-medium">*</span>
              </label>
              <Select
                placeholder={
                  city &&
                  city.map((val) => {
                    return candidateData.present_city_id === val.id
                      ? val.city_name
                      : "";
                  })
                }
                options={
                  city &&
                  city.map((val) => ({ value: val.id, label: val.city_name }))
                }
                onChange={(e) => {
                  setPresentCity(e.value);
                  setErrMsg("");
                }}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {errors?.city && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.city.message}
                </span>
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="">Postal Code</label>
              <input type="text" {...register("permanent_postal_code")} />
            </div>
          </div>
          <Line />
          <h3 className="flex items-center font-semibold text-lg text-gray-800 mb-4">
            <i className="ico-phone mr-4 grid place-items-center w-[36px] h-[36px] bg-cyan-50 rounded-md text-cyan-500"></i>
            Contact
          </h3>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">
                Personal Phone Number{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="number"
                {...register("phone", { required: "This field is required" })}
              />
              {errors?.phone && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.phone.message}
                </span>
              )}
            </div>
            <div className="flex-1">
              <label htmlFor="">
                Email Address{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors?.email && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.email.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-6 md:flex gap-4">
            <div className="flex-1 mb-6 md:mb-0">
              <label htmlFor="">Emergency Contact Name</label>
              <input type="text" {...register("emergency_contact_name")} />
            </div>
            <div className="flex-1">
              <label htmlFor="">Emergency Contact Number</label>
              <input type="number" {...register("emergency_phone_1")} />
            </div>
          </div>

          <Line />
          <div className="flex">
            <div>
              <button className="btn btn--full" onClick={() => navigate(-1)}>
                Back
              </button>
            </div>
            <div className="ml-auto flex">
              
              <button className="btn btn--border" onClick={() => navigate(-1)}>
                Decline Offer
              </button>
              <input type="submit" className="btn ml-5" value="Continue" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PersonalDetails;
