import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../../Icons/Icons";
import PolicyPreviewData from "./PolicyPreviewData";
import Line from "../../../Line";
import { useSystemPolicyApproval } from "../../../../queries/systemPolicyQueries";
import { toast } from "react-toastify";
import Avatar from "../../../Avatar";
// import Select from "react-select";
// import Select from "antd";
import { Alert, Select } from "antd";
import { useEmployeeList } from "../../../../queries/employeeQueries";
import LoadingButton from "../../../Buttons/LoadingButton";

const ApprovePolicy = ({
  isModalOpen,
  handleCloseModal,
  systemPolicy,
  handleClosePreviewAndOpenEdit,
  isPending,
}) => {
  const { id: system_policy_id, version } = systemPolicy;
  const policyVersion = Number(version);
  const [isSendToAllEmployees, setIsSendToAllEmployees] = useState(true);
  const { mutate: systemPolicyApprovalMutate, isPending: isMutationPending } =
    useSystemPolicyApproval();
  const [errMsg, setErrMsg] = useState("");
  const [remark, setRemark] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const { data: employeeData } = useEmployeeList({
    selectbox: 1,
    sortOrder: "DESC",
  });
  const employeeList = Array.isArray(employeeData?.employee)
    ? employeeData.employee
    : [];

  const approveSystemPolicy = async () => {
    if (!remark.trim()) {
      setErrMsg(
        "Approve remark is required. Please provide your comments before submitting."
      );
      return;
    }
    setErrMsg("");
    const payload = {
      approval_remark: remark,
      approval_status: 1, // 1 approve 3 decline,
      enable_view_for_all_employees: isSendToAllEmployees,
      eligible_employee_ids: selectedEmployees,
      system_policy_id,
    };
    systemPolicyApprovalMutate(payload, {
      onSuccess: (response) => {
        if (response?.status) {
          toast.success(response.message);
          handleCloseModal();
        }
      },
      onError: (error) => {
        const response = error?.response?.data;
        setErrMsg(response?.message || "Failed to approve system policy");
      },
    });
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      center
      showCloseIcon={false}
    >
      <div className="flex flex-col w-[400px] gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">Approve policy</h1>
          <div className="flex gap-3 items-center">
            {isPending && (
              <button
                className="text-sm"
                onClick={
                  typeof handleClosePreviewAndOpenEdit === "function"
                    ? handleClosePreviewAndOpenEdit
                    : null
                }
              >
                {" "}
                <img src="/images/editicon.svg" alt="" />
              </button>
            )}
            <button onClick={handleCloseModal}>{closeIcon}</button>
          </div>
        </div>
        <PolicyPreviewData systemPolicy={systemPolicy} />
        <Line />
        {/** START: view preferences */}
        <div className="flex flex-col gap-3">
          <div>
            <label>View preferences</label>
            <div className="flex items-center gap-2">
              Enable view for all employees{" "}
              <input
                checked={isSendToAllEmployees}
                onChange={(e) => setIsSendToAllEmployees(e.target.checked)}
                type="checkbox"
              />
            </div>
            {/** Once send to all employees is deselected show the employee selection component and procees */}
          </div>
          <div className="flex flex-wrap gap-2">
            {
              !isSendToAllEmployees && policyVersion > 1 && <Alert
              message="Once the policy is approved, old version of this policy will be depricated and selected employees can view this updated policy"
              showIcon
            />
            }
            {isSendToAllEmployees ? (
              <Alert
                message={`Once the policy is approved, ${
                  policyVersion > 1
                    ? "old version of this policy will be depricated and "
                    : ""
                } all employees will be able to view this ${
                  policyVersion > 1 ? "updated" : ""
                } policy.`}
                showIcon
              />
            ) : (
              <Select
                filterOption={(input, option) =>
                  (option?.employeeName ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase().trim())
                }
                onChange={(e) => setSelectedEmployees(e)}
                defaultValue={selectedEmployees}
                mode="multiple"
                allowClear
                className="w-full"
                placeholder="Select employees"
                options={employeeList.map((i) => {
                  const empLabel = (
                    <div className="flex items-center gap-2">
                      <Avatar image={i?.photo} width={"6"} height={"6"} />
                      <p>{`${i?.first_name || ""} ${i?.middle_name || ""} ${
                        i?.last_name || ""
                      }`}</p>
                    </div>
                  );
                  return {
                    label: empLabel,
                    value: i.employee_id,
                    employeeName: `${i?.first_name}${i.last_name}`,
                  };
                })}
              />
            )}
          </div>
        </div>
        {/** END: view preferences */}
        <div>
          <label>Remark</label>
          <textarea
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="mt-1"
          ></textarea>
        </div>
        <span className="text-red-600 font-medium">{errMsg}</span>

        <div className="flex items-center justify-end gap-3">
          <button onClick={handleCloseModal} className="btn btn--border">
            Cancel
          </button>
          {isMutationPending ? (
            <LoadingButton title="Approving..." bg="btn--green" />
          ) : (
            <button onClick={approveSystemPolicy} className="btn btn--green">
              Approve
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ApprovePolicy;
