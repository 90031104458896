import React from "react";
import SimpleBar from "simplebar-react";
import DashboardMemberList from "../../../../components/Dashboard/DashboardMemberList";

const birthdayData = [
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Arlene McCoy',
    'department': 'Head Of Dept'
  },
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Ronald Richards',
    'department': 'Officer'
  },
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Darlene Robertson',
    'department': 'Officer'
  },
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Kristin Watson',
    'department': 'Officer'
  },
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Shane Warne',
    'department': 'Officer'
  },
  {
    'image' : '/images/avatar.jpg',
    'Name': 'Ricky Melson',
    'department': 'Officer'
  },
]

const Birthday = () => {
  return (
    <div className="card flex flex-col">
      <div className="flex justify-between items-center text-gray-800 px-4 py-2 border-b border-b-gray-300">
        <h2>Birthday</h2>
        <span className="text-xs leading-4 font-medium w-7 h-5 rounded-[10px] bg-gray-100 flex justify-center items-center">
          6
        </span>
      </div>
      <SimpleBar style={{ maxHeight: 317 }}>

      {
                    birthdayData.map((val,key) => {
                        return(
                            <DashboardMemberList requestdata = {val} key={key}/>
                        )
                    })
        }
      </SimpleBar>
    </div>
  );
}

export default Birthday;
