import React, { useState } from "react";
import Aside from "../Dashboard/Aside/Aside";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";
const Payroll = () => {
  const [submenu, setSubmenu] = useState([
    {
      text: "My Payroll",
      link: "/payroll/my-payroll/payslip",
      roles: [1, 2, 4, 6],
      icon: "ico-down",
      menu: [
        {
          text: "Pay Slips",
          link: "/payroll/my-payroll/payslip",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Reimbursement",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Ytd Report",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Investment",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "FBP Declarations",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Loan",
          link: "#",
          roles: [1, 2, 4, 6],
        },
      ],
      status: false,
    },
    {
      text: "Employee Payroll",
      link: "/payroll/employee-payroll/overview",
      icon: "ico-down",
      roles: [1, 2, 4, 6],

      menu: [
        {
          text: "Overview",
          link: "/payroll/employee-payroll/overview",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Approval",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Pay Runs",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Form 16",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Loans",
          link: "#",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Reports",
          link: "#",
          roles: [1, 2, 4, 6],
        },
      ],
      status: false,
    },
    {
      text: "Settings",
      link: "/payroll/settings/tax",
      icon: "ico-down",
      roles: [1, 2, 4, 6],

      menu: [
        {
          text: "Tax",
          link: "/payroll/settings/tax",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Statutory Components",
          link: "/payroll/settings/staturary-components",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Salary Components",
          link: "/payroll/settings/salary-components",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Salary Templates",
          link: "/payroll/settings/salary-templates",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Payment Schedule",
          link: "/payroll/settings/payment-schedule",
          roles: [1, 2, 4, 6],
        },
        {
          text: "Preferences",
          link: "/payroll/settings/preferences",
          roles: [1, 2, 4, 6],
        },
      ],
      status: false,
    },
  ]);
  return (
    <div className="lg:flex ">
      <DashboardSidebar />
      <div className="flex flex-1">
        <Aside submenu={submenu} setmenu={setSubmenu} />
        <Outlet />
      </div>
    </div>
  );
};

export default Payroll;
