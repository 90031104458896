import React, { useState } from "react";
import { closeIcon } from "../../../../../Icons/Icons";
import RegularizationRequestDetailsSlidingPanel from "./RegularizationRequestDetailsSlidingPanel";
import CancelRegularizationRequestModal from "../CancelRegularizationRequestModal";

const MyReqularizationRequestData = ({ data, fetchDataAgain }) => {
  const {
    regularization_request_id,
    request_id,
    regularization_serial_id,
    requested_date,
    type,
    regularization_date,
    reason,
    approval_status,
    hr_name,
    //class: statusClass,
  } = data;

  const [requestedDateState, setRequestedDateState] = useState(
    requested_date || ""
  );
  const [idState, setIdState] = useState(regularization_request_id || "");

  const [
    isRegularizationDetailsSlidingPanelOpen,
    setRegularizationDetailsSlidingPanelOpen,
  ] = useState(false);

  const [
    openCancelRegularizationRequestModal,
    setCancelRegularizationRequestModal,
  ] = useState(false);

  const handleOpenRegularizationDetailsSlidingPanel = () => {
    setRequestedDateState(requested_date);
    setIdState(regularization_request_id);
    setRegularizationDetailsSlidingPanelOpen(true);
  };
  const handleCloseRegularizationDetailsSlidingPanel = () => {
    setRegularizationDetailsSlidingPanelOpen(false);
  };

  const handleOpenCancelRegularizationRequestModal = () => {
    setRequestedDateState(requested_date);
    setIdState(regularization_request_id);
    setCancelRegularizationRequestModal(true);
  };

  const handleCloseCancelRegularizationRequestModal = () => {
    setCancelRegularizationRequestModal(false);
  };

  const approvalStatusLabel = "Approve";
const rejectStatusLabel = "Reject";
const cancelStatusLabel = "Cancel";
const pendingStatusLabel = "Pending";

const getStatusLabel = (approvalStatus) => {
  switch (approvalStatus) {
    case approvalStatusLabel:
      return "Approved";
    case rejectStatusLabel:
      return "Rejected";
    case cancelStatusLabel:
      return "Canceled";
    default:
      return "Pending";
  }
};

const getStatusClass = (approvalStatus) => {
  switch (approvalStatus) {
    case approvalStatusLabel:
      return "status--green";
    case rejectStatusLabel:
      return "status--red";
    case cancelStatusLabel:
      return "status--gray";
    default:
      return "status--yellow";
  }
};

const statusLabel = getStatusLabel(approval_status);
const statusClass = getStatusClass(approval_status);


  return (
    <>
      <CancelRegularizationRequestModal
        open={openCancelRegularizationRequestModal}
        regularizationId={regularization_request_id}
        close={handleCloseCancelRegularizationRequestModal}
        fetchDataAgain={fetchDataAgain} 
      />
      <RegularizationRequestDetailsSlidingPanel
        open={isRegularizationDetailsSlidingPanelOpen}
        close={handleCloseRegularizationDetailsSlidingPanel}
        regularizationId={regularization_request_id}
        handleOpenRegularizationDetailsSlidingPanel={
          handleOpenRegularizationDetailsSlidingPanel
        }
      />

      <tr>
        <td
          className="text-blue-800 font-medium cursor-pointer"
          onClick={handleOpenRegularizationDetailsSlidingPanel}
        >
          {requested_date}
        </td>
        <td className="">{type}</td>
        <td className="">{regularization_date}</td>
        <td className="">{reason}</td>
        <td className="">
        <span className={`status ${statusClass}`}>{statusLabel}</span>
        </td>
        <td
          className="cursor-pointer"
          onClick={approval_status === pendingStatusLabel ? handleOpenCancelRegularizationRequestModal : null}
        >
          {approval_status === pendingStatusLabel ? closeIcon : null}
        </td>
      </tr>
    </>
  );
};

export default MyReqularizationRequestData;
