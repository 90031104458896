import React, { useState, useEffect } from "react";
import AttendanceOverviewSlidingPanel from "./AttendanceOverviewSlidingPanel/AttendanceOverviewSlidingPanel";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";

const AttendanceMiniOverview = ({fromDate,toDate}) => {
  const [viewFullOverviewSlidingPanel, setViewFullOverViewSlidingPanel] = useState(false);
  const [totalHoursPresent, setTotalHoursPresent] = useState();
  const [leaveTakenHours, setLeaveTakenHours] = useState(); 
  const [totalTakenHours, setTotalTakenHours] = useState(); 
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchTimeBreakDetails();
  }, []);

  const fetchTimeBreakDetails = async () => {
      const response = await axiosPrivate.get(
        `attendance/timebreakDetails`,
        {
          params: {
            from_date: fromDate, 
            to_date: toDate,
          },
        }
      )
      .then((response) => {
        const data = response?.data?.results;
        if (data && data.length > 0) {
          setTotalHoursPresent(parseFloat(data[0].total_hours_present));
            setLeaveTakenHours(parseFloat(data[0].total_leave_hours)); 
            setTotalTakenHours(parseFloat(data[0].total_combined_hours))
        }
      })
      .catch((error) => {
        
      });
  };

  const handleCloseViewFullOverviewSlidingPanel = () => {
    setViewFullOverViewSlidingPanel(false);
  };

  const handleOpenViewFullOverviewSlidingPanel = () => {
    setViewFullOverViewSlidingPanel(true);
  };

  const total = 140;
  const report = [
    {
      title: "Present",
      hours: totalHoursPresent, 
      color: "green-600",
    },
    {
      title: "Leave",
      hours: leaveTakenHours, 
      color: "red-600",
    },
  ];

  return (
    <>
      <AttendanceOverviewSlidingPanel open={viewFullOverviewSlidingPanel} close={handleCloseViewFullOverviewSlidingPanel} />
      <div className="flex text-white justify-between items-center px-4 py-6 bg-black h-16">
        <div className="w-5/6 px-4">
          <div className="flex justify-between">
            <div>Time breakdown | {totalTakenHours} hours</div>
            <div className="flex items-center gap-3">
              {report.map((item, index) => {
                return (
                  <div className="flex items-center" key={index}>
                    <span
                      className={`block w-[6px] h-[6px] bg-${item.color} rounded-full right-0 top-0 border-[2px] mx-1 border-${item.color}`}
                    ></span>
                    {item.title}: {item.hours} hrs
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="h-1.5 flex bg-black mb-2">
              {report.map((item, index) => {
                const percentage = item.hours / total * 100;
                return (
                  <div
                    className={`h-1.5  bg-${item.color} ${index > 0 ? "ml-1" : ""}`}
                    style={{ width: percentage + "%" }}
                    key={index}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="px-3">
          <button className="flex hover:bg-black gap-3 items-center btn btn--border border-white bg-black text-white" onClick={handleOpenViewFullOverviewSlidingPanel}>
            <span>View Details</span>
            <i className="ico-right text-xs text-white"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default AttendanceMiniOverview;

