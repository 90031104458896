import React from 'react'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";


const SendPasswordSetupLinkConfirmationModal = ({ open, close, functions, employeeName }) => {

  const handleSubmit = async () => {
    functions.sendPasswordLinkBtn()
    close()
  }
  return (
    <Modal open={open} onClose={close} center>
        <div className="p-5 max-w-[350px] text-black flex flex-col gap-3">
          <div className="flex flex-col items-center">
            
            <p className="font-medium text-lg text-center">
              Are you sure? <br/> Do you want to send password setup link for {employeeName}
            </p>
            <p className="text-gray-500 ">
            This will send an email to the employee containing a link to reset account password.
            </p>
           
            <div className="flex flex-col w-full gap-3 py-3">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn  w-full"
              >
                Yes, Send Password Setup Link
              </button>
              <button
                type="button"
                className="btn btn--border w-full"
                onClick={close}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
  )
}

export default SendPasswordSetupLinkConfirmationModal