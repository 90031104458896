import React from "react";

const AccessCardData = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Access Card</p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn--border text-sm bg-indigo-50 text-[#2A52B9]"
          >
            <span className="text-[#2A52B9] ico-upload mr-1 w-3 h-3"></span>{" "}
            Assign access card
          </button>
        </div>
      </div>
      <div className="flex justify-center employee-info-card">
        <div className="text-center">
          <p>No access card</p>
          <p>You have not assign any access card yet</p>
        </div>
      </div>
    </div>
  );
};

export default AccessCardData;
