import React from "react";
import SingleEmployeeTabDetails from "../SingleEmployeeTabDetails";

const SingleEmployeeInfo = () => {
  return (
    <div className="xl:mt-14 mb-0 mr-0  border-none w-full lg:flex justify-center flex-wrap">
      <div className="lg:py-0 lg:px-0 w-full xl:w-3/4">
        <SingleEmployeeTabDetails />
      </div>
    </div>
  );
};

export default SingleEmployeeInfo;
