import axios from 'axios';

const BASE_URL = (process.env.REACT_APP_BASE_URL);

export default axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json', 'api-key': (process.env.REACT_APP_API_KEY_VALUE) },
    withCredentials: true
});



export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json', 'api-key': (process.env.REACT_APP_API_KEY_VALUE) },
    withCredentials: true
});