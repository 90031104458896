import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import Line from "../../../components/Line/Line";
import { useForm, Controller } from "react-hook-form";
import {
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
  useGetEmployeeTypeList,
  useGetLineManagerList,
  useGetJobTitleList,
  useUpdateEmployeeInformation,
  useAssignLineManager,
} from "../../../queries/employeeQueries";
import { toast } from "react-toastify";

const EditOrAddPosition = ({ openModal, onCloseModal, employee }) => {
  const { register, handleSubmit, control,reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      designation_id: employee?.designation_id || "",
      location_id: employee?.location_id || "",
      employee_type_id: employee?.employee_type_id || "",
      department_id: employee?.department_id || "",
      line_manager_id: employee?.line_manager_id || "",
    },
  });

  const { data: locationData } = useGetLocationList();
  const { data: departmentData } = useGetDepartmentList();
  const { data: designationData } = useGetDesignationList();
  const { data: employeeTypeData } = useGetEmployeeTypeList();
  const { data: managerData } = useGetLineManagerList();
  const { data: jobTitleData } = useGetJobTitleList();

  const locationList = locationData?.result || [];
  const departmentList = departmentData?.result || [];
  const designationList = designationData?.result || [];
  const employeeTypeList = employeeTypeData?.result || [];
  const managerList = managerData?.result || [];
  const jobTitleList = jobTitleData?.result || [];
  const updateEmployeeMutation = useUpdateEmployeeInformation();
  const assignLineManagerMutation = useAssignLineManager();

  const onSubmit = async (data) => {

    try {
      reset({
        designation_id: employee?.designation_id || "",
        location_id: employee?.location_id || "",
        employee_type_id: employee?.employee_type_id || "",
        department_id: employee?.department_id || "",
        line_manager_id: employee?.line_manager_id || "",
      });
      if (data.line_manager_id && data.line_manager_id !== employee?.line_manager_id) {
        const assignLineManagerRes =
          await assignLineManagerMutation.mutateAsync({
            employeeList: [
              {
                employeeId: employee.employee_id,
                lineManagerId: data.line_manager_id,
              },
            ],
          });

        if (assignLineManagerRes.status) {
          onCloseModal();
          toast.success(assignLineManagerRes.message || "Line manager updated");
        } else {
          onCloseModal();
          toast.error(
            assignLineManagerRes.message || "Error while updating line manager"
          );
        }
      }

      // Update employee information
      const updateEmployeeRes = await updateEmployeeMutation.mutateAsync({
        employeeId: employee.employee_id,
        ...data,
      });

      if (updateEmployeeRes.status) {
        onCloseModal();
        toast.success(
          updateEmployeeRes.message || "Employee information updated"
        );
      } else {
        onCloseModal();
        toast.error(
          updateEmployeeRes.message ||
            "Error while updating employee information"
        );
      }
    } catch (error) {
      toast.error("Something went wrong in updating employee data");
      onCloseModal();
    }
  };

  return (
    <Modal open={openModal} onClose={onCloseModal} center>
      <div className="p-5 w-[520px]">
        <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
          Edit/Add Position
        </h2>
        <form
          className="flex flex-col gap-4 justify-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col">
            <label className="text-sm font-medium text-black">
              Location <span className="text-red-700"></span>
            </label>
            <Controller
              name="location_id"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  placeholder={employee?.office_location || "Select"}
                  options={locationList}
                  getOptionLabel={(option) => option.location_name}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => onChange(Number(e.id))}
                />
              )}
            />
          </div>

          <div className="flex justify-between">
            <div className="flex w-full flex-col">
              <label className="text-sm font-medium text-black">
                Position Type <span className="text-red-700"></span>
              </label>
              <input
                type="text"
                className="max-w-[220px]"
                value={employee?.user_role_label || ""}
                disabled
              />
            </div>

            <div className="flex w-full flex-col">
              <label className="text-sm font-medium text-black">
                Employment Type <span className="text-red-700"></span>
              </label>
              <Controller
                name="employee_type_id"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={employee?.employee_type_label || "Select"}
                    options={employeeTypeList}
                    getOptionLabel={(option) => option.type_label}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => onChange(Number(e.id))}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex justify-between gap-5">
            <div className="flex flex-col w-full">
              <label className="text-sm font-medium text-black">
                Designation <span className="text-red-700"></span>
              </label>
              <Controller
                name="designation_id"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={employee?.designation_title || "Select"}
                    options={designationList}
                    getOptionLabel={(option) => option.designation_title}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => onChange(Number(e.id))}
                  />
                )}
              />
            </div>

            <div className="flex w-full flex-col">
              <label className="text-sm font-medium text-black">
                Department <span className="text-red-700"></span>
              </label>
              <Controller
                name="department_id"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={employee?.department_name || "Select"}
                    options={departmentList}
                    getOptionLabel={(option) => option.department_name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => onChange(Number(e.id))}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <label className="text-sm font-medium text-black">
              Line Manager <span className="text-red-700"></span>
            </label>
            <Controller
  name="line_manager_id" 
  control={control}
  render={({ field: { onChange } }) => (
    <Select
      placeholder={employee?.line_manager_name || 'Select'}
      options={managerList}
      getOptionLabel={(option) => option.line_manager_name}
      getOptionValue={(option) => option.id}
      onChange={(selectedOption) => onChange(selectedOption.id)} 
    />
  )}
/>
          </div>

          <Line width={""} />

          <div className="flex justify-end gap-3 mr-5">
            <button
              type="button"
              className="btn btn--border text-sm font-medium"
              onClick={onCloseModal}
            >
              Cancel
            </button>
            <button type="submit" className="btn text-sm font-medium">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditOrAddPosition;
