import React from "react";
import Select from "react-select";
import Line from "../../Line/Line";

const SharedDocument = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Shared Document
          </p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn--border text-sm w-[85px] h-[38px] bg-indigo-50 text-[#2A52B9]"
          >
            <span className="text-[#2A52B9] ico-upload mr-1 w-3 h-3"></span>{" "}
            Upload
          </button>
        </div>
      </div>
      <div className="flex justify-between my-6 items-center">
        <div>
          <p>Found 5 document</p>
        </div>
        <div className="flex gap-3">
          <Select placeholder={"Category: All"} options={[]} />
          <Select placeholder={"Sort by date"} options={[]} />
        </div>
      </div>
      <div className="flex justify-between items-center pb-4">
        <div>
          <p>Document title</p>
          <p>Category</p>
        </div>
        <div className="flex items-center gap-4">
          <img className="m-auto" src="/images/delete-menu-icon.svg" alt="" />
          <img className="m-auto" src="/images/edit-black-icon.svg" alt="" />
          <i className="ico-download"></i>
        </div>
      </div>
      <Line />
      <div className="flex justify-between items-center pb-4">
        <div>
          <p>Document title</p>
          <p>Category</p>
        </div>
        <div className="flex items-center gap-4">
          <img className="m-auto" src="/images/delete-menu-icon.svg" alt="" />
          <img className="m-auto" src="/images/edit-black-icon.svg" alt="" />
          <i className="ico-download"></i>
        </div>
      </div>
      <Line />
      <div className="flex justify-between items-center pb-4">
        <div>
          <p>Document title</p>
          <p>Category</p>
        </div>
        <div className="flex items-center gap-4">
          <img className="m-auto" src="/images/delete-menu-icon.svg" alt="" />
          <img className="m-auto" src="/images/edit-black-icon.svg" alt="" />
          <i className="ico-download"></i>
        </div>
      </div>
    </div>
  );
};

export default SharedDocument;
