import React from "react";
import DepartmentListData from "./DepartmentListData";

const employeeOnboardListData = [
  {
    id: "883",
    department_name: "Information Tecnology",
    total_employees: "2",
    department_head: "Kristin Watson"
  },
  {
    id: "883",
    department_name: "Marketing",
    total_employees: "2",
    department_head: "Kristin Watson"
  },
  {
    id: "883",
    department_name: "HR",
    total_employees: "2",
    department_head: "Kristin Watson"
  },
  {
    id: "883",
    department_name: "Product",
    total_employees: "2",
    department_head: "Kristin Watson"
  }
];

const DepartmentList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-600 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              DEPARTMENT NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Total EMPLOYEEs
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              DEPARTMENT HEAD
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              IS ACTIVE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {employeeOnboardListData.map((val, key) => {
            return <DepartmentListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DepartmentList;
