import React, { useState } from "react";
import Modal from "react-responsive-modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const UpdateApprovalRemark = ({
  open,
  close,
  leaveApplicationId,
  leaveApprovalStatusId,
  remark,
  onCloseModal,
}) => {
  const [value, setValue] = useState(remark);
  const axios = useAxiosPrivate();

  const handleSubmit = async () => {
    if (value === remark) {
      close();
    }
    if (!leaveApplicationId) {
      return;
    }
    const result = await axios
      .post("lms/leave/updateLeaveApplicationRemark", {
        remark: value,
        leaveApplicationId,
        leaveApprovalStatusId,
      })
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      toast.success(result?.data?.message);
      close();
      onCloseModal();
    } else {
      toast.error(result?.response?.data?.message);
    }
  };
  return (
    <Modal center open={open} onClose={close} showCloseIcon={false}>
      <div className="p-5 max-w-[520px]">
        <>
          <form>
            <h2 className="text-lg font-semibold">
              Edit Leave Application Remark
            </h2>
            <p className="my-6">
              Update the remark for the selected leave application
            </p>
            <textarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
            ></textarea>
            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                className="btn btn--border text-sm font-medium"
                onClick={close}
              >
                Cancel
              </button>
              <button
                onClick={() => handleSubmit()}
                type="button"
                className="btn text-sm font-medium"
              >
                Update remark
              </button>
            </div>
          </form>
        </>
      </div>
    </Modal>
  );
};

export default UpdateApprovalRemark;
