import React from 'react'
import PolicyListTData from './PolicyListTData'
import { useSelector } from 'react-redux'
import userRoleAuth from '../../utils/userRoleAuth/userRoleAuth'
import userRoles from '../../config/userRoles'

const PolicyListTable = ({ data }) => {
  const loggedInUser = useSelector((state) => state.user.auth)
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isAdminOrHr = isAdmin || isHr;

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
      <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
        <tr>
          <th scope="col" className=" py-3  px-5">
            Policy
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          <th scope="col" className="text-center py-3  px-5">
            Policy Serial
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          <th scope="col" className="text-center py-3  px-5">
            Created By
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          <th scope="col" className="text-center py-3  px-5">
            Created Date
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          <th scope="col" className="text-center py-3  px-5">
            Version
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          {
            isAdminOrHr && <th scope="col" className="text-center py-3  px-5">
            Approval Status
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          }
          {
            isAdminOrHr && <th scope="col" className="text-center py-3  px-5">
            Policy Status
            <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
          </th>
          }
          <th scope="col" className=" py-3  px-5">
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((i, index) => (<PolicyListTData data={i} key={`${index}_p-list_data`}/>))
        }
        
      </tbody>
    </table>
  )
}

export default PolicyListTable