import React from "react";

const PreferenceFlexiblePlan = () => {
  return (
    <div className="px-10 py-6">
      <h3 className="text-lg text-[#1F2937] font-semibold">No Active FBP</h3>
      <p>
        Create multiple locations for your organization and manage location
        details
      </p>
    </div>
  );
};

export default PreferenceFlexiblePlan;
