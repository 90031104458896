import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ActionCreators } from "../../redux/actions/profile";


const RequireAuth = ({ allowedRoles }) => {
    const dispath = useDispatch()
    const auth  = useSelector((state) => state.user.auth);
    const isBrowseHistory = useSelector(state => state.user.isBrowseHistory)
    const location = useLocation();
    const insertBrowserHistory = () => {
        if (isBrowseHistory) {
            dispath(ActionCreators.browserHistory(location.pathname))
        }
    }

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <>
                {insertBrowserHistory()}
                <Navigate to="/login" state={{ from: location }} replace />
                </>
    );
}

export default RequireAuth;