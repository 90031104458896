import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const LeaveRemarkViewModal = ({ isOpen, close, remark }) => {
  

  const handleCancel = () => {
    close();
  };

  return (
    <>
      <Modal title="Remark" open={isOpen}  onCancel={handleCancel} footer={null}>
       
        <p>{remark}</p>
      </Modal>
    </>
  );
};

export default LeaveRemarkViewModal;
