import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const LeaveApprovalOverviewChart = () => {
  const colors = ["#0891B2", "#4875E9", "#404968"];
  useEffect(() => {
    const ctx = document.getElementById("leaveApprovalBarChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "March, 23",
          "March, 24",
          "March, 25",
          "March, 26",
          "March, 27",
          "March, 28",
          "March, 29",
        ],
        datasets: [
          {
            label: "Approved",
            backgroundColor: colors[0],
            data: [3, 7, 4, 4, 2, 6, 5],
          },
          {
            label: "Declined",
            backgroundColor: colors[1],
            data: [4, 3, 5, 5, 7, 3, 5],
          },
          {
            label: "Canceled",
            backgroundColor: colors[2],
            data: [4, 3, 5, 5, 7, 3, 5],
          },
        ],
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: function (value, index, ticks) {
                return (index + 1) * 10;
              },
            },
            grid: {
              drawBorder: false,
              lineWidth: 0.5,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        maxBarThickness: 50,
        categoryPercentage: 0.3,
      },
    });
    return () => {
      myChart.destroy();
    };
  });

  const leaveActions = ["Approved", "Declined", "Canceled"];

  return (
    <>
      <div className="border rounded-md p-5">
        <div className="flex justify-between font-medium">
          <p>Leave Approval</p>
          <p className="flex items-center gap-2">
            View: Yearly <i className="ico-down text-blue-600" />
          </p>
        </div>

        <div className="mx-auto my-3 h-[270px]">
          <canvas id="leaveApprovalBarChart" width="200" height="100"></canvas>
        </div>
        <div className="flex gap-2 justify-center">
          {leaveActions.map((actions, index) => (
            <div className="flex items-center" key={index}>
               <span className={`h-2 w-3 bg-[${colors[index]}] mr-1`}></span>
              <p className="text-sm font-medium">{actions}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LeaveApprovalOverviewChart;
