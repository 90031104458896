import React, { useState } from "react";
import Search from "../../../../components/Header/Search/Search";
import LeavePolicyListTable from "../../../../components/Leaves/Settings/CustomizePolicy/LeavePolicy/LeavePolicyListTable";
import HolidayPolicyListTable from "../../../../components/Leaves/Settings/CustomizePolicy/HolidayPolicy/HolidayPolicyListTable";
import AddLeavePolicyModal from "../../../../components/Leaves/Settings/CustomizePolicy/LeavePolicy/AddLeavePolicyModal";
import AddHolidayPolicyModal from "../../../../components/Leaves/Settings/CustomizePolicy/HolidayPolicy/AddHolidayPolicy";

const CustomizePolicy = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [addLeavePolicyModal, setAddLeavePolicyModal] = useState(false);
  const [addHolidayPolicyModal, setAddHolidayPolicyModal] = useState(false);
  const [refreshBtn, setRefresgBtn] = useState(false);
  const [fetchLeavePolicy, setFetchLeavePolicy] = useState(false);
  const [fetchHolidayPolicy, setFetchHolidayList] = useState(false);

  const handleAddPolicy = () => {
    if (toggleState === 1) {
      setAddLeavePolicyModal(true);
    } else {
      setAddHolidayPolicyModal(true);
    }
  };
  return (
    <>
      <AddLeavePolicyModal
        open={addLeavePolicyModal}
        close={() => setAddLeavePolicyModal(false)}
        makeApiCall={() => setFetchLeavePolicy((prev) => !prev)}
      />
      <AddHolidayPolicyModal
        open={addHolidayPolicyModal}
        close={() => setAddHolidayPolicyModal(false)}
        makeApiCall={() => setFetchHolidayList((prev) => !prev)}
      />
      <div className="w-full">
        <div className="px-10 pt-6 border-b border-gray-300">
          <div className="flex justify-between items-center">
            <div>
              <h2>Customize Policy</h2>
              <p className="pb-4">All policy list</p>
            </div>

            <div>
              <button className="btn" onClick={handleAddPolicy}>
                <span className="ico-plus"></span> &nbsp; Add{" "}
                {toggleState === 1 ? "Leave" : "Holiday"} Policy
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-12 pl-4 pt-6">
              <button
                className={
                  toggleState === 1
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(1)}
              >
                Leave Policy
              </button>
              <button
                className={
                  toggleState === 2
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(2)}
              >
                Holiday Policy
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-end px-10 py-4">
          <div>
            <button
              type="button"
              className="btn btn--border h-[38px]"
              onClick={() => setRefresgBtn((prev) => !prev)}
            >
              <span className="material-symbols-outlined">refresh</span>
            </button>
          </div>
          <div>
            <Search />
          </div>
          {/* <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a> */}
        </div>
        {toggleState === 1 ? (
          <LeavePolicyListTable
            refreshBtn={refreshBtn}
            fetchLeavePolicy={fetchLeavePolicy}
          />
        ) : (
          <HolidayPolicyListTable
            refreshBtn={refreshBtn}
            fetchHolidayPolicy={fetchHolidayPolicy}
          />
        )}
      </div>
    </>
  );
};

export default CustomizePolicy;
