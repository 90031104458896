import React from "react";
import "./Stepper.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Stepper = () => {
  let location = useLocation();
  useEffect(() => {
    document.querySelectorAll(".stepper > li").forEach((item) => {
      item.classList.remove("active");
    });
    const activeLink = document.querySelector(".stepper .active");
    if (activeLink) {
      activeLink.closest("li").classList.add("active");
    }
  }, [location]);
  return (
    <div>
      <ul className="stepper border-l-[2px] border-gray-300 mb-12">
        <li>
          <NavLink
            to="create-account"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <span className="icon"></span>
            <div className="flex flex-1 flex-col justify-center">
              <h3>CREATE ACCOUNT</h3>
              <p>Vitae sed mi luctus laoreet.</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="about"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <span className="icon"></span>
            <div className="flex flex-1 flex-col justify-center">
              <h3>ABOUT US</h3>
              <p>Cursus semper viverra facilisis et et</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="personal-details"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <span className="icon"></span>
            <div className="flex flex-1 flex-col justify-center">
              <h3>PERSONAL DETAILS</h3>
              <p>Cursus semper viverra facilisis et et</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="offer-details"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <span className="icon"></span>
            <div className="flex flex-1 flex-col justify-center">
              <h3>OFFER DETAILS</h3>
              <p>Penatibus eu quis ante.</p>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="supporting-files"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <span className="icon"></span>
            <div className="flex flex-1 flex-col justify-center">
              <h3>SUPPORTING FILES</h3>
              <p>Faucibus nec enim leo et.</p>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Stepper;
