import React from "react";
import Select from "react-select";
import Line from "../../Line/Line";
const AssetInfo = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Assets</p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn--border text-sm bg-indigo-50 text-[#2A52B9]"
          >
            <span className="text-[#2A52B9] ico-upload mr-1 w-3 h-3"></span>{" "}
            Assign new asset
          </button>
        </div>
      </div>
      <div className="flex justify-between my-6 items-center">
        <div>
          <p>Found 2 asset</p>
        </div>
        <div className="flex gap-3">
          <Select placeholder={"Status:All"} options={[]} />
          <Select placeholder={"Sort by date"} options={[]} />
        </div>
      </div>
      <div className="flex justify-between items-center pb-4">
        <div className="flex items-center gap-5">
          <div>
            <img src="/images/ivshr_logo.svg" alt="" />
          </div>
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#1F2937] font-medium">
              Macbook Pro M1 2022
            </p>
            <p className="text-[#065F46]">Assigned</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <img className="m-auto" src="/images/edit-black-icon.svg" alt="" />
          <img className="m-auto" src="/images/delete-menu-icon.svg" alt="" />
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0 mb-4">
          <tbody>
            <tr>
              <td className="title">Category: </td>
              <td className="data">Laptop </td>
            </tr>
            <tr>
              <td className="title">Assigned by: </td>
              <td className="data">Jhonatan Morisson</td>
            </tr>
            <tr>
              <td className="title">Assigned Date:</td>
              <td className="data">30 Dec 2022 </td>
            </tr>
            <tr>
              <td className="title">Return Date: </td>
              <td className="data">When employee exit company </td>
            </tr>
            <tr>
              <td className="title">Note:</td>
              <td className="data">
                Laptop for development until his laptop repaired
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Line />
      <div className="flex justify-between items-center pb-4">
        <div className="flex items-center gap-5">
          <div>
            <img src="/images/ivshr_logo.svg" alt="" />
          </div>
          <div className="flex items-center gap-2">
            <p className="text-sm text-[#1F2937] font-medium">
              Toyota Coroola Altis
            </p>
            <p className="text-[#991B1B]">Returned</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <img className="m-auto" src="/images/edit-black-icon.svg" alt="" />
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0 mb-4">
          <tbody>
            <tr>
              <td className="title">Category: </td>
              <td className="data">Vehicle </td>
            </tr>
            <tr>
              <td className="title">Type: </td>
              <td className="data">Non-Returnable Asset</td>
            </tr>
            <tr>
              <td className="title">Assigned by:</td>
              <td className="data">Jhonatan Morisson </td>
            </tr>
            <tr>
              <td className="title">Assigned Date: </td>
              <td className="data">30 Dec 2022</td>
            </tr>
            <tr>
              <td className="title">Return Date:</td>
              <td className="data">31 Dec 2022</td>
            </tr>
            <tr>
              <td className="title">Returned Date:</td>
              <td className="data">21 March 2023 10:30pm</td>
            </tr>
            <tr>
              <td className="title">Received by:</td>
              <td className="data">Jhonatan Morison</td>
            </tr>
            <tr>
              <td className="title">Note:</td>
              <td className="data">
                Daily operational vehicle until march 2023
              </td>
            </tr>
            <tr>
              <td className="title">Return note</td>
              <td className="data">Received and no damage</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex gap-4">
        <div>
          <img src="/images/ivshr_logo.svg" alt="" />
        </div>
        <div>
          <img src="/images/ivshr_logo.svg" alt="" />
        </div>
        <div>
          <img src="/images/ivshr_logo.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default AssetInfo;
