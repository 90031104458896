import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import BigCalendar from "../../../../BigCalendar/BigCalendar";
import moment from "moment";

const MyAttendanceCalendarView = ({
  selectedOption,
  label,
  fromDate,
  toDate,
}) => {
  const [calendarData, setCalendarData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const fetchAttendanceData = async () => {
    try {
      const response = await axiosPrivate.get(
        `/attendance/getListOfClockInAndClockOut`,
        {
          params: {
            label: label?.value,
            selectedOption: selectedOption?.value,
            from_date: fromDate,
            to_date: toDate,
          },
        }
      );

      if (response?.data?.data) {
        const formattedData = response.data.data.map((event) => ({
          title: (
            <div style={{ backgroundColor: "green", color: "white" }}>
              <p>{event.attendance_status_label}</p>
              <p className="text-xs">{event.total_hours}</p>
            </div>
          ),
          start: moment(event.attendance_date).toDate(),
          end: moment(event.attendance_date).toDate(),
        }));

        setAttendanceData(formattedData);
      } else {
        setAttendanceData([]);
      }
    } catch (error) {
      setAttendanceData([]);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
  }, [fromDate, toDate]);

  useEffect(() => {
    setCalendarData(attendanceData);
  }, [attendanceData]);


  return (
    <div className="p-5">
      <BigCalendar myEventsList={calendarData} />
    </div>
  );
};

export default MyAttendanceCalendarView;
