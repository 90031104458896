import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import Line from "../../../components/Line";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../helpers/axios";
import {validateDateOfBirth} from "../../../utils/FormatDate/formatEmployeeBirthday"
import { toast } from "react-toastify";
import {
  sourceFrom as sourceFromOptions,
  bloodGroup as bloodGroupOptions,
  genderOptions,
  residentialStatusOptions,
  employeeLevelOptions,
} from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const CreateCandidate = () => {
  const [toggle, setToggle] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const errRef = useRef();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [errMsg, setErrMsg] = useState(null);
  const [loader, setLoader] = useState(false);
  const [countryList, setCountry] = useState([]);
  const [stateList, setState] = useState([]);
  const [cityList, setCity] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const fetchJobTitleList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getJobTitleList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setJobTitleList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  //

  const fetchCountry = async () => {
    await axios.get("onboarding/services/location").then((response) => {
      setCountry(response.data.country);
    });
  };

  useEffect(() => {
    fetchJobTitleList();
    fetchDesignationList();
    fetchCountry();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  // GET STATE API STARTS
  const fetchState = async () => {
    if (selectedCountry) {
      let isMounted = true;
      const controller = new AbortController();

      await axiosPrivate
        .get(`onboarding/services/location?StatesFrom=${selectedCountry}`, {
          signal: controller.signal,
        })
        .then((response) => {
          // isMounted && console.log(response.data);
          setState(response.data.state);
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };

  useEffect(() => {
    fetchState();
  }, [selectedCountry]);

  // GET STATE API ENDS

  // Get city list api starts
  const fetchCity = async () => {
    if (selectedState) {
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .get(
          `onboarding/services/location?StatesFrom=${selectedCountry}&CityFrom=${selectedState}`,
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          // isMounted && console.log(response.data);
          setCity(response.data.city);
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };
  useEffect(() => {
    fetchCity();
  }, [selectedState]);
  // Get city list api ends

  const onSubmit = async (data) => {
    let reqObj = {
      ...data,
      birth_place: 0,
      photo: "/images/avatar.jpg",
      id_card: "N/A",
      birth_certificate: "NA",
      marriage_date: "2000-01-01",
      place_of_birth: "N/A",
      disability_type: "NA",
      religion: "N/A",
      agreement: false,
      status: 1,
    };
    if (data.checkbox) {
      reqObj = {
        ...reqObj,
        permanent_city_id: data.present_city_id,
        permanent_postal_code: data.present_postal_code,
        permanent_state_id: data.present_state_id,
        permanent_address: data.present_address,
        permanent_district_id: data.present_district_id,
      };
    }
    const candidateData = {
      ...reqObj,
      present_city_id: data?.present_city_id || data.present_district_id,
      permanent_city_id: data?.permanent_city_id || reqObj.permanent_district_id,
    };
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`onboarding/candidate/search?filter=${data.email}`, {
        signal: controller.signal,
      })
      .then(async (response) => {
        // isMounted && console.log(response.data);
        if (Number(response.data.statusCode) === 400) {
          try {
            setLoader(true);
            let isMounted = true;
            const controller = new AbortController();
            await axiosPrivate
              .post("onboarding/candidate/create", candidateData, {
                signal: controller.signal,
              })
              .then((response) => {
                // isMounted && console.log(response, "RESPONSE FROM SERV");
                if (Number(response.data.statusCode) === 200) {
                  toast.success("Candidate created successfully!", {
                    type: "success",
                  });
                  navigate("/candidate/candidate-list");
                } else if (
                  Number(response.data.statusCode) === 400 ||
                  response.data.error.data.id ||
                  response.data === undefined
                ) {
                  if (
                    response.data.error.validationErrors[0].field === "email"
                  ) {
                    toast.info("Email already Exists");
                    setErrMsg("Email already Exists");
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }
                  setLoader(false);
                  toast.error(
                    ` ${
                      response.data.error.validationErrors[0].field === "email"
                        ? "Email already exist!"
                        : "Candidate creation failed!"
                    }`
                  );
                }
              })
              .catch((err) => {
                console.error(err);
              });
            return () => {
              isMounted = false;
              controller.abort();
            };
          } catch (err) {
            setLoader(false);
            // console.log(err);
          } finally {
            setLoader(false);
          }
        } else if (Number(response.data.statusCode) === 200) {
          setErrMsg("Email already Exists");
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

 

  return (
    <div
      className="flex-1 justify-center max-w-[800px] text-[14px] py-7 m-auto"
      id="main"
    >
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-800 mb-2">
          Basic Informations
        </h2>
        <p className="text-[14px] mb-6">
          Fill candidate personal basic information
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <span
          className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
          ref={errRef}
        >
          {errMsg}
        </span>
        <br />
        <div className="lg:flex gap-4 text-[14px]">
          <div className="flex-1 mb-4" id="first-name">
            <label htmlFor="">
              First Name <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("first_name", {
                required: "This field is required",
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: "Numbers and Special Characters are not allowed!",
                },
              })}
            />
            {errors?.first_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.first_name.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">Middle Name </label>
            <input
              type="text"
              {...register("middle_name", {
               
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: "Numbers and Special Characters are not allowed!",
                },
              })}
            />
            {errors?.middle_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.middle_name.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Last Name <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("last_name", {
                required: "This field is required",
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message:
                    "Numbers , Special Characters and Space are not allowed!",
                },
              })}
            />
            {errors?.last_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.last_name.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4 text-[14px]" id="email">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Email Address <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              })}
            />
            {errors?.email && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.email.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="" className="">
              Designation <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="designation_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(Number(e.id))}
                  options={designationList}
                  getOptionLabel={(option) => option.designation_title}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.designation_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.designation_id.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Employee Level <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="emp_level"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(e.value)}
                  options={employeeLevelOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.emp_level && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.emp_level.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4 text-[14px]">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Phone Number <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="tel"
              {...register("phone", {
                required: "This field is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a number",
                },
              })}
            />
            {errors?.phone && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.phone.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Department<span className="text-red-600 font-medium">*</span>{" "}
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="department_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(Number(e.id))}
                  options={departmentList}
                  getOptionLabel={(option) => option.department_name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.department_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.department_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4 text-[14px]">
        
            {/* <label htmlFor="">
              Job Title <span className="text-red-600 font-medium">*</span>
            </label> */}

            {/* <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="job_title_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(Number(e.id))}
                  options={jobTitleList}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                />
              )}
            /> */}
            {/* {errors?.job_title_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.job_title_id.message}
              </span>
            )} */}
         
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Candidate Added Date{" "}
              <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="date"
              defaultValue={date}
              {...register("added_date", {
                required: "This field is required",
              })}
            />
            {errors?.added_date && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.added_date.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Office Location{" "}
              <span className="text-red-600 font-medium">*</span>
            </label>

            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="location_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(Number(e.id))}
                  options={locationList}
                  getOptionLabel={(option) => option.location_name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.location_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.location_id.message}
              </span>
            )}
          </div>
        </div>
        <br />
        <Line />

        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Personal Details{" "}
          </h2>
          <p>Fill all candidate personal details information</p>
        </div>
        <br />
        <div className="lg:flex gap-4">
        <div className="flex-1 mb-4">
        <label htmlFor="data_of_birth">
          Date Of Birth <span className="text-red-600 font-medium">*</span>
        </label>
        <div className="relative">
          <input
            type="date"
            id="data_of_birth"
            {...register("data_of_birth", {
              required: "This field is required",
              validate: validateDateOfBirth 
            })}
          />
          {errors?.data_of_birth && (
            <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {errors?.data_of_birth?.message}
            </span>
          
        
          )}
        </div>
      </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Blood Group <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="blood_group"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(e.value)}
                  options={bloodGroupOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.blood_group && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.blood_group.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Gender <span className="text-red-600 font-medium">*</span>{" "}
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="gender"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(e.value)}
                  options={genderOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.gender && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.gender.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Father's name <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("father_name", {
                required: "This field is required",
              })}
            />
            {errors?.father_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.father_name.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Marital Status<span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="martial_status"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    onChange(e.value);
                    setIsInputDisabled(e.value === "Single");
                  }}
                  options={[
                    { value: "Married", label: "Married" },
                    { value: "Single", label: "Single" },
                  ]}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.martial_status && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.martial_status.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">Spouse Name </label>
            <input
              type="text"
              {...register("spouse_name", {
                required: isInputDisabled ? false : "This field is required",
              })}
              disabled={isInputDisabled}
            />
            {errors?.spouse_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.spouse_name.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Residential Status
              <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="residential_status"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(e.value)}
                  options={residentialStatusOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.residential_status && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.residential_status.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Nationality <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="nationality"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    onChange(Number(e.id));
                    setSelectedCountry(e.id);
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option.flag}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.nationality && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.nationality.message}
              </span>
            )}
          </div>
        </div>

        <div className="lg:flex gap-4">
          <div className="flex-1 mb-4">
            <label htmlFor="">
              Source of Career information{" "}
              <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="source_from_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => onChange(e.value)}
                  options={sourceFromOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
            {errors?.source_from_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.source_from_id.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-4 mt-6">
            <label
              htmlFor=""
              className="text-sm leading-5 font-medium text-gray-500"
            >
              <input type="checkbox" {...register("phycically_challenged")} />{" "}
              Physically challenged{" "}
            </label>
          </div>
        </div>
        <br />
        <Line />

        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Emergency Contact Details
          </h2>
          <p className="mb-6">Fill emergency contact information</p>
        </div>
        <br />

        <div className="lg:flex gap-4">
          <div className="flex-1 mb-6">
            <label htmlFor="">
              Emergency Contact Name
              <span className="text-red-600 font-medium">*</span>{" "}
            </label>
            <input
              type="text"
              {...register("emergency_contact_name", {
                required: "This field is required",
              })}
            />
            {errors?.emergency_contact_name && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.emergency_contact_name.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-6">
            <label htmlFor="">
              Emergency Contact Number 1
              <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="tel"
              {...register("emergency_phone_1", {
                required: "This field is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a number",
                },
              })}
            />
            {errors?.emergency_phone_1 && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.emergency_phone_1.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-6">
            <label htmlFor="">
              Emergency Contact Number 2
              <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="tel"
              {...register("emergency_phone_2", {
                required: "This field is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a number",
                },
              })}
            />
            {errors?.emergency_phone_2 && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.emergency_phone_2.message}
              </span>
            )}
          </div>
        </div>
        <Line />
        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Present Address
          </h2>
          <p>Fill candidate present adress information</p>
        </div>
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label htmlFor="">
              Address <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("present_address", {
                required: "This field is required",
              })}
            />
            {errors?.present_address && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.present_address.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4">
          <div className="flex-1 mb-6">
            <label htmlFor="">
              State <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="present_state_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    onChange(Number(e.id));
                    setSelectedState(e.id);
                  }}
                  options={stateList}
                  getOptionLabel={(option) => option.state_name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.present_state_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.present_state_id.message}
              </span>
            )}
          </div>
          <div className="flex-1 mb-6">
            <label htmlFor="">
              District <span className="text-red-600 font-medium">*</span>
            </label>
            <Controller
              rules={{
                required: "This field is required",
              }}
              control={control}
              name="present_district_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    onChange(Number(e.id));
                    setSelectedCity(e.id);
                  }}
                  options={cityList}
                  getOptionLabel={(option) => option.city_name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.present_district_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.present_district_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex gap-4">
          <div className="flex-1 mb-6">
            <label htmlFor="">City</label>
            <Controller
              control={control}
              name="present_city_id"
              render={({ field: { onChange } }) => (
                <Select
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  onChange={(e) => {
                    onChange(Number(e.id));
                    setSelectedCity(e.id);
                  }}
                  options={cityList}
                  getOptionLabel={(option) => option.city_name}
                  getOptionValue={(option) => option.id}
                />
              )}
            />
            {errors?.present_city_id && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.present_city_id.message}
              </span>
            )}
          </div>

          <div className="flex-1 mb-6">
            <label htmlFor="">
              Postal Code <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="number"
              {...register("present_postal_code", {
                required: "This field is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a valid postal code",
                },
              })}
            />
            {errors?.present_postal_code && (
              <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                {errors.present_postal_code.message}
              </span>
            )}
          </div>
        </div>
        <Line />
        <div className="mb-5">
          <h2 className="text-xl font-bold text-gray-800 mb-2">
            Permanent Address
          </h2>
          <p>Fill candidate permanent adress information</p>
        </div>
        <div className=" mb-4">
          <label
            htmlFor=""
            className="text-sm leading-5 font-medium text-gray-500"
          >
            <input
              disabled={!!!(selectedCity && selectedState)}
              type="checkbox"
              {...register("checkbox")}
              onClick={(e) => {
                setToggle(e.target.checked);
              }}
            />{" "}
            Same as present address{" "}
          </label>
        </div>
        {!toggle ? (
          <>
            <div className="flex gap-4 mb-4">
              <div className="flex-1">
                <label htmlFor="">
                  Address <span className="text-red-600 font-medium">*</span>
                </label>
                <input
                  type="text"
                  {...register("permanent_address", {
                    required: "This field is required",
                  })}
                />
                {errors?.permanent_address && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.permanent_address.message}
                  </span>
                )}
              </div>
            </div>
            <div className="lg:flex gap-4">
              <div className="flex-1 mb-6">
                <label htmlFor="">
                  State <span className="text-red-600 font-medium">*</span>
                </label>
                <Controller
                  rules={{
                    required: "This field is required",
                  }}
                  control={control}
                  name="permanent_state_id"
                  render={({ field: { onChange } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(Number(e.id));
                        setSelectedState(e.id);
                      }}
                      options={stateList}
                      getOptionLabel={(option) => option.state_name}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
                {errors?.permanent_state_id && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.permanent_state_id.message}
                  </span>
                )}
              </div>
              <div className="flex-1 mb-6">
                <label htmlFor="">
                  District <span className="text-red-600 font-medium">*</span>
                </label>
                <Controller
                  rules={{
                    required: "This field is required",
                  }}
                  control={control}
                  name="permanent_district_id"
                  render={({ field: { onChange } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(Number(e.id));
                        setSelectedCity(e.id);
                      }}
                      options={cityList}
                      getOptionLabel={(option) => option.city_name}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
                {errors?.permanent_district_id && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.permanent_district_id.message}
                  </span>
                )}
              </div>
            </div>
            <div className="lg:flex gap-4">
              <div className="flex-1 mb-6">
                <label htmlFor="">City</label>
                <Controller
                  control={control}
                  name="permanent_city_id"
                  render={({ field: { onChange } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(Number(e.id));
                        setSelectedCity(e.id);
                      }}
                      options={cityList}
                      getOptionLabel={(option) => option.city_name}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
                {errors?.permanent_city_id && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.permanent_city_id.message}
                  </span>
                )}
              </div>

              <div className="flex-1 mb-6">
                <label htmlFor="">
                  Postal Code{" "}
                  <span className="text-red-600 font-medium">*</span>
                </label>
                <input
                  type="tel"
                  {...register("permanent_postal_code", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid postal code",
                    },
                  })}
                />
                {errors?.permanent_postal_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.permanent_postal_code.message}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <Line />
        <div className="flex justify-end pt-6">
          <Link
            to="/candidate/candidate-list"
            className="btn btn--border ml-3 text-[14px]"
          >
            Discard
          </Link>
          {loader ? (
            <div role="status">
              <button
                className="btn ml-3 text-[14px] disabled:opacity-100"
                disabled
              >
                <svg
                  aria-hidden="true"
                  className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Creating new Candidate
              </button>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <input
              type="submit"
              className="btn ml-3 text-[14px]"
              value="Create Candidate"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateCandidate;
