import React from "react";
import Aside from "../Dashboard/Aside/Aside";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";
const submenu = [
  {
    text: "My Assets",
    link: "/assets/my-assets",
    roles: [1, 2, 4, 6],
  },
  {
    text: "Employee Assets",
    link: "/assets/employee-assets",
    roles: [1, 2, 4, 6],
  },
  {
    text: "Manage Assets",
    link: "/assets/manage-assets",
    roles: [1, 2, 4, 6],
  },
];

const AssetModule = () => {
  return (
    <div className="lg:flex ">
      <DashboardSidebar/>
      <div className="flex flex-1">
        <Aside submenu={submenu} />
        <Outlet />
      </div>
    </div>
  );
};

export default AssetModule;
