import React from "react";

const arrowLeftSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M13.706 9.698a.99.99 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .99.99 0 0 0 0-1.407l-2.318-2.297z"
    ></path>
  </svg>
);
const arrowRightSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M10.294 9.698a.99.99 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .99.99 0 0 1 0-1.407l2.318-2.297z"
    ></path>
  </svg>
);
const ToggleSideBarButton = (props) => {
  return (
    <button {...props}>
      <span>{props?.isExpanded ? arrowLeftSvg : arrowRightSvg}</span>
    </button>
  );
};

export default ToggleSideBarButton;
