import React, { useState } from "react";
import EditOrAddPosition from "../EditOrAddPositionModal/EditOrAddPosition";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";

const JobWorkInfo = ({employee }) => {
  const user = useSelector((state) => state.user.auth);
  const isHrAuth = userRoleAuth(user, 2);
  const isAdminAuth = userRoleAuth(user, 1);
  
  
  const officeLocation = employee?.office_location || "";
  const designationTitle = employee?.designation_title || "";
  const employeeTypeLabel = employee?.employee_type_label || "";
  const deparmentName = employee?.department_name || "";
  const lineManagerName = employee?.line_manager_name || ""
  const userRoleLabel = employee?.user_role_label || ""

  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Work Informations
          </p>
        </div>
        {!!(isHrAuth || isAdminAuth) && (
          <div className="cursor-pointer">
            <img
              src="/images/editicon.svg"
              alt=""
              onClick={() => onOpenModal()}
            />
            <EditOrAddPosition
              openModal={openModal}
              onCloseModal={onCloseModal}
              employee={employee}
            />
          </div>
        )}
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Location </td>
              <td className="data">{officeLocation} </td>
            </tr>
            <tr>
              <td className="title">Position Type </td>
              <td className="data">{userRoleLabel}</td>
            </tr>
            <tr>
              <td className="title">Employment Type</td>
              <td className="data">{employeeTypeLabel} </td>
            </tr>
            <tr>
              <td className="title">Designation </td>
              <td className="data">{designationTitle} </td>
            </tr>
            <tr>
              <td className="title">Department </td>
              <td className="data">{deparmentName} </td>
            </tr>
            {/* <tr>
              <td className="title">Effective Date </td>
              <td className="data">27 Apr 2022 </td>
            </tr> */}
            <tr>
              <td className="title">Line Manager </td>
              <td className="data">{lineManagerName} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobWorkInfo;
