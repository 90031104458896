import React, { useEffect, useState } from "react";
import Line from "../../../../../components/Line";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const EmployeeContactDetails = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const employeeBasicData = JSON.parse(
    localStorage.getItem("employeeBasicData")
  );
  const employeePersonalData = JSON.parse(
    localStorage.getItem("employeePersonalData")
  );

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [errMsg, setErrMsg] = useState([]);
  const startDate = new Date();
  const addedDate =
    startDate.getFullYear() +
    "-" +
    (startDate.getMonth() + 1) +
    "-" +
    startDate.getDate();

  // GET STATE API STARTS
  const fetchNationaliyLocation = async () => {
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get(
        `onboarding/services/location?StatesFrom=${employeePersonalData?.nationality}`,
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        // isMounted && console.log(response.data);
        setState(response.data.state);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    fetchNationaliyLocation();
  }, [employeePersonalData?.nationality]);

  // GET STATE API ENDS

  // GET CITY API STARTS
  const fetchStateLocation = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        `onboarding/services/location?StatesFrom=${employeePersonalData?.nationality}&CityFrom=${selectedState}`,
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        // isMounted && console.log(response.data);
        setCity(response.data.city);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    fetchStateLocation();
  }, [selectedState]);

  // GET CITY API ENDS


  const onSubmit = async (data) => {
    const employeeData = {
      ...employeeBasicData,
      ...employeePersonalData,
      ...data,
    };

    try {
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post(
          "employee/create",
          {
            phone: employeeData.phone,
            gender: employeeData.gender,
            father_name: employeeData.fatherName,
            birth_place: 4355,
            martial_status: employeeData.martialStatus,
            spouse_name: employeeData.spouseName
              ? employeeData.spouseName
              : "NA",
            residential_status: employeeData.residentialStatus,
            nationality: employeeData.nationality,
            present_address: employeeData.adress,
            present_city_id: Number(selectedCity),
            permanent_address: employeeData.adress,
            permanent_city_id: Number(selectedCity),
            present_state_id: Number(selectedState),
            present_postal_code: Number(employeeData.postal_code),
            permanent_state_id: Number(selectedState),
            permanent_postal_code: employeeData.postal_code,
            emergency_contact_name: employeeData.emergency_contact_name,
            emergency_phone_1: Number(employeeData.emergency_phone),
            emergency_phone_2: Number(employeeData.emergency_phone),
            photo: "/images/avatar.jpg",
            id_card: "998562",
            birth_certificate: "NA",
            data_of_birth: employeeData.dateOfBirth,
            marriage_date: "2021-01-14",
            blood_group: employeeData.bloodGroup,
            place_of_birth: employeeData.placeOfBirth,
            disability_type: "NA",
            phycically_challenged: employeeData.physicallyChallenged,
            religion: employeeData.religion,
            job_title_id: Number(employeeData.job_title),
            added_date: addedDate,
            source_hire_id: Number(employeeData.source),
            email: employeeData.email,
            agreement: false,
            location_id: Number(employeeData.office_location),
            department_id: Number(employeeData.department),
            status: 1,
            first_name: employeeData.firstName,
            middle_name: employeeData.middleName
              ? employeeData.middleName
              : "NA",
            last_name: employeeData.lastName,
            attendance_schema_id: 1,
            employee_type_id: employeeData.employementType,
            portal_access: employeeData.portal_access,
            designation_id: employeeData.designation,
            emp_level: employeeData.empLevel,
            role_id: 6,
            serial_number: Number(employeeData?.serial_id.trim()) || null,
            present_district_id: Number(selectedCity),
            permanent_district_id: Number(selectedCity),
          },
          {
            signal: controller.signal,
          }
        )
        .then((response) => {
          // isMounted && console.log(response, "RESPONSE FROM SERV");
          if (
            response.data.status &&
            response.data.statusCode == 200 &&
            response.data.message == "Employee creation success"
          ) {
            toast.success("Employee creation success");
            localStorage.removeItem("employeeBasicData");
            localStorage.removeItem("employeePersonalData");
            navigate("/employee/manage-employee/employee-list");
          } else if (response?.data?.error?.validationErrors) {
            response.data.error.validationErrors.map((error) => {
              toast.error(error.message);
            });
          } else if (
            response.data.statusCode == 401 &&
            response.data.message == "UNAUTHORIZED"
          ) {
            toast.error("No server response!");
            toast.info("Try to Log in again");
          } else {
            toast.error("Employee creation failed!");
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } catch (err) {
      toast.error("Employee creation failed!");
      // console.log(err);
    }
  };

  return (
    <div className="flex w-full justify-center">
      <div className="flex-1 justify-center max-w-[900px] text-[14px] h-screen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1">
            {errMsg}
          </span>
          <div className="mb-5">
            <h2 className="text-xl font-bold text-gray-800 mb-2">
              Contact Details
            </h2>
            <p className="mb-6">Fill all employee personal basic information</p>
          </div>
          <div className="lg:flex gap-4">
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Personal Phone Number{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="tel"
                {...register("phone", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                })}
              />
              {errors?.phone && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.phone.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-4">
              <label htmlFor="">
                Personal Email Address{" "}
                {/* <span className="text-red-600 font-medium">*</span> */}
              </label>
              <input type="text" />
            </div>
          </div>
          <div className="lg:flex gap-4">
            <div className="flex-1 mb-6">
              <label htmlFor="">
                Emergency Contact Name{" "}
                <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("emergency_contact_name", {
                  required: "This field is required",
                })}
              />
              {errors?.emergency_contact_name && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.emergency_contact_name.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-6">
              <label htmlFor="">
                Emergency Contact Number{" "}
                <span className="text-red-600 font-medium">*</span>{" "}
              </label>
              <input
                type="tel"
                {...register("emergency_phone", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                })}
              />
              {errors?.emergency_phone && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.emergency_phone.message}
                </span>
              )}
            </div>
          </div>

          <Line />

          <div className="mb-5">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Address</h2>
            <p>Fill all employee personal basic information</p>
          </div>
          <div className="flex gap-4 mb-4">
            <div className="flex-1">
              <label htmlFor="">
                Address <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="text"
                {...register("adress", {
                  required: "This field is required",
                })}
              />
              {errors?.adress && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.adress.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-6">
              <label htmlFor="">
                State <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="state_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      state.find((i) => Number(i?.id) === Number(value))
                            ?.state_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.id);
                      setSelectedState(e.id);
                    }}
                    options={state}
                    getOptionLabel={(option) => option.state_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.state_id && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.state_id.message}
                </span>
              )}
            </div>
          </div>

          <div className="lg:flex gap-4">
            <div className="flex-1 mb-6">
              <label htmlFor="">
                District <span className="text-red-600 font-medium">*</span>
              </label>
              <Controller
                rules={{
                  required: "This field is required",
                }}
                control={control}
                name="district_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      city?.find((i) => Number(i?.id) === Number(value))
                        ?.city_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.id);
                      // setSelectedState(e.id);
                    }}
                    options={city}
                    getOptionLabel={(option) => option.city_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.district_id && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.district_id.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-6">
              <label htmlFor="">
                City
                {/* <span className="text-red-600 font-medium">*</span> */}
              </label>
              <Controller
                control={control}
                name="city_id"
                render={({ field: { onChange, value } }) => (
                  <Select
                    placeholder={
                      city?.find((i) => Number(i?.id) === Number(value))
                        ?.city_name
                    }
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    onChange={(e) => {
                      onChange(e.id);
                      setSelectedCity(e.id);
                    }}
                    options={city}
                    getOptionLabel={(option) => option.city_name}
                    getOptionValue={(option) => option.id}
                  />
                )}
              />
              {errors?.city_id && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.city_id.message}
                </span>
              )}
            </div>
            <div className="flex-1 mb-6">
              <label htmlFor="">
                Postal Code <span className="text-red-600 font-medium">*</span>
              </label>
              <input
                type="tel"
                {...register("postal_code", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a number",
                  },
                })}
              />
              {errors?.postal_code && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                  {errors.postal_code.message}
                </span>
              )}
            </div>
          </div>
          <Line />

          <div className="flex justify-end pt-6">
            <Link
              to="/employee-module/employee-personal-details"
              className="btn btn--border ml-3 text-[14px]"
            >
              Back
            </Link>
            <button type="submit" className="btn ml-3 text-[14px]">
              Create Employee
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmployeeContactDetails;
