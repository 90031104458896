import React, { Fragment, useState, useRef, useEffect } from "react";
import BigCalendar from "../../../components/BigCalendar/BigCalendar";
import Select from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DropdownIndicator } from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import moment from "moment";
import { generateDates } from "../../../utils/FormatDate/generateDates";

const HolidayCalendar = () => {
  const axiosPrivate = useAxiosPrivate();
  const [holidayList, setHolidayList] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [locationList, setLocationList] = useState({
    country: [],
    state: [],
    district: [],
  });
  const [location, setLocation] = useState({
    country: 0,
    state: 0,
    city: 0,
    date: "",
    search: "",
  }); // selected locaition id and date filtering
  const [dateRange, setDateRange] = useState({
    fromDate: moment()
      .startOf("month")
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    toDate: moment().endOf("month").add(7, "days").format("YYYY-MM-DD"),
  });
  const { fromDate, toDate } = dateRange;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [makeApiCall, setMakeApiCall] = useState(false);
  const filterRef = useRef({
    country: "",
    state: "",
    date: "",
  });
  const isFilterApplied =
    location.country || location.state || location.date || location.search;

  const handleClearFilters = () => {
    setLocation({
      country: 0,
      state: 0,
      city: 0,
      date: "",
    });
    if (filterRef?.current?.country) {
      filterRef.current.country.clearValue();
    }
    if (filterRef?.current?.state) {
      filterRef.current.state.clearValue();
    }
    if (filterRef?.current?.date) {
      filterRef.current.date.value = "";
    }
  };

  const handleMakeApiCallToFetchHolidayList = () => {
    setMakeApiCall((prev) => !prev);
  };

  const fetchLocationList = async (baseUrl) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status || res.statusCode === 200) {
          if (res.country) {
            setLocationList((prev) => {
              return {
                ...prev,
                country: res.country,
              };
            });
          }
          if (res.state) {
            setLocationList((prev) => {
              return {
                ...prev,
                state: res.state,
              };
            });
          }
          if (res.city) {
            setLocationList((prev) => {
              return {
                ...prev,
                city: res.city,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchHolidayList = async (url) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(url)
      .then((response) => {
        // isMounted && console.log(response.data);
        const res = response?.data;
        if (res.status) {
          setTotalRecords(res.total);
          setHolidayList(res.holidays);
        } else {
          setTotalRecords(0);
          setHolidayList(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setTotalRecords(0);
        setHolidayList(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    let baseUrl = `onboarding/services/location?`;
    let holidayListBaseUrl = `lms/holiday/holidayList?holiday&page_number=${pageNo}`;
    if (location.country) {
      baseUrl += `StatesFrom=${location.country}`;
      holidayListBaseUrl += `&country_id=${location.country}`;
    }
    if (location.state) {
      baseUrl += `&CityFrom=${location.state}`;
      holidayListBaseUrl += `&state_id=${location.state}`;
    }
    if (location.date) {
      holidayListBaseUrl += `&date=${location.date}`;
    }
    if (fromDate) {
      holidayListBaseUrl += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      holidayListBaseUrl += `&toDate=${toDate}`;
    }

    fetchLocationList(baseUrl);
    fetchHolidayList(holidayListBaseUrl);
  }, [location, pageNo, makeApiCall, dateRange]);

  const handleChangeDate = (e) => {
    const { start, end } = e;
    if (start && end) {
      setDateRange({
        fromDate: moment(start).format("YYYY-MM-DD"),
        toDate: moment(end).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    const formattedData = [];
    if (Array.isArray(holidayList)) {
      const dates = generateDates(fromDate, toDate);
      dates.forEach((date) => {
        const getHoliday = holidayList.find(
          (i) => moment(i.date).format("YYYY-MM-DD") === date
        );
        if (getHoliday) {
          const { holiday_name, holiday_type_name, date, holiday_type_id } = getHoliday;
          const color = Number(holiday_type_id) === 1 ? "#95ED9E" : "#C072EA"
          formattedData.push({
            title: (
              <div>
                <p>{holiday_name}</p>
                <p className="text-xs">{holiday_type_name}</p>
              </div>
            ),
            start: new Date(moment(date).format("YYYY-MM-DD")),
            end: new Date(moment(date).format("YYYY-MM-DD")),
            titleColor: "",
            backGroundColor: color,
            borderColor: "#021704",
          });
        }
      });
    }
    setEventList(formattedData);
  }, [holidayList]);

  return (
    <Fragment>
      <div className="bg-gray-50 flex-1 px-10 pt-8 pb-6">
        <div className="mb-4">
          <h2 className="text-lg">Holiday Calendar</h2>
          <p>View all holiday</p>
        </div>
        {/* <div className='flex'>
          <Select placeholder="Month View"/>
        </div> */}
        <div className="flex justify-between items-center px-10 mb-4">
          <div className="flex gap-3">
            
            <Select
              ref={(ele) => (filterRef.current.country = ele)}
              components={{ DropdownIndicator }}
              className="w-[200px]"
              placeholder={"Country"}
              options={
                locationList.country &&
                locationList.country.map((item) => {
                  return { label: item.flag, value: item.id };
                })
              }
              onChange={(e) =>
                setLocation((prev) => {
                  return { ...prev, country: e?.value };
                })
              }
            />
            <Select
              ref={(ele) => (filterRef.current.state = ele)}
              components={{ DropdownIndicator }}
              className="w-[200px]"
              placeholder={"State"}
              options={
                locationList.state &&
                locationList.state.map((item) => {
                  return { label: item.state_name, value: item.id };
                })
              }
              onChange={(e) => {
                setLocation((prev) => {
                  return { ...prev, state: e?.value };
                });
              }}
            />
            {isFilterApplied && (
              <button
                className="text-blue-600 font-medium"
                onClick={handleClearFilters}
              >
                Clear Filters
              </button>
            )}
          </div>
        </div>
        <div className="">
          <BigCalendar
            myEventsList={eventList}
            handleChangeDate={handleChangeDate}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HolidayCalendar;
