const userRoleAuth = (user, allowedRole) => {
    return !!user.roles.find((role) => {
      if (Number(role) === Number(allowedRole)) {
        return true
      } else {
        return false
      }
    })
  }

export default userRoleAuth;