import React, { useState} from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import Avatar from "../../Avatar/Avatar";
import Line from "../../Line/Line";
import SpinnerLoader from "../../PageLoader/SpinnerLoader";
import formatLeaveDuration from "../../../utils/FormatLeaveDuration/formatLeaveDuration";
import {
  useLeaveTypeList,
  useEmployeeLeaveBalanceList,
  useAssignLeavePolicy,
  useListLeavePolicyMapping,
} from "../../../queries/leaveQueries";
import { toast } from "react-toastify";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const AssignLeaveModal = ({ open, close, employee, leavePolicy }) => {
  const employeeName = `${employee?.first_name} ${employee?.last_name}`;
  const employeeSerial = employee?.employee_serial;
  const department = employee?.department_name;
  const roleName = employee?.role_name;
  const employeePhoto = employee?.photo;
  const employeeId = employee?.employee_id;
  const [errMsg, setErrMsg] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const { data: leaveTypesData, isLoading: leaveTypesLoading } =
    useLeaveTypeList({ status: 1, fullDetails: 1 });
  const { data: employeeLeaveBalanceData, isLoading: leaveBalanceLoading } =
    useEmployeeLeaveBalanceList({
      sortOrder: "DESC",
      employee_serial: employeeSerial,
    });

  const assignLeavePolicyMutation = useAssignLeavePolicy();

  const handleCloseModal = () => {
    close();
  };

  const leaveTypes = Array.isArray(leaveTypesData?.data)
    ? leaveTypesData?.data
    : [];
  const employeeLeaveBalance = Array.isArray(
    employeeLeaveBalanceData?.employee?.[0]?.leaveBalances
  )
    ? employeeLeaveBalanceData?.employee?.[0]?.leaveBalances
    : [];
  const loader = leaveTypesLoading || leaveBalanceLoading;

  const {
    data: policyDetails,
    isLoading: isLoadingPolicyDetails,
    error: policyDetailsError,
  } = useListLeavePolicyMapping(
    { policy_id: selectedPolicy },
    !!selectedPolicy
  );
  const disableUpdateButton =
    assignLeavePolicyMutation.isLoading ||
    policyDetails?.policy_list?.length > 0
      ? false
      : true;

  const handleSubmit = () => {
    if (selectedPolicy) {
      setErrMsg("");
      const formattedLeaveTypes = policyDetails.policy_list.map((item) => ({
        leaveTypeId: item.leave_type_id,
        leaveBalance: item.number_days_allowed,
      }));

      assignLeavePolicyMutation.mutate(
        {
          employee_id: employeeId,
          leaveTypes: formattedLeaveTypes,
          leavePolicy: selectedPolicy,
        },
        {
          onSuccess: (data) => {
            toast.success(
              data?.message || "Leave policy assigned successfully!"
            );
            handleCloseModal();
          },
          onError: (error) => {
            const errorMessage =
              error?.response?.data?.message ||
              "Something went wrong while assigning leave policy";
            toast.error(errorMessage);
          },
        }
      );
    } else {
      toast.error("Select a leave policy");
    }
  };

  const handleDropdownChange = (selectedOption) => {
    setSelectedPolicy(selectedOption.value);
  };

  return (
    <>
      <Modal open={open} onClose={handleCloseModal} center>
        <div className="p-5 min-w-[420px] text-black">
          <h1 className="text-base font-medium">Assign leave types</h1>
          <Line />
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3">
              <Avatar image={employeePhoto} />
              <span className="text-base font-medium">{employeeName}</span>
            </div>
            <div className="flex justify-between">
              <ul className="text-gray-600">
                <li>Employee serial:</li>
                <li>Department:</li>
                <li>Role:</li>
                <li>Current Leave Policy:</li>
              </ul>
              <ul>
                <li>{employeeSerial || "-"}</li>
                <li>{department || "-"}</li>
                <li>{roleName || "-"}</li>
                <li>{employeeLeaveBalance?.[0]?.policy_name || "-"}</li>
              </ul>
            </div>

            <div className="flex flex-col justify-between">
              <span className="text-red-600 font-medium">{errMsg}</span>
              {loader ? (
                <div className="flex justify-center">
                  <SpinnerLoader />
                </div>
              ) : Array.isArray(employeeLeaveBalance) &&
                employeeLeaveBalance.length ? (
                <div className="border rounded-md p-2 shadow-md">
                  <label>Current leave balances:</label>
                  {employeeLeaveBalance.map((i) => {
                    const leaveTypeData =
                      leaveTypes.find(
                        (leave) => Number(leave.id) === Number(i.leave_type_id)
                      ) || {};
                    const isLopLeaveType = leaveTypeData?.is_lop;
                    const leaveBalance = isLopLeaveType
                      ? "Not Applicable"
                      : formatLeaveDuration({
                          durationInDay: i.leave_balance_days,
                          durationInHour: i.leave_balance_hours,
                        });

                    return (
                      <ul
                        className="flex justify-between"
                        key={i.leave_type_id}
                      >
                        <li>{leaveTypeData.leave_name}:</li>
                        <li>{leaveBalance}</li>
                      </ul>
                    );
                  })}
                </div>
              ) : (
                <span className="status">
                  No leave policy assigned for this employee
                </span>
              )}

              <div>
                {Array.isArray(leavePolicy) && leavePolicy.length ? (
                  <>
                    <p className="py-4 text-sm font-medium">
                      Select leave policy:
                    </p>
                    <Select
                      components={{ DropdownIndicator }}
                      options={leavePolicy.map((item) => ({
                        label: item.policy_name,
                        value: item.id,
                      }))}
                      onChange={handleDropdownChange}
                      formatOptionLabel={(option) => (
                        <div className="flex items-center justify-between">
                          {option.label}
                        </div>
                      )}
                    />

                    {isLoadingPolicyDetails ? (
                      <SpinnerLoader />
                    ) : policyDetailsError ? (
                      <span className="text-red-600 font-medium">
                        Failed to load policy details
                      </span>
                    ) : policyDetails?.policy_list?.length ? (
                      <div className="border rounded-md p-2 shadow-md mt-4">
                        <label>Leave Policy Details:</label>
                        {policyDetails?.policy_list?.map((detail) => (
                          <ul
                            className="flex justify-between"
                            key={detail?.leave_type_id}
                          >
                            <li>{detail?.leave_name}</li>
                            <li>{detail?.number_days_allowed} day(s)</li>
                          </ul>
                        ))}
                      </div>
                    ) : selectedPolicy ? (
                      <span className="text-red-600 font-medium">
                        Selected policy have no active leave types
                      </span>
                    ) : null}

                  </>
                ) : (
                  <span className="text-red-600 font-medium">
                    Create leave policy to assign
                  </span>
                )}
              </div>
              <div className="flex justify-end gap-3 pt-4">
                <button
                  type="button"
                  className="btn btn--border"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className={`btn text-[14px] pt-[11px] ${
                    disableUpdateButton
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={disableUpdateButton}
                >
                  {assignLeavePolicyMutation.isLoading
                    ? "Updating..."
                    : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AssignLeaveModal;
