import moment from "moment";

export const generateDates =  (fromDate, toDate, dateFormat = "YYYY-MM-DD") => {
  let dates = [];
  if (fromDate && toDate) {
    let startDate = moment(fromDate);
    const endDate = moment(toDate);
    dates.push(startDate.format(dateFormat))
    while (!moment(startDate).isSame(endDate)) {
      startDate = startDate.add(1, "day");
      dates.push(startDate.format(dateFormat));
    }
  }
  return dates;
};