import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Avatar from "../../../../components/Avatar/Avatar";
import Select from "react-select";

const NewSeperationModal = ({ openModal, onCloseModal }) => {
  const handleSubmit = () => {
    onCloseModal();
  };
  return (
    <div>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 max-w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit Basic Informations
            </h2>
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="flex">
                <div className="mr-4">
                  <Avatar width={72} height={72} />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn--border text-sm w-[151px] h-[38px]"
                  >
                    <span className="ico-upload mr-1 w-3 h-3"></span> Upload
                    Photo
                  </button>
                  <p className="text-xs mt-2">
                    Allowed file type: jpg and png, max file size 1 mb.
                  </p>
                </div>
              </div>

              <div className="flex">
                <label className="text-sm font-medium text-black mr-4">
                  Employee ID Series <span className="text-red-700">*</span>
                  <Select
                    className="w-[220px]"
                    placeholder={"Manual Entry"}
                    options={[]}
                  />
                </label>
                <label className="text-sm font-medium text-black w-[220px]">
                  ID <span className="text-red-700">*</span>
                  <input type="text" placeholder="IVS-1001" />
                </label>
              </div>

              <div className="flex">
                <label className="text-sm font-medium text-black mr-4">
                  Title <span className="text-red-700">*</span>
                  <Select
                    className="w-[220px]"
                    placeholder={"Select Title"}
                    options={[]}
                  />
                </label>
                <label className="text-sm font-medium text-black">
                  Gender <span className="text-red-700">*</span>
                  <Select
                    className="w-[220px]"
                    placeholder={"Select Gender"}
                    options={[]}
                  />
                </label>
              </div>

              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Full Name <span className="text-red-700">*</span>
                  <input type="text" placeholder="Enter your fullname" />
                </label>
              </div>
              <div className="">
                <label className="text-sm font-medium text-black">
                  Phone Number <span className="text-red-700">*</span>
                </label>
                <div className="flex h-[38px]">
                  <Select
                    options={[]}
                    placeholder={"+91"}
                    className="w-[100px]"
                  />
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    className=""
                  />
                </div>
              </div>

              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Email <span className="text-red-700">*</span>
                  <input type="text" placeholder="Enter your fullname" />
                </label>
              </div>

              <div className="">
                <label className="text-sm font-medium text-black">
                  Company Phone Number <span className="text-red-700">*</span>
                </label>
                <div className="flex h-[38px]">
                  <Select
                    options={[]}
                    placeholder={"+91"}
                    className="w-[100px]"
                  />
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    className=""
                  />
                </div>
              </div>

              <div>
                <label className="text-sm font-medium text-black w-[220px]">
                  Company Email <span className="text-red-700">*</span>
                  <input type="text" placeholder="Enter your fullname" />
                </label>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  className="btn btn--border text-sm font-medium"
                  onClick={() => onCloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </div>
  );
};

export default NewSeperationModal;
