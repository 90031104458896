import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  bloodGroup as bloodGroupOptions,
  residentialStatusOptions,
} from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import formatDate from "../../../utils/FormatDate/formatDate";
import { toast } from "react-toastify";
import { getFormErrMsg } from "../../../utils/helperFunctions";
import {
  calculateAge,
  validateDateOfBirth,
  validateMinDate,
} from "../../../utils/FormatDate/formatEmployeeBirthday";
import { nameRegex, cannotStartWithSpace } from "../../../utils/regex/regex";
import {
  useGetCountryList,
  useUpdatePersonalDetails,
} from "../../../queries/employeeQueries";

const EditPersonalInformationModal = ({
  openModal,
  onCloseModal,
  employee,
}) => {
  const { data: countryList } = useGetCountryList();
  const [isPhysicallyChallenged, setIsPhysicallyChallenged] = useState(
    !!employee?.phycically_challenged
  );
  const { mutate: updatePersonalDetails, isPending: isUpdating } =
    useUpdatePersonalDetails();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      data_of_birth: employee?.data_of_birth
        ? formatDate(employee.data_of_birth).date
        : "",
      father_name: employee?.father_name || "",
      marriage_date: employee?.marriage_date
        ? formatDate(employee?.marriage_date).date
        : "",
      spouse_name: employee?.spouse_name || "",
      nationality: employee?.nationality || "",
      residential_status: employee?.residential_status || "",
      blood_group: employee?.blood_group || "",
      martial_status: employee?.martial_status || "",
      disability_type: employee?.disability_type || "",
      phycically_challenged: !!employee?.phycically_challenged,
    },
  });
  const employeeId = employee?.employee_id;

  const [maritalStatusNotMarried, setMaritalStatus] = useState(
    !!!getValues("martial_status")
  );

  const onSubmit = (data) => {
    const reqObj = {};
    const dataKeys = Object.keys(data);
    dataKeys.forEach((item) => {
      const getValue = data[item];
      if (getValue || typeof getValue === "boolean") {
        reqObj[item] = getValue;
      }
    });

    updatePersonalDetails(
      { ...reqObj, employeeId: employee?.employee_id },
      {
        onSuccess: (res) => {
          if (res?.status) {
            toast.success(res.message);
            onCloseModal();
          } else {
            toast.info("Failed to update personal details");
          }
        },
        onError: () => {
          toast.error("Failed to update personal details");
        },
      }
    );
  };

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 max-w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              Edit Personal Informations
            </h2>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Nationality
                  </label>
                  <Controller
                    name="nationality"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className=""
                          placeholder={
                            countryList?.find((i) => i?.id === Number(value))
                              ?.flag || "Select"
                          }
                          options={countryList}
                          getOptionLabel={(option) => option.flag}
                          getOptionValue={(option) => option.id}
                          onChange={(e) => onChange(Number(e.id))}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("nationality", errors)}
                </div>
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Residential Status
                  </label>
                  <Controller
                    name="residential_status"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          placeholder={
                            residentialStatusOptions?.find(
                              (i) => i?.value === value
                            )?.label || "Select"
                          }
                          className=""
                          options={residentialStatusOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => onChange(e.value)}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("residential_status", errors)}
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black">
                    Date Of Birth
                  </label>
                  <input
                    {...register("data_of_birth", {
                      validate: { validateDateOfBirth, validateMinDate },
                    })}
                    type="date"
                    className=""
                    max={formatDate(new Date()).date} // Set max date to today
                    // min = "1900-01-01"
                  />
                  {getFormErrMsg("data_of_birth", errors)}
                </div>
                <div className="w-full">
                  <label className="text-sm font-medium text-black">
                    Father Name
                  </label>
                  <input
                    {...register("father_name", {
                      pattern: {
                        value: nameRegex,
                        message:
                          "Numbers , Special Characters and Space are not allowed!",
                      },
                    })}
                    type="text"
                    className=""
                  />
                  {getFormErrMsg("father_name", errors)}
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Blood Group
                  </label>
                  <Controller
                    name="blood_group"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          placeholder={
                            bloodGroupOptions?.find((i) => i?.value === value)
                              ?.label || ""
                          }
                          className=""
                          options={bloodGroupOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => onChange(e.value)}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("blood_group", errors)}
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Martial Status
                  </label>
                  <Controller
                    name="martial_status"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          placeholder={
                            [
                              { label: "Single", value: "Single" },
                              { label: "Married", value: "Married" },
                            ]?.find((i) => i?.value === value)?.label || ""
                          }
                          className=""
                          options={[
                            { label: "Single", value: "Single" },
                            { label: "Married", value: "Married" },
                          ]}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            onChange(e.value);
                            setMaritalStatus(!(e.value === "Married"));
                          }}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("martial_status", errors)}
                </div>
                <div className="w-full">
                  <label className="text-sm font-medium text-black mr-4">
                    Marriage Date
                  </label>

                  <input
                    disabled={maritalStatusNotMarried}
                    {...register("marriage_date", {
                      validate: validateMinDate,
                    })}
                    type="date"
                    className=""
                  />
                  {getFormErrMsg("marriage_date", errors)}
                </div>
              </div>

              <div className="flex justify-between gap-5">
                <div className="w-full">
                  <label className="text-sm font-medium text-black">
                    Physically Challenged{" "}
                  </label>
                  <Controller
                    name="phycically_challenged"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          placeholder={
                            [
                              { label: "No", value: false },
                              { label: "Yes", value: true },
                            ]?.find((i) => i?.value === value)?.label || ""
                          }
                          className=""
                          options={[
                            { label: "No", value: false },
                            { label: "Yes", value: true },
                          ]}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            onChange(e.value);
                            setIsPhysicallyChallenged(e.value);
                          }}
                        />
                      );
                    }}
                  />
                  {getFormErrMsg("phycically_challenged", errors)}
                </div>
              </div>

              {/* Conditionally Render Disability Type Field */}
              {isPhysicallyChallenged && (
                <div className="w-full">
                  <label className="text-sm font-medium text-black">
                    Disability Type
                  </label>
                  <input
                    {...register("disability_type", {
                      pattern: {
                        value: cannotStartWithSpace,
                        message: "Disability type cannot start with a space.",
                      },
                    })}
                    type="text"
                    className=""
                    placeholder="Enter disability type"
                  />
                  {getFormErrMsg("disability_type", errors)}
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditPersonalInformationModal;
