import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";

const {
  getSystemPolicyList,
  createSystemPolicyList,
  getSystemPolicyAttachments,
  getSystemPolicyListForSelectBox,
  updateSystemPolicyList,
  systemPolicyApproval,
  updateSystemPolicyStatus,
  getEmployeeViewListSystemPolicy,
  updateEmployeeSystemPolicyViewStatus,
} = queryKeys;

export const useGetSystemPolicyList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-list",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useGetSystemPolicyListForSelectBox = (params = {}, enabled) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyListForSelectBox],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-list",
        {
          params: {
            ...formattedParams,
            selectbox: 1,
          },
        }
      );
      return data;
    },
    enabled,
  });
};

export const useGetSystemPolicyAttachments = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyAttachments],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-attachments",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: !!formattedParams?.system_policy,
  });
};

export const useCreateSystemPolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [createSystemPolicyList],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/create-policy",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useUpdateSystemPolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateSystemPolicyList],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-policy",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useSystemPolicyApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [systemPolicyApproval],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/policy-approval",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useUpdateSystemPolicyStatus = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateSystemPolicyStatus],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-policy-status",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetEmployeeSystemPolicyViewList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeViewListSystemPolicy, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/employee-policy-view",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useUpdateEmployeeSystemPolicyViewStatus = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateEmployeeSystemPolicyViewStatus],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-employee-view-status",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeViewListSystemPolicy]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
