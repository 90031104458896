import React, { useEffect, useState } from "react";
import BigCalendar from "../../../../components/BigCalendar/BigCalendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { useEmployeeShiftList, useShiftList, useDownloadMyShift } from "../../../../queries/attendanceQuries"; 
import PageLoader from "../../../../components/PageLoader";

const MyShifts = () => {
  const user = useSelector((state) => state.user);
  const employeeId = user?.userProfile?.id;

  const currentDate = moment();
  const startOfMonth = moment().startOf("month");
  const endOfMonth = moment().endOf("month");
  const startDate = startOfMonth.clone().subtract(7, "days").format("YYYY-MM-DD");
  const endDate = endOfMonth.clone().add(7, "days").format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    startDate,
    endDate,
  });

 

  const { data: employeeShiftListData, isLoading } = useEmployeeShiftList({
    fromDate: dateRange.startDate,
    toDate: dateRange.endDate,
    employeeId,

  });


  const { data: shiftListData, } = useShiftList();

  const [datesCol, setDatesCol] = useState([]);
  const [calendarData, setCalendarData] = useState([]);


useEffect(() => {
  const daysInMonth =
    moment(dateRange.endDate).diff(moment(dateRange.startDate), "days") + 1;
  let datesArr = [];
  for (let i = 0; i < daysInMonth; i++) {
    const date = moment(dateRange.startDate)
      .add(i, "day")
      .format("YYYY-MM-DD");
    datesArr.push(date);
  }
  setDatesCol(datesArr);
}, [dateRange]);

const handleChangeDate = (e) => {
  const { start, end } = e;
  if (start && end) {
    setDateRange({
      startDate: moment(start).startOf(start).format("YYYY-MM-DD"), 
      endDate: moment(end).endOf(end).format("YYYY-MM-DD"), 
    });
  }
};


useEffect(() => {
  const formatData = [];
  datesCol.forEach((date) => {
    const shift = Array.isArray(employeeShiftListData?.result?.[0]?.shiftDetails) &&
      employeeShiftListData?.result?.[0]?.shiftDetails?.find(
        (i) => moment(i.date).format("YYYY-MM-DD") === date
      );

    if (shift) {
      const shiftName = shift?.shift_name || "";
      const color = shift?.color_code || "";
      const isWorkingDay = shift?.isWorkingDay;
      if (isWorkingDay) {
        const shiftTimeFrom = formatDate(`${date}T${shift?.time_from}`)?.localString2;
        const shiftTimeTo = formatDate(`${date}T${shift?.time_to}`)?.localString2;

        formatData.push({
          shiftName,
          color,
          title: (
            <div>
              <p>{shiftName}</p>
              <p className="text-xs">
                {shiftTimeFrom} to {shiftTimeTo}
              </p>
            </div>
          ),
          start: new Date(`${date}T${shift?.time_from}`),
          end: new Date(`${date}T${shift?.time_to}`),
          titleColor: "",
          backGroundColor: color,
          borderColor: color,
        });
      }
    }
  });
  setCalendarData(formatData);
}, [employeeShiftListData, datesCol]);

 
 
  const {mutateAsync, isPending} = useDownloadMyShift()
  const handleDownload = async () => {
 
  
    try {
      const response = await   mutateAsync({
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
        employeeId
      });
      if (response.data) {
        const csvData = response.data; 
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `employee_shift_data_${Date.now()}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("File downloaded");
      } else {
        toast.error("Error while downloading the file!");
      }
    } catch (error) {
     const errorMessage =
        error?.response?.status === 400
          ? "No shift data found for the selected employee!"
          : error.message || "Internal server error";
      toast.error(errorMessage);
    } 
  };

  

  return (
    <div className="px-10 w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-black font-medium text-lg">My Shifts</h1>
        <button className="btn btn--border" onClick={handleDownload}>
          <i className="ico-download mr-2"></i>Download
        </button>
      </div>
      <div id="calendar-div">
       
          <BigCalendar
          myEventsList={calendarData}
          handleChangeDate={handleChangeDate}
        />
        
      
      </div>
    </div>
  );
};

export default MyShifts;
