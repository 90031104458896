import React from "react";


const MiniShiftOverviewBar = ({ shiftList }) => {
  return (
    <div className="px-10 py-3 border flex gap-4">
      {shiftList &&
        shiftList.map((item, index) => {
          const { shift } = item;
          const colorCode = shift?.color_code;

          return (
            <div className={`flex`} key={`${index + 2}_mini_shift_overview_bar_item`}>
              <span
                style={{ backgroundColor: colorCode }}
                className={`w-[2px] p-[2px]`}
              ></span>
              <div className="ml-2">
                <p className="font-medium text-black">{shift?.shift_name}</p>
                <p>{"09:00 am - 05:00 pm"}</p>{" "}
                {/* change this hard coded time to shift time while populating emp data */}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MiniShiftOverviewBar;
