// moment
import moment from "moment"

export const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    return age;
  };
  
  export const validateDateOfBirth = (value) => {
    const age = calculateAge(value);
    if (age < 18) {
      return "Employee must be at least 18 years old.";
    }
    return true; 
  };

  export const validateMinDate = (value) => {
    const year = moment(value).year()
    if(year < 1850){
      return "Please choose a valid date. The minimum date allowed is 01-01-1850"
    }
    return true;
  }