import React, { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon, closeIconRoundRed } from "../../Icons/Icons";
import { FaPlus } from "react-icons/fa";
import { useForm, Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { convertFileSize, getFormErrMsg } from "../../utils/helperFunctions";
import Select from "react-select";
import { Alert, Progress } from "antd";
import {
  useCreateSystemPolicy,
  useGetSystemPolicyListForSelectBox,
  useUpdateSystemPolicy,
} from "../../queries/systemPolicyQueries";
import LoadingButton from "../Buttons/LoadingButton";
import { Link } from "react-router-dom";

const SerialNumberNotExistsMessage = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium">
        Info: Policy serial number hasn't been set yet.
        <br /> To proceed, please follow these steps: <br />
      </div>
      <ol>
        <li>1. Go to tenant settings.</li>
        <li>2. Click on Add Details.</li>
        <li>
          3. Enter the Prefix, Serial starts from, Suffix and choose "policy" as
          type from the select box.
        </li>
        <li>4. Click on the "+" button to add the data.</li>
        <li>
          5. Click on the Add Tenant Details button to save the information to
          the system.
        </li>
      </ol>
      <div className="font-medium">
        <Link
          to={"/settings/tenant-setting"}
          className="text-blue-600 cursor-pointer"
        >
          Click
        </Link>{" "}
        here to go to the page and set the serial key for policy.
      </div>
    </div>
  );
};

const CreateOrEditPolicy = ({
  isModalOpen,
  handleCloseModal,
  systemPolicy = {},
}) => {
  const isEditPolicy = !!systemPolicy?.id;
  const { data: getSystemPolicyListData } = useGetSystemPolicyListForSelectBox(
    {},
    !isEditPolicy
  );
  const systemPolicyListData = Array.isArray(getSystemPolicyListData?.data)
    ? getSystemPolicyListData.data
    : [];

  const {
    mutate: mutateCreateSystemPolicy,
    isPending: isPendingCreateSystemPolicy,
  } = useCreateSystemPolicy();
  const {
    mutate: mutateUpdateSystemPolicy,
    isPending: isPendingUpdateSystemPolicy,
  } = useUpdateSystemPolicy();

  const isPending = isPendingCreateSystemPolicy || isPendingUpdateSystemPolicy;

  const [errMsg, setErrMsg] = useState("");
  const [showPolicySerialErrInfo, setShowPolicySerialErrInfo] = useState(false);
  const policyAttachmentFileRef = useRef();
  const [policyAttachments, setPolicyAttachments] = useState([]);
  const maximumFileUploadSizeBytes = 5242880; // 5mb;
  const formattedMaximumFileUploadSize = convertFileSize(
    maximumFileUploadSizeBytes
  );
  const uploadedFileSize = policyAttachments.reduce((prev, curr) => {
    if (curr.sizeInBytes) {
      return prev + curr.sizeInBytes;
    }
    return prev;
  }, 0);
  const formatterUploadFileSize = convertFileSize(uploadedFileSize);
  const fileUploadPercentage =
    (uploadedFileSize / maximumFileUploadSizeBytes) * 100;
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [
    isPendingApplicationExistsForVersionChange,
    setIsPendingApplicationExistsForVersionChange,
  ] = useState(false);
  const [
    isUpdatedVersionOfExistingPolicy,
    setIsUpdatedVersionOfExistingPolicy,
  ] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onBlur", defaultValues: systemPolicy });

  useEffect(() => {
    if (Array.isArray(systemPolicy?.attachments)) {
      const exisitingAttachments = systemPolicy.attachments.map((i) => {
        const type = i.file_name.split(".").pop();
        return {
          type,
          name: i.file_name,
          id: i.id,
          size: null,
          src: i.src,
          file: null,
        };
      });
      setPolicyAttachments(exisitingAttachments);
    }
  }, [systemPolicy]);

  const createPolicy = (formData) => {
    setShowPolicySerialErrInfo(false);
    mutateCreateSystemPolicy(formData, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data.message);
          handleCloseModal();
        }
      },
      onError: (error) => {
        const errResponse = error?.response?.data;

        const responseMsg =
          error?.data?.message ||
          errResponse?.message ||
          "Failed to create policy. Please try again later.";
        setErrMsg(responseMsg);
        toast.error(responseMsg);
        if (errResponse?.error_code === "PENDING_APPLICATION_ALREADY_EXISTS") {
          setIsPendingApplicationExistsForVersionChange(true);
        }
        if (
          errResponse?.message &&
          errResponse?.message?.includes("Serial number")
        ) {
          setShowPolicySerialErrInfo(true);
        }
      },
    });
  };

  const updatePolicy = (formData) => {
    mutateUpdateSystemPolicy(formData, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data.message);
          handleCloseModal();
        }
      },
      onError: (error) => {
        const responseMsg =
          error?.data?.message ||
          error?.response?.data?.message ||
          "Failed to update policy. Please try again later.";
        setErrMsg(responseMsg);
        toast.error(responseMsg);
      },
    });
  };

  const onSubmit = async (data) => {
    if (!policyAttachments.length) {
      toast.info(
        "Please select the necessary attachments for the policy. Only PDF and image files are allowed."
      );
      return;
    }
    const {
      policy_name,
      description,
      is_updated_version_of_previous_policy,
      previous_policy_id,
    } = data;

    // handleCloseModal();
    const formData = new FormData();

    policyAttachments.forEach((i) => {
      if (i.file) {
        formData.append("attachments", i.file);
      }
    });
    formData.append("policy_name", policy_name);
    formData.append("description", description);
    formData.append("file_count", policyAttachments.length);

    formData.append(
      "is_updated_version_of_previous_policy",
      is_updated_version_of_previous_policy ? "true" : "false"
    );
    if (is_updated_version_of_previous_policy) {
      formData.append("previous_policy_id", previous_policy_id);
    }
    if (isPendingApplicationExistsForVersionChange) {
      formData.append("update_existing_pending_application", true);
    }
    if (isEditPolicy) {
      formData.append("id", systemPolicy.id);
      formData.append("attachments_to_delete", attachmentsToDelete.join(",")); //
      updatePolicy(formData);
    } else {
      createPolicy(formData);
    }
  };

  const handleAttachmentUpload = (e) => {
    const { files = [] } = e?.target;
    // File validation is required -> size type etc...
    const file = files?.[0] || null;
    if (!file) {
      return; // no file is founded
    }
    const { type, size, name } = file;
    if (uploadedFileSize + size >= maximumFileUploadSizeBytes) {
      toast.info(
        "The uploaded file size exceeds the allowed limit. Please upload a smaller file."
      );
    } else {
      setPolicyAttachments((prev) => [
        ...prev,
        {
          type,
          name,
          file,
          id: uuidv4(),
          size: convertFileSize(size),
          sizeInBytes: size,
        },
      ]);
    }

    if (policyAttachmentFileRef.current) {
      policyAttachmentFileRef.current.value = "";
    }
  };

  const removeAttachment = (id) => {
    if (Number.isInteger(Number(id))) {
      setAttachmentsToDelete((prev) => [...prev, id]);
    }
    setPolicyAttachments((prev) => prev.filter((i) => i.id !== id));
  };

  return (
    <Modal
      open={isModalOpen}
      closeOnEsc={false}
      onClose={handleCloseModal}
      showCloseIcon={false}
      center
      closeOnOverlayClick={false}
    >
      <div className="w-[500px] flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">
            {isEditPolicy ? "Edit" : "Create"} policy
          </h1>
          <button onClick={handleCloseModal}>{closeIcon}</button>
        </div>
        <span className="text-red-600 font-medium">{errMsg}</span>
        {showPolicySerialErrInfo && (
          <Alert showIcon message={<SerialNumberNotExistsMessage />} />
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <div>
            <label>Policy name</label>
            <input
              {...register("policy_name", {
                required: "Title is required",
                pattern: {
                  value: /^\S.*/,
                  message: "Cannot start with space",
                },
              })}
              type="text"
            />
            {getFormErrMsg("policy_name", errors)}
          </div>
          <div>
            <label>Description</label>
            <textarea
              {...register("description", {
                required: "Description is required",
                pattern: {
                  value: /^\S.*/,
                  message: "Cannot start with space",
                },
              })}
            ></textarea>
            {getFormErrMsg("description", errors)}
          </div>
          {!isEditPolicy && (
            <div className="flex items-center gap-2">
              <label>
                Is this policy an updated version of any existing policy?
              </label>
              <input
                {...register("is_updated_version_of_previous_policy", {
                  onChange: (e) =>
                    setIsUpdatedVersionOfExistingPolicy(e.target.checked),
                })}
                checked={isUpdatedVersionOfExistingPolicy}
                type="checkbox"
              />
            </div>
          )}
          {isUpdatedVersionOfExistingPolicy && (
            <Alert
              showIcon
              type="info"
              message="Please note: Once this new policy is approved by the Admin, the existing policy version will be deprecated and replaced. The new policy will then be visible to the employees."
            />
          )}
          {isUpdatedVersionOfExistingPolicy && (
            <div className="flex flex-col">
              <label>Select policy</label>
              <Controller
                name="previous_policy_id"
                control={control}
                rules={{
                  required:
                    "To update the existing policy, please select the previous policy version.",
                }}
                render={({ field }) => (
                  <Select
                    options={systemPolicyListData.map((i) => ({
                      label: i.policy_name,
                      value: i.id,
                    }))}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
              {getFormErrMsg("previous_policy_id", errors)}
            </div>
          )}

          {!!policyAttachments.length && !!uploadedFileSize && (
            <>
              <span
                className={`text-xs ${
                  formatterUploadFileSize === formattedMaximumFileUploadSize
                    ? "text-red-600"
                    : ""
                }`}
              >
                {formatterUploadFileSize} / {formattedMaximumFileUploadSize} (
                {fileUploadPercentage.toFixed(2)}% used)
              </span>
              <Progress
                percent={fileUploadPercentage}
                showInfo={false}
                strokeColor={
                  fileUploadPercentage <= 25
                    ? "#4CAF50"
                    : fileUploadPercentage <= 50
                    ? "#FFD700"
                    : fileUploadPercentage <= 70
                    ? "#FFA500"
                    : "#FF4C4C"
                }
              />
            </>
          )}

          <div>
            <label>Attachments</label>
            <div className="flex flex-wrap gap-3 mt-2">
              {policyAttachments.map((i, index) => (
                <div
                  className="flex w-[50px] flex-col gap-1"
                  key={`${i.id}_${index}`}
                >
                  <div className="w-[50px]  h-[63px]  border relative rounded-md">
                    <button
                      onClick={() => removeAttachment(i.id)}
                      className="absolute left-[38px] bottom-[50px]"
                    >
                      {closeIconRoundRed}
                    </button>

                    <img
                      className="object-cover cursor-pointer"
                      src={
                        i?.type?.match("pdf")
                          ? "/images/pdf-thumbnail.svg"
                          : "/images/jpg-thumbnail.svg"
                      }
                    />
                  </div>
                  <marquee scrollamount={3}>
                    {i.name} {i.size && `(${i.size})`}
                  </marquee>
                </div>
              ))}
              <label
                htmlFor="policy-attatchment"
                className="w-[50px] cursor-pointer h-[63px]  border flex justify-center items-center rounded-md"
              >
                {<FaPlus />}
                <input
                  ref={policyAttachmentFileRef}
                  id="policy-attatchment"
                  type="file"
                  hidden
                  accept=".pdf, image/*"
                  onChange={handleAttachmentUpload}
                />
              </label>
            </div>
          </div>
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={handleCloseModal}
              className="btn btn--border"
            >
              Cancel
            </button>
            {isPending ? (
              <LoadingButton
                title={
                  isEditPolicy || isPendingApplicationExistsForVersionChange
                    ? "Updating..."
                    : "Creating..."
                }
              />
            ) : (
              <input
                className="btn"
                type="submit"
                value={
                  isEditPolicy
                    ? "Update"
                    : isPendingApplicationExistsForVersionChange
                    ? "Update existing application"
                    : "Create"
                }
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateOrEditPolicy;
