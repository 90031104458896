import React from "react";
import Select, { components } from "react-select";
import Search from "../../Header/Search/Search";
import EmployeeAssetList from "../AssetList/EmployeeAssetList";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const Assets = () => {
  return (
    <div className="w-full">
      <div className="px-10 py-6">
        <h2>My Assets</h2>
        <p>Manage assets</p>
      </div>
      <div className="flex justify-between items-center px-10 pb-4">
        <div className="flex gap-3">
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "27 Apr 2022", label: "27 Apr 2022" },
              { value: "27 Apr 2022", label: "27 Apr 2022" },
              { value: "27 Apr 2022", label: "27 Apr 2022" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Assigned Date: All",
              label: "Assigned Date: All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "27 Apr 2022", label: "27 Apr 2022" },
              { value: "27 Apr 2022", label: "27 Apr 2022" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Return Date: All",
              label: "Return Date: All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              {
                value: "IV Main Office India",
                label: "IV Main Office India"
              },
              { value: "IV Main Office USA", label: "IV Main Office USA" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Office: All",
              label: "Office: All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "9am-5pm", label: "9am-5pm" },
              { value: "9am-5pm", label: "9am-5pm" },
              { value: "9am-5pm", label: "9am-5pm" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Shifts:All",
              label: "Shifts:All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "Assigned", label: "Assigned" },
              { value: "Returned", label: "Returned" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Status:All",
              label: "Status:All"
            }}
          />
        </div>
        <div className="flex">
          <div>
            <Search />
          </div>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <div>
        <EmployeeAssetList />
      </div>
    </div>
  );
};

export default Assets;
