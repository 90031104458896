import React from "react";

const CardListView = ({ value }) => {
  const { data, loaderComponent, cardTitle } = value;
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex flex-col">
          <label className="text-gray-800 text-left">{cardTitle}</label>
          {loaderComponent ? (
            <div className="flex justify-center items-center w-full h-[200px]">
              {loaderComponent}
            </div>
          ) : (
            <div className="">
              {data.map((val, index) => (
                <div key={`card_list_view_data_map_${index}`} className="">
                  <li className="text-gray-800">{val.title}</li>
                  {val.description ? (
                    <span className="text-xs ml-5">{val.description}</span>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex justify-center items-center w-full">
        <button className="text-blue-600 font-medium">Add details</button>
      </div> */}
    </div>
  );
};

export default CardListView;
