import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../../Icons/Icons";
import { useSystemPolicyApproval } from "../../../../queries/systemPolicyQueries";
import { toast } from "react-toastify";
import LoadingButton from "../../../Buttons/LoadingButton";

const DeclinePolicy = ({
  isModalOpen,
  handleCloseModal,
  systemPolicy = {},
}) => {
  const { policy_name, id: system_policy_id } = systemPolicy;
  const { mutate: systemPolicyApprovalMutate, isPending } =
    useSystemPolicyApproval();
  const [remark, setRemark] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const declineSystemPolicy = async () => {
    if (!remark.trim()) {
      setErrMsg(
        "Decline remark is required. Please provide your comments before submitting."
      );
      return;
    }
    setErrMsg("");
    const payload = {
      approval_remark: remark,
      approval_status: 3, //decline 3, approve 1
      system_policy_id,
    };
    systemPolicyApprovalMutate(payload, {
      onSuccess: (response) => {
        if (response?.status) {
          toast.success(response.message);
          handleCloseModal();
        }
      },
      onError: (error) => {
        const response = error?.response?.data;
        setErrMsg(response?.message || "Failed to decline system policy");
      },
    });
  };
  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      center
      showCloseIcon={false}
    >
      <div className="flex flex-col w-[400px] gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">Decline policy</h1>
          <button onClick={handleCloseModal}>{closeIcon}</button>
        </div>

        <h3>
          Are you sure you want to decline the{" "}
          <span className="font-medium text-gray-800">{policy_name}</span>?
        </h3>
        <span className="text-red-600 font-medium">{errMsg}</span>
        <div>
          <label>Remark</label>
          <textarea
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="mt-1"
          ></textarea>
        </div>
        <div className="flex items-center justify-end gap-3">
          <button onClick={handleCloseModal} className="btn btn--border">
            Cancel
          </button>
          {isPending ? (
            <LoadingButton title="Declining..." bg="btn--red"/>
          ) : (
            <button onClick={declineSystemPolicy} className="btn btn--red">
              Decline
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DeclinePolicy;
