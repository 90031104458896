import React from "react";

const hoursData = [
  {
    title: "Payable Hours",
    hours: "160 hours",
    color: "green-600",
  },
  {
    title: "Present Hours",
    hours: "160 hours",
    color: "green-600",
  },
  {
    title: "Paid Leave",
    hours: "160 hours",
    color: "purple-600",
  },
  {
    title: "Paid Hours",
    hours: "160 hours",
    color: "orange-600",
  },
  {
    title: "Overtime",
    hours: "160 hours",
    color: "green-600",
  },
  {
    title: "Deviation",
    hours: "160 hours",
    color: "orange-600",
  },
  {
    title: "Holidays",
    hours: "160 hours",
    color: "green-600",
  },
];

const AttendanceOverviewHoursView = () => {
  return (
    <div className="px-5 text-black py-3 ">
      <div>
        <h1 className="py-1 font-medium text-base">
          Total Hours / Work Schedule
        </h1>
        <div className="h-9 flex bg-gray-100 mb-2 rounded-md">
          <div
            className={`h-9  bg-gray-200 rounded-md flex items-center`}
            style={{ width: 85 + "%" }}
          >
            {" "}
            <span className="mx-3 font-medium"> 196/ 240h</span>
          </div>
        </div>
      </div>

      <div className="flex justify-between flex-wrap gap-4">
        {hoursData.map((item, index) => {
          return (
            <div key={index} className="rounded-md  border flex flex-col w-[178px] min-h-[76px] p-4 gap-1">
              <div
                className={`h-1  bg-${item.color} rounded-md flex items-center`}
                style={{ width: 45 + "%" }}
              ></div>
              <p>{item.title}</p>
              <span className="font-medium">{item.hours}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttendanceOverviewHoursView;
