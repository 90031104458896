import React from "react";
import "./Header.scss";
import Notification from "./Notification";
import Search from "./Search";
import {Link} from "react-router-dom"

const Header = ({openSlide,showMenu,setShowMenu}) => {
  return (
    <header className="bg-white py-1 px-3 border-b border-gray-200 sticky top-0 z-50">
      <div className="flex items-center">
        <h1>
          <Link to="/dashboard"><img src="/images/ivshr_logo.svg" className="w-[150px]" alt="" /></Link>
        </h1>
        {/* <div className="hidden md:block">
          <Search /> // Need to work on this later
        </div> */}
        <div className="ml-auto">
          <Notification openSlide={openSlide} showMenu={showMenu} setShowMenu={setShowMenu} />
        </div>
      </div>
    </header>
  );
};

export default Header;
