import React, { useState, useEffect } from "react";
import AddAutoIncrementLeaveBalance from "./AddAutoIncrementLeaveBalance";
import { isFinalIncrementPeriodOfMonth, formatHourAndMinute, getFormattedFullDate } from "../../../../utils/FormatDate/formaDateInMonths"


const AutoIncrementLeaveDetails = ({ details, months, leaveTypeId, refreshDetails, leaveTypeName }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);



  useEffect(() => {
    refreshDetails();
  }, [selectedMonth]);

  const openModal = (index) => {
    setSelectedMonth(index);
  };

  const closeModal = () => {
    setSelectedMonth(null);
    refreshDetails();
  };


  return (
    <>
      {months.map((month, index) => {
        const monthDetails = details.find(
          (detail) => detail.updated_month === index + 1
        );
        const isCurrentMonth = index === new Date().getMonth();
        const isIncremented = monthDetails && monthDetails.increment_value;
        const formattedTime = monthDetails
        ? formatHourAndMinute(monthDetails.created_date)
        : "-";
        const formattedDate = monthDetails
          ? getFormattedFullDate(monthDetails.created_date)
          : "-";

        return (
          <tr key={index} className="hover:bg-gray-50">
            <td className="px-6 py-3">{month}</td>
            <td className="px-6 py-3">{new Date().getFullYear()}</td>
            <td className="px-6 py-3">{formattedDate}</td>
            <td className="px-6 py-3">{formattedTime}</td>
            <td className="px-6 py-3">{monthDetails ? monthDetails.updated_type : "-"}</td>
            <td className="px-6 py-3">
              <span className={isIncremented ? "status status--green" : "status status--red"}>
                {isIncremented ? "Incremented" : "Non-incremented"}
              </span>
            </td>
            <td className="px-6 py-3 text-right">
              {isCurrentMonth && !isIncremented && ( 
              <button
              className={`btn px-4 py-2 rounded text-white ${(!isFinalIncrementPeriodOfMonth() || isIncremented) 
                ? "bg-gray-300 cursor-not-allowed opacity-50" 
                : "bg-blue-500 hover:bg-blue-600 cursor-pointer"}`}
              onClick={() => openModal(index)}
              disabled={!isFinalIncrementPeriodOfMonth() || isIncremented}
            >
              <p>Manual Increment</p>
            </button>
              )}
            </td>
          </tr>
        );
      })}
      {selectedMonth !== null && (
        <AddAutoIncrementLeaveBalance
          open={selectedMonth !== null}
          close={closeModal}
          leaveTypeId={leaveTypeId}
          updatedMonth={selectedMonth + 1}
          refreshDetails={refreshDetails}
          leaveTypeName={leaveTypeName}
        />
      )}
    </>
  );
};

export default AutoIncrementLeaveDetails;
