import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import {
  useApplyAdditionalLeave,
  useGetListConditionalLeaveType,
} from "../../queries/leaveQueries";
import SpinnerLoader from "../PageLoader/SpinnerLoader";
import { Alert } from "antd";
import LoadingButton from "../Buttons/LoadingButton";
import { useSelector } from "react-redux";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const ApplyAdditionalLeaveModal = ({ open, close }) => {
  const [errMsg, setErrMsg] = useState("");

  const user = useSelector((state) => state.user);
  const [managerDetails] = user?.managerDetails;
  const [hrDetails] = user?.hrDetails;
  const [data, setData] = useState({
    leave_type_id: null,
    applied_for_date: null,
  });

  useEffect(() => {
    if (!managerDetails || !hrDetails) {
      let message =
        "It seems like the line manager isn't assigned to you. Please contact HR for assistance.";
      if (!hrDetails) {
        message =
          "It seems like the HR manager isn't assigned to you. Please contact the HR department for assistance.";
      }
      setErrMsg(message);
    }
  }, [user]);

  const { data: getListConditionalLeaveTypeData, isLoading } =
    useGetListConditionalLeaveType({});
  const conditionalLeave = Array.isArray(getListConditionalLeaveTypeData?.data)
    ? getListConditionalLeaveTypeData?.data
    : [];

  const { mutate: mutateApplyAdditionalLeave, isPending } =
    useApplyAdditionalLeave();

  const handleSubmit = () => {
    setErrMsg("");
    if (!managerDetails || !hrDetails) {
      let message =
        "It seems like the line manager isn't assigned to you. Please contact HR for assistance.";
      if (!hrDetails) {
        message =
          "It seems like the HR manager isn't assigned to you. Please contact the HR department for assistance.";
      }
      toast.info(message);
      setErrMsg(message);
      return;
    }
    if (!data.applied_for_date || !data.leave_type_id) {
      setErrMsg("Fill the required fields");
      return;
    }
    mutateApplyAdditionalLeave(data, {
      onSuccess: (res) => {
        if (res?.status) {
          toast.success(res.message);
          close();
        } else {
          toast.error(res.message || "Failed to apply additional leave");
        }
      },
      onError: (err) => {
        toast.error(
          err?.response?.data.message || "Something went wrong, try again"
        );
      },
    });
  };

  return (
    <>
      <Modal open={open} onClose={close} center>
        <div className="p-5 min-w-[450px] text-black flex flex-col gap-3">
          <h1 className="text-lg font font-medium border-b border-gray-300 pb-4">
            Request additional leave
          </h1>
          <span className="text-red-600">{errMsg}</span>
          {isLoading ? (
            <div className="flex justify-center">
              <SpinnerLoader />
            </div>
          ) : !!conditionalLeave.length ? (
            <>
              <div>
                <label>
                  Select leave type<span className="text-red-600">*</span>
                </label>
                <Select
                  options={
                    conditionalLeave.length &&
                    conditionalLeave.map((i) => ({
                      label: i?.leave_name,
                      value: i?.id,
                    }))
                  }
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      leave_type_id: Number(e.value),
                    }))
                  }
                  components={{ DropdownIndicator }}
                />
              </div>
              <div>
                <label>
                  Requested for date<span className="text-red-600">*</span>
                </label>
                <input
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      applied_for_date: e.target.value,
                    }))
                  }
                />
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message="There are no leave types eligible for additional leave requests."
              showIcon
            />
          )}

          <div className="flex justify-end gap-3">
            <button type="button" onClick={close} className="btn btn--border">
              Close
            </button>
            {!!conditionalLeave.length && (
              <>
                {isLoading ? (
                  <LoadingButton title="Applying leave" />
                ) : (
                  <button type="button" onClick={handleSubmit} className="btn">
                    Apply leave
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplyAdditionalLeaveModal;
