import React from "react";
import "./AttendanceCalendar.scss";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "react-big-calendar/lib/css/react-big-calendar.css";
import enUS from "date-fns/locale/en-US";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

const myEventsList = [
  { start: new Date(), end: new Date(), title: "Present" },
  {
    start: new Date("2022-09-05"),
    end: new Date("2022-09-06"),
    title: "Special Leave",
  },
  {
    start: new Date("2022-09-07"),
    end: new Date("2022-09-07"),
    title: "Present",
  },
  {
    start: new Date("2022-09-08"),
    end: new Date("2022-09-08"),
    title: "Present",
  },
  {
    start: new Date("2022-09-09"),
    end: new Date("2022-09-09"),
    title: "Present",
  },
  {
    start: new Date("2022-07-11"),
    end: new Date("2022-07-11"),
    title: "Present",
  },
  {
    start: new Date("2022-07-12"),
    end: new Date("2022-07-12"),
    title: "Present",
  },
  {
    start: new Date("2022-07-13"),
    end: new Date("2022-07-13"),
    title: "Present",
  },
  {
    start: new Date("2022-09-23"),
    end: new Date("2022-09-23"),
    title: "Absent",
  },
  {
    start: new Date("2022-07-26"),
    end: new Date("2022-07-26"),
    title: "Present",
  },
  {
    start: new Date("2022-07-28"),
    end: new Date("2022-07-28"),
    title: "Present",
  },
];
var today = new Date();
var getTot = daysInMonth(today.getMonth() + 1, today.getFullYear());

for (var i = 1; i <= getTot; i++) {
  var newDate = new Date(today.getFullYear(), today.getMonth(), i);
  const day = newDate.getDay();
  if (day == 0 || day == 6) {
    const event = myEventsList.find(
      (event) => event.start == newDate || event.end == newDate
    );
    if (event == null) {
      myEventsList.push({ start: newDate, end: newDate, title: "Weekend" });
    }
  }
}
const colors = {
  Present: "#ECFDF5",
  "Special Leave": "#F0F9FF",
  Absent: "#FEF2F2",
  Weekend: "#FFF7ED",
};
const darkColors = {
  Present: "#34D399",
  "Special Leave": "#38BDF8",
  Absent: "#F87171",
  Weekend: "#FB923C",
};

function getColorForDay(day) {
  return colors[day];
}
function getborderColor(day) {
  return darkColors[day];
}
const AttendanceCalendar = () => {
  return (
    <div className="mt-14 mb-14 px-3">
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={{ month: true }}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          style: {
            backgroundColor: getColorForDay(event.title),
            margin: "10px",
            borderLeft: "3px solid" + getborderColor(event.title),
          },
        })}
      />
    </div>
  );
};

export default AttendanceCalendar;
