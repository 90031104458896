import axios from '../helpers/axios';
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { ActionCreators } from '../redux/actions/profile';


const useRefreshToken = () => {
    const auth = useSelector((state) => state.user.auth);
    const dispatch = useDispatch();

    const refresh = async () => {
        const response = await axios.get('auth/newToken', {
            withCredentials: true
        });
        const accessToken = response?.data?.user_auth_data?.hrms_token;
        const decodedToken = jwt_decode(accessToken);
        const user = decodedToken?.data?.user_name;
        const roles = [];
        roles.push(decodedToken?.data?.role_id);
        const connectedAthority = auth.connectedAthority;
        const recentNotice = auth.recentNotice;
        const persist = auth.persist;
        dispatch(ActionCreators.login({ user, roles, accessToken, persist, connectedAthority, recentNotice }));
        dispatch(ActionCreators.fetchUserProfile(accessToken))
        dispatch(ActionCreators.fetchTenantSetting(accessToken))

        return response.data.user_auth_data.hrms_token;
    }
    return refresh;
};

export default useRefreshToken;
