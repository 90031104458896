import React from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import Select from "react-select";
import Avatar from "../../../Avatar/Avatar";

const AddManulTimeSlidingPanel = ({
  open,
  close,
  handleOpenAttendanceDetailsSlidingPanel,
}) => {
  const handleGoBack = () => {
    close();
    handleOpenAttendanceDetailsSlidingPanel();
  };
  return (
    <ReactSlidingPane isOpen={open} onRequestClose={close} width="480px">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="px-5 flex justify-between border-b py-3">
            <div className="flex items-center gap-2">
              <span className="cursor-pointer" onClick={handleGoBack}>
                <i className="ico-left text-xs"></i>
              </span>
              <h1 className="text-black font-medium text-base">
                Add Attendance Time
              </h1>
            </div>
            <button onClick={close}>{closeIcon}</button>
          </div>
          <div className="p-5 flex flex-col gap-3 text-black">
            <div>
              <label>Employee</label>
              <Select
                value={{
                  label: (
                    <div className="flex items-center gap-3">
                      <Avatar width={"6"} height={"6"} /> darlene
                    </div>
                  ),
                }}
              />
            </div>
            <div>
              <label>Reason</label>
              <Select />
            </div>
            <div>
              <label>Enter time</label>
              <div className="flex items-center gap-3">
                <div>
                  <label>Clock-In</label>
                  <input type="date" />
                </div>
                <span>to</span>
                <div>
                  <label>Clock-Out</label>
                  <input type="date" />
                </div>
              </div>
            </div>
            <div>total hurs</div>
            <div>
              <label>Add note</label>
              <textarea></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-3 px-5 pt-3 border-t">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn">Save Attendance Time</button>
        </div>
      </div>
    </ReactSlidingPane>
  );
};

export default AddManulTimeSlidingPanel;
