import React from "react";
import Line from "../../Line/Line";
import ToggleSwitch from "../../ToggleSwitch";

const SettingsInfo = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Settings</p>
        </div>
      </div>

      <div className="flex justify-between employee-info-card">
        <table className="border-0 mb-4">
          <tbody>
            <tr>
              <td className="font-medium text-[#1D2939]">Account Status </td>
              <td className="flex gap-3">
                <ToggleSwitch />
                <div>
                  <p>Active</p>
                  <p>Mark this employee as active</p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-medium text-[#1D2939]">Portal Access </td>
              <td className="flex gap-3">
                <ToggleSwitch />
                <div>
                  <p>Active</p>
                  <p>Enable portal access</p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-medium text-[#1D2939]">Security</td>
              <td className="flex gap-3">
                <ToggleSwitch />
                <div>
                  <p>Active</p>
                  <p>Enable 2 factor authentifications</p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-medium text-[#1D2939]">Passwords </td>
              <td className="">Change Passwords</td>
            </tr>
            <tr>
              <td className="font-medium text-[#1D2939]">Delete account</td>
              <td className="">Delete account</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Line />
    </div>
  );
};

export default SettingsInfo;
