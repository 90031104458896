import React, { useState, useContext } from "react";
import MyAttendanceHead from "../../../../components/Attendance/ManageAttendance/MyAttendanceHead";
import MyAttendanceTable from "../../../../components/Attendance/AttendanceList/MyAttendanceTable";
import AttendanceMiniOverview from "../../../../components/Attendance/SelfService/MyAttendance/AttendanceMiniOverview/AttendanceMiniOverview";
import MyAttendanceCalendarView from "../../../../components/Attendance/SelfService/MyAttendance/MyAttendanceCalendarView/MyAttendanceCalendarView";
import { format, startOfMonth, endOfMonth } from "date-fns";
const TABLE_VIEW = 1;
const CALENDAR_VIEW = 2;

const MyAttendance = () => {
    const [attendanceData, setAttendanceData] = useState([]);
  const [dataView, setDataView] = useState(TABLE_VIEW);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "1",
    label: "All Status",
  });
  const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
  const [toDate, setToDate] = useState(new Date());

  const handleSetView = (e) => {
    setDataView(e);
  };

  const handleSelectedOptionChange = (selected) => {
    setSelectedOption(selected);
    setSelectedLabel(selected);
  };

  const handleStatusChange = (selected) => {
    setSelectedStatus(selected);
  };

  const handleDateChange = (newFromDate, newToDate) => {
    setFromDate(newFromDate);
    setToDate(newToDate);
  };

  return (
    <div className="flex flex-1">
      <div className="flex-1 flex-col">
        <div className="relative mb-20">
          <MyAttendanceHead
            handleSetView={handleSetView}
            dataView={dataView}
            selectedOption={selectedOption}
            onSelectedOptionChange={handleSelectedOptionChange}
            setSelectedOption={setSelectedOption}
            setSelectedLabel={setSelectedLabel}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            selectedStatus={selectedStatus}
            onStatusChange={handleStatusChange}
            onDateChange={handleDateChange}
            showRegularizationPanel={true} 
            showClockData={true}
           
          />

          {dataView === TABLE_VIEW ? (
            <MyAttendanceTable
              selectedOption={selectedOption}
              label={selectedStatus}
              fromDate={fromDate}
              toDate={toDate}
            
            />
          ) : (
            <MyAttendanceCalendarView
              selectedOption={selectedOption}
              label={selectedStatus}
              fromDate={fromDate}
              toDate={toDate}
            />
          )}
        </div>
        <div className="fixed w-5/6 bottom-0">
          <AttendanceMiniOverview fromDate={fromDate} toDate={toDate} />
        </div>
      </div>
    </div>
  );
};

export default MyAttendance;
