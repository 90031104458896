import React, { useEffect, useState
 } from "react";
import "react-datepicker/dist/react-datepicker.css";
import SimpleBar from "simplebar-react";
import NewJoinerMemberList from "./NewJoinerMemenerList";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const NewJoiner = () => {
  const [newJoinerList, setNewJoinerList] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const fetchNewJoinerList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate
      .get("employee/newJoinerEmployeeList")
      .then((response) => {
        if (response.data.status) {
             const dashMemberList = response.data.result
            setNewJoinerList(dashMemberList); 
        } else {
          setNewJoinerList([]);
        }
      })
      .catch((error) => {
        setNewJoinerList([]);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }
  useEffect(() => {
    fetchNewJoinerList();
  }, []);
  return (
    <>
      <div className="border rounded-md p-3 w-full h-full">
        <div className="flex justify-between font-medium">
          <p>New Joiner</p>
          <p className="flex items-center gap-2">
        {newJoinerList.length }
          </p>
        </div>
        <SimpleBar style={{ maxHeight: 317 }}>
         {newJoinerList.length === 0 ? (
          <div className="flex gap-2 justify-center py-12 max-h-full">
 <span className="flex items-center py-2 text-gray-800" >No recent joiners! </span>
          </div>
          
         ):(
          newJoinerList.map((newjoiner,key) => { 
            return <NewJoinerMemberList newjoiner={newjoiner} key={key}/>
          })
         )}
        </SimpleBar>

       
      </div>
    </>
  );
};

export default NewJoiner;
