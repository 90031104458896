import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import CandidateList from "../../../components/Candidate/CandidateList/CandidateList";
import OfferList from "../../../components/Candidate/CandidateOfferList/OfferList";
import Search from "../../../components/Header/Search";
import "./Candidate.scss";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ExcelExport from "../../../components/ExcelDataExport/ExcelExport";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import Pagination from "../../../components/Pagination/Pagination";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader/PageLoader";


const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down"></span>
    </components.DropdownIndicator>
  );
};

const ManageCandidate = () => {
  const axiosPrivate = useAxiosPrivate();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [candidateList, setGetCandidate] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const [searchValue, setSearchValue] = useState("");
  const [jobTitle, setJobTitle] = useState(null);
  let baseUrl = `onboarding/candidate/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;
  const [candidateSearchUrl, setCandidateSearchUrl] = useState(baseUrl);

  const user = useSelector((state) => state.user.auth);
  const isHrAuth = userRoleAuth(user, 2);
  const isAdmin = userRoleAuth(user, 1);
  const [loaderComponent, setLoaderComponent] = useState(<PageLoader />);
  const [jobTitleList, setJobTitleList] = useState([]);

  const getSearchValue = (data) => {
    setSearchValue(data);
  };

  const fetchJobTitleList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getJobTitleList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setJobTitleList(data.result);
        }
      })
      .catch((err) => {
        // console.log(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchJobTitleList();
  }, [])

  useEffect(() => {
    let baseUrl = `onboarding/candidate/search?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC`;
    if (searchValue) {
      baseUrl = baseUrl + `&filter=${searchValue}`;
    }
    // if (jobTitle && jobTitle != 0) {
    //   baseUrl = baseUrl + `&jobTitle=${jobTitle}`;
    // }
    setCandidateSearchUrl(baseUrl);
  }, [searchValue, jobTitle, pageNo]);

  const clearStateValue = async () => {
    setGetCandidate(null);
    setTotalRecords(null);
  };

  const fetchCandiateList = async () => {
    setLoaderComponent(<PageLoader />);
    clearStateValue();
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(candidateSearchUrl, {
        signal: controller.signal,
      })
      .then((response) => {
        // isMounted && console.log(response.data, "candidate list");
        const res = response?.data;
        if (Number(res.statusCode) === 200) {
          setTimeout(() => {
            setGetCandidate(res.candidate);
            setTotalRecords(Number(res.currentRecords));
            setLoaderComponent(null);
          }, 500);
        } else if (Number(res.message) === "Inavlid page number") {
          setPageNo(1);
        } else {
          clearStateValue();
          setLoaderComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        console.error(err);
        clearStateValue();
        setLoaderComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchCandiateList();
  }, [pageNo, toggleState, candidateSearchUrl]);

  const [makeApiCall, setMakeApiCall] = useState(false);

  const fetchData = () => {
    fetchCandiateList();
    setMakeApiCall((prev) => !prev);
  };

  return (
    <div className="w-full min-h-screen overflow-x-scroll">
      <div className="flex justify-between items-start px-10 pt-6 border-b border-gray-300">
        <div>
          <h2 className="">Manage Candidate</h2>
          <div className="flex items-center gap-12 pl-4 pt-6">
            <button
              className={
                toggleState === 1
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(1)}
            >
              Candidate List
            </button>
            <button
              className={
                toggleState === 2
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => toggleTab(2)}
            >
              Offer
            </button>
          </div>
        </div>
        <div>
          <div className="flex justify-evenly gap-3 select-none">
            <ExcelExport data={candidateList} fileName={"CandidateList"} />
            {isHrAuth || isAdmin ? (
              <Link
                to="/candidate/create-candidate"
                className="btn text-[14px]"
              >
                <span>
                  <i className="ico-plus text-white mr-1"></i>
                </span>{" "}
                Add Candidate
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center py-4 px-10">
        <div className="flex items-center gap-3 max-h-[38px]">
          <Select
            placeholder={"Category: All"}
            options={jobTitleList.map((i) => ({ label: i.title, value: i.id}))}
            components={{ DropdownIndicator }}
            className="custom-select-container"
            onChange={(e) => {
              setJobTitle(e.value);
            }}
          />
          {jobTitle || searchValue ? (
            <span
              className="text-blue-600 font-medium cursor-pointer"
              onClick={() => {
                setJobTitle(null);
                setSearchValue(null);
              }}
            >
              Clear filters
            </span>
          ) : null}
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            className="btn btn--border h-[38px]"
            onClick={() => fetchData()}
          >
            <span className="material-symbols-outlined">refresh</span>
          </button>
          <div>
            <Search onChange={getSearchValue} />
          </div>
          <div>
            <a
              href="#"
              className="btn btn--border text-[14px] max-h-[38px] max-w-[30px]"
            >
              <span>
                <i className="ico-grid mr-1"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      {toggleState === 1 ? (
        <>
          {loaderComponent}
          {candidateList && <CandidateList data={candidateList} />}
          {candidateList && (
            <Pagination
              page={{
                pageNo,
                setPageNo,
                pageSize,
                setPageSize,
                totalRecords,
              }}
            />
          )}
        </>
      ) : null}
      {toggleState === 2 ? (
        <>
          <OfferList
            toggleData={toggleState}
            searchData={searchValue}
            category={jobTitle}
            makeApiCall={makeApiCall}
          />
        </>
      ) : null}
    </div>
  );
};

export default ManageCandidate;
