import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ToggleSideBarButton from "./ToggleSideBarButton";
import { LayoutActionCreators } from "../../../redux/actions/layout";

const excludeAsideBarForPaths = [
  "/attendance/manage-attendance/finalize-attendance/view-employee-finalize-attendance",
];

const Aside = ({ submenu }) => {
  const [isSubmenu, setIsSubmenu] = useState(false);
  const dispatch = useDispatch();
  const setSubMenu = (item) => {
    item.status = !item.status;
    setPath(item.link);
    setIsSubmenu(!isSubmenu);
    navigate(item.link);
  };

  const navigate = useNavigate();
  const [path, setPath] = useState(window.location.pathname);

  const setActiveClass = (item) => {
    setPath(item.link);
    navigate(item.link);
  };

  const formatMenuPath = (pathname) => {
    let menuPath = pathname.split("/")[2];
    return menuPath;
  };

  const auth = useSelector((state) => state.user.auth);
  const layoutState = useSelector((state) => state.layout);

  const roles = auth.roles;

  const isExcludedpath = !!excludeAsideBarForPaths.find(
    (i) => i === window.location.pathname
  );

  const { isAsideBarExpanded } = layoutState;
  const handleToggleAsideBar = () => {
    dispatch(LayoutActionCreators.toggleAsideBar());
  };

  if (!isExcludedpath) {
    return (
      <div
        className={`${
          isAsideBarExpanded ? "w-[211px] border-r px-6" : "w-5 border-r-2"
        } relative py-8  border-gray-200  flex-shrink-0`}
      >
        <ToggleSideBarButton
          className="rounded-full border bg-white hover:bg-blue-600 hover:text-white absolute right-[-14px]"
          isExpanded={isAsideBarExpanded}
          onClick={handleToggleAsideBar}
        />
        {isAsideBarExpanded && (
          <ul className="text-gray-600">
            {submenu?.map((item, index) => {
              const isIcon = item?.menu?.find((i) =>
                i?.roles?.find((role) => roles?.includes(role))
              );
              return item?.roles?.find((role) => roles.includes(role)) ? (
                <ul className="mb-4 text-sm leading-5 font-medium " key={index}>
                  <li
                    className={`cursor-pointer mb-2 select-none ${
                      formatMenuPath(path) === formatMenuPath(item.link)
                        ? "text-[#2A52B9]"
                        : "hover:text-blue-800"
                    }`}
                    onClick={() => {
                      setSubMenu(item);
                    }}
                  >
                    {" "}
                    {item.text}{" "}
                    <i
                      className={`${isIcon ? item.icon : ""} ${
                        item.status ? "rotate-180 inline-block" : ""
                      }`}
                    ></i>
                  </li>

                  {item?.menu?.map((sub, index) => {
                    if (item.status) {
                      return sub?.roles?.find((role) =>
                        roles?.includes(role)
                      ) ? (
                        <li
                          key={index}
                          className={`cursor-pointer text-sm leading-5  px-4 py-2 ${
                            path === sub.link
                              ? "text-[#2A52B9] bg-[#EBF0FD] border-2 font-medium border-l-[#2A52B9]"
                              : "font-normal hover:text-blue-800 hover:font-medium"
                          }`}
                          onClick={() => setActiveClass(sub)}
                        >
                          {sub.text}
                        </li>
                      ) : null;
                    }
                  })}
                </ul>
              ) : null;
            })}
          </ul>
        )}
      </div>
    );
  }
  return null;
};

export default Aside;
