import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import SpinnerLoader from "../PageLoader/SpinnerLoader";
import moment from "moment";

const ViewEventFullListModal = ({ open, close, events }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(open);

    let delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [open]);

  return (
    <Modal title="Details" open={open} onOk={close} onCancel={close}>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <SpinnerLoader />
        </div>
      ) : Array.isArray(events) && events.length ? (
        events.map((item) => {
          const startDate = item.start
            ? moment(item.start).format("DD-MM-YYYY")
            : "";
          const endDate = item.end ? moment(item.end).format("DD-MM-YYYY") : "";
          return (
            <div
              className="my-2 p-2 rounded-sm"
              style={{
                backgroundColor: item.backGroundColor,
                border: `1px solid ${item.borderColor}`,
              }}
            >
              <p style={{ color: item.titleColor }}>{item.title}</p>
              <p>{item.details}</p>
              {(startDate && endDate) && (
                <p>
                  Date: {startDate} - {endDate}
                </p>
              )}
            </div>
          );
        })
      ) : (
        "No data found."
      )}
    </Modal>
  );
};

export default ViewEventFullListModal;
