import React, { useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../../Icons/Icons";
import Line from "../../../../Line/Line";
import Avatar from "../../../../Avatar/Avatar";
import RegularizationRequestHistorySlidingPanel from "../RegularizationRequestHistorySlidingPanel";
import CancelRegularizationRequestModal from "../CancelRegularizationRequestModal";
import RegularizationRequestActionModal from "../../../ManageAttendance/RegularizationApproval/RegularizationRequestActionModal";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const MODAL_VIEW = {
  APPROVE_MODAL: 1,
  DECLINE_MODAL: 2,
  REVOKE_MODAL: 3,
};

const RegularizationRequestDetailsSlidingPanel = ({
  open,
  close,
  handleOpenRegularizationDetailsSlidingPanel,
  isViewFromRegularizationApprovalPage,
  isRegularizationIdClicked,
  regularizationId,
}) => {
  const [openViewHistorySlidingPanel, setViewHistorySlidingPanel] =
    useState(false);
  const [openRegularizationActionModal, setRegularizationActionModalState] =
    useState(false);
  const [openCancelRequestModal, setCancelRequestModal] = useState(false);

  const [modalViewState, setModalViewState] = useState(null);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const handleOpenCancelRequest = () => {
    setCancelRequestModal(true);
  };
  const handleCloseCancelRequest = () => {
    setCancelRequestModal(false);
  };

  const handleViewHistory = () => {
    close();
    setViewHistorySlidingPanel(true);
  };

  const handleCloseViewHistorySlidingPanel = () => {
    setViewHistorySlidingPanel(false);
  };
  const [hrNote, setHrNote] = useState("");

  const handleOpenRegularizationRequestActionModal = (modalType) => {
    setModalViewState(modalType);
    setRegularizationActionModalState(true);
  };

  const handleCloseRegularizationRequestActionModal = () => {
    setRegularizationActionModalState(false);
  };
  const [regularizationRequestDetails, setRegularizationRequestDetails] =
    useState(null);

  const [sidePanelData, setSidePanelData] = useState({});
  const fetchRegularizationRequestDetails = async () => {
    try {
      const response = await axiosPrivate.get(
        `attendance/getRegularizationDetails?regularization_id=${regularizationId}`
      );

      if (response && response?.data) {
        const getRegularizationDetails = response.data.result[0];
        setRegularizationRequestDetails(getRegularizationDetails);
      } else {
        setRegularizationRequestDetails([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (regularizationId && open) {
      fetchRegularizationRequestDetails();
    }
  }, [regularizationId, open]);

  const handleApproveOrDecline = async () => {
    try {
      let apiUrl;
      if (modalViewState === MODAL_VIEW.APPROVE_MODAL) {
        apiUrl = "/attendance/hrRegularizationApproval";
      } else if (modalViewState === MODAL_VIEW.DECLINE_MODAL) {
        apiUrl = "/attendance/hrRegularizationReject";
      } else {
        return;
      }

      const requestBody = {
        regularization_application_id: regularizationId,
        hr_note: hrNote,
      };

      const response = await axiosPrivate.put(apiUrl, requestBody);
      if (response && response.data) {
        toast.success("Regularization status has been updated");
      } else {
        toast.error("Request status has already been updated");
      }
      close();
    } catch (error) {
      toast.error("Something went wrong while updating data");
      close();
    }
  };
  useEffect(() => {
    if (regularizationId) {
      handleApproveOrDecline();
    }
  }, [regularizationId]);

  const approvalStatusLabel = "Approve";
  const rejectStatusLabel = "Reject";
  const pendingStatusLabel = "Pending";
  const cancelStatusLabel = "Cancel";

  const statusLabel =
    regularizationRequestDetails?.approval_status === approvalStatusLabel
      ? "Approved"
      : regularizationRequestDetails?.approval_status === rejectStatusLabel
      ? "Rejected"
      : regularizationRequestDetails?.approval_status === cancelStatusLabel
      ? "Canceled"
      : regularizationRequestDetails?.approval_status === pendingStatusLabel
      ? "Pending"
      : "";

  const statusClass =
    regularizationRequestDetails?.approval_status === approvalStatusLabel
      ? "status--green"
      : regularizationRequestDetails?.approval_status === rejectStatusLabel
      ? "status--red"
      : regularizationRequestDetails?.approval_status === cancelStatusLabel
      ? "status--gray"
      : regularizationRequestDetails?.approval_status === pendingStatusLabel
      ? "status--yellow"
      : "";

  return (
    <>
      <CancelRegularizationRequestModal
        open={openCancelRequestModal}
        close={handleCloseCancelRequest}
      />
      <RegularizationRequestHistorySlidingPanel
        open={openViewHistorySlidingPanel}
        close={handleCloseViewHistorySlidingPanel}
        handleOpenRegularizationDetailsSlidingPanel={
          handleOpenRegularizationDetailsSlidingPanel
        }
        regularizationId={regularizationId}
      />
      <RegularizationRequestActionModal
        open={openRegularizationActionModal}
        close={handleCloseRegularizationRequestActionModal}
        modalViewState={modalViewState}
        modalView={MODAL_VIEW}
      />
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="560px">
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex justify-between items-center px-5 py-3">
              <h1 className="text-base font-medium text-black">
                Regularization Request Details
              </h1>
              <div className="flex items-center gap-3">
                <button className="text-blue-600" onClick={handleViewHistory}>
                  View History
                </button>
                <button onClick={close}>{closeIcon}</button>
              </div>
            </div>
            <Line />
            <div className="flex justify-between px-5">
              <div className="flex flex-col gap-1">
                <ol>Request ID</ol>
                <ol>Approval Status</ol>
                <ol>Request Date</ol>
                <ol>Type</ol>
                <ol>Reason</ol>
                <ol>Regularization Date</ol>
                <ol>Regularization Time</ol>
                {/* <ol>Total</ol> */}
              </div>
              <div className="flex flex-col text-black gap-1 font-medium text-end">
                <ol>
                  {regularizationRequestDetails?.regularization_serial_id ||
                    "No regularization id found"}
                </ol>
                <ol>{regularizationRequestDetails?.approval_status}</ol>
                <ol>{regularizationRequestDetails?.requested_date}</ol>
                <ol>{regularizationRequestDetails?.type}</ol>
                <ol>{regularizationRequestDetails?.reason}</ol>
                <ol>{regularizationRequestDetails?.regularization_date}</ol>
                <ol>{regularizationRequestDetails?.from_time}</ol>
                {/* <ol>{regularizationRequestDetails}</ol> */}
              </div>
            </div>
            <div className="px-5 py-3">
              <h1>Employee Note</h1>
              <div className="bg-gray-100 mt-1 py-3 px-4 flex gap-1 rounded-md">
                <i className="ico-clock mt-1"></i>
                <p>
                  {regularizationRequestDetails?.employee_note || "no note"}
                </p>
              </div>
            </div>
            <div className="px-5">
              <h1>Attachment</h1>
              <div className="flex justify-between py-1">
                <span className="text-black">
                  <i className="ico-attachment mr-1"></i>Image.jpg
                </span>
                <button className="text-blue-600 font-medium">Download</button>
              </div>
              <Line />
            </div>
            <div className="px-5">
              <h1 className="text-base font-medium py-2 text-black">
                Approval status
              </h1>
              <div className="flex flex-col gap-5">
                <div className="flex gap-3">
                  <div>
                    <Avatar />
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between">
                      <span className="font-medium text-black">
                        {regularizationRequestDetails?.hr_name}
                      </span>
                      <div>
                        <span className={`status ${statusClass}`}>
                          {statusLabel}
                        </span>
                      </div>
                    </div>
                    <span>Hr Manager</span>
                    <div className="bg-gray-100 rounded-md py-3 px-4 mt-2">
                      <i className="ico-clock mr-1"></i>
                      {regularizationRequestDetails?.hr_note || "No Note"}
                    </div>
                  </div>
                </div>
                <div className="flex gap-3"></div>
              </div>
            </div>
          </div>
          <div>
            <Line />
            <div className="px-5 flex gap-3 justify-end">
              {regularizationRequestDetails?.approval_status ===
                pendingStatusLabel && (
                <>
                  <button
                    className={`btn btn--${
                      isViewFromRegularizationApprovalPage ? "red" : "border"
                    }`}
                    onClick={
                      isViewFromRegularizationApprovalPage
                        ? () =>
                            handleOpenRegularizationRequestActionModal(
                              MODAL_VIEW.DECLINE_MODAL
                            )
                        : () => handleApproveOrDecline
                    }
                  >
                    {isViewFromRegularizationApprovalPage
                      ? "Decline Regularization"
                      : "Close"}
                  </button>
                  <button
                    className={`btn btn--${
                      isViewFromRegularizationApprovalPage ? "green" : "red"
                    }`}
                    onClick={
                      isViewFromRegularizationApprovalPage
                        ? () =>
                            handleOpenRegularizationRequestActionModal(
                              MODAL_VIEW.APPROVE_MODAL
                            )
                        : handleOpenCancelRequest
                    }
                  >
                    {isViewFromRegularizationApprovalPage
                      ? "Approve Regularization"
                      : "Cancel Request"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default RegularizationRequestDetailsSlidingPanel;
