import React, { useEffect, useState } from "react";


const IdleStateSessionTimeoutTimer = ({ logOut }) => {
  const timeout = 60; // in seconds
  const [timer, setTimer] = useState(timeout);



  useEffect(() => {
    if (Number(timer) === 0) {
      logOut()
    }
    const delayTimer = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(delayTimer);
    };
  }, [timer]);
  return <div className="text-gray-800 text-xl font-medium">{timer}</div>;
};

export default IdleStateSessionTimeoutTimer;
