import React, { useState } from "react";
import Line from "../../../components/Line";
import { Link, useNavigate } from "react-router-dom";
import FsLightbox from "fslightbox-react";

const Welcome = () => {
  const navigate = useNavigate();
  const [toggler, setToggler] = useState(false);

  const onButtonClick = () => {
    fetch("/Rest Client.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "handbook.pdf";
        alink.click();
      });
    });
  };

  return (
    <div className="flex-1 justify-center max-w-[740px]">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Welcome to our company
        </h2>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
      </div>
      <figure
        className="relative mb-8 rounded-md overflow-hidden"
        onClick={() => setToggler(!toggler)}
      >
        <img src="/images/video1.jpg" alt="" className="w-full" />
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ring-6px ring-white/[.25] rounded-full group">
          <svg
            className="fill-white group-hover:fill-green-400 transition duration-[.3s]"
            width="46"
            height="46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23 45.514c12.371 0 22.4-10.029 22.4-22.4 0-12.371-10.029-22.4-22.4-22.4C10.629.714.6 10.743.6 23.114c0 12.371 10.029 22.4 22.4 22.4Zm-1.247-30.33a2.8 2.8 0 0 0-4.353 2.33v11.2a2.8 2.8 0 0 0 4.353 2.33l8.4-5.6a2.8 2.8 0 0 0 0-4.66l-8.4-5.6Z"
            />
          </svg>
        </span>
      </figure>
      <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/watch?v=bzFLYp-gOv4"]}
      />
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">Overview </h2>
        <h2 className="text-xl  text-gray-900 mb-2">Our Mission</h2>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-xl  text-gray-900 mb-2">Our Story</h2>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
        <p>
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Company Culture{" "}
        </h2>
        <p>
          Our culture leads us to find ways to help people do ordinary and
          sometimes extraordinary things.
        </p>
        <ul className="list-disc ml-6">
          <li>Are cost-effective.</li>
          <li>Go the extra mile.</li>
          <li>Do the job properly, first time.</li>
          <li>Deliver the results our clients want.</li>
          <li>Are pleasant to work with.</li>
        </ul>
        <p>
          To learn more about our company culture please visit :{" "}
          <Link to="#" className="text-blue-700">
            company culture
          </Link>
          .
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Explore the handbook
        </h2>
        <p className="mb-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </p>
      </div>
      <div className="mb-8 grid grid-cols-2 gap-8">
        <div className="flex">
          <figure className="border border-gray-300 rounded-md overflow-hidden mr-3">
            <img src="/images/pdf1.jpg" alt="" />
          </figure>
          <div className="flex flex-1 flex-col justify-end">
            <div className="mb-4 flex">
              <i className="ico-doc text-[20px] pr-3"></i>
              <p>handbook.pdf</p>
            </div>
            <div>
              <button className="btn" onClick={onButtonClick}>
                {" "}
                Download{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Line />
      <div className="flex">
        {/* <div>
          <button className="btn btn--border" onClick={() => navigate(-1)}>
            Back
          </button>
        </div> */}
        <div className="ml-auto flex">
          <Link to="/onboarding/know-the-job" className="btn ml-5">
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
