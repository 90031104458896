import React, { useEffect, useState } from "react";
import "./Search.scss";
import LoadingIcon from "../../../Icons/Loading";
const Search = ({
  onChange,
  value = "",
  mx = "6",
  className = "",
  placeholder = "search",
  eleRef,
}) => {
 
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState();

  const searchHandle = (e) => {
    setInputValue(e);
    setLoader(true);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoader(false);
      if (typeof onChange === "function") onChange(inputValue);
    }, 600);

    return () => {
      clearTimeout(delay);
    };
  }, [inputValue]);

  return (
    <div className={`search mx-${mx || "6"} relative ${className}`}>
      <input
        ref={eleRef}
        className="rounded-md max-w-[240px] h-[38px]"
        type="text"
        placeholder={placeholder}
        onChange={(e) => searchHandle(e.target.value)}
      />
      <div className=" icon-div absolute left-4 top-2">
        <i className="ico-search text-base text-gray-400"></i>
      </div>
      {loader && (
        <div className="absolute top-[7px] right-[7px]">
          <LoadingIcon />
        </div>
      )}
    </div>
  );
};
export default Search;
