import React, { Fragment, useEffect } from "react";
import "./InformationCard.scss";
import SimpleCard from "../../components/SimpleCard";
import { addIcon } from "../../Icons/Icons";
function InformationCard({
  titleIcon,
  title,
  editIcon,
  rows,
  layout,
  footer,
  footerAction
}) {
 
  return (
    <div className="information-card-div">
      <div className="header-div">
        <span className="title-icon">{titleIcon}</span>
        {title && <label>{title}</label>}
        <span className="edit-icon">{editIcon}</span>
      </div>
      {layout == "table" ? (
        <div className="overflow-x-auto">
          {rows &&
            rows.map((t, i) => (
              <table
                key={i}
                className={`section section-${
                  title && title.replace(/\s/g, "-")
                }-${i}`}
              >
                <tbody>
                  {t.section &&
                    t.section.map((r, s) => (
                      <tr key={s}>
                        {r.row &&
                          r.row.map((d, j) => (
                            <Fragment key={j}>
                              <td
                                valign="top"
                                className={`td-label td-label-${j}`}
                              >
                                {d.label && d.label}
                              </td>
                              <td
                                valign="top"
                                className={`td-data td-data-${j}`}
                              >
                                {d.data && d.data !== "" ? d.data : "-"}
                              </td>
                            </Fragment>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            ))}
          {footer && (
            <table className="footer-table">
              <tbody>
                <tr>
                  <td className="td-label" valign="top">
                    {footer.label && footer.label}
                  </td>
                  <td valign="top">{footer.data && footer.data}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      ) : layout == "card" ? (
        <div className="overflow-x-auto">
          {rows &&
            rows.map((r, c) => (
              <SimpleCard
                key={c}
                titleIcon={r.title_icon ? r.title_icon : ""}
                editIcon={r.edit_icon ? r.edit_icon : ""}
                header={r.header ? r.header : ""}
                subHead={r.sub_head ? r.sub_head : ""}
                date={r.date ? r.date : ""}
                dateType={r.date_type ? r.date_type : ""}
                footerText={r.footer_text ? r.footer_text : ""}
              />
            ))}
        </div>
      ) : (
        ""
      )}
      {footerAction && (
        <button className="add-btn">
          {addIcon}
          <span>Add More</span>
        </button>
      )}
    </div>
  );
}
export default InformationCard;
