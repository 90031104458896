import React, { useState } from "react";
import { DatePicker, Select, Alert } from "antd";
import dayjs from "dayjs";
import EmployeeHistory from "./EmployeeHistoryData";
import {
  useGetActionTypesForEmployeeHistory,
  useGetEmployeeHistory,
} from "../../../queries/employeeQueries";
import Loader from "../../Loader";

const FILTER_CONSTS = {
  action_type_id: "action_type_id",
  updated_at: "updated_at",
  pageSize: "pageSize",
  pageNo: "pageNo",
};


const searchFilterInitialState = {
  [FILTER_CONSTS.action_type_id]: "",
  [FILTER_CONSTS.updated_at]: "",
  [FILTER_CONSTS.pageSize]: 10,
  [FILTER_CONSTS.pageNo]: 1,
};

const EmployeeHistoryInfo = ({ employee }) => {
  const [searchParams, setSearchParams] = useState(searchFilterInitialState);
  const isFilterApplied =
    searchParams?.updated_at || searchParams?.action_type_id;
  const {
    data: getEmployeeHistory,
    isLoading,
    refetch: fetchEmployeeHistory,
  } = useGetEmployeeHistory({
    ...searchParams,
    employee_id: employee?.employee_id,
  });
  const handleSetPageNo = (e) => {
    handleSetSearchParams(e, FILTER_CONSTS.pageNo)
    window.scrollTo(0,0)
  }

  const timelineData = Array.isArray(getEmployeeHistory?.employeeHistoryData)
    ? getEmployeeHistory?.employeeHistoryData?.map((item) => ({
        updated_by: item.updated_by,
        action: item.action_type,
        date: item.updated_at,
        updates: item.updated_value,
        descriptions: item.label,
        id: item.action_type_id,
        label_color: item.label_color,
      }))
    : [];
  const totalRecords = getEmployeeHistory?.totalRecords || 0;
  const { data: getActionTypes } = useGetActionTypesForEmployeeHistory();

  const actionTypes = Array.isArray(getActionTypes?.result)
    ? getActionTypes?.result.map((actionType) => ({
        value: actionType.id,
        label: actionType.log_name,
        id: actionType.id,
        color: actionType.label_color,
      }))
    : [];

  const handleClearFilters = () => {
    setSearchParams(searchFilterInitialState);
  };

  const handleSetSearchParams = (e, source) => {
    if (source === FILTER_CONSTS.pageNo) {
      setSearchParams((prev) => ({
        ...prev,
        [source]: e,
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        [source]: e,
        pageNo: 1,
      }));
    }
  };

  return (
    <div className="p-4">
      <div className="flex gap-3 text-black mb-10">
        <DatePicker
          value={
            searchParams?.[FILTER_CONSTS.updated_at]
              ? dayjs(searchParams?.[FILTER_CONSTS.updated_at])
              : null
          }
          onChange={(date) =>
            handleSetSearchParams(
              date ? dayjs(date).format('YYYY-MM-DD') : null,
              FILTER_CONSTS.updated_at
            )
          }
          placeholder="Action Taken On"
          className="h-10"
        />
        <Select
          value={searchParams?.action_type_id || null}
          onChange={(e) => {
            handleSetSearchParams(e, FILTER_CONSTS.action_type_id);
          }}
          options={actionTypes}
          placeholder="Action Types"
          className="h-10 w-52"
        />
        <button
          type="button"
          className="btn btn--border h-10"
          onClick={fetchEmployeeHistory}
        >
          <span className="material-symbols-outlined">refresh</span>
        </button>
        {!!isFilterApplied && (
          <button
            className="text-blue-600 cursor-pointer"
            onClick={handleClearFilters}
          >
            Clear filters
          </button>
        )}
      </div>

      {isLoading ? (
        <Loader />
      ) : Array.isArray(timelineData) && timelineData.length > 0 ? (
        <EmployeeHistory
          timelineData={timelineData}
          totalRecords={totalRecords}
          pageNo={searchParams.pageNo}
          pageSize={searchParams.pageSize}
          setPageNo={handleSetPageNo}
          setPageSize={(e) => handleSetSearchParams(e, FILTER_CONSTS.pageSize)}
        />
      ) : (
        <div className="mb-4 text-center">
          <Alert message={"No records found"} type="warning" showIcon />
        </div>
      )}
    </div>
  );
};

export default EmployeeHistoryInfo;
