import React from "react";
import Select, { components } from "react-select";
import Search from "../../Header/Search/Search";
import EmployeeAssetWithImage from "../../Assets/AssetList/EmployeeAssetWithImage";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const EmployeeAsset = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div>
          <h2>Employee Assets</h2>
          <p>View and manage employee assets</p>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            <span>
              <i className="ico-plus text-white mr-1"></i>
            </span>
            Assign Asset
          </a>
        </div>
      </div>
      <div className="flex justify-between items-center px-10 pb-4">
        <div className="flex gap-3">
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "India", label: "India" },
              { value: "USA", label: "USA" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Office: All",
              label: "Office: All"
            }}
          />
          <Select
            options={[
              { value: "All", label: "All" },
              {
                value: "Available",
                label: "Available"
              },
              { value: "Out Of Stock", label: "Out Of Stock" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Status:All",
              label: "Status:All"
            }}
          />
        </div>
        <div className="flex">
          <div>
            <Search />
          </div>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <div>
        <EmployeeAssetWithImage />
      </div>
    </div>
  );
};

export default EmployeeAsset;
