import React, { useState, useEffect, Fragment } from "react";
import ApplyLeaveModal from "../../../components/ApplyLeaveModal/ApplyLeaveModal";
import LeaveDetailsModal from "../../../components/LeaveDetailsModal/LeaveDetailsModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import LeaveRequestData from "../../../components/Leaves/LeaveRequestData/LeaveRequestData";
import Pagination from "../../../components/Pagination/Pagination";
import Select from "react-select";
import Search from "../../../components/Header/Search/Search";


const MyLeaves = () => {
  const axiosPrivate = useAxiosPrivate();
  const [teamList, setTeamList] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [leaveBalances, setLeaveBalances] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [leaveRequestList, setLeaveRequestList] = useState(null);
  const [hrApprovalLog, setHrApprovalLog] = useState(null);
  const [managerApprovalLog, setManagerApprovalLog] = useState(null);
  const [slidingPanelLeaveData, setSlidingPanelLeaveData] = useState(null);
  const [isLeaveApplicationSubmitted, setIsLeaveApplicationSubmitted] =
    useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listNumberTo, setListNumberTo] = useState(10);
  const [listNumberFrom, setListNumberFrom] = useState(1);
  let leaveRequestListBaseUrl = `employee/leaveRequestList?pageNo=${pageNo}&pageSize=${pageSize}`;

  // fetch team details api **starts**
  const fetchTeamDetails = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/teams`)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          setTeamList(response.data);
        } else {
          setErrMsg("Failed to load team details, Try again.");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // fetch team details **ends**

  // FETCH_LEAVE_BALACES_API___STARTS**
  const fetchLeaveBalances = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveBalances`)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          setLeaveBalances(response.data.results);
        } else {
          setErrMsg("Failed to load team details, Try again.");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // FETCH_LEAVE_BALACES_API___ENDS**

  // FETCH_LEAVE_REQUEST_LIST_API__STARTS**
  const fetchLeaveRequestList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(leaveRequestListBaseUrl)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          setLeaveRequestList(response.data.leaveRequestList);
          setHrApprovalLog(response.data.hrApprovalLog);
          setManagerApprovalLog(response.data.managerApprovalLog);
          setTotalNumberOfPages(response.data.totalPages);
          setTotalRecords(response.data.totalRecords);
        } else {
          setErrMsg("Failed to load team details, Try again.");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // FETCH_LEAVE_REQUEST_LIST_API__ENDS**

  useEffect(() => {
    fetchLeaveBalances();
    fetchTeamDetails();
  }, [isLeaveApplicationSubmitted]);

  useEffect(() => {
    fetchLeaveRequestList();
  }, [isLeaveApplicationSubmitted, pageNo, leaveRequestListBaseUrl]);

  const previousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
      setListNumberFrom(listNumberFrom - pageSize);
      setListNumberTo(listNumberTo - pageSize);
    }
  };
  const nextPage = () => {
    if (pageNo < totalNumberOfPages) {
      setPageNo(pageNo + 1);
      setListNumberFrom(listNumberTo + 1);
      setListNumberTo(listNumberTo + pageSize);
    }
  };

  //state for applyleave modal
  const [applyLeaveSlidingPanel, setApplyLeaveSlidingPanel] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  //state for leavedetailsmodal
  const [leaveDetailsSlidingPanel, setLeaveDetailsSlidingPanel] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  // function for apply leaveDetailsSlidingPanel
  const applyleave = () => {
    setApplyLeaveSlidingPanel({ isPaneOpen: true });
  };
  // function for leavedetails
  const leaveDetails = async (leaveApplcationId) => {
    let managerLog = null;
    (await managerApprovalLog) &&
      managerApprovalLog.map((log) => {
        if (log.leave_application_id === leaveApplcationId) {
          managerLog = log;
        }
      });
    let hrLog = null;
    (await hrApprovalLog) &&
      hrApprovalLog.map((log) => {
        if (log.leave_application_id === leaveApplcationId) {
          hrLog = log;
        }
      });
    let leaveApplication = null;
    leaveRequestList &&
      leaveRequestList.map((request) => {
        if (request.id === leaveApplcationId) {
          leaveApplication = request;
        }
      });
    setSlidingPanelLeaveData({
      managerLog,
      hrLog,
      leaveApplication,
    });
    setLeaveDetailsSlidingPanel({ isPaneOpen: true });
  };

  return (
    <Fragment>
      <ApplyLeaveModal
        state={applyLeaveSlidingPanel}
        setState={setApplyLeaveSlidingPanel}
        teams={teamList}
        leaveTypes={leaveBalances}
        setIsLeaveApplicationSubmitted={setIsLeaveApplicationSubmitted}
      />
      <LeaveDetailsModal
        state={leaveDetailsSlidingPanel}
        setState={setLeaveDetailsSlidingPanel}
        leaveDetails={slidingPanelLeaveData}
      />
      <div className="overflow-hidden flex-1">
        <div className="px-10 pt-8">
          <div className="flex items-center justify-between">
            <div>
              <h2>Employee Leave</h2>
              <p>Manage all employee regularization</p>
            </div>
            <div className="flex items-center">
              <div>
                <button
                  className="btn bg-[#3466E7] max-w-[159px] max-h-[38px] text-sm"
                  onClick={applyleave}
                >
                  <span>
                    <i className="ico-plus text-white mr-1 h-3.5 w-3.5"></i>
                  </span>
                  Leave Request
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-full overflow-x-auto">
          <div className="py-4 px-10">
            <div className="flex justify-between">
              <div className="flex pt-2.5 ">
                <div className="pr-3">
                  <input type="date" className="h-[38px]" />
                </div>
                <div className="pr-3">
                  <Select placeholder="Type: All" />
                </div>
                <div className="pr-3 ">
                  <Select placeholder="Status: All" />
                </div>
              </div>
              <div className="flex pt-2.5">
                <div>
                  <Search mx={3} />
                </div>
                <div>
                  <button className="btn btn--border text-[#374151] max-h-[38px] max-w-[127px] text-sm">
                    <span>
                      <i className="ico-download text-[#6B7280] mr-1 h-3.5 w-3.5"></i>
                    </span>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table className="">
            <thead>
              <tr className="pl-10">
                <td>
                  <input type="checkbox" />
                </td>
                <td>ID</td>
                <td>Request date</td>
                <td>Days/Hours Taken</td>
                <td>Leave Type</td>
                <td>Leave Date</td>
                <td>STATUS</td>
                <td>HR STATUS</td>
                <td>MANAGER STATUS</td>
                <td scope="col" className="px-10 py-3"></td>
              </tr>
            </thead>
            <tbody>
              <LeaveRequestData
                leaveRequestList={leaveRequestList}
                leaveDetails={leaveDetails}
                hrApprovalLog={hrApprovalLog}
                managerApprovalLog={managerApprovalLog}
              />
            </tbody>
          </table>
          <Pagination
            pageNo={pageNo}
            totalNumberOfPages={totalNumberOfPages}
            totalRecords={totalRecords}
            listNumberTo={listNumberTo}
            previousPage={previousPage}
            nextPage={nextPage}
            listNumberFrom={listNumberFrom}
            isAsideBar={true}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MyLeaves;
