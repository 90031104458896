import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Chart, registerables } from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
Chart.register(...registerables);

const AttendanceAnalyticsOverviewChart = () => {
  const colors = ["#0891B2", "#4875E9", "#404968"];
  const axiosPrivate = useAxiosPrivate();
  const [attendanceAnalyticData, setAttendanceAnalyticData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const status_types = ["Present", "Absent", "Leave"]
  const fetchData = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; 
    const fromDateStr = `${selectedYear}-01-01`;
    const toDateStr = selectedYear === currentYear 
      ? `${selectedYear}-${String(currentMonth).padStart(2, '0')}-01`
      : `${selectedYear}-12-31`;

    axiosPrivate
      .get(`attendance/attendanceAnalyticsOverView?from_date=${fromDateStr}&to_date=${toDateStr}`)
      .then((response) => {
        if (response?.data?.results) {
          const attendanceAnalyticsResponseData = response?.data?.results;
          setAttendanceAnalyticData(attendanceAnalyticsResponseData);
        } else {
          setAttendanceAnalyticData([]);
        }
      })
      .catch((error) => {
        setAttendanceAnalyticData([]);
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    const ctx = document.getElementById("AttendanceAnalysisChart").getContext("2d");

    const months = [...new Set(attendanceAnalyticData.map(item => item.month_name))];
    const statusLabels = status_types;

    const datasets = statusLabels.map((status, index) => ({
      label: status,
      backgroundColor: colors[index],
      data: months.map(month => {
        const dataItem = attendanceAnalyticData.find(item =>
          item.month_name === month && item.status_label === status
        );
        return dataItem ? dataItem.status_count : 0;
      })
    }));

    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: months,
        datasets: datasets,
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: function (value, index, ticks) {
                return value;
              },
            },
            grid: {
              drawBorder: false,
              lineWidth: 0.5,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        maxBarThickness: 50,
        categoryPercentage: 0.3,
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [attendanceAnalyticData]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setShowYearDropdown(false);
  };

  return (
    <>
      <div className="border rounded-md p-5 h-full">
        <div className="flex justify-between font-medium">
          <p>Attendance Analytics</p>
          <div className="relative">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowYearDropdown(!showYearDropdown)}
            >
              <span>{selectedYear}</span>
              <i className="ico-down text-blue-600" />
            </div>
            {showYearDropdown && (
              <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg z-10">
                {[...Array(10).keys()].map((i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <div
                      key={year}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleYearChange(year)}
                    >
                      {year}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="mx-auto my-3 h-[270px]">
          <canvas id="AttendanceAnalysisChart" width="200" height="100"></canvas>
        </div>
        <div className="flex gap-2 justify-center">
          {status_types.map((status, index) => (
            <div className="flex items-center" key={status}>
              <span className={`h-2 w-3`} style={{backgroundColor: colors[index]}}></span>
              <p className="text-xs font-medium">{status}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AttendanceAnalyticsOverviewChart;
