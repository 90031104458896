import React, { useState, useEffect } from "react";
import EditOrAddPaymentInformation from "../EditOrAddPaymentInformationModal/EditOrAddPaymentInformation";
import BankAccountDetails from "./BankAccountDetails";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const BankPaymentInfo = ({ employee, handleMakeApiCall }) => {
  const [openModal, setOpenModel] = useState(false);
  const [bankList, setBankList] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);

  const getBankDetails = async () => {
    try {
      const getAccountDetails = await axiosPrivate.get(`employee/getBankList?emp_id=${employee.employee_id}`);
      setBankList(getAccountDetails?.data?.bank_details);
    } catch (error) {
      setBankList([]);
    }
  };

  useEffect(() => {
    setBankList([]);
    getBankDetails();
  }, [employee, handleMakeApiCall]);

  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Payment Information
          </p>
        </div>
        <div className="cursor-pointer">
          <button
            onClick={() => onOpenModal()}
            className="btn text-sm font-medium"
          >
            Add Bank Account
          </button>
          {openModal ? (
            <EditOrAddPaymentInformation
              openModal={openModal}
              employee={employee}
              onCloseModal={onCloseModal}
              handleMakeApiCall={getBankDetails}
              isAdd={true}
            />
          ) : null}
        </div>
      </div>
      {bankList.length > 0 ? (
        bankList.map((item, index) => (
          <BankAccountDetails
            handleMakeApiCall={handleMakeApiCall}
            bank_list={item}
            employee={employee}
            key={index}
            count={index + 1}
            getBankDetails={getBankDetails}
          />
        ))
      ) : (
        <p> No registered bank data found</p>
      )}
    </div>
  );
};

export default BankPaymentInfo;
