

import React, { useState, useEffect } from "react";
import { warningIcon } from "../../../../Icons/Icons";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { getCurrentMonthDates } from "../../../../utils/FormatDate/formaDateInMonths";

const PendingFinalizeAttendance = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [pendingData, setPendingData] = useState({});
  const { firstDay, lastDay } = getCurrentMonthDates();

  const handleGoToViewFinalizeAttendance = () => {
    navigate("/attendance/manage-attendance/finalize-attendance/view-employee-finalize-attendance");
  };

  const fetchAttendanceEmployeeList = async () => {
    try {
      const response = await axiosPrivate.get("attendance/getEmployeeAttendanceFinalizationData");
      const data = response?.data?.result;
      if (data && data?.length > 0) {
        setPendingData(data[0]);
      }
    } catch (err) {
      // console.error("Error fetching attendance data", err);
    }
  };

  useEffect(() => {
    fetchAttendanceEmployeeList();
  }, []);

  return (
    <div className="m-10 rounded-md border">
      <div className="py-5 px-4">
        <h1 className="text-black font-semibold text-base">Finalize Attendance</h1>
        <p>
          You can process and finalize attendance after completing all Pending Employee
          <br /> Leave and Regularization Approval for the current attendance period
        </p>
      </div>

      <div className="flex justify-between items-center px-4">
        <div className="flex gap-4">
          <div className="border-r p-3">
            <p>Attendance Period</p>
            <h1 className="text-black font-semibold text-lg">{firstDay} - {lastDay}</h1>
          </div>
          <div>
            <p>No Of Employee</p>
            <h1 className="text-black font-semibold text-lg">
              {pendingData?.total_employee_count}
            </h1>
          </div>
          <div>
            <p>Pending Approval</p>
            <h1 className="text-black font-semibold text-lg">
              {pendingData?.total_regularization_pending_count ?? '0'} / {pendingData?.total_employee_count ?? '0'}
            </h1>
          </div>
        </div>
        <div>
          <button className="btn" onClick={handleGoToViewFinalizeAttendance}>View Attendance</button>
        </div>
      </div>
      <div className="p-4">
        <span className="status">
          <p className="p-2 flex gap-2">
            {warningIcon}You have {pendingData?.total_regularization_pending_count ?? '0'} pending approvals
          </p>
        </span>
      </div>
    </div>
  );
};

export default PendingFinalizeAttendance;
