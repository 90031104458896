import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";
const {widgetList,updateWidgetViewStatus} = queryKeys

export const useFetchListOfWidget = (params = {}) => {

    const formatedSearchParams = formatSearchParams(params)
    const axiosPrivate = useAxiosPrivate();
    return useQuery({
      queryKey: [widgetList, params],
      queryFn: async () => {
        const { data } = await axiosPrivate.get("employee/listOfWidgets", {
          params: formatedSearchParams,
        });
        return data;
      },
    });

}

export const useUpdateWidgetViewStatus = () =>
{
    const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [updateWidgetViewStatus], 
    mutationFn: async (data) => {
   
      const response = await axiosPrivate.put("employee/updateWidgetViewStatus", data);
      return response.data;
    },
    onSuccess: (data) => {
        if (data?.status) {
      queryClient.invalidateQueries([widgetList]); 
        }
    },
    onError: (error) => {
        console.log("error:",error)
    }
  });
}