import React from "react";
import Select, { components } from "react-select";
import DirectoryCard from "../../../../components/Employee/EmployeeDirectoryCard";
import Search from "../../../../components/Header/Search/Search";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const Directory = () => {
  return (
    <div className="w-full px-10 py-6 bg-gray-50">
      <div className="flex justify-between">
        <div>
          <h2>Employee Directory</h2>
          <p className="pb-4">Manage all employe on your ogranization</p>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            <span>
              <i className="ico-plus text-white mr-1"></i>
            </span>
            Add Employee
          </a>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <Select
            options={[
              { value: "All Designation", label: "All Designation" },
              { value: "Hr Manager", label: "Hr Manager" },
              { value: "Finance Manager", label: "Finance Manager" },
              { value: "Account Manager", label: "Account Manager" },
              { value: "Business Manager", label: "Business Manager" },
              { value: "Sales Manager", label: "Sales Manager" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Designation",
              label: "Designation"
            }}
          />
          <Select
            options={[
              { value: "All Department", label: "All Department" },
              { value: "Sales", label: "Sales" },
              { value: "Network", label: "Network" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Department",
              label: "Department"
            }}
          />
          <Select
            options={[
              { value: "All Location", label: "All Location" },
              {
                value: "IV Main Office India",
                label: "IV Main Office India"
              },
              { value: "IV Main Office USA", label: "IV Main Office USA" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Location",
              label: "Location"
            }}
          />
          <Select
            options={[
              { value: "All Status", label: "All Status" },
              { value: "Active", label: "Active" },
              { value: "Onboarding", label: "Onboarding" },
              { value: "Off-boarding", label: "Off-boarding" },
              { value: "Probationary", label: "Probationary" },
              { value: "Leave", label: "Leave" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Employment Status",
              label: "Employment Status"
            }}
          />
        </div>
        <div className="flex">
          <div>
            <Search />
          </div>
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <div>
        <DirectoryCard />
      </div>
    </div>
  );
};

export default Directory;
