import React, { useState } from "react";
import {
  closeIconRoundRed,
  greenTickRound,
  viewIcon,
} from "../../../../Icons/Icons";
import DeclinePolicy from "./DeclinePolicy";
import ApprovePolicy from "./ApprovePolicy";
import PolicyPreview from "./PolicyPreview";
import CreateOrEditPolicy from "../../../Policy/CreateOrEditPolicy";
import { SYSTEM_POLICY_APPROVAL_STATUS } from "../../../../Consts/consts";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { Badge } from "antd";

const PolicyApprovalTData = ({ data }) => {
  const {
    policy_name,
    policy_serial,
    created_by_name,
    created_date,
    version,
    approval_status_id,
  } = data;
  const isPending =
    Number(approval_status_id) === SYSTEM_POLICY_APPROVAL_STATUS.pending;
  const approvalStatusLabel =
    Number(approval_status_id) === SYSTEM_POLICY_APPROVAL_STATUS.approved
      ? "Approved"
      : Number(approval_status_id) === SYSTEM_POLICY_APPROVAL_STATUS.declined
      ? "Declined"
      : "Pending";
  const approvalStatusClass =
    Number(approval_status_id) === SYSTEM_POLICY_APPROVAL_STATUS.approved
      ? "status--green"
      : Number(approval_status_id) === SYSTEM_POLICY_APPROVAL_STATUS.declined
      ? "status--red"
      : "";
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);
  const formattedCreatedDate = formatDate(created_date).dateString2;

  const handleClosePreviewAndOpenEdit = () => {
    setIsPreviewModalOpen(false);
    setIsApproveModalOpen(false);
    setIsCreatePolicyModalOpen(true);
  };

  return (
    <>
      {isDeclineModalOpen && (
        <DeclinePolicy
          isModalOpen={isDeclineModalOpen}
          handleCloseModal={() => setIsDeclineModalOpen(false)}
          systemPolicy={data}
        />
      )}
      {isApproveModalOpen && (
        <ApprovePolicy
          isModalOpen={isApproveModalOpen}
          handleCloseModal={() => setIsApproveModalOpen(false)}
          systemPolicy={data}
          // isEditable={true}
          handleClosePreviewAndOpenEdit={handleClosePreviewAndOpenEdit}
          // isPending={isPending}
        />
      )}
      {isPreviewModalOpen && (
        <PolicyPreview
          isModalOpen={isPreviewModalOpen}
          handleCloseModal={() => setIsPreviewModalOpen(false)}
          // isEditable={true}
          systemPolicy={data}
          handleClosePreviewAndOpenEdit={handleClosePreviewAndOpenEdit}
          // isPending={isPending}
        />
      )}
      {isCreatePolicyModalOpen && (
        <CreateOrEditPolicy
          isModalOpen={isCreatePolicyModalOpen}
          handleCloseModal={() => setIsCreatePolicyModalOpen(false)}
          systemPolicy={data}
        />
      )}
      {/*  */}
      <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200 ">
        <td className=" py-3 px-5 border-0">
          <button
            onClick={() => setIsPreviewModalOpen(true)}
            className="hover:text-blue-600"
          >
            {policy_name}
          </button>
        </td>
        <td className="text-center py-3 px-5 border-0">{policy_serial}</td>
        <td className="text-center py-3 px-5 border-0">{created_by_name}</td>
        <td className="text-center py-3 px-5 border-0">
          {formattedCreatedDate}
        </td>
        <td className=" py-3 px-5 border-0">
          <div className="flex justify-center">
            <Badge count={version} showZero color="blue" />
          </div>
        </td>
        <td className=" py-3 px-5 border-0">
          <div className="flex items-center gap-3">
            <button onClick={() => setIsPreviewModalOpen(true)}>
              {viewIcon}
            </button>
            {isPending ? (
              <>
                <button onClick={() => setIsDeclineModalOpen(true)}>
                  {closeIconRoundRed}
                </button>
                <button onClick={() => setIsApproveModalOpen(true)}>
                  {greenTickRound}
                </button>
              </>
            ) : (
              <span className={`status ${approvalStatusClass}`}>
                {approvalStatusLabel}
              </span>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default PolicyApprovalTData;
