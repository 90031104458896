import React from "react";
import SalaryComponentListData from "./SalaryComponentListData";

const salarycomponentData = [
  {
    id: "883",
    name: "CEO Salary",
    earning_type: "Basic	",
    calculation_type: "Fixed; 50% of CTC	",
    epf_consideration: "Yes (If PF Wage < 15k)",
    esi_consideration: "yes"
  },
  {
    id: "883",
    name: "General Officer Salary",
    earning_type: "Basic	",
    calculation_type: "Fixed; 50% of CTC",
    epf_consideration: "Yes",
    esi_consideration: "yes"
  },
  {
    id: "883",
    name: "Marketing Department Salary",
    earning_type: "Basic	",
    calculation_type: "Fixed; 50% of CTC	",
    epf_consideration: "Yes (If PF Wage < 15k)",
    esi_consideration: "yes"
  },
  {
    id: "883",
    name: "Security Salary",
    earning_type: "Basic	",
    calculation_type: "Fixed; 50% of CTC	",
    epf_consideration: "Yes",
    esi_consideration: "yes"
  }
];

const SalaryComponentList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-600 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3 flex items-center gap-3">
              <input type="checkbox" />
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              EARNING TYPE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CALCULATION TYPE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CONSIDER FOR EPF
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CONSIDER FOR ESI
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              IS ACTIVE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {salarycomponentData.map((val, key) => {
            return <SalaryComponentListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SalaryComponentList;
