import React, { Fragment, useEffect, useState } from "react";
import { closeIcon } from "../../../../../Icons/Icons";
// import HolidayTypesInPolicyList from "./HolidayTypesInPolicyList";
import { Transition } from "@headlessui/react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import HolidayList from "../../../Holiday/HolidayList/HolidayList";
import Modal from "react-responsive-modal";

const EditHolidayPolicyModal = ({ open, close, policy, makeApiCall }) => {
  const axiosPrivate = useAxiosPrivate();
  const [policyName, setPolicyName] = useState(policy?.policy_name);
  const [errMsg, setErrMsg] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState({
    insert: [],
    update: [],
  });
  const [policyMapping, setPolicyMapping] = useState([]);

  const updatePolicyName = async () => {
    if (policyName) {
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(`lms/leave/updateHolidayPolicy`, {
          policy_id: policy?.id,
          policy_name: policyName,
        })
        .then((response) => {
          // isMounted && console.log(response.data, "update holiday policy");
          const res = response?.data;
          if (res.status) {
            toast.success("Successfully updated policy name");
            makeApiCall();
          } else {
            toast.error(res.message);
            setErrMsg(res.message);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong, try again");
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Enter a valid policy name");
    }
  };

  const fetchViewPolicyMapping = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/holiday/viewPolicyMapping?policy_id=${policy.id}`)
      .then((response) => {
        // isMounted && console.log(response.data, "policymap");
        const res = response?.data;
        if (res.status) {
          setPolicyMapping(res.holidays);
        } else {
          //else part
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchViewPolicyMapping();
  }, []);

  const handleCreatePolicy = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/holiday/createPolicyMapping`, {
        policy_id: policy.id,
        holiday_id: selectedPolicy.insert,
      })
      .then((response) => {
        // isMounted && console.log(response.data, "createpolicymap");
        const res = response?.data;
        if (res.status) {
        } else {
          //else part
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleUpdatePolicy = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .put(`lms/holiday/updatePolicyMapping`, {
        policy_id: policy.id,
        holiday_id: selectedPolicy.update,
      })
      .then((response) => {
        // isMounted && console.log(response.data, "updatepolicymap");
        const res = response?.data;
        if (res.status) {
        } else {
          //else part
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const refreshUI = () => {
    setSelectedPolicy({ insert: [], update: [] });
    fetchViewPolicyMapping();
    close();
  };

  const handleSubmit = async () => {
    const data = selectedPolicy;
    if (data.update.length) {
      await handleUpdatePolicy().then(() => {
        if (data.insert.length) {
          handleCreatePolicy();
        }
        toast.success("Holiday types updated");
        refreshUI();
      });
    } else if (data.insert.length) {
      await handleCreatePolicy();
      toast.success("Holiday types updated");
      refreshUI();
    }
  };

  return (
    <Modal
      styles={{
        root: {
          zIndex: 10,
        },
      }}
      open={open}
      onClose={close}
      center
      showCloseIcon={false}
    >
      <div className="flex flex-col bg-white overflow-y-auto rounded-md justify-center  m-auto w-auto max-h-full mt-20">
        <div className="flex items-center justify-between px-6 py-4">
          <h1 className="text-lg font-medium text-black">Holiday Policy</h1>
          <span className="cursor-pointer" onClick={close}>
            {closeIcon}
          </span>
        </div>
        <div className="flex gap-4 pb-4 px-6">
          <input
            type="text"
            className="max-w-[220px]"
            defaultValue={policy.policy_name}
            onChange={(e) => setPolicyName(e.target.value)}
          />
          <button type="button" onClick={updatePolicyName} className="btn">
            Update policy name
          </button>
        </div>
        <span className="px-6 pb-3 text-red-600">{errMsg}</span>
        <div className="flex  flex-col justify-between min-h-[350px]">
          <div className="px-5">
            <HolidayList
              policyMapping={policyMapping}
              setSelectedPolicy={setSelectedPolicy}
            />
          </div>
          <div className="flex justify-end gap-3 px-5 py-6 mb-10">
            <button className="btn btn--border" onClick={close}>
              Close
            </button>
            <button className="btn" onClick={handleSubmit}>
              Update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditHolidayPolicyModal;
