import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { paySlipNotAvaible } from "../../../../Icons/Icons";

const Payslip = () => {
  const iconStyle = {
    fontSize: '60px',
  };
  return (
    
      <div className="border rounded-md p-5 h-full w-full">
        <div className="flex justify-between font-medium">
          <p>Pay Slip</p>
          <p className="flex items-center gap-2">
            Jan 2023 <i className="ico-down text-blue-600" />
          </p>
        </div>

        <div className="flex justify-center font-medium py-4"> 
        <span style={iconStyle}>{paySlipNotAvaible}</span>
        </div>

        <div className="flex gap-2 justify-center py-6">
          <div className="text-center">Payslip not available yet</div>
        </div>
        <div className="flex gap-2 justify-center py-12">
        </div>
      </div>
      
    
  )
};

export default Payslip;
