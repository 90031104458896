import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Line from "../../../components/Line";

const KnowTheJob = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 justify-center max-w-[660px]">
      <div className="pb-12">
        <h2 className="text-2xl leading-8 font-bold text-gray-800">
          Get to know the job
        </h2>
        <p className="text-sm leading-5 font-normal text-gray-400 pt-2 pb-6">
          For nearly 150 years, we’ve been changing the way the world moves
          while making it more safe, smart, sustainable, and accessible to all.
          By linking people with their destinations, we link people and their
          destinies through our cutting-edge technologies and employee culture.
        </p>

        <h3 className="text-lg leading-8 font-semibold text-gray-800">
          Your Role
        </h3>
        <p className="text-sm leading-5 font-normal text-gray-400 pt-2 pb-6">
          There's this notion that to grow a business, you have to be ruthless.
          But we know there's a better way to grow. One where what's good for
          the bottom line is also good for customers. We believe businesses can
          grow with a conscience, and succeed with a soul — and that they can do
          it with inbound. That's why we've created an ecosystem uniting
          software, education, and community to help businesses grow better
          every day.
        </p>

        <h3 className="text-lg leading-8 font-semibold text-gray-800">
          Your Responsibilites
        </h3>
        <p className="text-sm leading-5 font-normal text-gray-400 pt-2 pb-6">
          There's this notion that to grow a business, you have to be ruthless.
          But we know there's a better way to grow. One where what's good for
          the bottom line is also good for customers. We believe businesses can
          grow with a conscience, and succeed with a soul — and that they can do
          it with inbound. That's why we've created an ecosystem uniting
          software, education, and community to help businesses grow better
          every day.
        </p>

        <h3 className="text-lg leading-8 font-semibold text-gray-800">
          Your day to day task
        </h3>
        <p className="text-sm leading-5 font-normal text-gray-400 pt-2 pb-6">
          There's this notion that to grow a business, you have to be ruthless.
          But we know there's a better way to grow. One where what's good for
          the bottom line is also good for customers. We believe businesses can
          grow with a conscience, and succeed with a soul — and that they can do
          it with inbound. That's why we've created an ecosystem uniting
          software, education, and community to help businesses grow better
          every day.
        </p>
      </div>
      <Line />
      <div className="flex justify-between pt-6">
        <button className="btn btn--border" onClick={() => navigate(-1)}>
          Back
        </button>
        <Link to="/onboarding/meet-team" className="btn ">
          Continue
        </Link>
      </div>
    </div>
  );
};

export default KnowTheJob;
