import React, { useEffect, useState } from "react";
import { closeIcon } from "../../../../../Icons/Icons";
import LeaveTypesInPolicyList from "./LeaveTypesInPolicyList";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import Line from "../../../../Line";
import LoadingButton from "../../../../Buttons/LoadingButton";
import SpinnerLoader from "../../../../PageLoader/SpinnerLoader";

const EditLeavePolicyModal = ({
  open,
  close,
  policy,
  leaveTypesList,
  makeApiCall,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [policyName, setPolicyName] = useState(policy?.policy_name);
  const [errMsg, setErrMsg] = useState("");
  const policyId = policy?.id;
  const [leavePolicyMappingList, setLeavePolicyMappingList] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const isPolicyNameChanged = policyName !== policy?.policy_name;
  const isPolicyDataChanged = policyData.length;
  const [isPolicyNameUpdating, setIsPolicyNameUpdating] = useState(false);
  const [isPolicyDataUpdating, setIsPolicyDataUpdating] = useState(false);
  const [isPolicyMappingLoader, setIsPolicyMappingLoader] = useState(false);

  const updatePolicyName = async () => {
    if (policyName) {
      setIsPolicyNameUpdating(true);
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .put(`lms/leave/updateLeavePolicy`, {
          policy_id: policy?.id,
          policy_name: policyName,
        })
        .then((response) => {
          // isMounted && console.log(response.data, "update leave policy");
          const res = response?.data;
          if (res.status) {
            toast.success("Successfully updated policy name");
            makeApiCall();
          } else {
            toast.error(res.message);
            setErrMsg(res.message);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went wrong, try again");
        })
        .finally(() => {
          setIsPolicyNameUpdating(false);
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Enter a valid policy name");
    }
  };

  const fetchListLeavePolicyMapping = async () => {
    setIsPolicyMappingLoader(true);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listLeavePolicyMapping?policy_id=${policyId}`)
      .then((response) => {
        // isMounted && console.log(response.data, "update leave policy");
        const res = response?.data;
        if (res.status) {
          setLeavePolicyMappingList(res.policy_list);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Something went wrong while listing the leave policy mapping, try again"
        );
      })
      .finally(() => {
        setIsPolicyMappingLoader(false);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const manageLeavePolicyMapping = async (data) => {
    setIsPolicyDataUpdating(true);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/manageLeavePolicyMapping`, {
        policy_id: policyId,
        leave_policy_details: data,
      })
      .then((response) => {
        const res = response?.data;
        if (res?.status) {
          toast.success(res.message);
          fetchListLeavePolicyMapping();
          close();
        } else {
          toast.error(
            res?.message || "Leave policy mapping failed, Try again later."
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      })
      .finally(() => {
        setIsPolicyDataUpdating(false);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleUpdate = async () => {
    if (policyData.length) {
      manageLeavePolicyMapping(policyData);
    } else {
      close();
    }
  };

  useEffect(() => {
    fetchListLeavePolicyMapping();
  }, []);

  const [formattedLeaveTypes, setFormattedLeavetypes] = useState([]);
  useEffect(() => {
    if (Array.isArray(leaveTypesList)) {
      let formattedArr = [];

      leaveTypesList.forEach((item) => {
        const checkLeavePolicyActive = leavePolicyMappingList?.find(
          (i) => Number(i.leave_type_id) === Number(item.id)
        );
        if (checkLeavePolicyActive) {
          formattedArr.push(checkLeavePolicyActive);
        } else {
          formattedArr.push({
            ...item,
            status: 0,
            leave_type_id: Number(item.id),
          });
        }
      });
      setFormattedLeavetypes(formattedArr);
    } else {
      setFormattedLeavetypes([]);
    }
  }, [leavePolicyMappingList, leaveTypesList]);

  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={close}
      showCloseIcon={false}
      center
    >
      <div className="flex flex-col bg-white overflow-y-auto rounded-md justify-center p-5  m-auto w-auto">
        <div className="flex items-center justify-between px-6 py-4">
          <h1 className="text-lg font-medium text-black">Leave Policy</h1>
          <span className="cursor-pointer" onClick={close}>
            {closeIcon}
          </span>
        </div>
        <div className="flex gap-4 pb-4 px-6">
          <input
            type="text"
            className="max-w-[220px]"
            defaultValue={policy.policy_name}
            onChange={(e) => setPolicyName(e.target.value)}
          />

          {isPolicyNameUpdating ? (
            <LoadingButton title="Updating..." />
          ) : (
            <button
              type="button"
              disabled={!isPolicyNameChanged}
              onClick={updatePolicyName}
              className={`btn ${!isPolicyNameChanged && "opacity-50"}`}
            >
              Update policy name
            </button>
          )}
        </div>
        <span className="text-red-600 px-6 pb-3">{errMsg}</span>
        <Line className="py-3" />
        <div className="flex flex-col justify-between">
          <div className=" max-h-[450px] min-h-[200px] overflow-y-auto">
            {isPolicyMappingLoader ? (
              <div className="flex justify-center items-center h-full min-h-[200px]">
                <SpinnerLoader />
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500 ">
                <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
                  <tr>
                    <th className="px-4 py-4">Leave name</th>

                    <th className="px-4 py-4">Number of days allowed</th>
                    <th className="px-4 py-4">Status</th>
                    <th className="px-4 py-4"></th>
                  </tr>
                </thead>
                <tbody className="">
                  {Array.isArray(formattedLeaveTypes) &&
                    formattedLeaveTypes.map((item, i) => (
                      <LeaveTypesInPolicyList
                        key={`${i}_leave_types_in_policy_mappping`}
                        leave={item}
                        policyId={policyId}
                        fetchListLeavePolicyMapping={
                          fetchListLeavePolicyMapping
                        }
                        setPolicyData={setPolicyData}
                        policyData={policyData}
                      />
                    ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex justify-end gap-3 px-5 py-6">
            <button className="btn btn--border" onClick={close}>
              Close
            </button>

            {isPolicyDataUpdating ? (
              <LoadingButton title="Updating..." />
            ) : (
              <button
                disabled={!isPolicyDataChanged}
                className={`btn ${!isPolicyDataChanged && "opacity-50"}`}
                onClick={handleUpdate}
              >
                Update
              </button>
            )}
          </div>
          <div
            className="p-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
            role="alert"
          >
            <span className="font-medium">Info: </span>
            Once you are done your draft, Please click on update
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditLeavePolicyModal;
