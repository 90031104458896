import React from "react";

const PaymentSchedule = () => {
  return (
    <div className="px-10 py-6 employee-info-card bg-[#F9FAFB] w-full">
      <div className="py-7">
        <h2>Payment Schedule</h2>
      </div>
      <div className="bg-white p-6 w-1/2">
        <p className="text-[#1F2937] mb-4 font-semibold">
          Your Organizations payroll runs on this schedule
        </p>
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Pay Frequency </td>
              <td className="data">Every month (Monthly) </td>
            </tr>
            <tr>
              <td className="title">Working Days </td>
              <td className="data">Mon, Tue, Wed, Thu, Fri</td>
            </tr>
            <tr>
              <td className="title">Pay Day</td>
              <td className="data">
                25th of every month{" "}
                <span className="text-[#3466E7] text-sm">edit </span>{" "}
              </td>
            </tr>
            <tr>
              <td className="title">First Pay Period </td>
              <td className="data">March 2022 </td>
            </tr>
            <tr>
              <td className="title">No. of Working Days </td>
              <td className="data">25 </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentSchedule;
