import React from "react";
import styles from "./Payslip.module.scss";

const Payslip = () => {
  return (
    <div className="bg-gray-50 flex-1 px-10 pt-8 pb-6 flex justify-center">
      <div className="w-full max-w-[1084px]">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-lg pb-1">Payslips</h2>
            <p>Manage all employe regularization</p>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <a
                href=""
                className="btn btn--border flex items-center text-sm text-[#6B7280]"
              >
                Date: This Month
                <i className="ico-down ml-3 text-[#3466E7]"></i>
              </a>
            </div>
            <div>
              <a href="" className="btn text-sm font-medium">
                <i className="ico-download pr-3"></i>
                Download
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg mb-6">
          <div className="py-4 px-6">
            <h3 className="text-base text-gray-800 font-semibold">Summary</h3>
          </div>
          <div className="px-6 pb-6 lg:flex gap-6">
            <div className="flex-1">
              <ul className={styles.summary}>
                <li>
                  <p>Name</p>
                  <p>Jhonattan Morrizon #1216</p>
                </li>
                <li>
                  <p>Work Location</p>
                  <p>Main Office, India</p>
                </li>
                <li>
                  <p>Designations</p>
                  <p>Manager</p>
                </li>
                <li>
                  <p>Department</p>
                  <p>IT</p>
                </li>
                <li>
                  <p>PF A/C Number</p>
                  <p>SVD/27277738/261/5156</p>
                </li>
                <li>
                  <p>UAN</p>
                  <p>111882637908</p>
                </li>
                <li>
                  <p>PAN</p>
                  <p>2251882637565</p>
                </li>
                <li>
                  <p>Pay Period</p>
                  <p>01/03/2022 - 31/04/2022</p>
                </li>
                <li>
                  <p>Pay Day</p>
                  <p>24</p>
                </li>
                <li>
                  <p>LOP Days</p>
                  <p>-</p>
                </li>
              </ul>
            </div>
            <div className="bg-green-50 rounded-md px-6 py-4 flex items-center">
              <div>
                <p className="text-xs text-green-700">Net Pay for Nov 2021</p>
                <h3 className="font-bold text-green-900 text-2xl">
                  ₹ 1,43,224.00
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg mb-6 ${styles.pay}`}
        >
          <div className="py-4 px-6 flex justify-between">
            <h3 className="text-base text-gray-800 font-semibold">Earning</h3>
            <span className="text-xs">Ammount (in INR)</span>
          </div>
          <div className="px-6 pb-6">
            <ul>
              <li>
                <span>Basic</span>
                <span className="font-medium text-gray-800">66,667.00</span>
              </li>
              <li>
                <span>House Rent Allowance</span>
                <span className="font-medium text-gray-800">26,667.00</span>
              </li>
              <li>
                <span>Special Allowance</span>
                <span className="font-medium text-gray-800">1,600.00</span>
              </li>
              <li>
                <span>Bonus/ WFH Allowances</span>
                <span className="font-medium text-gray-800">71,733.00</span>
              </li>
            </ul>
          </div>
          <div className="py-3 px-6 bg-gray-100">
            <div className="flex justify-between">
              <span className="font-semibold text-gray-800">Gross Earning</span>
              <span className="font-medium text-gray-800">1,66,667.00</span>
            </div>
          </div>
        </div>

        <div
          className={`bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg mb-6 ${styles.pay}`}
        >
          <div className="py-4 px-6 flex justify-between">
            <h3 className="text-base text-gray-800 font-semibold">
              Deductions
            </h3>
            <span className="text-xs">Ammount (in INR)</span>
          </div>
          <div className="px-6 pb-6">
            <ul>
              <li>
                <span>Provident Fund</span>
                <span className="font-medium text-gray-800">1,800.00</span>
              </li>
              <li>
                <span>KS&CEWF/LWF</span>
                <span className="font-medium text-gray-800">11,443.00</span>
              </li>
              <li>
                <span>TDS/Income Tax</span>
                <span className="font-medium text-gray-800">200.00</span>
              </li>
              <li>
                <span>Profesional Tax</span>
                <span className="font-medium text-gray-800">10,000.00</span>
              </li>
            </ul>
          </div>
          <div className="py-3 px-6 bg-gray-100">
            <div className="flex justify-between">
              <span className="font-semibold text-gray-800">
                Total Deduction
              </span>
              <span className="font-medium text-gray-800">-23,443.00</span>
            </div>
          </div>
        </div>

        <div
          className={`bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg mb-6 ${styles.pay}`}
        >
          <div className="py-4 px-6 flex justify-between">
            <h3 className="text-base text-gray-800 font-semibold">
              Reimbursement
            </h3>
            <span className="text-xs">Ammount (in INR)</span>
          </div>
          <div className="px-6 pb-6">
            <ul>
              <li>
                <span>Fuel Reimbursement</span>
                <span className="font-medium text-gray-800">100.00</span>
              </li>
              <li>
                <span>Travel Ticket</span>
                <span className="font-medium text-gray-800">400.00</span>
              </li>
            </ul>
          </div>
          <div className="py-3 px-6 bg-gray-100">
            <div className="flex justify-between">
              <span className="font-semibold text-gray-800">
                Total Deduction
              </span>
              <span className="font-medium text-gray-800">500.00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payslip;
