import React, { useState } from "react";

import ApplicableForDepartmentSelectComp from "./ApplicableForDepartmentSelectComp";
import ApplicableForDesignationSelectComp from "./ApplicableForDesignationSelectComp";
import ApplicableForEmployeeSelectComp from "./ApplicableForEmployeeSelectComp";
import ApplicableForEmpTypeSelectComp from "./ApplicableForEmpTypeSelectComp";
import ApplicableForLocationsSelectComp from "./ApplicableForLocationsSelectComp";

const ApplicableForSelectComp = ({
  handleChangeApplicableFor,
  applicableForData,
}) => {
  const menu = [
    "Employee",
    "Emp. Type",
    "Location",
    "Departments",
    "Designations",
  ];
  const tabs = [
    <ApplicableForEmployeeSelectComp
      applicableForData={applicableForData}
      handleChangeApplicableFor={handleChangeApplicableFor}
    />,
    <ApplicableForEmpTypeSelectComp
      applicableForData={applicableForData}
      handleChangeApplicableFor={handleChangeApplicableFor}
    />,
    <ApplicableForLocationsSelectComp
      applicableForData={applicableForData}
      handleChangeApplicableFor={handleChangeApplicableFor}
    />,
    <ApplicableForDepartmentSelectComp
      applicableForData={applicableForData}
      handleChangeApplicableFor={handleChangeApplicableFor}
    />,
    <ApplicableForDesignationSelectComp
      applicableForData={applicableForData}
      handleChangeApplicableFor={handleChangeApplicableFor}
    />,
  ];
  const [index, setIndex] = useState(0);

  const handleToggleTab = (e) => {
    setIndex(e);
  };

  return (
    <>
      <div className="sticky mt-[30px] z-40 left-0 bg-white shadow w-full border rounded-md border-gray-300">
        <div className="flex">
          <div className="border-r-2">
            <ul>
              {menu.map((item, i) => {
                return (
                  <li
                    className={`font-medium p-3 cursor-pointer hover:bg-gray-200 ${
                      index === i ? "bg-gray-200" : ""
                    }`}
                    key={i}
                    onClick={() => handleToggleTab(i)}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="p-3 w-full">{tabs[index]}</div>
        </div>
      </div>
    </>
  );
};
export default ApplicableForSelectComp;
