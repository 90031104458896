import React from "react";
import Avatar from "../../Avatar";

const DashboardMemberList = (props) => {
  const dashMemberList = props.requestdata;
  return (
    <div className="py-2 px-4">
      <div className="flex justify-between items-center border-b border-b-gray-300 py-3">
        <div className="flex justify-between items-center gap-3">
          <Avatar image={dashMemberList.image} status="available" />
          <div>
            <p className="text-sm leading-5 font-medium text-gray-800">
              {dashMemberList.Name}
            </p>
            <p className="text-xs leading-4 font-normal text-gray-500">
              {dashMemberList.department}
            </p>
          </div>
        </div>
        <div className="flex gap-2 relative">
          <i className="ico-phone text-gray-600 text-xl cursor-pointer"></i>
          <i className="ico-chat text-gray-600 text-xl cursor-pointer"></i>
          <span className="absolute block w-[10px] h-[10px] bg-red-400 rounded-full right-[2px] top-[2px] border-[2px] border-white"></span>
        </div>
      </div>
    </div>
  );
};

export default DashboardMemberList;
