import React from "react";

const CompletedFinalizeAttendaceData = ({ data }) => {
  return (
    <tr>
      {" "}
      <td className="px-10 py-4 font-medium text-blue-600">
        {data.attendanceDate}
      </td>
      <td className="px-10 py-4 text-black">{data.finalizationDate}</td>
    </tr>
  );
};

export default CompletedFinalizeAttendaceData;
