import React, { useEffect, useState } from "react";
import LeavePolicyListData from "./LeavePolicyListData";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import EmptyState from "../../../../EmptyState/EmptyState";
import PageLoader from "../../../../PageLoader/PageLoader";

const LeavePolicyListTable = ({ refreshBtn, fetchLeavePolicy }) => {
  const axiosPrivate = useAxiosPrivate();
  const [leavePolicyList, setLeavePolicyList] = useState(null);
  const [leaveTypesList, setLeaveTypesList] = useState(null);
  const [component, setComponent] = useState(<PageLoader />);

  const fetchLeavePolicyList = async () => {
    setComponent(<PageLoader />);
    setLeavePolicyList(null)
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/listLeavePolicy`)
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          if (res?.policy_list?.length) {
            setLeavePolicyList(res.policy_list);
          setComponent(null);
          } else {
            setComponent(<EmptyState/>)
          }
        } else {
          setLeavePolicyList(null);
          setComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        setLeavePolicyList(null);
        setComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchLeaveTypesList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("lms/leave/leaveTypesList?status=1&fullDetails=1")
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          setLeaveTypesList(res.data);
        }
      })
      .catch((err) => {
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeavePolicyList();
    fetchLeaveTypesList();
  }, [refreshBtn, fetchLeavePolicy]);

  return (
    <>
      <div className="relative">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
          <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              <th scope="col" className="px-10 py-3">
                <div className="flex items-center">
                  Leave Policy
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </div>
              </th>
              <th scope="col" className="px-10 py-3"></th>
              <th scope="col" className="px-10 py-3"></th>
              <th scope="col" className="px-10 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {leavePolicyList &&
              leavePolicyList.map((item) => (
                <LeavePolicyListData
                  policy={item}
                  leaveTypesList={leaveTypesList}
                  makeApiCall={() => fetchLeavePolicyList()}
                />
              ))}
          </tbody>
        </table>
        {component}
      </div>
    </>
  );
};

export default LeavePolicyListTable;
