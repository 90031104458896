import React from "react";
import Avatar from "../Avatar";

const TalentPoolList = (props) => {
  return (
    <tr class="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200">
      <td class="px-10 py-4">
        <input type="checkbox" />
      </td>
      <td scope="row" class="px-10 py-4 whitespace-nowrap">
        {props.requestdata.id}
      </td>
      <td class="px-10 py-4 flex items-center gap-3 border-0">
        <Avatar image={props.requestdata.image} />
        {props.requestdata.candidate_name}
      </td>
      <td class="px-10 py-4">{props.requestdata.added_by}</td>
      <td class="px-10 py-4">{props.requestdata.added_date}</td>
      <td class="px-10 py-4">{props.requestdata.source}</td>
      <td class="px-10 py-4">{props.requestdata.email}</td>
      <td class="px-10 py-4">{props.requestdata.phone}</td>
      <td class="px-10 py-4 text-right">
        <i className="ico-more text-gray-500 cursor-pointer"></i>
      </td>
    </tr>
  );
};

export default TalentPoolList;
