import formatSecondsToTime from "../FormatDate/formatSecondsToTime";
import moment from "moment";

export const formatClockDuration = (attendanceActivityData) => {
  const CLOCK_IN = 1;
  if (attendanceActivityData) {
    const { lastActivityType, lastClockIn, lastActivitiyDurationInSecond } =
      attendanceActivityData;
    if (lastActivityType && lastActivityType === CLOCK_IN) {
      const lastClockInTime = `${lastClockIn.split(".")[0]}`;
      const now = `${moment().format("YYYY-MM-DD")}T${moment().format(
        "HH:mm:ss"
      )}`.slice(0, 19);
      const diffOfNowAndLastClockIn = moment(now).diff(
        moment(lastClockInTime),
        "seconds"
      );
      const finalDiffSeconds =
        diffOfNowAndLastClockIn +
        (Number.isInteger(lastActivitiyDurationInSecond)
          ? lastActivitiyDurationInSecond
          : 0);
      return {
        durationInSeconds: finalDiffSeconds,
        message: formatSecondsToTime(finalDiffSeconds),
      };
    } else {
      return {
        durationInSeconds: lastActivitiyDurationInSecond,
        message: formatSecondsToTime(lastActivitiyDurationInSecond),
      };
    }
  }
};
