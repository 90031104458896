import React, { useState } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../../../Icons/Icons";
import AttendanceOverviewDaysView from "../AttendanceOverviewSlidingPanel/AttendanceOverviewDaysView"
import AttendanceOverviewHoursView from "../AttendanceOverviewSlidingPanel/AttendanceOverviewHoursView"


const HOURS_TAB = 1;
const DAYS_TAB = 2;

const AttendanceOverviewSlidingPanel = ({ open, close }) => {
  const [toggleState, toggleTab] = useState(HOURS_TAB);
  return (
    <>
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="420px">
        <div className="text-black">
          <div className="px-5 flex justify-between">
            <span className="font-medium text-base">
              Januray 2023 Attendance
            </span>
            <button onClick={close}>{closeIcon}</button>
          </div>
          <div className="flex items-center justify-between  border-b-2">
            <div className="flex items-center gap-12 pl-4 pt-6">
              <button
                className={
                  toggleState === HOURS_TAB
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(HOURS_TAB)}
              >
                Hours
              </button>
              <button
                className={
                  toggleState === DAYS_TAB
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(DAYS_TAB)}
              >
                Days
              </button>
            </div>
          </div>
        </div>
        {
          toggleState === HOURS_TAB ? <AttendanceOverviewHoursView/> : <AttendanceOverviewDaysView/>
        }
      </ReactSlidingPane>
    </>
  );
};

export default AttendanceOverviewSlidingPanel;
