import React, { useEffect, useState } from "react";
import BigCalendar from "../../../../components/BigCalendar/BigCalendar";
import Select, { components } from "react-select";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import moment from "moment";

const FULL_DAY_LEAVE_MODE_ID = 2;
const FILTER_OPTIONS = {
  TEAM: "team"
}


const EmployeeLeaveCalendar = () => {
  const axiosPrivate = useAxiosPrivate();
  const [dateRange, setDateRange] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const { fromDate, toDate } = dateRange;
  const [calendarData, setCalendarData] = useState([]);

  const [teamList, setTeamList] = useState([]);
  const [filters, setFilters] = useState({});

  const handleSetFilter = (value, source) => {
    setFilters((prev) => ({
      ...prev,
      [source]: value,
    }))
  }

  const fetchTeamList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    await axiosPrivate
      .get("employee/teamlist", {
        signal: controller.signal,
      })
      .then((response) => {
        // console.log(response);
        if (response?.data?.status) {
          const formattedData = response?.data?.teams?.map((val) => ({
            label: val.team_name,
            value: val.id,
          }));
          setTeamList(formattedData);
        }
      })
      .catch((err) => {});

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const formatCalendarData = (data) => {
    const formatedData = [];
    data.forEach((item) => {
      const {
        leave_mode_id,
        from_date,
        to_date,
        from_time,
        to_time,
        leave_serial,
        duration_in_day,
        duration_in_hour,
        employee_name,
        designation_title,
        department_name,
        leave_name,
      } = item;
      const isFullDayLeaveMode =
        Number(leave_mode_id) === FULL_DAY_LEAVE_MODE_ID;
      const fromDate = moment(from_date).format("YYYY-MM-DD");
      const toDate = moment(to_date).format("YYYY-MM-DD");
      const backGroundColor = isFullDayLeaveMode ? "#86efac" : "#a78bfa";
      const borderColor = isFullDayLeaveMode ? "#16a34a" : "#7c3aed";
      formatedData.push({
        title: (
          <div>
            <p>{employee_name}</p>
          </div>
        ),
        details: (
          <div>
            <p className="text-xs">
              {department_name} {department_name && designation_title && "|"}{" "}
              {designation_title}
            </p>
            <p>
              {leave_name} | {leave_serial}
            </p>
          </div>
        ),
        start: new Date(fromDate),
        end: new Date(toDate),
        titleColor: "",
        backGroundColor,
        borderColor,
      });
    });
    setCalendarData(formatedData);
  };

  const fetchEmployeeLeaveList = async () => {
    const response = await axiosPrivate
      .get(
        `lms/leave/employeeLeaveList`, {
          params: {
            ...filters,
            from_date: fromDate,
            to_date: toDate
          }
        }
      )
      .then((res) => res)
      .catch((err) => err);
    if (response?.data?.status) {
      const data = response?.data?.results || [];
      formatCalendarData(data);
    } else {
      setCalendarData([])
    }
  };

  useEffect(() => {
    if (teamList.length === 0) {
      fetchTeamList()
    }
    fetchEmployeeLeaveList();
  }, [dateRange, filters]);

  const handleChangeDate = (e) => {
    const { start, end } = e;
    if (start && end) {
      setDateRange({
        fromDate: moment(start).format("YYYY-MM-DD"),
        toDate: moment(end).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className="flex-1 px-10 pt-8 pb-6 border-b border-gray-200">
      <div className="mb-10">
        <h2 className="text-xl">Employee Leave</h2>
        <p>Manage and view all employee leavs</p>
      </div>
      <div className="flex mb-5">
        {/* <Select placeholder="All Employee" components={{ DropdownIndicator }} /> */}
        <Select placeholder="Team: All" isClearable
              onChange={(e) => handleSetFilter(e?.value, FILTER_OPTIONS.TEAM)} options={teamList} components={{ DropdownIndicator }} />
      </div>
      <BigCalendar
        myEventsList={calendarData}
        handleChangeDate={handleChangeDate}
      />
    </div>
  );
};

export default EmployeeLeaveCalendar;
