import React, { useEffect, useState } from "react";
import MyAttendanceListData from "../AttendanceListData/MyAttendanceListData";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
const MyAttendanceTable = ({
  isProcessAttendance,
  selectedOption,
  label,
  fromDate,
  toDate,
}) => {
  const [clockInClockOutList, setClockInClockOutList] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const fetchClockInClockOutData = async () => {
    const fromDateFormatted = moment(fromDate).format("YYYY-MM-DD");
    const toDateFormatted = moment(toDate).format("YYYY-MM-DD");
    try {
      const response = await axiosPrivate.get(
        `attendance/getListOfClockInAndClockOut`,
        {
          params: {
            label: label?.value,
            selectedOption: selectedOption?.value,
            from_date: fromDateFormatted,
            to_date: toDateFormatted,
          },
        }
      );

      if (response?.data?.data) {
        setClockInClockOutList(response.data.data);
      } else {
        setClockInClockOutList([]);
      }
    } catch (error) {
      setClockInClockOutList([]);
    }
  };

  useEffect(() => {
    fetchClockInClockOutData();
  }, [selectedOption, fromDate, toDate, label]);

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" className="px-10 py-3">
              Attendance Date
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              First Clock-In
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Last Clock-out
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Overtime/Deviation
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Total Hours
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Attendance Status
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {clockInClockOutList.map((val, index) => {
            return (
              <MyAttendanceListData
                isProcessAttendance={isProcessAttendance}
                requestdata={val}
                key={index}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyAttendanceTable;
