import React from "react";
import LeavePercentage from "../../../../components/LeavePercentage/LeavePercentage";

function LeaveItem({ leave }) {
  return (
    <div className="p-4 rounded-md border border-gray-300 hover:border-blue-600 min-h-[149px] min-w-[234px]">
      <LeavePercentage leavedata={leave} />
    </div>
  );
}

export default LeaveItem;
