import React, { Fragment, useState } from "react";
import SlidingPanel from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./Slidingpane.scss";
import Line from "../Line/Line";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { dragIcon, closeIcon } from "../../Icons/Icons";

const CustomizeTableColumn = (props) => {
  const getSidePanelColState = (e) => {};
  return (
    <Fragment>
      <div>
        <div className="w-3/4">
          <div>
            <SlidingPanel
              isOpen={props.isSidePanelOpen}
              width="480px"
              onRequestClose={() => {
                props.setSidePanelState(false);
              }}
            >
              <div className="flex py-6 px-4 justify-between items-center">
                <h2 className="">Customize Table Column</h2>
                <button
                  onClick={() => {
                    props.setSidePanelState(false);
                  }}
                >
                  {closeIcon}
                </button>
              </div>
              <Line />
              {props.tableCol &&
                props.tableCol.map((item, index) => {
                  return (
                    <div
                      className="px-7 flex items-center justify-between mb-5"
                      key={index}
                    >
                      <span className="flex items-center">
                        {dragIcon}
                        <p className="ml-6 font-medium text-black">
                          {item.col}
                        </p>
                      </span>
                      <ToggleSwitch
                        checked={item.status}
                        // onChange={getSidePanelColState}
                      />
                    </div>
                  );
                })}
            </SlidingPanel>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomizeTableColumn;
