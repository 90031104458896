import React, { useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../../../Buttons/LoadingButton";

const EMPLOYEE_LEAVE_REPORT = "employeeLeaveReport";
const EMPLOYEE_CONSOLIDATE_REPORT = "employeeConsolidateReport";

const LeaveReportDownloadOption = ({ filters, setIsReportDownloadModal }) => {
  const axiosPrivate = useAxiosPrivate();
  const urlParams = new URLSearchParams(filters);
  const queryParams = Object.fromEntries(urlParams.entries());
  const [errMsg, setErrMsg] = useState("");
  const [btnLoader, setBtnLoader] = useState("");
  const isEmployeeLeaveReportBtn = btnLoader === EMPLOYEE_LEAVE_REPORT;
  const isEmployeeConsolidatedReportBtn =
    btnLoader === EMPLOYEE_CONSOLIDATE_REPORT;

  const formatReportUrl = async (baseUrl) => {
    let url = baseUrl;
    const excludedFilters = [
      "pageNo",
      "pageSize",
      "lms/leave/hrApprovalList?pageNo",
    ];
    const params = Object.keys(queryParams);
    params.forEach((param) => {
      if (!excludedFilters.includes(param)) {
        url += `&${param}=${queryParams[param]}`;
      }
    });
    return url;
  };

  const handleDownloadReport = async (source) => {
    let baseUrl = "";
    if (source === EMPLOYEE_LEAVE_REPORT) {
      baseUrl = `lms/leave/generateEmployeeLeaveReport?reportData`;
    } else {
      baseUrl = `lms/leave/consolidationEmployeeLeaveReport?reportData`;
    }
    setBtnLoader(source);
    const formattedUrl = await formatReportUrl(baseUrl);
    const response = await axiosPrivate
      .get(formattedUrl)
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setBtnLoader("");
      });
    if (Number(response?.status === 200) && typeof response.data === "string") {
      try {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName =
          source === EMPLOYEE_LEAVE_REPORT
            ? `employee_leave_report`
            : "consolidation_report";
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Report downloaded");
        setIsReportDownloadModal(false);
      } catch (error) {
        setErrMsg(response?.data?.message || "Failed to generate report");
      }
    } else {
      setErrMsg(
        response?.response?.data?.message || "Failed to generate report"
      );
    }
  };
  return (
    <div className="flex flex-col gap-1 whitespace-nowrap py-3">
      <span className="font-medium text-red-600">{errMsg}</span>
      <div className="flex gap-3 items-center justify-between border-b p-2">
        <span className="fonr-medium text-gray-800">Employee Leave Report</span>
        <div className="flex gap-3">
          {isEmployeeLeaveReportBtn ? (
            <LoadingButton bg="bg-white" title=" " />
          ) : (
            <button
              onClick={() => handleDownloadReport(EMPLOYEE_LEAVE_REPORT)}
              className="btn btn--border text-[14px]"
            >
              <span>
                <i className="ico-download"></i>
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="flex gap-3 items-center justify-between border-b p-2">
        <span className="fonr-medium text-gray-800">
          Employee Consolidation Report
        </span>
        <div className="flex gap-3">
          {isEmployeeConsolidatedReportBtn ? (
            <LoadingButton bg="bg-white" title=" " />
          ) : (
            <button
              onClick={() => handleDownloadReport(EMPLOYEE_CONSOLIDATE_REPORT)}
              className="btn btn--border text-[14px]"
            >
              <span>
                <i className="ico-download "></i>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveReportDownloadOption;
