import React from "react";
import SharedDocument from "./SharedDocument";
import PersonalDocument from "./PersonalDocument";

const DocumentsInfo = () => {
  return (
    <div>
      <SharedDocument />
      <PersonalDocument />
    </div>
  );
};

export default DocumentsInfo;
