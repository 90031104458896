import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import RegularizationRequestOverviewSwiper from "../../../../components/Attendance/SelfService/MyRegularizationRequest/RegularizationRequestOverviewSwiper";
import MyRegularizationRequestTable from "../../../../components/Attendance/SelfService/MyRegularizationRequest/MyRegularizationRequestTable/MyRegularizationRequestTable";
import NewRegularizationRequestSlidingPanel from "../../../../components/Attendance/SelfService/MyAttendance/NewRegularizationRequestSlidingPanel";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {
  regularizationStatusOptions,
  regularizationTypeOptions,
} from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const MyRegularizationRequest = () => {
  const [regularizationCount, setRegularizationCount] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const regularizationRequestData = async () => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate
      .get("attendance/regularizationRequestCount")
      .then((response) => {
        if (response?.data?.result) {
          const regularizationCount = response.data.result;
          setRegularizationCount(regularizationCount);
        } else {
          setRegularizationCount([]);
        }
      })
      .catch((error) => {
        setRegularizationCount([]);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  useEffect(() => {
    regularizationRequestData();
  }, []);
  const [
    newRegularizationRequestSlidingPanelOpen,
    setNewRegularizationRequestSlidingPanelOpen,
  ] = useState(false);

  const handleOpenReqularizationRequestSlidingPanel = () => {
    setNewRegularizationRequestSlidingPanelOpen(true);
  };
  const handleCloseReqularizationRequestSlidingPanel = () => {
    setNewRegularizationRequestSlidingPanelOpen(false);
  };
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setSelectedDate(dateValue);
    setFilterDate(dateValue);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
    setFilterType(selectedOption?.value);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setFilterStatus(selectedOption?.value);
  };
  const clearFilters = () => {
    setFilterDate(null);
    setFilterType(null);
    setFilterStatus(null);
    setSelectedDate(null);
    setSelectedType(null);
    setSelectedStatus(null);
  };
  return (
    <>
      <NewRegularizationRequestSlidingPanel
        open={newRegularizationRequestSlidingPanelOpen}
        close={handleCloseReqularizationRequestSlidingPanel}
      />
      <div className="w-full flex flex-col gap-3">
        <div className="flex justify-between pt-5 px-10">
          <div>
            <h1 className="text-lg font-medium text-black">
              My Regularization Requests
            </h1>
            <span>
              you can raise a request and rectify incorrect attendance entries
            </span>
          </div>
          <div>
            <button
              className="btn"
              onClick={handleOpenReqularizationRequestSlidingPanel}
            >
              <i className="ico-plus pr-1"></i> New Request
            </button>
          </div>
        </div>
        <div className="swiper-outer mr-10 relative overflow-hidden">
          <Swiper
            modules={[Navigation]}
            spaceBetween={1}
            slidesPerView={"auto"}
            navigation
            updateOnWindowResize
          >
            {regularizationCount &&
              regularizationCount.map((item, index) => {
                return (
                  <SwiperSlide>
                    <RegularizationRequestOverviewSwiper
                      key={index}
                      data={item}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="flex justify-between px-10 py-4 ">
          <div className="flex gap-3">
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
            <Select
              components={{ DropdownIndicator }}
              value={selectedType}
              onChange={handleTypeChange}
              options={regularizationTypeOptions}
              placeholder="Select Type"
              styles={{ minWidth: "200px" }}
        
            />
            <Select
              components={{ DropdownIndicator }}
              value={selectedStatus}
              onChange={handleStatusChange}
              options={regularizationStatusOptions}
              placeholder="Select Status"
             
            />
          {filterDate || filterType || filterStatus ? (
      <span onClick={clearFilters}>Clear Filters</span>
    ) : null}
          </div>
          

          <div>
            {/* <button className="btn btn--border">
              <i className="ico-download"></i> Download
            </button> */}
          </div>
        </div>
        <MyRegularizationRequestTable
          filterDate={filterDate}
          filterType={filterType}
          filterStatus={filterStatus}
        />
      </div>

    </>
  );
};

export default MyRegularizationRequest;
