import React from "react";
import AdjustLeaveBalanceSlidingPanelData from "./AdjustLeaveBalanceSlidingPanelData";
import formatLeaveDuration from "../../../utils/FormatLeaveDuration/formatLeaveDuration";

const cols = [
  {
    colName: "Leave Policy",
  },
  {
    colName: "Type",
  },
  // {
  //   colName: "Date",
  // },
  {
    colName: "Existing Balance",
  },
  {
    colName: "New Balance in Day(s)",
  },
  {
    colName: "New Balance in Hour(s)",
  },
  {
    colName: "Adjustment Reason",
  },
];

const AdjustLeaveBalanceSlidingPanelTable = ({
  selectedEmployee,
  leaveTypes,
  handleSetLeaveTypeValue,
  leaveType,
}) => {
  const leaveBalances = selectedEmployee?.leaveBalances || [];
  return (
    <table>
      <thead>
        <tr>
          {cols.map((col, index) => {
            return (
              <th
                key={`${index}_table_col_adjust_leave_bal`}
                className="text-left pl-5 py-3 whitespace-nowrap"
              >
                <div className="flex items-center gap-1">
                  {col.colName}
                  <i className="ico-down text-blue-600" />
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {leaveTypes.map((item, index) => {
          const getLeave = leaveBalances?.find(
            (i) => Number(i?.leave_type_id) === Number(item?.id)
          );
          const availableBalanceInDays = getLeave?.leave_balance_days || 0;
          const availableBalanceInHours = getLeave?.leave_balance_hours || 0;
          const availableBalance = formatLeaveDuration({
            durationInDay: availableBalanceInDays,
            durationInHour: availableBalanceInHours,
          });
          const isLopLeave = item.is_lop;
          if (getLeave && !isLopLeave) {
            return (
              <AdjustLeaveBalanceSlidingPanelData
                data={{ ...item, availableBalance, availableBalanceInDays, availableBalanceInHours }}
                key={index}
                handleSetLeaveTypeValue={handleSetLeaveTypeValue}
              />
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default AdjustLeaveBalanceSlidingPanelTable;
