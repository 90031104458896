import React from "react";
import { useSelector } from "react-redux";
import Aside from "../../containers/Dashboard/Aside";
import DashboardSidebar from "../../components/DashboardSidebar";
import { Outlet } from "react-router-dom";

const DashboardLayout = ({ asideBarMenu }) => {
  const layoutState = useSelector((state) => state.layout);
  const isAsideBarHaveMenu =
    Array.isArray(asideBarMenu) && asideBarMenu.length > 0;
  const isAsideBarExpanded =
    isAsideBarHaveMenu && layoutState?.isAsideBarExpanded;

  return (
    <div className="flex">
      <DashboardSidebar />
      {isAsideBarHaveMenu && (
        <div
          className={`flex`}
        >
          <Aside submenu={asideBarMenu} />
        </div>
      )}
      <div
        className={`${
          isAsideBarExpanded
            ? "w-[calc(100%-319px)]"
            : !isAsideBarHaveMenu
            ? "w-[calc(100%-105px)]"
            : "w-[calc(100%-125px)]"
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
