import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import userRoles from "../../config/userRoles";

const TaskModule = () => {
  const [submenu, setSubmenu] = useState([
    {
      text: "Approval",
      link: "/task/approval/leave-applications",
      icon: "ico-down",
      roles: [userRoles.TENANTADMIN],
      menu: [
        {
          text: "Regularization",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Leave Applications",
          link: "/task/approval/leave-applications",
          roles: [2, 4],
        },
        {
          text: "Reimbursement",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Separation",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Task",
          link: "#",
          roles: [2, 4],
        },
        {
          text: "Policy",
          link: "/task/approval/policy",
          roles: [userRoles.TENANTADMIN],
        },
      ],
      status: true,
    },
    // {
    //   text: "Task",
    //   link: "#",
    //   icon: "ico-down",
    //   roles: [1, 2, 4, 6],
    //   menu: [
    //     {
    //       text: "Task",
    //       link: "#",
    //       roles: [1, 2, 4, 6],
    //     },
    //   ],
    //   status: false,
    // },
  ]);
  return <DashboardLayout asideBarMenu={submenu} />;
};

export default TaskModule;
