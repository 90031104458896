import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Line from "../../../Line";
import Pagination from "../../../Pagination/Pagination";
import { useGetEmployeeSystemPolicyViewList } from "../../../../queries/systemPolicyQueries";
import PolicyEmployeeViewStatusTable from "./PolicyEmployeeViewStatusTable";
import Search from "../../../Header/Search";
import PageLoader from "../../../PageLoader";
import { Empty } from "antd";

const PolicyManageEmployeeView = ({
  isModalOpen,
  handleCloseModal,
  systemPolicy,
}) => {
  const { id, policy_name, policy_serial } = systemPolicy;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const { data: getEmployeeViewStatus, isLoading } =
    useGetEmployeeSystemPolicyViewList({
      page_number: pageNo,
      page_limit: pageSize,
      system_policy: id,
      filter: searchValue,
    });
  const employeeViewStatus = Array.isArray(getEmployeeViewStatus?.data)
    ? getEmployeeViewStatus?.data
    : [];
  const totalRecords = Number(getEmployeeViewStatus?.totalRecords || 0);

  useEffect(() => {
    if (getEmployeeViewStatus?.description === "End of pagination.") {
      setPageNo(1);
    }
  }, [getEmployeeViewStatus]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      center
      closeOnOverlayClick={false}
    >
      <div className="w-[700px]">
        <div className="">
          <h1 className="text-lg font-medium text-gray-800">
            Employee view status for policy
          </h1>
          <span>Manage employee view access for policy</span>
        </div>
        <Line className="my-2" />
        <div className="my-2 flex justify-between items-center">
          <div className="flex flex-col gap-1 text-xs">
          <span>Policy name: {policy_name}</span>
          <span>Policy serial: {policy_serial}</span>
          </div>
          <Search onChange={(e) => setSearchValue(e)} />
        </div>
        {isLoading ? (
          <PageLoader />
        ) : employeeViewStatus?.length ? (
          <PolicyEmployeeViewStatusTable data={employeeViewStatus} />
        ) : (
          <Empty />
        )}

        <Pagination
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      </div>
    </Modal>
  );
};

export default PolicyManageEmployeeView;
