import React, { useEffect, useState } from "react";
import RegularizationApprovalData from "./RegularizationApprovalData";
import { TAB } from "../../../../containers/Attendance/consts";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import Pagination from "../../../../components/Pagination/Pagination";

const RegularizationApprovalTable = ({
  toggleState,
  handleSelectMultipleRequest,
  filters,
}) => {
  const isCancelTab = toggleState === TAB.CANCELED_TAB;
  const cols = [
    {
      colName: <input type="checkbox" onClick={handleSelectMultipleRequest} />,
      status: isCancelTab ? false : true,
    },
    { colName: "Requested By", status: true },
    { colName: "Request Date", status: true },
    { colName: "Attendance Date", status: true },
    {
      colName: isCancelTab ? "Cancelation Reason" : "Regularization Reason",
      status: true,
    },
    { colName: "Total Hour", status: true },
    { colName: <i className="ico-grid text-blue-600"></i>, status: true },
  ];
  const [regularizationRequestList, setRegularizationRequestList] = useState([]);
  const [filteredRequestList, setFilteredRequestList] = useState([])
  const axiosPrivate = useAxiosPrivate();
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, 2);

  
  const generateApiEndpoint = (filters) => {
    let endpoint = `/attendance/hr_listOfReqularizationRequests?`;
  
    if (filters.department) {
      endpoint += `&department=${filters.department}`;
    }
  
    if (filters.location) {
      endpoint += `&location=${filters.location}`;
    }
    if(filters.designation){
      endpoint += `&designation=${filters.designation}`
    }
    if (filters.from_date) {
      endpoint += `&from_date=${filters.from_date}`;
    }
  
    switch (toggleState) {
      case TAB.PENDING_TAB:
        endpoint += `&status=2`;
        break;
      case TAB.APPROVED_TAB:
        endpoint += `&status=1`;
        break;
      case TAB.DECLINED_TAB:
        endpoint += `&status=3`;
        break;
      case TAB.CANCELED_TAB:
        endpoint += `&status=4`;
        break;
      default:
        break;
    }
  
    return endpoint;
  };


  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get(generateApiEndpoint(filters));
      if (response) {
        const regularizationRequestList = response?.data?.data;
        setRegularizationRequestList(regularizationRequestList);
        setFilteredRequestList(regularizationRequestList);
      } else {
        setRegularizationRequestList([]);
        setFilteredRequestList([]);
      }
    } catch (error) {
      setRegularizationRequestList([]);
      setFilteredRequestList([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [toggleState, filters]);
  
  const MakeAPICall = async () => {
    await fetchData(); 
  };
  
  return (
    <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {cols.map((item, index) => {
              if (item.status) {
                return (
                  <th key={index} scope="col" className="px-10 py-3">
                    {item.colName}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {filteredRequestList && filteredRequestList.length > 0 ? (
            filteredRequestList.map((item, index) => (
              <RegularizationApprovalData key={index} data={item} toggleState={toggleState} MakeAPICall={MakeAPICall} />
            ))
          ) : (
            <tr>
              <td colSpan={cols.length} className="px-10 py-3 text-center">
                No data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div>
        <Pagination />
      </div>
    </>
  );
};

export default RegularizationApprovalTable;
