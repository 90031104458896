import React from 'react';
import Avatar from '../Avatar';

const SingleProfile = (props) => {
  const memberData = props?.memberdata
  return (
      <div className="rounded border border-gray-300 p-5 flex flex-col items-center mb-4">
        <Avatar image={memberData.photo} />
        <div className='flex flex-col items-center'>
              <h3 className='text-gray-900 font-semibold text-base leading-6 pt-4'>{`${memberData.first_name} ${memberData.last_name}`}</h3>
              <h4 className='text-gray-600 text-sm leading-5 font-medium'>{memberData.role_name}</h4>
              {/* <p className='text-gray-500 text-center text-sm leading-5 font-normal pt-4'>{props.memberdata.details}</p> */}
        </div>
      </div>  
  )
}

export default SingleProfile;