import React from "react";
import TalentPoolList from "./TalentPoolList";

const assetList = [
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Leslie Alexander Joseph",
    added_by: "Kristin Watson",
    added_date: "27 Apr 2022 ",
    source: "Facebook",
    email: "nvt.isst.nute@gmail.com",
    phone: "(+33)7 55 55 49 84"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Leslie Alexander Joseph",
    added_by: "Kristin Watson",
    added_date: "27 Apr 2022 ",
    source: "Facebook",
    email: "nvt.isst.nute@gmail.com",
    phone: "(+33)7 55 55 49 84"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Leslie Alexander Joseph",
    added_by: "Kristin Watson",
    added_date: "27 Apr 2022 ",
    source: "Facebook",
    email: "nvt.isst.nute@gmail.com",
    phone: "(+33)7 55 55 49 84"
  },
  {
    id: "798",
    image: "/images/avatar.jpg",
    candidate_name: "Leslie Alexander Joseph",
    added_by: "Kristin Watson",
    added_date: "27 Apr 2022 ",
    source: "Facebook",
    email: "nvt.isst.nute@gmail.com",
    phone: "(+33)7 55 55 49 84"
  }
];

const Talentpool = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              CANDIDATE NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ADDED BY
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ADDED DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              SOURCE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Email
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              PHONE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {assetList.map((val, key) => {
            return <TalentPoolList requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Talentpool;
