import React, { useState , useEffect} from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import {
  bankNames as bankOptions,
  accountTypes as accountTypeOptions,
} from "../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
const EditOrAddPaymentInformation = ({
  openModal,
  employee,
  onCloseModal,
  isAdd,
  bankList,
  handleMakeApiCall,
}) => {
  const form = useForm({
    defaultValues: {
      account_holder_name: bankList?.account_holder_name,
      account_number: bankList?.account_number,
      account_type: bankList?.account_type.value,
      bank_branch: bankList?.bank_branch,
      bank_name: bankList?.bank_name.value,
      ifsc_code: bankList?.ifsc_code,
      is_default: bankList?.is_default,
    },
  });
  const { register, handleSubmit, control, formState } = form;
  const { errors } = formState;

  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const onSubmit = async (data) => {
    if (isAdd) {
      try {
        const addBankDetails = await axiosPrivate.post(`employee/addBank`, {
          employeeId: employee?.employee_id,
          account_holder_name: data?.account_holder_name,
          account_number: data?.account_number,
          bank_name: data?.bank_name?.value,
          ifsc_code: data?.ifsc_code,
          bank_branch: data?.bank_branch,
          account_type: data?.account_type?.value,
          is_default: data?.is_default,
        });
        if (Number(addBankDetails?.data?.status_code) === 200) {
          toast.success("Bank details added successfully ");
          onCloseModal();
          handleMakeApiCall(); 
          setTimeout(() => {
            setOpen(!open);
          }, 500);
        }
      } catch (error) {
        if (Number(error?.response?.data?.status_code) === 400) {
          toast.error(error?.response?.data?.message);
          setTimeout(() => {
            setOpen(!open);
          }, 500);
        } else {
          toast.error("Something went wrong");
          setTimeout(() => {
            setOpen(!open);
          }, 500);
        }
      }
    } else {
      try {
        const editBankDetails = await axiosPrivate.put(`employee/editBank`, {
          emp_id: employee?.employee_id,
          account_holder_name: data?.account_holder_name,
          account_number: data?.account_number,
          bank_name: data?.bank_name.value,
          bank_id: bankList?.id,
          ifsc_code: data?.ifsc_code,
          bank_branch: data?.bank_branch,
          account_type: data?.account_type.value,
          // is_default: data.is_default,
        });
        if (
          Number(editBankDetails?.data?.status_code) === 200 &&
          data?.is_default
        ) {
          try {
    
            const updateBankStatus = await axiosPrivate.put(
              `employee/updateBankStatus`,
              {
                emp_id: employee?.employee_id,
                bank_id: bankList?.id,
                status: data?.is_default,
              }
            );
            if (Number(updateBankStatus?.data?.status_code) === 200) {
              toast.success("Bank details updated successfully ");
              onCloseModal();
              handleMakeApiCall();
            
          
              setTimeout(() => {
                setOpen(!open);
              }, 500);
            }
          } catch (error) {
            if (Number(error?.response?.data?.status_code) === 400) {
              toast.error(error?.response?.data?.message);
              setTimeout(() => {
                setOpen(!open);
              }, 500);
            }
          }
        } else if (Number(editBankDetails?.data?.status_code) === 200) {
       
       
          toast.success("Bank details edited successfully ");
          onCloseModal();
          handleMakeApiCall();
          setTimeout(() => {
            setOpen(!open);
          }, 500);
        }
      } catch (error) {
        if (Number(error?.response?.data?.status_code) === 400) {
          toast.error(error?.response?.data?.message);
          setTimeout(() => {
            setOpen(!open);
          }, 500);
        }
      }
    }
  };
  const componentTitile = isAdd
    ? "Add Bank Account Informations"
    : "Edit Bank Account Informations";
  const isAllDigitsSame = (str) => {
    return new Set(str).size === 1;
  };
  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <div className="p-5 w-[520px]">
          <>
            <h2 className="text-lg leading-6 text-[#1F2937] font-semibold mb-5">
              {componentTitile}
            </h2>
            <form
              className="flex flex-col gap-4 justify-center"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              {/* <div className="flex flex-col">
                <label className="text-sm font-medium text-black">
                  Payment Mode <span className="text-red-700">*</span>
                </label>
                <div className="flex gap-4">
                  <button
                    type="button"
                    className="btn btn--border w-[220px] h-[38px] text-sm"
                  >
                    Bank Transfer
                  </button>
                  <button
                    type="button"
                    className="btn btn--border w-[220px] h-[38px] text-sm"
                  >
                    Cheque
                  </button>
                </div>
              </div> */}
              {/* <span
                className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
                ref={errRef}
              >
                {errMsg}
              </span>
              <br /> */}

              <div className="w-[456px]">
                <label className="text-sm font-medium text-black">
                  Account Holder Name <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  className="h-[38px]"
                  id="account_holder_name"
                  {...register("account_holder_name", {
                    required: {
                      value: true,
                      message: "Account holder name is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z\s]*$/,
                      message:
                        "Account holder name can only contain letters and spaces, and cannot start with a space",
                    },
                  })}
                />
                {errors?.account_holder_name && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors?.account_holder_name?.message}
                  </span>
                )}
              </div>

              <div className="w-[456px]">
                <label className="text-sm font-medium text-black">
                  Account Number <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  className=" h-[38px]"
                  id="account_number"
                  {...register("account_number", {
                    required: {
                      value: true,
                      message: "Account number is required",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Account number must contain only numbers",
                    },
                    minLength: {
                      value: 9,
                      message: "length should be between 9 and 18",
                    },
                    maxLength: {
                      value: 18,
                      message: "length should be between 9 and 18",
                    },
                   
                    validate: {
                      notAllSame: (value) =>
                        !isAllDigitsSame(value) ||
                        "Please provide valid account number",
                    },
                  })}
                />
                {errors?.account_number && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors?.account_number?.message}
                  </span>
                )}
              </div>

              <div className="w-[456px]">
                <label htmlFor="" className="text-sm font-medium text-black">
                  Bank <span className="text-red-600 font-medium">*</span>
                </label>
                <Controller
  name="bank_name"
  control={control}
  defaultValue={
    bankList ? { value: bankList.bank_name, label: bankList.bank_name } : null
  }
  render={({ field }) => (
    <Select
      {...field}
      options={bankOptions}
      placeholder="Select Bank"
    />
  )}
  rules={{ required: 'Bank name is required' }}
/>
{errors?.bank_name && (
  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
    {errors?.bank_name?.message}
  </span>
)}

              </div>
              <div className="w-[456px]">
                <label className="text-sm font-medium text-black">
                  Branch <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  className="h-[38px]"
                  id="bank_branch"
                  {...register("bank_branch", {
                    required: {
                      value: true,
                      message: "Bank branch is required",
                    },
                    pattern: {
                      value: /^(?!\s)(?!.*[\s]{2})[a-zA-Z0-9\s.,#-]+$/,
                      message: "Branch name cannot start with a space",
                    },
                  })}
                />
                {errors?.bank_branch && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors?.bank_branch?.message}
                  </span>
                )}
              </div>

              <div className="w-[456px]">
                <label className="text-sm font-medium text-black">
                  IFSC <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  className="h-[38px]"
                  id="ifsc_code"
                  {...register("ifsc_code", {
                    required: {
                      value: true,
                      message: "IFSC code is required",
                    },
                    pattern: {
                      value: /^[A-Za-z0-9]{11}$/,
                      message: "Provide a valid IFSC code",
                    },
                  })}
                />
                {errors?.ifsc_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors?.ifsc_code?.message}
                  </span>
                )}
              </div>

              <div className="w-[456px]">
                <label htmlFor="" className="text-sm font-medium text-black">
                  Account Type <span className="text-red-700">*</span>
                </label>
               
                <Controller
  name="account_type"
  control={control}
  defaultValue={
    bankList ? { value: bankList.account_type, label: bankList.account_type } : null
  }
  render={({ field }) => (
    <Select
      {...field}
      options={accountTypeOptions}
      placeholder="Select Account Type"
    />
  )}
  rules={{ required: 'Account type is required' }}
/>
{errors?.account_type && (
  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
    {errors?.account_type?.message}
  </span>
)}

              </div>

              <div className="flex items-center">
                {!bankList?.is_default && (
                  <label
                    htmlFor=""
                    className="text-sm leading-5 font-medium text-gray-500"
                  >
                    <input type="checkbox" {...register("is_default")} />  
                    {" "}   I agree to set this account as default
                  </label>
                )}
              </div>

              {/* MODAL BUTTONS AREA */}

              <div className="flex justify-end gap-3 mr-5">
                <button
                  type="button"
                  className="btn btn--border text-sm font-medium"
                  onClick={() => onCloseModal()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn text-sm font-medium">
                  Save Changes
                </button>
              </div>
            </form>
          </>
        </div>
      </Modal>
    </>
  );
};

export default EditOrAddPaymentInformation;
