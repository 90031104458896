import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import SendingButton from "../../SubmitButtonWithLoader/SendingButton";
import Select, { components } from "react-select";
import Avatar from "../../Avatar/Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../PageLoader/SpinnerLoader";
import { toast } from "react-toastify";

const EmployeeTeamAllocaitonModal = ({
  open,
  close,
  employee,
  functions,
  teamListData,
  allocateToTeam,
  isAllocatingButton,
  errMsg,
  errRef,
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span className="ico-down text-blue-800"></span>
      </components.DropdownIndicator>
    );
  };
  const axiosPrivate = useAxiosPrivate();

  const [teamList, setTeamList] = useState([]);
  const [loader, setLoader] = useState(false);
  const fetchEmployeeTeamList = async () => {
    if (!employee?.employee_id) {
      return;
    }
    setLoader(true);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`employee/teams?employeeId=${employee.employee_id}`, {
        signal: controller.signal,
      })
      .then((res) => {
        const { teamList } = res.data;
        setTeamList(teamList || []);
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchEmployeeTeamList();
  }, []);
  const handleOnRemoveClick = (teamId, isDeleteClicked) => {
    setTeamList((prev) =>
      prev.map((item) => {
        if (Number(item.team_id) === Number(teamId)) {
          return {
            ...item,
            isDeleteClicked,
          };
        } else {
          return item;
        }
      })
    );
  };
  const removeEmployeeFromTeam = async (teamId) => {
    const result = await axiosPrivate
      .put("employee/removeEmployeeFromTeam", {
        teamId,
        employeeId: employee.employee_id,
      })
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      toast.success(result?.data?.message || "");
      setTeamList((prev) =>
        prev.filter((item) => Number(item.team_id) !== Number(teamId))
      );
    } else {
      toast.error(
        "Something went wrong while removing this employee from team."
      );
    }
  };

  return (
    <>
      <Modal open={open} onClose={close} center>
        <div className="text-center items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t min-w-[500px] ">
          <h3 className="text-xl text-black font-semibold">
            Employee Team Allocation
          </h3>
        </div>
        <div className="flex justify-center">
          <div className=" min-w-[350px] mb-5 font-semibold">
            <br />
            <span
              className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 mb-2"
              ref={errRef}
            >
              {errMsg}
            </span>

            <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4">
              <h5 className="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white inline-flex items-center">
                <Avatar image={employee.photo} />
                &nbsp; {employee.first_name} {employee.last_name}
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">
                <p> Email: {employee.email}</p>
                <p> Role: {employee.role_name}</p>
                <p> Department: {employee.department_name}</p>
              </p>
            </div>
            {loader ? (
              <div className="flex justify-center">
                <SpinnerLoader />
              </div>
            ) : !!teamList.length ? (
              <>
                <label>Employee's Assigned Teams</label>
                <div className="border rounded-md">
                  {teamList.map((item) => {
                    return (
                      <div className="flex items-center justify-between gap-5 p-2 border-b">
                        <span>{item.team_name}</span>
                        {item?.isDeleteClicked ? (
                          <div className="flex items-center gap-3">
                            <button
                              onClick={() =>
                                handleOnRemoveClick(item.team_id, false)
                              }
                              type="button"
                              className="btn btn--border"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                removeEmployeeFromTeam(item.team_id)
                              }
                              className="btn btn--red"
                            >
                              Confirm Remove
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              handleOnRemoveClick(item.team_id, true)
                            }
                            className="btn btn--red"
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <span className="status">No Assigned Teams for Employee</span>
            )}

            <div className="py-3">
              <label htmlFor="">Select team to allocate</label>
              <Select
                options={
                  teamListData &&
                  teamListData.map((val) => ({
                    value: val.id,
                    label: val.team_name,
                  }))
                }
                onChange={(e) => functions.selectTeam(e)}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
          <button
            className="btn btn--border mr-2"
            type="button"
            onClick={functions.closeModal}
          >
            Close
          </button>
          {isAllocatingButton ? (
            <SendingButton title={"Allocating to team"} />
          ) : (
            <button className="btn" type="button" onClick={allocateToTeam}>
              Allocate to team
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EmployeeTeamAllocaitonModal;
