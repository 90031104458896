import React, { useState } from "react";
import ToggleSwitch from "../../../ToggleSwitch";
import Avatar from "../../../Avatar";
import { useUpdateEmployeeSystemPolicyViewStatus } from "../../../../queries/systemPolicyQueries";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../../config/userRoles";

const PolicyEmployeeViewStatusTData = ({ data }) => {
  const loggedInUser = useSelector((state) => state.user.auth);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const {
    view_status,
    employee_name,
    employee_serial,
    employee_id,
    system_policy_id,
  } = data;
  const { mutate } = useUpdateEmployeeSystemPolicyViewStatus();
  const [isViewEnabled, setIsViewEnabled] = useState(Number(view_status));

  const handleChangeViewStatus = (e) => {
    setIsViewEnabled(Number(e));
    const payload = {
      employee_id,
      system_policy: system_policy_id,
      view_status: Number(e),
    };
    mutate(payload, {
      onSuccess: (data) => {
        if (data?.status) {
          toast.success(data.message);
        } else {
          setIsViewEnabled(Number(view_status));
        }
      },
      onError: (error) => {
        const responseMsg =
          error?.data?.message ||
          error?.response?.data?.message ||
          "Failed to update policy view status. Please try again later.";
        toast.error(responseMsg);
        setIsViewEnabled(Number(view_status));
      },
    });
  };

  const viewStatusLabel = isViewEnabled ? "Active" : "In active";
  const viewStatusLabelClass = isViewEnabled ? "status--green" : "status--red";


  return (
    <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200 ">
      <td className="py-3 px-5 border-0">
        <div className="flex items-center gap-2">
          <Avatar />{" "}
          <div className="flex flex-col gap-1">
            <span className="font-medium">{employee_name}</span>
            <span className="text-xs text-gray-600">{employee_serial}</span>
          </div>
        </div>
      </td>
      <td className="text-center py-3 px-5 border-0">
        {
          isAdmin ? <ToggleSwitch
          checked={isViewEnabled}
          getToggleState={handleChangeViewStatus}
        /> : <span className={`status ${viewStatusLabelClass}`}>{viewStatusLabel}</span>
        }
        
      </td>
    </tr>
  );
};

export default PolicyEmployeeViewStatusTData;
