import React, { useState } from "react";
import { viewIcon } from "../../Icons/Icons";
import PolicyPreview from "../Task/Approval/Policy/PolicyPreview";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";
import { SYSTEM_POLICY_APPROVAL_STATUS } from "../../Consts/consts";
import formatDate from "../../utils/FormatDate/formatDate";
import ToggleSwitch from "../ToggleSwitch";
import { useUpdateSystemPolicyStatus } from "../../queries/systemPolicyQueries";
import { toast } from "react-toastify";
import { Badge } from "antd";
import CreateOrEditPolicy from "./CreateOrEditPolicy";

const PolicyListTData = ({ data }) => {
  const { mutate } = useUpdateSystemPolicyStatus();
  const loggedInUser = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isAdminOrHr = isAdmin || isHr;
  const {
    policy_name,
    created_date,
    version,
    policy_serial,
    created_by_name,
    approval_status_id,
    is_active,
    id,
    is_depricated,
  } = data;
  const [isPolicyActive, setIsPolicyActive] = useState(Number(is_active));
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState(false);
  const approvalStatusId = Number(approval_status_id);
  const isDepricated = Number(is_depricated);
  const isPolicyApprovalPending = approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.pending;
  const isPolicyDeclined = approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.declined;
  const approvedStatusLabel =
    approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.approved
      ? "Approved"
      : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.declined
      ? "Declined"
      : "Pending";
  const approvedStatusLabelClass =
    approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.approved
      ? "status--green"
      : approvalStatusId === SYSTEM_POLICY_APPROVAL_STATUS.declined
      ? "status--red"
      : "";
  const policyStatusLabel = isDepricated ? "Depricated" : Number(is_active) ? "Active" : "In active";
  const policyStatusLabelClass = isDepricated ? "status--gray" : Number(is_active) ? "status--green" : "status--red";
  const formattedCreatedDate = formatDate(created_date).dateString2;
  const isEligibleToTogglePolicyStatus = isAdmin && !isDepricated;
  const isTogglePolicyStatusDisabled = !isAdmin || isPolicyApprovalPending || isPolicyDeclined;

  const togglePolicyStatus = (e) => {
    setIsPolicyActive(Number(e));
    mutate(
      {
        policy_status: Number(e),
        system_policy_id: id,
      },
      {
        onSuccess: (data) => {
          if (data?.status) {
            toast.success(data.message);
          } else {
            setIsPolicyActive(Number(is_active));
          }
        },
        onError: (error) => {
          const responseMsg =
            error?.data?.message ||
            error?.response?.data?.message ||
            "Failed to update policy status. Please try again later.";
          toast.error(responseMsg);
          setIsPolicyActive(Number(is_active));
        },
      }
    );
  };

  const handleClosePreviewAndOpenEdit = () => {
    setIsPreviewModalOpen(false);
    setIsCreatePolicyModalOpen(true);
  };
  return (
    <>
      {isPreviewModalOpen && (
        <PolicyPreview
          isModalOpen={isPreviewModalOpen}
          handleCloseModal={() => setIsPreviewModalOpen(false)}
          systemPolicy={data}
          handleClosePreviewAndOpenEdit={handleClosePreviewAndOpenEdit}

        />
      )}
      {isCreatePolicyModalOpen && (
        <CreateOrEditPolicy
          isModalOpen={isCreatePolicyModalOpen}
          handleCloseModal={() => setIsCreatePolicyModalOpen(false)}
          systemPolicy={data}
        />
      )}
      <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200 ">
        <td className=" py-3 px-5 border-0">
          <button
            onClick={() => setIsPreviewModalOpen(true)}
            className="hover:text-blue-600"
          >
            {policy_name}
          </button>
        </td>
        <td className="text-center py-3 px-5 border-0">{policy_serial}</td>
        <td className="text-center py-3 px-5 border-0">{created_by_name}</td>
        <td className="text-center py-3 px-5 border-0">{formattedCreatedDate}</td>
        <td className="text-center py-3 px-5 border-0">
        <div className="flex justify-center">
            <Badge count={version} showZero color="blue" />
          </div>
        </td>
        {isAdminOrHr && (
          <td className="text-center py-3 px-5 border-0">
            <span className={`status ${approvedStatusLabelClass}`}>{approvedStatusLabel}</span>
          </td>
        )}
        {isAdminOrHr && (
          <td className="text-center py-3 px-5 border-0">
            {
              isEligibleToTogglePolicyStatus ? <ToggleSwitch
              disabled={isTogglePolicyStatusDisabled}
              checked={isPolicyActive}
              getToggleState={togglePolicyStatus}
            /> :  <span className={`status ${policyStatusLabelClass}`}>{policyStatusLabel}</span>
            }
          </td>
        )}
        <td className=" py-3 px-5 border-0">
          <div className="flex items-center gap-3">
            <button onClick={() => setIsPreviewModalOpen(true)}>
              {viewIcon}
            </button>
            {/** For HR role approved, pending or declined status will be shows here */}
          </div>
        </td>
      </tr>
    </>
  );
};

export default PolicyListTData;
