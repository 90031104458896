export const Types = {
  LOGIN: 'LOGIN',
  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  FORM_SUBMITION_STATUS: 'FORM_SUBMITION_STATUS',
  USER_PROFILE: 'USER_PROFILE',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  LOGOUT: 'LOGOUT',
  FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
  BROWSER_HISTORY: 'BROWSER_HISTORY',
  TENANT_SETTING: "TENANT_SETTING",
  TOGGLE_ASIDEBAR: "TOGGLE_ASIDEBAR",
}
