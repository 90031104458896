import React, { useState } from "react";
import EditPersonalInformationModal from "../EditPersonalInformationsModal/EditPersonalInformationsModal";
import {getFormattedFullDate} from "../../../utils/FormatDate/formaDateInMonths";

const PersonalInfo = ({ employee }) => {
  const [openModal, setOpenModel] = useState(false);
  const onOpenModal = () => setOpenModel(true);
  const onCloseModal = () => setOpenModel(false);
  const nationality = employee?.country_label || "";
  const residentialStatus = employee?.residential_status || "";
  const dateOfBirth = employee?.data_of_birth
    ? getFormattedFullDate(employee?.data_of_birth)
    : "";

  const placeOfBirth = employee?.place_of_birth || "";
  const bloodGroup = employee?.blood_group || "";
  const religion = employee?.religion || "";
  const martialStatus = employee?.martial_status || "";
  const marriageDate = employee?.marriage_date
    ? getFormattedFullDate(employee?.marriage_date)
    : "";
  const spouseName = employee?.spouse_name || "";
  const fatherName = employee?.father_name || "";
  const physicallyChallenged = employee?.phycically_challenged || false;
  const disabilityType = employee?.disability_type || "No";

  const isMarried = martialStatus === "Married";


  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">
            Personal Information
          </p>
        </div>
        <div className="cursor-pointer">
          <img
            src="/images/editicon.svg"
            alt=""
            onClick={() => onOpenModal()}
          />
          {openModal ? (
            <EditPersonalInformationModal
              openModal={openModal}
              onCloseModal={onCloseModal}
              employee={employee}
            />
          ) : null}
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              {isMarried && (
                <>
                  <td className="title">Spouse Name </td>
                  <td className="data">{spouseName}</td>
                  <td className="title">Marriage Date </td>
                  <td className="data">{marriageDate}</td>
                </>
              )}
            </tr>
            <tr>
              <td className="title">Residential Status </td>
              <td className="data">{residentialStatus}</td>
              <td className="title">Nationality </td>
              <td className="data">{nationality} </td>
            </tr>
            <tr>
              <td className="title">Date Of Birth </td>
              <td className="data">{dateOfBirth}</td>
              <td className="title">Father's Name </td>
              <td className="data">{fatherName}</td>
            </tr>
            <tr>
              {/* <td className="title">Place Of Birth </td>
              <td className="data">{placeOfBirth} </td> */}
              <td className="title">Marital Status </td>
              <td className="data">{martialStatus} </td>
              <td className="title">Physically Challenged </td>
              <td className="data">{physicallyChallenged ? "Yes" : "No"} </td>
           
              {/* <td className="title">Mother Name </td>
              <td className="data">suchet555@ivhsr.com </td> */}
            </tr>
            <tr>
              <td className="title">Blood Group </td>
              <td className="data">{bloodGroup} </td>
              {physicallyChallenged && (
                <>
                  <td className="title">Disability Type </td>
                  <td className="data">{disabilityType || "N/A"} </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PersonalInfo;
