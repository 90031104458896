import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "../../../helpers/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState("");
  const { candidateToken } = useParams();
  const [state, setState] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const defValues = location.state.personaldetail;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: { ...location.state.candidate, ...defValues },
  });

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState([]);

  const onSubmit = async (data) => {
    setState(true);
    try {
      await axios
        .put(
          "onboarding/candidate/updateCandidate",
          {
            email: data.email,
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            phone: data.phone,
            agreement: true,
          },
          { headers: { Authorization: `Bearer ${location.state.token}` } }
        )
        .then((response) => {
          const statusCode = response.data.statusCode;
          if (Number(statusCode) === 200) {
            axios
              .get("employee/createEmployeeWithCandidate", {
                headers: {
                  Authorization: `Bearer ${searchParams.get("token")}`,
                },
              })
              .then((res) => {
                setEmployee(res.data.employee);
                if (res.data.statusCode == 200) {
                  toast.success("Account created successfully!", {
                    type: "success",
                  });
                  navigate("/offer/about", {
                    state: { token: location.state.token, candidate: data },
                  });
                }
              })
              .catch((err) => {
                if (err.response.data.statusCode === 400) {
                  if (
                    err.response.data.message === "User email already exists"
                  ) {
                    toast.success(`Welcome back ${defValues.first_name}`, {
                      type: "success",
                    });
                    navigate("/offer/about", {
                      state: { token: location.state.token, candidate: data },
                    });
                  } else {
                    setErrMsg("No server response");
                  }
                  setErrMsg("");
                }
              })
              .finally(() => {
                setState(false);
              });
          } else if (!statusCode) {
            setErrMsg("No Server Response");
          } else if (statusCode === 400) {
            setErrMsg("Details is not added in request");
          } else if (statusCode === 401) {
            setErrMsg("Unauthorized");
          } else {
            setErrMsg("Account Creation Failed");
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response!");
      } else if (Number(err.response?.statusCode) === 400) {
        setErrMsg("Details is not added in request!");
      } else if (Number(err.response?.statusCode) === 401) {
        setErrMsg("Unauthorized!");
      } else {
        setErrMsg("Failed to add Create Account!");
      }

      errRef.current.focus();
    } finally {
      setState(false);
    }
  };

  return (
    <div className="flex-1 justify-center max-w-[500px]">
      <div className="mb-5">
        <h2 className="text-2xl font-bold text-gray-900 mb-2">
          Create account
        </h2>
        <p>Please login or create account below to view our offer.</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <span
          className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
          ref={errRef}
        >
          {errMsg}
        </span>
        <div className="lg:flex gap-4 mb-4">
          <div className="mb-4 lg:mb-0">
            <label htmlFor="">
              First Name <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("first_name", { required: "Username Required" })}
            />
            {errors?.first_name && (
              <p style={{ color: "red" }}> {errors.first_name.message}</p>
            )}
          </div>
          <div className="mb-4 lg:mb-0">
            <label htmlFor="">Midle Name</label>
            <input type="text" {...register("middle_name")} />
          </div>
          <div>
            <label htmlFor="">
              Last Name <span className="text-red-600 font-medium">*</span>
            </label>
            <input
              type="text"
              {...register("last_name", { required: "Last Name is required" })}
            />
            {errors?.last_name && (
              <p style={{ color: "red" }}> {errors.last_name.message}</p>
            )}
          </div>
        </div>
        <div className=" mb-4">
          <label htmlFor="">
            Email Address <span className="text-red-600 font-medium">*</span>
          </label>
          <input
            type="text"
            {...register("email", {
              required: "Email required",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email",
              },
            })}
          />
          {errors?.email && (
            <p style={{ color: "red" }}> {errors.email.message}</p>
          )}
        </div>
        <div className=" mb-4">
          <label htmlFor="">
            Personal Phone Number{" "}
            <span className="text-red-600 font-medium">*</span>
          </label>
          <input
            type="text"
            {...register("phone", { required: "Phone number Required" })}
          />
          {errors?.phone && (
            <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {errors.phone.message}
            </span>
          )}
        </div>
        <div className=" mb-3 flex items-center">
          <input
            type="checkbox"
            text="I agree to the terms & conditions ivshr"
            id="terms"
            textclass="font-medium text-gray-700"
            {...register("checkbox", {
              required: "Please accept the terms & conditions",
            })}
          />
          <label htmlFor="chooseCb" className="form-check-label pl-2 mb-0">
            I agree to the terms & conditions ivshr
          </label>
        </div>
        {errors.checkbox && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mb-1 ml-1">
            {errors.checkbox.message}
          </span>
        )}
        <div className=" mb-4">
          {state ? (
            <div role="status">
              <button className="btn btn--full cursor-pointer">
                <svg
                  aria-hidden="true"
                  className="mr-2 w-8 h-8 text-gray-200 animate-spin fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                Creating
              </button>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <input
              type="submit"
              className="btn btn--full cursor-pointer"
              value="Create Account"
            />
          )}
          {/* <ToastContainer style={{ margin: "100px" }} /> */}
        </div>

        <div className=" mb-4">
          <Link to="/offer/login" className="btn btn--full btn--border">
            Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
