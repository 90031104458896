import React, { useContext, useState } from "react";
import LeaveApplicationListData from "./LeaveApplicationListData";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { LeaveApprovalContext } from "./TaskLeaveApplications";

const LeaveApplicationList = ({
  leaveApplications,
}) => {
  const {
    isLeaveReqTab,
    // isAdditionalLeaveReqTab,
    handleSetSelectedIds,
    selectedIds,
  } = useContext(LeaveApprovalContext);
  const leaveNames = [
    ...new Set([...leaveApplications.map((i) => i.leave_name)]),
  ];
  const [activeAccordian, setActiveAccordian] = useState(0);

  return (
    <div className="mb-20 overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {isLeaveReqTab ? (
              <th scope="col" className="px-5 py-2 w-[6%]">
                <div className="flex items-center">
                  <input
                    checked={selectedIds.length === leaveApplications.length}
                    type="checkbox"
                    className=""
                    onChange={(e) => {
                      if (isLeaveReqTab)
                        handleSetSelectedIds(null, e.target.checked);
                    }}
                  />
                </div>
              </th>
            ) : null}

            <th
              scope="col"
              className={` py-2 ${
                isLeaveReqTab ? "w-[10%] px-5" : "w-[20%] px-10"
              }`}
            >
              Leave ID
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th
              scope="col"
              className={` py-2 ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              }`}
            >
              Requested By
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th
              scope="col"
              className={` py-2 ${
                isLeaveReqTab ? "w-[15%] px-5" : "w-[20%] px-10"
              }`}
            >
              Request Date
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th
              scope="col"
              className={` py-2 ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              }`}
            >
              {isLeaveReqTab ? "From" : "Applied for date"}
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            {isLeaveReqTab ? (
              <>
                <th scope="col" className=" py-2 w-[14%] px-5">
                  to
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </th>
                <th scope="col" className=" py-2 w-[14%] px-5">
                  Total
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                </th>
              </>
            ) : null}
            <th
              scope="col"
              className={` py-2 text-right cursor-pointer ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              }`}
            >
              <i className="ico-grid text-blue-600"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {leaveNames.map((leaveName, index) => {
            const data = leaveApplications.filter(
              (i) => i.leave_name === leaveName
            );
            return (
              <tr key={`${index+256}_collapse_leave_name_tr`}>
                <td colSpan={isLeaveReqTab ? 8 : 5} className="p-0 m-0">
                  <Collapse
                    activeKey={activeAccordian + "leave-type-name-accordian"}
                    onChange={() => setActiveAccordian(activeAccordian === index ? null : index)}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="border-0 rounded-none"
                    size="small"
                    items={[
                      {
                        key: index + "leave-type-name-accordian",
                        label: (
                          <span className="font-medium">{`${leaveName} (${data.length})`}</span>
                        ),
                        children: data.map((val, index) => {
                          return (
                            <LeaveApplicationListData
                              requestdata={val}
                              key={`${index + 125}_leave_application_list_data`}
                            />
                          );
                        }),
                      },
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveApplicationList;
