import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import formatDate from "../../../../utils/FormatDate/formatDate";
import { Chart, registerables } from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
Chart.register(...registerables);

const { RangePicker } = DatePicker;

const LeaveTakenOverView = () => {
  const colors = [
    "#4875E9",
    "#9AB3F3",
    "#C2D1F8",
    "#3730a3",
    "#7d9df0",
    "#f0f4fd",
  ];
  const defaultColor = "#D3D3D3";
  const axiosPrivate = useAxiosPrivate();
  const [leaveTakenList, setLeaveTakenList] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const chartRef = useRef(null);
  let chartInstance = useRef(null);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  const fetchTakenOverView = () => {
    const [fromDate, toDate] = dateRange;
    if (fromDate && toDate) {
      const fromDateStr = fromDate.format("YYYY-MM-DD");
      const toDateStr = toDate.format("YYYY-MM-DD");
      axiosPrivate
        .get(
          `lms/leave/leaveTakenWidgetOverView?from_date=${fromDateStr}&to_date=${toDateStr}`
        )
        .then((response) => {
          if (response?.data?.data) {
            const leaveTakenResponseData = response?.data?.data;
            setLeaveTakenList(leaveTakenResponseData);
          } else {
            setLeaveTakenList([]);
          }
        })
        .catch((error) => {
          setLeaveTakenList([]);
        });
    } else {
      setLeaveTakenList([]);
    }
  };

  useEffect(() => {
    fetchTakenOverView();
  }, [dateRange]);

  useEffect(() => {
    if (leaveTakenList) {
      const ctx = chartRef?.current?.getContext("2d");
      const dataValues = leaveTakenList.map((item) => item.total_leaves_taken);
      const totalLeavesTaken = dataValues.reduce((total, value) => total + value, 0);
      const backgroundColor = totalLeavesTaken > 0 ? colors : [defaultColor];

      if (chartInstance?.current) {
        chartInstance.current.data.labels = leaveTakenList.map(
          (item) => item.leave_name
        );
        chartInstance.current.data.datasets[0].data = totalLeavesTaken > 0 ? dataValues : [1];
        chartInstance.current.data.datasets[0].backgroundColor = backgroundColor;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: leaveTakenList.map((item) => item.leave_name),
            datasets: [
              {
                label: "# of Leaves Taken",
                data: totalLeavesTaken > 0 ? dataValues : [1],
                backgroundColor: backgroundColor,
              },
            ],
          },
          options: {
            scales: {},
            plugins: {
              legend: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [leaveTakenList]);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const totalLeavesTaken = leaveTakenList.reduce(
    (total, item) => total + parseInt(item.total_leaves_taken),
    0
  );
 

  return (
    <>
      <div className="border rounded-md p-3">
        <div className="flex justify-between font-medium text-black">
          <p className="">Leave Taken</p>
          <p className="flex items-center gap-2">
            {isCalendarOpen ? (
              <RangePicker
                value={dateRange}
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
                disabledDate={disableFutureDates}
              />
            ) : (
              "Today"
            )}
            <i
              className={`ico-${isCalendarOpen ? "up" : "down"}`}
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            />
          </p>
        </div>

        <div className="flex items-center py-2">
          <div className="w-[160px] px-3">
            <canvas
              id="LeaveTakenOverView"
              ref={chartRef}
              width="160"
              height="160"
            ></canvas>
          </div>

          <div className="flex flex-wrap w-full">
            {leaveTakenList?.length === 0 ? (
              <div className="flex gap-2 justify-center py-10 max-h-full">
                <span className="flex items-center py-2 text-gray-800">
                  No data available!
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-2 w-full">
                {leaveTakenList?.map((item, index) => (
                  <div className="flex pb-3 pr-5" key={index}>
                    <span
                      className={`h-3 w-5 rounded-[20px] mr-2`}
                      style={{ backgroundColor: colors[index % colors.length] }}
                    ></span>
                    <p>{item?.leave_name}</p>
                    <h3 className="ml-auto text-gray-800 font-medium">
                      {item?.total_leaves_taken}
                    </h3>
                  </div>
                ))}
                <div className="flex pb-3 pr-5 w-full text-gray-800">
                  <p className="font-medium">Total Leaves Taken</p>
                  <h3 className="ml-auto font-medium">
                    {totalLeavesTaken}
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveTakenOverView;
