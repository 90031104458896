import React, { useState } from "react";
import "./AssetsTab.scss";

const PreferenceTab = ({ tabs, getTabIndex }) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <ul className="tab-list preference-tab-head">
      {tabs.map((item, i) => (
        <li
          key={i}
          className={`tab-li ${tabIndex === i && `active-tab`}`}
          onClick={() => {
            setTabIndex(i);
            getTabIndex(i);
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default PreferenceTab;
