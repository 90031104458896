import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";
import { closeIcon } from "../../../Icons/Icons";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../../Buttons/LoadingButton";

const AddTenantDetails = ({ open, close, fetchAllTenantData }) => {
  const axiosPrivate = useAxiosPrivate();
  const serialTypeList = [
    "employee",
    "candidate",
    "task",
    "leave",
    "attendance",
    "regularization",
    "policy",
  ];

  const entries = [
    {
      title: "Location",
      key: "location",
      field: [
        {
          key: "locationName",
          title: "Location name",
        },
      ],
    },
    {
      title: "Job Title",
      key: "job_title",
      field: [
        {
          key: "jobTitleName",
          title: "Job title name",
        },
      ],
    },
    {
      title: "Team",
      key: "team",
      field: [
        {
          key: "teamName",
          title: "Team name",
        },
      ],
    },
    {
      title: "Department",
      key: "department",
      field: [
        {
          key: "departmentName",
          title: "Department name",
        },
      ],
    },

    {
      title: "Employee type",
      key: "employee_type",
      field: [
        {
          key: "employeeTypeLabel",
          title: "Employee type label",
        },
      ],
    },
    {
      title: "Designation",
      key: "designation",
      field: [
        {
          key: "designationTitle",
          title: "Designation title",
        },
        {
          key: "designationDescription",
          title: "Designation description",
        },
      ],
    },
    {
      title: "Serial Type",
      key: "tenant_serial_setup",
      field: [
        {
          key: "prefix",
          title: "Prefix",
        },
        {
          key: "serialStartFrom",
          title: "Serial start from",
          type: "number",
        },
        {
          key: "suffix",
          title: "Suffix",
        },
        {
          key: "serialType",
          title: "Type",
          list: serialTypeList,
        },
      ],
    },
  ];

  const [includedTags, setIncludedTags] = useState({});
  const [data, setData] = useState({});
  const [dataTags, setTagData] = useState([]);
  const defaultSelectBoxValue = { label: "Select Type", value: 1000 };
  const [selectedBoxValue, setSelectBoxValue] = useState(defaultSelectBoxValue);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isLoaderBtn, setIsLoaderBtn] = useState(false);

  const handleChange = (data) => {
    setData((prev) => {
      // const regex = /^[a-zA-Z0-9_@./|\-\s]+$/;
      // const isValidValue = !!data.value ? regex.test(data.value) : true; currently we can avoid this validation
      return ({
        ...prev,
        [data.entry]: {
          ...prev[data.entry],
          [data.field]: data.value,
          // error: !isValidValue
          error: false
        },
      })
    });
  };

  const handleAddItem = (key) => {
    if (data[key] && !data[key]?.error) {
      if (Object.prototype.hasOwnProperty.call(data[key], "error")) {
        delete data[key].error
      }
      if (key === "tenant_serial_setup") {
        setSelectBoxValue(defaultSelectBoxValue);
      }
      const radomKey = Math.floor(Math.random() * 100);
      const isExists = includedTags?.[key];
      if (isExists) {
        setIncludedTags((prev) => ({
          ...prev,
          [key]: [...prev[key], { ...data[key], tagId: `${key}_${radomKey}` }],
        }));
      } else {
        setIncludedTags((prev) => ({
          ...prev,
          [key]: [{ ...data[key], tagId: `${key}_${radomKey}` }],
        }));
      }
      setData((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  };

  const handleRemoveTag = (item) => {
    const { tag, tagId } = item;
    setIncludedTags((prev) => {
      return {
        ...prev,
        [tag]: prev[tag].filter((i) => i.tagId !== tagId),
      };
    });
  };

  const getLabelOfSelectedTags = () => {
    let tagArray = [];
    let isValueNotExists = true;
    const keys = Object.keys(includedTags);
    keys.forEach((key) => {
      const category = includedTags[key];
      category.forEach((k) => {
        const fieldKey = Object.keys(k);
        let value = "";
        fieldKey.forEach((i) => {
          if (i !== "tagId") {
            value += `${k[i]} - `;
          }
        });
        const formattedKey = key
          .split("_")
          .map((i) => `${i[0].toUpperCase()}${i.slice(1)}`);
        tagArray.push({
          tag: key,
          tagId: k.tagId,
          title: formattedKey.join(" "),
          value: value.slice(0, -2),
        });
        isValueNotExists = false;
      });
    });
    setIsSubmitBtnDisabled(isValueNotExists);
    setTagData(tagArray);
  };

  useEffect(() => {
    getLabelOfSelectedTags();
  }, [includedTags]);

  const onSubmit = async () => {
    setIsLoaderBtn(true);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post("employee/tenant/addDetails", includedTags)
      .then((response) => {
        if (response?.data?.status) {
          fetchAllTenantData();
          close();
          toast.success(response?.data?.message || "Details added");
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later"
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(
          err?.response?.data?.message || "Something went wrong try again later"
        );
      })
      .finally(() => {
        setIsLoaderBtn(false);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <Modal open={open} closeOnOverlayClick={false} showCloseIcon={false} center>
      <div className="py-10">
        <div className="flex justify-between items-center gap-10">
          <h1 className="text-gray-800 text-base font-medium pb-3">
            Add Tenant Details
          </h1>
          <button onClick={close}>
            <i className="ico-close" />
          </button>
        </div>
        {dataTags.length ? (
          <div className="shadow-md flex flex-wrap gap-2 border p-2 rounded-md">
            {dataTags.map((item, index) => (
              <div
                className="bg-gray-100 w-fit rounded-3xl flex items-center p-1"
                key={`applcablefor_uniq_${index + 120}`}
              >
                <p className="text-sm font-medium ml-2 mr-4">
                  {item.title}: {item.value}
                </p>
                <button className="mx-1" onClick={() => handleRemoveTag(item)}>
                  {closeIcon}
                </button>
              </div>
            ))}
          </div>
        ) : null}

        {entries.map((item, index) => {
          return (
            <div
              key={`entries_key_tenant_tag_${index}`}
              className="flex rounded-md border my-2 items-center shadow-sm gap-10  p-2"
            >
              <div className="text-base w-[130px]">{item.title}</div>
              <div className="flex w-full gap-2">
                {item.field.map((fields) => {
                  const isErrorValue = !!data?.[item?.key]?.error;
                  
                  const value = data?.[item?.key]?.[fields?.key] || "";
                  return (
                    <div className="min-w-[130px] w-full relative">
                      <span className={`bg-white absolute z-50 text-xs left-[10px] bottom-[30px] ${isErrorValue && "border rounded-sm border-red-600"} px-1`}>
                      {fields.title} {isErrorValue && <span className="mx-3 text-red-600">Invalid entry, Allowed special characters are  _, @, ., /, |, - </span>}
                        
                      </span>
                      {fields?.list ? (
                        <Select
                          value={selectedBoxValue}
                          options={serialTypeList.map((i, index) => {
                            const isUsed =
                              !!includedTags?.tenant_serial_setup?.find(
                                (k) => k.serialType === i
                              );
                            return {
                              label: i,
                              value: index,
                              isDisabled: isUsed,
                            };
                          })}
                          onChange={(e) => {
                            handleChange({
                              value: e.label,
                              entry: item.key,
                              field: fields.key,
                            });
                            setSelectBoxValue(e);
                          }}
                        />
                      ) : (
                        <>
                        <input
                          value={value}
                          onChange={(e) =>
                            handleChange({
                              value: e.target.value,
                              entry: item.key,
                              field: fields.key,
                            })
                          }
                          type={fields?.type ? fields?.type : "text"}
                          className="w-full"
                        />
                        </>
                      )}
                    </div>
                  );
                })}
                <div className=" min-w-[100px]">
                  <button
                    onClick={() => handleAddItem(item.key)}
                    className="btn btn--border"
                  >
                    <i className="ico-plus" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-end">
          {isLoaderBtn ? (
            <LoadingButton />
          ) : (
            <button
              disabled={isSubmitBtnDisabled}
              onClick={onSubmit}
              className={`btn ${
                isSubmitBtnDisabled ? "bg-blue-400 hover:bg-blue-400" : ""
              }`}
            >
              Add Tenant Details
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddTenantDetails;
