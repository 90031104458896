import React from "react";
import BankPaymentInfo from "./BankPaymentInfo";
import PanInfo from "./PanInfo";
import EmployeePFInfo from "./EmployeePFInfo";
import EmployeeInsurance from "./EmployeeInsurance";
import ProfessionalTaxInfo from "./ProfessionalTaxInfo";

const BankInfo = ({employee}) => {
  return (
    <div>
      <BankPaymentInfo employee={employee} />
      {/* <PanInfo />
      <EmployeePFInfo />
      <EmployeeInsurance />
      <ProfessionalTaxInfo /> */}
    </div>
  );
};

export default BankInfo;
