export const formatHourAndMinitueDuration = (duration) => {
  try {
    const splitDuration = duration.toString().split(".");
    const hour = splitDuration[0];
    const minitues = splitDuration[1] || "0";
    const formatMinFromHrConvertion = (Number("0." + minitues) * 60)
      .toString()
      .split(".")[0]; // converts "0.016666666666667" to minitues like 1

    return {
      result: `${hour}h : ${formatMinFromHrConvertion || 0}m`,
      hour,
      minitue: formatMinFromHrConvertion || 0,
      minToHr: minitues,
    };
  } catch (err) {
    return null;
  }
};
