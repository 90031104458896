import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const LeaveTakenOverviewChart = () => {
  const leaveColors = [
    "#EBF0FD",
    "#D6E0FA",
    "#C2D1F8",
    "#9AB3F3",
    "#789BF6",
    "#4875E9",
    "#3466E7",
    "#2A53B9",
    "#1F3D8B",
  ];
  const data = [
    {
      leave: "Annual",
      balance: 12,
    },
    {
      leave: "Engagement",
      balance: 12,
    },
    {
      leave: "Maternity",
      balance: 12,
    },
    {
      leave: "Paternity",
      balance: 12,
    },
    {
      leave: "Relative Funeral",
      balance: 12,
    },
    {
      leave: "Sick Leave",
      balance: 12,
    },
    {
      leave: "Unpaid Time Off",
      balance: 12,
    },
    {
      leave: "Wedding",
      balance: 12,
    },
    {
      leave: "Special Leave",
      balance: 12,
    },
    {
      leave: "Total Leave",
      balance: 12,
    },
  ];
  useEffect(() => {
    const ctx = document.getElementById("leaveTakenChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["", "", "", "", ""],
        datasets: [
          {
            label: "# of Votes",
            data: [10, 10, 10, 10, 10, 10, 10, 10, 10],
            backgroundColor: leaveColors,
          },
        ],
      },
      options: {
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  });

  return (
    <>
      <div className="border rounded-md p-3 w-[630px]">
        <div className="flex justify-between">
          <p className="font-medium">Leave Taken</p>
          <p className="flex items-center gap-2">
            This Year
            <i className="ico-down" />
          </p>
        </div>

        <div className="flex items-center py-4">
          <div className="w-1/4 px-3">
            <canvas id="leaveTakenChart" width="160" height="160"></canvas>
          </div>

          <div className="flex flex-wrap w-3/4">
            {data.map((item, index) => {
              return (
                <>
                  <div className="w-1/2 flex pb-3 pr-5" key={index}>
                    <span
                      className={`h-3 w-5 rounded-[20px] bg-[${leaveColors[index]}] mr-3`}
                    ></span>
                    <p>{item.leave}</p>
                    <h3 className="ml-auto text-gray-800 font-medium">
                      {item.balance}
                    </h3>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveTakenOverviewChart;
