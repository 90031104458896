import React from "react";
import BasicInfo from "./BasicInfo";
import PersonalInfo from "./PersonalInfo";
import AddressInfo from "./AddressInfo";
import EmergencyContact from "./EmergencyContact";
import WorkExperience from "./WorkExperience";
import EmployeeEducationInfo from "./EmployeeEducationInfo";
import BackgroundCheckInfo from "./BackgroundCheckInfo";

const GeneralInfo = ({ employee }) => {
  return (
    <div>
      <BasicInfo employee={employee} />
      <PersonalInfo employee={employee} />
      <AddressInfo employee={employee} />
      <EmergencyContact
        employee={employee}
      />
      {/* <WorkExperience />
      <EmployeeEducationInfo />
      <BackgroundCheckInfo /> */}
    </div>
  );
};

export default GeneralInfo;
