import React, { useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import Avatar from "../../../Avatar/Avatar";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const RegularizationRequestHistorySlidingPanel = ({
  open,
  close,
  handleOpenRegularizationDetailsSlidingPanel,
  regularizationId,
}) => {
  const handleGoBackToDetailsSlidingPanel = () => {
    close();
    handleOpenRegularizationDetailsSlidingPanel();
  };

  const [regularizationHistoryDetails, setRegularizationHistoryDetails] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const fetchRegularizationRequestDetails = async () => {
    try {
      const response = await axiosPrivate.get(`attendance/employeeRegularizationHistory?regularization_id=${regularizationId}`);
      if (response && response.data && response.data.results) {
        setRegularizationHistoryDetails(response.data.results);
      } else {
        setRegularizationHistoryDetails([]);
      }
    } catch (error) {
      setRegularizationHistoryDetails([]);
    }
  };
  
  useEffect(() => {
    if (regularizationId && open) {
      fetchRegularizationRequestDetails();
    }
  }, [regularizationId,open]);
  
  return (
    <>
      <ReactSlidingPane isOpen={open} onRequestClose={close} width="560px">
        <div className="flex border-b justify-between px-5 py-3">
          <div className="flex items-center gap-3 text-black">
            <button onClick={handleGoBackToDetailsSlidingPanel}>
              <i className="ico-left text-xs cursor-pointer"></i>
            </button>
            <h1 className="font-medium text-base">History</h1>
          </div>
          <div>
            <button onClick={close}>{closeIcon}</button>
          </div>
        </div>
        <div className="flex justify-between px-5 py-3">
          <span>Found {regularizationHistoryDetails ? regularizationHistoryDetails.length : 0} history</span>
          <span className="flex gap-2 items-center text-black font-medium">
            Sort Descending<i className="ico-down"></i>
          </span>
        </div>
      
        {regularizationHistoryDetails && regularizationHistoryDetails.map((item, index) => (
          <div key={index} className="flex px-5 py-4 flex-col">
            <span className="font-medium text-black">{item.updated_date}</span>
            <div className="flex gap-3 py-3">
              <div>
                <Avatar />
              </div>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex justify-between">
                  <div className="">
                    <span className="text-black">{item.employee_name}</span>
                    <h6 className="flex gap-1 text-black">
                      <span className="font-medium text-black">{item.status_name}</span>
                      Regularizations
                      <span className="text-blue-600">{item.employee_regularization_serial}</span>
                    </h6>
                  </div>
                  <span>{item.updated_time}</span>
                </div>
                {item.remark && (
                  <div className="bg-gray-100 rounded-md px-5 py-2">
                    <i className="ico-clock mr-2"></i>
                    {item.remark || "No Note"}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
         <div className="bg-gray-100 rounded-md p-4 flex gap-28">
          <div>
            <ol>From Date</ol>
            <ol>To Date</ol>
            <ol>From Time</ol>
            <ol>To Time</ol>
          </div>
          <div>
       
            <ol>: {regularizationHistoryDetails && regularizationHistoryDetails.length > 0 ? regularizationHistoryDetails[0].from_date : ""}</ol>
            <ol>: {regularizationHistoryDetails && regularizationHistoryDetails.length > 0 ? regularizationHistoryDetails[0].to_date : ""}</ol>
            <ol>: {regularizationHistoryDetails && regularizationHistoryDetails.length > 0 ? regularizationHistoryDetails[0].from_time : ""}</ol>
            <ol>: {regularizationHistoryDetails && regularizationHistoryDetails.length > 0 ? regularizationHistoryDetails[0].to_time : ""}</ol>
          </div>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default RegularizationRequestHistorySlidingPanel;
