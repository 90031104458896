import { useEffect, useMemo, useState } from "react";

const useGetQueryParams = (
  paramItems,
  pageNumberKey = "page_number",
  pageSizeKey = "page_limit"
) => {
  const [searchQuery, setSearchQuery] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // this flag is used to reset the page number to 1
  let isPageNoFlag = false;

  const formatParams = useMemo(() => {
    const params = {};
    const paramItemsFiltersKeys = Object.keys(paramItems);

    paramItemsFiltersKeys.forEach((key) => {
      const value = paramItems?.[key];
      const isKeyNotPageNo = key !== pageNumberKey;
      const isDifferentValue = value !== searchQuery[key];
      const isPageNumToOne = isDifferentValue && isKeyNotPageNo;
      if (isPageNumToOne) {
        isPageNoFlag = true;
      }

      const valueIsStringAndNotEmpty =
        typeof value === "string" ? value.trim() : true;
      if (value !== null && value !== undefined && valueIsStringAndNotEmpty) {
        params[key] = value;
      }
    });
    params[pageNumberKey] = isPageNoFlag ? 1 : pageNo;
    params[pageSizeKey] = pageSize;
    return { params, isPageNoFlag };
  }, [paramItems, pageNo, pageSize]);

  useEffect(() => {
    if (JSON.stringify(formatParams.params) !== JSON.stringify(searchQuery)) {
      setSearchQuery(formatParams.params);
      if (formatParams.isPageNoFlag) {
        setPageNo(1);
        // resetting the flag
        isPageNoFlag = false;
      }
    }
  }, [formatParams, searchQuery]);
  return {
    searchQuery: {
      ...searchQuery,
      [pageNumberKey]: pageNo,
      [pageSizeKey]: pageSize,
    },
    pageNo,
    setPageNo,
    pageSize,
    setPageSize,
  };
};

export default useGetQueryParams;
