import React, { useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import Line from "../../../Line/Line";
import Avatar from "../../../Avatar/Avatar";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const ViewAttendanceHistorySlidingPanel = ({ open, close, attendanceId, handleOpenAttendanceDetailsSlidingPanel }) => {
    const axiosPrivate = useAxiosPrivate();
    const [historyData, setHistoryData] = useState([]);
    const [historyDate, setHistoryDate] = useState("");

    const fetchAttendanceDataForId = async () => {
        try {
            const response = await axiosPrivate.get(`attendance/getAttendanceDetails?attendance_id=${attendanceId}`);
            if (response && response?.data && response?.data?.result && response?.data?.result.length > 0) {
                setHistoryData(response.data.result);
                setHistoryDate(response.data.result[0].attendance_date);
            } else {
                setHistoryData([]);
                setHistoryDate("");
            }
        } catch (error) {
            setHistoryData([]);
            setHistoryDate("");
        }
    };

    useEffect(() => {
        if (attendanceId && open) {
            fetchAttendanceDataForId();
        }
    }, [attendanceId, axiosPrivate, open]);

    const handleBackButton = () => {
        close();
        handleOpenAttendanceDetailsSlidingPanel();
    };

    return (
        <ReactSlidingPane isOpen={open} onRequestClose={close} width="480px">
            <div className="text-black">
                <div className="flex justify-between px-5 py-3">
                    <div className="flex items-center gap-2">
                        <i className="ico-left text-xs cursor-pointer" onClick={handleBackButton}></i>
                        <h1 className="font-medium text-base">History</h1>
                    </div>
                    <button onClick={close}>{closeIcon}</button>
                </div>
                <Line />
                <div className="px-5 flex gap-3 flex-col">
                    <div className="flex justify-between">
                        <div>Found {historyData.length || 0} History</div>
                        <div className="flex items-center gap-1 font-medium">
                            Sort Descending <i className="ico-down"></i>
                        </div>
                    </div>
                    <span className="font-medium">{historyDate}</span>

                    {historyData.map((item, index) => {
                        const { shift_name, clock_in_time, clock_out_time, break_from, break_to, location_name, attendance_date } = item;
                        const isBreak = break_from && break_to; 
                        return (
                            <div className="flex justify-between items-center" key={index}>
                                <div className="flex items-center gap-3">
                                    <Avatar />
                                    <div className="flex flex-col">
                                        <span>{shift_name}</span>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {clock_in_time && <p className="clock-in">Clock-In: {clock_in_time}</p>}
                                            {isBreak && break_from && break_to && <p className="break">Break: {break_from} - {break_to}</p>}
                                            {clock_out_time && <p className="clock-out">Clock-Out: {clock_out_time}</p>}
                                            {location_name && <p className="location">Location: {location_name}</p>}
                                        </div>
                                    </div>
                                </div>
                                <span>{attendance_date}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </ReactSlidingPane>
    );
};

export default ViewAttendanceHistorySlidingPanel;
