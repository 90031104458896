import React from "react";
import ViewEmployeeFinalizeAttendaceData from "./ViewEmployeeFinalizeAttendaceData";

const ViewEmployeeFinalizeAttendaceTable = ({ data }) => {
  const cols = [
    {
      colName: <input type="checkbox" />,
      status: true,
    },
    { colName: "Employee Name", status: true },
    { colName: "Emp. Type", status: true },
    { colName: "Location", status: true },
    {
      colName: "Payable Hours / Work Schedule",
      status: true,
    },
    { colName: "Pending App.", status: true },
    { colName: "Processing Status", status: true },
    { colName: <i className="ico-grid text-blue-600"></i>, status: true },
  ];

  return (
    <>
      <div className="relative overflow-x-auto min-h-screen bg-white shadow-md rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
          <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
            <tr>
              {cols.map((item, index) => {
                if (item.status) {
                  return (
                    <th key={index} scope="col" className="px-10 py-3">
                      {item?.colName}
                    </th>
                  );
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <ViewEmployeeFinalizeAttendaceData key={index} data={item} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end mt-4 mr-3">
        <button className="text-[14px]">
          <span className="btn">Process Attendance</span>
        </button>
      </div>
    </>
  );
};

export default ViewEmployeeFinalizeAttendaceTable;
