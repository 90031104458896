import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const DeleteHoliday = ({ open, onClose, holidayId,handleMakeApiCallToFetchHolidayList }) => {
  const axiosPrivate = useAxiosPrivate();
  const [isModalOpen, setIsModalOpen] = useState(open);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axiosPrivate.delete(`lms/holiday/deleteHoliday?id=${holidayId}`);
      const res = response?.data;
      if (res.status) {
        toast.success(res.message);
        handleCloseModal();
        handleMakeApiCallToFetchHolidayList()
      } else {
        setErrMsg(res.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong, try again");
    }
  };
  

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      center
    >
      <div className="flex items-center justify-center">
        <div>
          <h2 className="text-gray-800 font-medium">Delete Holiday</h2>
          <p>Are you sure you want to delete this holiday?</p>
          {errMsg && <p className="text-red-600">{errMsg}</p>}
          <div className="mt-4 flex justify-end gap-3">
            <button
              className="btn btn--border"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn btn--red"
              onClick={handleConfirmDelete}
            >
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteHoliday;
