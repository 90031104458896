import React from "react";
import Menu from "../../../components/DashboardSidebar/Menu/Menu";

const EmployeeModuleSidebar = () => {
  return (
    <div className="flex">
      <div className="flex">
        <Menu />
      </div>
    </div>
  );
};

export default EmployeeModuleSidebar;