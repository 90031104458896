import React from "react";
import styled from "@emotion/styled";
import { Tree, TreeNode } from "react-organizational-chart";
import OrganizationTreeCard from "./OrganizationTreeCard"

const StyledNode = styled.div`
  padding: 5px;
  display: inline-block;
  border: none;
`;

const OrganizationTree = () => {
  return (
    <div className="w-full bg-gray-50">
      <div className="flex justify-between items-center px-10 py-6">
        <div>
          <h2>Organization Tree</h2>
          <p>Manage all employe on your organization</p>
        </div>
        <div>
          <a href="" className="btn text-[14px]">
            <span>
              <i className="ico-plus text-white mr-1"></i>
            </span>
            Add Employee
          </a>
        </div>
      </div>
      <div className="flex justify-center">
        <Tree
          lineWidth={"2px"}
          lineColor={"#4875E9"}
          // lineBorderRadius={"10px"}
          label={
            <StyledNode>
              <OrganizationTreeCard />
            </StyledNode>
          }
        >
          <TreeNode
            label={
              <StyledNode>
                <OrganizationTreeCard />
              </StyledNode>
            }
          >
            <TreeNode
              label={
                <StyledNode>
                  <OrganizationTreeCard />
                </StyledNode>
              }
            />
            <TreeNode
              label={
                <StyledNode>
                  <OrganizationTreeCard />
                </StyledNode>
              }
            />
          </TreeNode>
          <TreeNode
            label={
              <StyledNode>
                <OrganizationTreeCard />
              </StyledNode>
            }
          ></TreeNode>
          <TreeNode
            label={
              <StyledNode>
                <OrganizationTreeCard />
              </StyledNode>
            }
          ></TreeNode>
        </Tree>
      </div>
    </div>
  );
};

export default OrganizationTree;
