import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEdit, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { emailRegex, phoneRegex } from "../../utils/regex/regex";
import { FaCamera } from "react-icons/fa";
import Avatar from "../Avatar/Avatar";
import { useDispatch } from "react-redux";
import { ActionCreators } from "../../redux/actions/profile";


const EditProfileForm = ({ profile, setEditProfile }) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const [previewOfProfileImage, setPreviewOfProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPreviewOfProfileImage(`${profile?.photo || ""}`);
  }, [profile.photo]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      first_name: profile?.first_name || "",
      middle_name: profile?.middle_name || "",
      last_name: profile?.last_name || "",
      personal_email: profile?.personal_email || "",
      phone: profile?.phone || "",
    },
  });

  const validateUploadedImageFile = (e) => {
    let uploadedFile = e.target.files[0];
    let fileSize = uploadedFile.size;
    let fileType = uploadedFile.type;
    if (fileType === "image/jpeg" || fileType === "image/png") {
      if (fileSize < 1033414) {
        setUploadedProfileImage(uploadedFile);
        setPreviewOfProfileImage(URL.createObjectURL(uploadedFile));
      } else {
        toast.error(
          "Uploaded image file is too large, try uploading an image less than 1 MB"
        );
      }
    } else {
      toast.error("Only JPG and PNG files are allowed.");
    }
  };
  const updateUserProfile = (data) => {
    dispatch(ActionCreators.updateUserProfile(data));
  };
  const updateProfile = async (data) => {
    setLoading(true);
    const { personal_email, first_name, last_name, middle_name, phone } = data;
    let formData = new FormData();
    uploadedProfileImage && formData.append("photo", uploadedProfileImage);
    formData.append("personal_email", personal_email);
    formData.append("phone", phone);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("middle_name", middle_name);

    try {
      const response = await axiosPrivate.put(
        `employee/updateProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data?.status) {
        updateUserProfile({
          ...profile,
          first_name,
          last_name,
          middle_name,
          phone,
          personal_email,
          photo: previewOfProfileImage,
        });
        setEditProfile(false);
        toast.success("Profile updated successfully");
      } else {
        toast.error(response?.data?.message || "Failed to update profile.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-3.5 mb-6 relative">
        <div className="text-sm font-medium leading-5 text-gray-800 inline ">
          <div className="">
            <div className="relative flex justify-center item-center ">
              <Avatar
                height={"20"}
                width={"20"}
                image={previewOfProfileImage}
                isProfileSideBar={true}
              />
            </div>

            <div className="relative flex justify-center items-center mt-4">
              <label className="cursor-pointer absolute bottom-2 right-44 ">
                <div className="flex justify-center items-center w-[30px] h-[30px] bg-green-600 text-white rounded-full">
                  <FaCamera />
                </div>
                <input
                  type="file"
                  className="hidden"
                  accept="image/jpeg, image/png"
                  onChange={(e) => {
                    validateUploadedImageFile(e);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(updateProfile)}
        className="min-h-[600px] flex"
      >
        <div className="w-full flex flex-col justify-between">
          <div className="md:text-left border border-solid border-r-0 border-l-0 border-gray-200 mt-7">
            <div className="mt-3.5">
              <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                <div className="search relative">
                  <input
                    id="first-name"
                    type="text"
                    {...register("first_name", {
                      required: "First name is required",
                      validate: {
                        notEmpty: (value) =>
                          value.trim() !== "" ||
                          "This field cannot be empty or just spaces",
                        noLeadingSpace: (value) =>
                          value[0] !== " " ||
                          "This field cannot start with a space",
                      },
                    })}
                  />
                  <label
                    for="first-name"
                    className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                  >
                    First name
                  </label>
                  {errors?.first_name && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                      {errors.first_name.message}
                    </span>
                  )}
                  <div className="icon-div absolute left-2 top-3 mt-1">
                    <i className="text-base ">
                      <AiOutlineEdit />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3.5">
              <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                <div className="search relative">
                  <input
                    id="middle-name"
                    type="text"
                    {...register("middle_name", {
                      validate: {
                        noLeadingSpace: (value) =>
                          value[0] !== " " ||
                          "This field cannot start with a space",
                      },
                    })}
                  />

                  <label
                    for="middle-name"
                    className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                  >
                    Middle name
                  </label>

                  {errors?.middle_name && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                      {errors.middle_name.message}
                    </span>
                  )}
                  <div className="icon-div absolute left-2 top-3 mt-1">
                    <i className="text-base ">
                      <AiOutlineEdit />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3.5">
              <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                <div className="search relative">
                  <input
                    id="last-name"
                    type="text"
                    {...register("last_name", {
                      required: "Last name is required",
                      validate: {
                        notEmpty: (value) =>
                          value.trim() !== "" ||
                          "This field cannot be empty or just spaces",
                        noLeadingSpace: (value) =>
                          value[0] !== " " ||
                          "This field cannot start with a space",
                      },
                    })}
                  />
                  <label
                    for="last-name"
                    className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                  >
                    Last name
                  </label>
                  {errors?.last_name && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1  ml-1">
                      {errors.last_name.message}
                    </span>
                  )}
                  <div className="icon-div absolute left-2 top-3 mt-1">
                    <i className="text-base ">
                      <AiOutlineEdit />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3.5">
              <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                <div className="search relative">
                  <label
                    for="email"
                    className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                  >
                    Personal Email
                  </label>
                  <input
                    type="text"
                    id="personal_email"
                    {...register("personal_email", {
                      pattern: {
                        value: emailRegex,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                  {errors.personal_email && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.personal_email.message}
                    </p>
                  )}
                  <div className="icon-div absolute left-2 top-3 mt-1">
                    <i className="text-base ">
                      <AiOutlineMail />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3.5">
              <div className="text-sm font-medium leading-5 text-gray-800 inline ">
                <div className="search relative">
                  <input
                    type="text"
                    id="phone"
                    {...register("phone", {
                      pattern: {
                        value: phoneRegex,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  />
                  <label
                    for="phone"
                    className="absolute text-base font-semibold dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] left-3 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 focus:bg-white peer-focus:-translate-y-4 bg-white px-1.5"
                  >
                    Phone
                  </label>
                  {errors?.phone && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.phone.message}
                    </span>
                  )}
                  <div className="icon-div absolute left-2 top-4">
                    <AiOutlinePhone />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex mt-10">
                          <div className="pb-2 px-5 flex justify-end  my-3 border-t-2 pt-7 w-full">
                            <button
                              type="button"
                              className="btn btn--border text-[14px] mr-2"
                              onClick={() => {
                                setEditProfile(false);
                                setPreviewOfProfileImage(`${profile.photo}`);
                                setUploadedProfileImage(null);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className=" mr-3  btn text-[13px]"
                              type="submit"
                            >
                              Update
                            </button>
                          </div>
                        </div> */}
          </div>
          <div className="flex">
            <div className="pb-2 px-5 flex justify-end  my-3 border-t-2 pt-7 w-full">
              <button
                type="button"
                className="btn btn--border text-[14px] mr-2"
                onClick={() => {
                  setEditProfile(false);
                  setPreviewOfProfileImage(`${profile.photo}`);
                  setUploadedProfileImage(null);
                  clearErrors();
                  reset();
                }}
              >
                Cancel
              </button>
              <button
                className={`mr-3 btn text-[13px] ${
                  loading ? "btn-disabled" : ""
                }`}
                type="submit"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditProfileForm;
