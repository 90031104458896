import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";

const AddHolidayPolicyModal = ({ open, close, makeApiCall }) => {
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { policyName } = data;

    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .post(`lms/leave/createHolidayPolicy`, {
        policy_name: policyName,
      })
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          toast.success("New holiday policy created successfully");
          makeApiCall();
          close();
          reset();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <Modal open={open} onClose={close} center>
      <div className="p-5 min-w-[450px] text-black flex flex-col gap-3">
        <h1 className="text-lg font font-medium border-b border-gray-300 pb-4">
          Add holiday policy
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label>
              Holiday policy name<span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              {...register("policyName", {
                required: "Policy name cannot be empty.",
                pattern: {
                  value: /^[^\s].*$/,
                  message: "Policy name cannot start with a space.",
                },
              })}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
            {errors?.policyName && (
              <p className="text-red-600 text-sm mt-1">
                {errors?.policyName?.message}
              </p>
            )}
          </div>
          <div className="flex justify-end space-x-4">
            <button type="button" onClick={close} className="btn btn--border">
              Close
            </button>
            <button type="submit" className="btn">
              Add holiday policy
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddHolidayPolicyModal;
