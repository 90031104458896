import React from "react";
import LeaveTakenOverviewChart from "../../../../components/Leaves/ManageLeave/Overview/LeaveTakenOverviewChart";
import LeaveByTypeOverviewChart from "../../../../components/Leaves/ManageLeave/Overview/LeaveByTypeOverviewChart";
import LeaveApprovalOverviewChart from "../../../../components/Leaves/ManageLeave/Overview/LeaveApprovalOverviewChart";

const data = [
  {
    count: 100,
    content: "Total Leave Request",
  },
  {
    count: 20,
    content: "Pending",
  },
  {
    count: 30,
    content: "Approved",
  },
  {
    count: 3,
    content: "Declined",
  },
  {
    count: 8,
    content: "Canceled",
  },
];

const LeaveOverView = () => {
  return (
    <div className="p-10 w-full text-black">
      <div className="flex justify-between">
        <div>
          <p className="text-lg font-medium">Leave Overview</p>
          <p className="text-gray-500">
            Get an overview of youy employee attendance information
          </p>
        </div>
        <div>
          <button className="btn">
            <i className="ico-download mr-2" /> Download{" "}
          </button>
        </div>
      </div>

      <div className="py-5 flex gap-3">
        {data.map((item, index) => (
          <div className="rounded-md border w-[255px] px-5 py-3" key={index}>
            <p className="font-medium text-lg">{item.count}</p>
            <p className="text-gray-500">{item.content}</p>
          </div>
        ))}
      </div>

      <div className="flex gap-10 pb-5">
        <LeaveTakenOverviewChart />
        <LeaveByTypeOverviewChart />
      </div>
      <LeaveApprovalOverviewChart />
    </div>
  );
};

export default LeaveOverView;
