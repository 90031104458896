import React from "react";
import LoadingIcon from "../../Icons/Loading";

const LoadingButton = (props) => {
  return (
    <>
      <div role="status">
        <button className={`btn text-[14px] ${props.bg || "bg-[#3466E7]"} disabled:opacity-100 ${props.class}`} disabled>
          <LoadingIcon width={props.iconWidth || null} height={props.iconHeight || null} iconClass={props.iconClass || ""}/>
          {props.title || "Loading"}
        </button>
      </div>
    </>
  );
};

export default LoadingButton;
