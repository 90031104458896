import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Select from "react-select";
import {
  DropdownIndicator,
  // location as locationOptions,
  // designation as designationOptions,
  // department as departmentOptions,
} from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import Search from "../../../../components/Header/Search/Search";
import RegularizationApprovalTable from "../../../../components/Attendance/ManageAttendance/RegularizationApproval/RegularizationApprovalTable";
import { closeIconRoundRed2, revokeIcon2 } from "../../../../Icons/Icons";
import RegularizationRequestMultipleActionModal from "../../../../components/Attendance/ManageAttendance/RegularizationApproval/RegularizationRequestMultipleActionModal";
import { TAB, MODAL_VIEW } from "../../consts";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../../utils/userRoleAuth/userRoleAuth";

const RegularizationApproval = () => {
  const axiosPrivate = useAxiosPrivate();
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, 2);
  const [toggleState, toggleTab] = useState(TAB.ALL_TAB);
  const [isMultipleAction, setIsMultipleAction] = useState(false);
  const [openMultipleActionModal, setMultipleActionModalState] =
    useState(false);
  const [modalViewState, setModalViewState] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filters, setFilters] = useState({
    location: null,
    designation: null,
    department: null,
    from_date: null,
  });
  const handleSelectMultipleRequest = (e) => {
    setIsMultipleAction(e.target.checked);
  };
  const handleOpenMultipleActionModal = (e) => {
    setModalViewState(e);
    setMultipleActionModalState(true);
  };
  const handleCloseMultipleActionModal = () => {
    setMultipleActionModalState(false);
  };
  const handleToggleTab = (selectedTab) => {
    toggleTab(selectedTab);
  };
  const handleFromDateChange = (e) => {
    setSelectedDate(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      from_date: e.target.value,
    }));
  };

  const handleLocationChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      location: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleDesignationChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      designation: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      department: selectedOption ? selectedOption.value : null,
    }));
  };

  const [locationList, setLocationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const fetchLocationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getLocationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setLocationList(data.result);
        }
      })
      .catch((err) => {
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDepartmentList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDepartmentList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDepartmentList(data.result);
        }
      })
      .catch((err) => {
      });
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchDesignationList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get("employee/getDesignationList", {
        signal: controller.signal,
      })
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setDesignationList(data.result);
        }
      })
      .catch((err) => {
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchDesignationList();
    fetchDepartmentList();
    fetchLocationList();
  }, []);

  const clearFilters = () => {
    setFilters({
      location: null,
      designation: null,
      department: null,
      from_date: null,
    });
  };

  return (
    <>
      <RegularizationRequestMultipleActionModal
        open={openMultipleActionModal}
        close={handleCloseMultipleActionModal}
        modalViewState={modalViewState}
      />
      <div className="w-full">
        <div className="px-10 py-5">
          <h1 className="font-medium text-black text-lg">
            Regularization Approval
          </h1>
          <p>Manage all regularization requests</p>
        </div>
        <div className="flex items-center justify-between  border-b-2 px-5">
          <div className="flex items-center gap-5 pl-4 pt-6">
            <button
              className={
                toggleState === TAB.ALL_TAB
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => handleToggleTab(TAB.ALL_TAB)}
            >
              ALL
            </button>
            <button
              className={
                toggleState === TAB.PENDING_TAB
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => handleToggleTab(TAB.PENDING_TAB)}
            >
              Pending
            </button>
            <button
              className={
                toggleState === TAB.APPROVED_TAB
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => handleToggleTab(TAB.APPROVED_TAB)}
            >
              Approved
            </button>
            <button
              className={
                toggleState === TAB.DECLINED_TAB
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => handleToggleTab(TAB.DECLINED_TAB)}
            >
              Declined
            </button>
            <button
              className={
                toggleState === TAB.CANCELED_TAB
                  ? "tabs active-tabsnew text-blue-800 font-medium"
                  : "tabs"
              }
              onClick={() => handleToggleTab(TAB.CANCELED_TAB)}
            >
              Cancelled
            </button>
          </div>
        </div>

        {isMultipleAction ? (
          <>
            <div className="flex items-center justify-between px-10 py-4">
              <div className="flex items-center gap-3">
                <button className="btn btn--border">
                  <i className="ico-download2"></i>
                </button>
                <button className="btn btn--border">
                  <i className="ico-printer"></i>
                </button>
                <button
                  className="btn"
                  onClick={() =>
                    handleOpenMultipleActionModal(MODAL_VIEW.REVOKE_MODAL)
                  }
                >
                  Revoke{" "}
                  <span className="ml-2">{revokeIcon2({ fill: "white" })}</span>
                </button>
                <button
                  className="btn btn--red"
                  onClick={() =>
                    handleOpenMultipleActionModal(MODAL_VIEW.DECLINE_MODAL)
                  }
                >
                  Decline <i className="ico-close text-xs ml-2"></i>
                </button>
                <button
                  className="btn btn--green"
                  onClick={() =>
                    handleOpenMultipleActionModal(MODAL_VIEW.APPROVE_MODAL)
                  }
                >
                  Approve <i className="ico-tick text-xs ml-2"></i>
                </button>
              </div>

              <div className="flex gap-2 items-center">
                <p>7 pending request selected</p>
                <button>{closeIconRoundRed2}</button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between px-10 py-4">
              <div className="flex gap-4">
                <input
                  type="date"
                  value={selectedDate}
                  onChange={handleFromDateChange}
                />
                <Select
                  options={designationList.map((i) => ({
                    label: i.designation_title,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  placeholder="Designation"
                  onChange={handleDesignationChange}
                />
                <Select
                  options={departmentList.map((i) => ({
                    label: i.department_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  placeholder="Department"
                  onChange={handleDepartmentChange}
                />
                <Select
                  options={locationList.map((i) => ({
                    label: i.location_name,
                    value: i.id,
                  }))}
                  components={{ DropdownIndicator }}
                  className="custom-select-container"
                  placeholder="Location"
                  onChange={handleLocationChange}
                />
                {Object.values(filters).some((filter) => filter !== null) && (
                  <span onClick={clearFilters}>Clear Filters</span>
                )}
              </div>

              <div className="flex">
                <Search />
              </div>
            </div>
          </>
        )}

        <RegularizationApprovalTable
          toggleState={toggleState}
          handleSelectMultipleRequest={handleSelectMultipleRequest}
          filters={filters}
        />
      </div>
    </>
  );
};

export default RegularizationApproval;
