import React, { useState } from "react";
import ViewPolicyAttachment from "./ViewPolicyAttachment";
import { useGetSystemPolicyAttachments } from "../../../../queries/systemPolicyQueries";
import SpinnerLoader from "../../../PageLoader/SpinnerLoader";
import PolicyAttachment from "./PolicyAttachment";

const PolicyPreviewData = ({ systemPolicy = {} }) => {
  const { policy_name, description, id, version, policy_serial } = systemPolicy;

  const { data: getSystemPolicyAttachmentsData, isLoading } =
    useGetSystemPolicyAttachments({
      system_policy: id,
    });
  const systemPolicyAttachments = Array.isArray(
    getSystemPolicyAttachmentsData?.data
  )
    ? getSystemPolicyAttachmentsData.data
    : [];
  systemPolicy.attachments = systemPolicyAttachments;

  return (
    <div className="flex flex-col gap-3">
      <div>
        <label>Policy name</label>
        <div className="font-medium text-gray-800">{policy_name}</div>
      </div>
      <div>
        <label>Policy version</label>
        <div className="font-medium text-gray-800">{version}</div>
      </div>
      <div>
        <label>Policy serial</label>
        <div className="font-medium text-gray-800">{policy_serial}</div>
      </div>
      <div>
        <label>Description</label>
        <div className="font-medium text-gray-800 max-h-[100px] overflow-y-auto">
          {description}
        </div>
      </div>
      <div>
        <label>Attachments</label>
        <div className="flex flex-wrap gap-3 mt-2">
          {isLoading ? (
            <div className="flex flex-wrap justify-center w-full">
              <SpinnerLoader />{" "}
            </div>
          ) : !!systemPolicyAttachments.length ? (
            systemPolicyAttachments.map((i, index) => {
              return (
                <PolicyAttachment key={`${index + 2}_${i.src}`} data={i} />
              );
            })
          ) : (
            <div className="w-full">No attachments found for this policy</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PolicyPreviewData;
