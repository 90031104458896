import React from "react";
import Select, { components } from "react-select";
import Search from "../../components/Header/Search/Search";
import SalaryComponentList from "./SalaryComponentList";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const SalaryComponentTable = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-10 pb-4 mt-8">
        <div className="flex gap-3 items-center">
          <input type="date" className="btn btn--border h-[38px]" />
          <Select
            options={[
              { value: "All", label: "All" },
              { value: "Laptop", label: "Laptop" },
              { value: "Computer", label: "Computer" }
            ]}
            components={{ DropdownIndicator }}
            className="custom-select-container text-gray-500"
            defaultValue={{
              value: "Added:by All",
              label: "Added:by All"
            }}
          />
        </div>
        <div className="flex items-center">
          <Search />

          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      <SalaryComponentList />
    </div>
  );
};

export default SalaryComponentTable;
