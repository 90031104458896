import React from "react";

const RegistrationCompleted = () => {
  return (
    <div className="text-center max-w-[400px]">
      <figure className="mb-6">
        <img className="m-auto" src="/images/illustration1.svg" alt="" />
      </figure>
      <h2 className="text-2xl font-bold mb-2 text-gray-800">Thank you</h2>
      <p className="mb-6">
        We will process your employee account in max 2 business days, please
        check your email regularly to receive your account details and some
        informations we might needed to process your account.
      </p>
      <div className="inline-flex gap-4">
        <a href="" className="btn btn--border">
          Get Help
        </a>
      </div>
    </div>
  );
};

export default RegistrationCompleted;
