import React from "react";
import SimpleBar from "simplebar-react";
import PendingConfirmationData from "./PendingConfirmationData";

const confirmationData = [
  {
    image: "/images/avatar.jpg",
    Name: "Dianne Russell",
    date: "in 3 days"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ronald Richards",
    date: "in 3 days"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Darlene Robertson",
    date: "in 3 days"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Kristin Watson",
    date: "in 3 days"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Shane Warne",
    date: "in 3 days"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    date: "in 3 days"
  }
];

const PendingConfirmation = () => {
  return (
    <div className="card flex flex-col">
      <div className="flex justify-between items-center text-gray-800 px-4 py-2">
        <h2>Pending Confirmations</h2>
        <span className="flex items-center px-3 text-gray-800 font-medium border border-gray-200 mr-3 cursor-pointer text-xs leading-4 py-1.5 rounded-md">
          Onboarding <i className="ico-down ml-2"></i>
        </span>
      </div>
      <SimpleBar style={{ maxHeight: 317 }}>
        {confirmationData.map((val, key) => {
          return <PendingConfirmationData requestdata={val} />;
        })}
      </SimpleBar>
    </div>
  );
};

export default PendingConfirmation;
