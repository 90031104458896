import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import userRoles from "../../../../config/userRoles";
import { useSelector } from "react-redux";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const { RangePicker } = DatePicker;

const LeaveApprovalOverView = () => {
  const colors = [
    "#4875E9",
    "#9AB3F3",
    "#C2D1F8",
    "#7d9df0",
    "#f0f4fd",
  ];
  const defaultColor = "#D3D3D3";
  const axiosPrivate = useAxiosPrivate();
  const [leaveApprovalList, setLeaveApprovalList] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const currentUser = useSelector((state) => state.user.auth);
  const allowedRoles = [userRoles.HR, userRoles.TENANTADMIN, userRoles.MANAGER];
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [totalPending, setTotalPending] = useState(0); 
  const chartRef = useRef(null);
  let chartInstance = useRef(null);

  useEffect(() => {
    fetchApprovalOverView();
  }, [dateRange, currentUser]);

  const fetchApprovalOverView = () => {
    const [fromDate, toDate] = dateRange;
    if (fromDate && toDate) {
      const fromDateStr = fromDate.format("YYYY-MM-DD");
      const toDateStr = toDate.format("YYYY-MM-DD");

      axiosPrivate
        .get(
          `lms/leave/leaveApprovalWidgetOverView?from_date=${fromDateStr}&to_date=${toDateStr}`
        )
        .then((response) => {
          if (response?.data?.data) {
            const approvalData = response.data.data;
            const filteredData = approvalData.filter(
              (item) => item.leave_status !== "Pending"
            );
            setLeaveApprovalList(filteredData);

           
            const totalLeavesApproved = filteredData.reduce(
              (total, item) => total + parseInt(item.count),
              0
            );

          
            const pendingItem = approvalData.find(
              (item) => item.leave_status === "Pending"
            );
            const newTotalPending = pendingItem ? parseInt(pendingItem.count) : 0;
            setTotalPending(newTotalPending); 

         
            updateChart(filteredData, totalLeavesApproved);
          } else {
            setLeaveApprovalList([]);
            setTotalPending(0); 
          }
        })
        .catch((error) => {
          console.error("Error fetching leave approval overview:", error);
          setLeaveApprovalList([]);
          setTotalPending(0); 
        });
    } else {
      setLeaveApprovalList([]);
      setTotalPending(0); 
    }
  };

  const updateChart = (data, totalLeavesApproved) => {
    const ctx = chartRef?.current?.getContext("2d");
    const dataValues = data.map((item) => item.count);
    const backgroundColor = totalLeavesApproved > 0 ? colors : [defaultColor];

    if (chartInstance?.current) {
      chartInstance.current.data.labels = data.map((item) => item.leave_status);
      chartInstance.current.data.datasets[0].data = totalLeavesApproved > 0 ? dataValues : [1];
      chartInstance.current.data.datasets[0].backgroundColor = backgroundColor;
      chartInstance.current.update();
    } else {
      chartInstance.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: data.map((item) => item.leave_status),
          datasets: [
            {
              label: "# of Leaves Approval",
              data: totalLeavesApproved > 0 ? dataValues : [1],
              backgroundColor: backgroundColor,
            },
          ],
        },
        options: {
          scales: {},
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <>
      <div className="border rounded-md p-3">
        <div className="flex justify-between font-medium text-black">
          <p className="">Leave Approvals</p>
          <p className="flex items-center gap-2">
            {isCalendarOpen ? (
              <RangePicker
                value={dateRange}
                onChange={handleDateRangeChange}
                format="YYYY-MM-DD"
                disabledDate={disableFutureDates}
              />
            ) : (
              "Today"
            )}
            <i
              className={`ico-${isCalendarOpen ? "up" : "down"}`}
              onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            />
          </p>
        </div>

        <div className="flex items-center py-2">
          <div className="w-[160px] px-3">
            <canvas
              id="LeaveApprovalOverView"
              ref={chartRef}
              width="160"
              height="160"
            ></canvas>
          </div>

          <div className="flex flex-wrap w-full">
            {leaveApprovalList?.length === 0 ? (
              <div className="flex gap-2 justify-center py-10 max-h-full">
                <span className="flex items-center py-2 text-gray-800">
                  No data available!
                </span>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-2 w-full">
                {leaveApprovalList?.map((item, index) => (
                  <div className="flex pb-3 pr-5" key={index}>
                    <span
                      className={`h-3 w-5 rounded-[20px] mr-2`}
                      style={{ backgroundColor: colors[index % colors.length] }}
                    ></span>
                    <p>{item?.leave_status}</p>
                    <h3 className="ml-auto text-gray-800 font-medium">
                      {item?.count}
                    </h3>
                  </div>
                ))}
                <br/>
                <div className="flex pb-3 pr-5 w-full text-gray-800">
                  <p className="font-medium">Total Pending</p>
                  <h3 className="ml-auto font-medium">
                    {totalPending}
                  </h3>
                </div>
                <div className="flex pb-3 pr-5 w-full text-gray-800">
                  <p className="font-medium">Total Actions Taken</p>
                  <h3 className="ml-auto font-medium">
                    {leaveApprovalList.reduce((total, item) => total + parseInt(item.count), 0)}
                  </h3>
                </div>
               
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApprovalOverView;
