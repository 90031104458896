import React from "react";

const StatutoryCard = () => {
  return (
    <div className="w-full flex mx-10 gap-6">
      <div className="bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg max-w-[490px]  p-6 mt-8">
        <div className="flex items-center justify-between pb-4">
          <p className="text-base font-semibold text-[#1F2937]">Head Office</p>
          <img src="/images/editicon.svg" alt="" className="cursor-pointer" />
        </div>
        <div className="flex gap-6">
          <ul className="flex flex-col gap-3 text-sm leading-5 font-normal">
            <li>PT Number</li>
            <li>State</li>
            <li>Deduction Cycle</li>
            <li>PT Slabs</li>
          </ul>
          <ul className="flex flex-col gap-3 text-sm leading-5 font-medium text-[#1F2937]">
            <li>1</li>
            <li>Kerala</li>
            <li>Monthly</li>
            <li className="cursor-pointer text-[#3466E7] text-sm font-medium">
              View Tax Slabs
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-white shadow-md shadow-[#F3F4F6] border border-gray-100 rounded-lg max-w-[490px] p-6 mt-8">
        <div className="flex items-center justify-between pb-4">
          <p className="text-base font-semibold text-[#1F2937]">
            Branch Office 2
          </p>
          <img src="/images/editicon.svg" alt="" className="cursor-pointer" />
        </div>
        <div className="flex gap-6">
          <ul className="flex flex-col gap-3 text-sm leading-5 font-normal">
            <li>PT Number</li>
            <li>State</li>
            <li>Deduction Cycle</li>
            <li>PT Slabs</li>
          </ul>
          <ul className="flex flex-col gap-3 text-sm leading-5 font-medium text-[#1F2937]">
            <li>2</li>
            <li>Mumbai</li>
            <li>Monthly</li>
            <li className="cursor-pointer text-[#3466E7] text-sm font-medium">
              View Tax Slabs
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StatutoryCard;
