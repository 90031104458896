export const MODAL_VIEW = {
  APPROVE_MODAL: 1,
  DECLINE_MODAL: 2,
  REVOKE_MODAL: 3,
};

export const TAB = {
  PENDING_TAB: 0,
  APPROVED_TAB: 1,
  DECLINED_TAB: 2,
  CANCELED_TAB: 3,
};
