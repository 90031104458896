const employeeAccountStatusFormat =  (status) => {
    // 1 Active
    // 2 Onboarding
    // 3 Off-boarding
    // 4 On Leave
    // 5 Probationary
    const currentStatus = status;
    const employeeAccountStatus = [
      {
        status: 1,
        bgColor: "FFFFFF",
        label: "Active",
        borderColor: "#059669",
        fontColor: "#059669",
      },
      {
        status: 2,
        bgColor: "#D1FAE5",
        label: "Onboarding",
        borderColor: "none",
        fontColor: "#065F46",
      },
      {
        status: 3,
        bgColor: "#EDE9FE",
        label: "On Leave",
        borderColor: "none",
        fontColor: "#5B21B6",
      },
      {
        status: 4,
        bgColor: "#FEF3C7",
        label: "Probationary",
        borderColor: "none",
        fontColor: "#92400E",
      },
      {
        status: 5,
        bgColor: "#FEE2E2",
        label: "Off-boarding",
        borderColor: "none",
        fontColor: "#991B1B",
      },
      {
        status: 0,
        bgColor: "FFFFFF",
        label: "Loading",
        borderColor: "#059669",
        fontColor: "#059669",
      },
    ];
    let employeeStatus = null; 
    employeeAccountStatus.forEach((item) => {
      if (Number(item.status) === Number(currentStatus)) {
        employeeStatus = item
      }
    });
    return employeeStatus;
  }

export default employeeAccountStatusFormat;