import React from "react";
import ManageAssetData from "./ManageAssetData";

const employeeOnboardListData = [
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    added_by: "Courtney Henry",
    added_date: "27 Apr 2022",
    category: "Laptop",
    total_asset: "12",
    assigned: "10",
    status: "Out Of Stock"
  }
];

const ManageAssetList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-600 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              ASSET NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ADDED BY
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ADDED DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              CATEGORY
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              TOTAL ASSET
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ASSIGNED
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Status
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {employeeOnboardListData.map((val, key) => {
            return <ManageAssetData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManageAssetList;
