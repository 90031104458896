import React from "react";
import JobEmployementInfo from "./JobEmployementInfo";
import JobWorkInfo from "./JobWorkInfo";
import JobShiftInfo from "./JobShiftInfo";

const JobInfo = ({ employee }) => {
  return (
    <div>
      {/* <JobEmployementInfo /> */}
      <JobWorkInfo  employee={employee} />
      {/* <JobShiftInfo /> */}
    </div>
  );
};

export default JobInfo;
