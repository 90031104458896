import React, { useEffect, useState } from "react";
import BigCalendar from "../../../components/BigCalendar/BigCalendar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";

const LeaveCalendar = () => {
  const axiosPrivate = useAxiosPrivate();
  const [dateRange, setDateRange] = useState({
    fromDate: moment()
      .startOf("month")
      .subtract(7, "days")
      .format("YYYY-MM-DD"),
    toDate: moment().endOf("month").add(7, "days").format("YYYY-MM-DD"),
  });
  // const [makeApiCall, setMakeApiCall] = useState(false);
  const [eventList, setEventList] = useState([]);

  const { fromDate, toDate } = dateRange;

  const formatLeaveDataForCalendar = (leaveData) => {
    const formattedData = [];
    if (Array.isArray(leaveData)) {
      leaveData.forEach((item) => {
        formattedData.push({
          title: (
            <div>
              <p>{item.leave_name}</p>
              <p className="text-xs">{item.leave_serial}</p>
            </div>
          ),
          start: new Date(moment(item.from_date).format("YYYY-MM-DD")),
          end: new Date(moment(item.to_date).format("YYYY-MM-DD")),
          titleColor: "",
          backGroundColor: "#f6ffed",
          borderColor: "#b7eb8f",
        });
      });
      setEventList(formattedData);
    }
  };

  const fetchLeaveRequestList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveRequestList?pageNo=${1}&pageSize=${50}&status=1&fromDate=${fromDate}&toDate=${toDate}`)
      .then((response) => {
        // isMounted && console.log(response.data);
        if (response.data.status) {
          const { leaveRequestList } = response.data;
          formatLeaveDataForCalendar(leaveRequestList);
        } else {
          formatLeaveDataForCalendar([])
        }
      })
      .catch((err) => {});

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeaveRequestList();
  }, [dateRange]);

  const handleChangeDate = (e) => {
    const { start, end } = e;
    if (start && end) {
      setDateRange({
        fromDate: moment(start).format("YYYY-MM-DD"),
        toDate: moment(end).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className="flex-1 px-10 pt-8 pb-6 border-b border-gray-200">
      <div className="flex items-center justify-between mb-4">
        <h2>Leave Calendar</h2>
      </div>
      <BigCalendar
        myEventsList={eventList}
        handleChangeDate={handleChangeDate}
      />
    </div>
  );
};

export default LeaveCalendar;
