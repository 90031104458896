import React, { useState } from "react";
import TabSelector from "../../components/TabSelector/TabSelector";
import PreferenceFlexiblePlan from "./PreferenceFlexiblePlan";
import PreferenceTab from "../../components/Assets/AssetsTab/PreferenceTab";

const PreferenceSettings = () => {
  const [index, setIndex] = useState(0);

  const getTabIndex = (e) => {
    setIndex(e);
  };
  const tabs = [
    "Flexible Benefit Plans",
    "Reimbursement Claims",
    "IT Declaration",
    "Proof Of Invesments"
  ];
  const components = [<PreferenceFlexiblePlan />];
  return (
    <div className="px-10 py-6 w-full">
      <div className="px-10">
        <h2 className="text-xl font-semibold">Preferences</h2>
      </div>
      <PreferenceTab tabs={tabs} getTabIndex={getTabIndex} />
      <TabSelector tabIndex={index} components={components} />
    </div>
  );
};

export default PreferenceSettings;
