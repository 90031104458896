import React from "react";
import CandidateListData from "./CandidateListData";

const CandidateList = (props) => {
  const candidateList = props.data;

  return (
    <div className="relative">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-800 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" className="px-10 py-3">
              ID
            </th>
            <th scope="col" className="px-10 py-3">
              candidate Name
            </th>
            <th scope="col" className="px-10 py-3">
              Added date
            </th>
            <th scope="col" className="px-10 py-3">
             Designation
            </th>
            <th scope="col" className="px-10 py-3">
              Added by
            </th>
            <th scope="col" className="px-10 py-3">
              Source
            </th>
            <th scope="col" className="px-10 py-3">
              Offer status
            </th>
            <th scope="col" className="px-10 py-3"></th>
          </tr>
        </thead>
        <tbody className="">
          {candidateList &&
            candidateList.map((val, key) => {
              return <CandidateListData requestdata={val} key={key} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CandidateList;
