import React from "react";
import AssetListData from "./AssetListData";

const employeeOnboardListData = [
  {
    id: "798",
    asset_name: "Laptop Asus GZ200",
    assigned_by: "Kristin Watson",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  },
  {
    id: "543",
    asset_name: "Nissan Teana GM",
    assigned_by: "Courtney Henry",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  },
  {
    id: "544",
    asset_name: "Desktop PC",
    assigned_by: "Kristin Watson",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  },
  {
    id: "555",
    asset_name: "Desktop PC",
    assigned_by: "Courtney Henry",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  },
  {
    id: "556",
    asset_name: "Macbook Air",
    assigned_by: "Courtney Henry",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  },
  {
    id: "558",
    asset_name: "Macbook Air",
    assigned_by: "Courtney Henry",
    assigned_date: "27 Apr 2022",
    return_date: "28 Apr 2022",
    type: "laptop",
    status: [
      { id: 1, label: "Assigned" },
      { id: 2, label: "Returned" },
      { id: 3, label: "Pending" }
    ],
    notes: "_"
  }
];

const EmployeeAssetList = () => {
  return (
    <div class="relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead class="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-gray-600 border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            <th scope="col" class="px-10 py-3">
              <input type="checkbox" />
            </th>
            <th scope="col" class="px-10 py-3">
              ID
            </th>
            <th scope="col" class="px-10 py-3">
              ASSET NAME
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ASSIGNED BY
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              ASSIGNED DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              RETURN DATE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              TYPE
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Status
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3">
              Notes
              <i className="ico-down text-blue-600 cursor-pointer pl-2"></i>
            </th>
            <th scope="col" class="px-10 py-3 text-right">
              <i className="ico-grid text-blue-600 cursor-pointer pl-2"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {employeeOnboardListData.map((val, key) => {
            return <AssetListData requestdata={val} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeAssetList;
