import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../helpers/axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "simplebar/dist/simplebar.min.css";

const Activation = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState([]);
  const { token } = useParams();
  const [candidate, setCandidate] = useState(null);
  const [pageComponent, setPageComponent] = useState("");

  const getUserDetails = async () => {
    setPageComponent("Loading...");
    await axios
      .get("auth/get-user", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response?.data?.userData) {
          setCandidate(response.data.userData);
          setPageComponent("");
        } else {
          toast.info("Link has expired");
          setPageComponent("Link has expired");
        }
      })
      .catch(() => {
        setPageComponent("Something went wrong try again...");
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const onSubmit = async (data) => {
    const newPassword = data.password;
    try {
      const response = await axios
        .post(
          "employee/activateAccount",
          {
            new_password: newPassword,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const statusCode = response.data.statusCode;
          if (statusCode == 200) {
            toast.success("Account activation successfully!", {
              type: "success",
            });
            navigate("/offer/login");
          } else if (!statusCode) {
            setErrMsg("No Server Response!");
          } else if (statusCode == 400) {
            setErrMsg("Please provide a valid password!");
          } else if (statusCode == 401) {
            setErrMsg("Unauthorized!");
          } else {
            setErrMsg("Password Creation Failed!");
          }
        });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Password is not added in request");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Password Creation Failed");
      }

      errRef.current.focus();
    }
  };

  if (pageComponent) {
    return (
      <div className="flex justify-center items-center h-screen">
        {pageComponent}
      </div>
    );
  }

  return (
    <>
      <main className="lg:flex items-start lg:h-screen  ">
        <div className="sidebar flex flex-col bg-blue-800  p-10 text-white min-h-screen ">
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2 text-white">
              Your success with us <br />
              start here
            </h2>
            <br />
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.{" "}
            </p>
          </div>
          <div className="flex items-center gap-2 mt-auto">
            <div className="rounded-full overflow-hidden">
              <img src="/images/thumb1.png" className="w-[42px] h-[42px]" />
            </div>
            <div>
              <h3 className="font-semibold mb-1">Need any help ?</h3>
              <h4 className="text-xs">Contact Support</h4>
            </div>
          </div>
        </div>

        {/* ACTIVATE ACCOUNT SECTION */}
        <div className="flex justify-center w-full items-center h-screen">
          <div className="flex-1 justify-center set-middle max-w-[380px]">
            <div className="mb-6 text-center">
              <div>
                <img
                  src="/images/logo-large.svg"
                  className="inline-block mb-6 w-20 h-20"
                  alt=""
                />
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                Activate your account
              </h2>
              <p>
                Welcome onboard {candidate && candidate.firstName}, to activate
                your account please create new password below.
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <span
                className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1"
                ref={errRef}
              >
                {errMsg}
              </span>
              <div className=" mb-4">
                <label htmlFor="">Passsword</label>
                <input
                  type="password"
                  {...register("password", {
                    required: "Password is required!",
                    minLength: {
                      value: 8,
                      message: "Minimum 8 characters required for passsword",
                    },
                    validate: (value) => {
                      return (
                        [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every(
                          (pattern) => pattern.test(value)
                        ) ||
                        "Passwords must contain a minimum of 1 lower case,upper case,number and special character"
                      );
                    },
                  })}
                />
                {errors.password && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1">
                    {errors.password.message}
                  </span>
                )}
              </div>

              <div className=" mb-4">
                <label htmlFor="">Confirm Passsword</label>
                <input
                  type="password"
                  {...register("passwordConfirmation", {
                    required: "Password is required",
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { password } = getValues();
                        return password === value || "Passwords should match!";
                      },
                    },
                  })}
                />
                {errors.passwordConfirmation && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1">
                    {errors.passwordConfirmation.message}
                  </span>
                )}
              </div>

              <div className=" mb-3 flex items-center">
                <input
                  type="checkbox"
                  text=" "
                  id="terms"
                  textclass="font-medium text-gray-700"
                  {...register("checkbox", {
                    required: "Please accept the privacy policy",
                  })}
                />
                <label
                  htmlFor="checkbox"
                  className="form-check-label pl-2 mb-0"
                >
                  I accept the{" "}
                  <a href="#" className="underline hover:text-blue-700">
                    privacy policy
                  </a>
                </label>
              </div>
              {errors.checkbox && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mb-1">
                  {errors.checkbox.message}
                </span>
              )}
              <div className=" mb-3 flex items-center">
                <input
                  type="checkbox"
                  text=" "
                  id="general"
                  textclass="font-medium text-gray-700"
                  {...register("checkbox2", {
                    required: "Please accept the General use conditions",
                  })}
                />
                <label
                  htmlFor="checkbox2"
                  className="form-check-label pl-2 mb-0"
                >
                  I have read, understood and{" "}
                  <a href="#" className="underline hover:text-blue-700">
                    accepted the General
                    <br /> Use Conditions
                  </a>
                </label>
              </div>

              {errors.checkbox2 && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mb-1">
                  {errors.checkbox2.message}
                </span>
              )}

              <div className=" mb-4">
                <button type="submit" className="btn btn--full">
                  Activate Account
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Activation;
