import React from "react";
import DirectoryCardData from "./DirectoryCardData";

const directoryCardData = [
  {
    image: "/images/avatar.jpg",
    Name: "Arlene McCoy",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ronald Richards",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Darlene Robertson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Kristin Watson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Shane Warne",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    Designation: "Delivery Head",
    Status: "Active",
    Phone: "82-57545613",
    Extension: "553",
    Email: "jeremy@mail.com",
    Line_Manager: "@james"
  }
];

const DirectoryCard = () => {
  return (
    <div className="pt-6">
      <div className="lg:grid xl:grid-cols-5 grid-cols-3 gap-6">
        {directoryCardData.map((val, key) => {
          return <DirectoryCardData requestdata={val} key={key} />;
        })}
      </div>
    </div>
  );
};

export default DirectoryCard;
