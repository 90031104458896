import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SimpleBar from "simplebar-react";
import AbsentEmployeeList from "./AbsentEmployeeList";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import userRoles from "../../../../config/userRoles";
import { useSelector } from "react-redux";
import moment from "moment";

const AbsentEmployee = () => {
  const [absentEmployeeList, setAbsentEmployeeList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.auth)
  const allowedRoles = [userRoles.HR,userRoles.TENANTADMIN,userRoles.MANAGER]

  const fetchAbsentAllTeamEmployeeList = async (date) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(`attendance/whoIsIn?status=4&date=${date}&pageSize=5`, {

      });
      if (response?.data?.status) {
        const absentEmployeeList = response?.data?.employee || [];
        setAbsentEmployeeList(absentEmployeeList);
      } else {
        setAbsentEmployeeList([]);
      }
    } catch (error) {
      console.error("Error fetching all team absent employee list:", error?.response?.data?.message || error.message);
      setAbsentEmployeeList([]);
    }
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    if (allowedRoles.find((role) => currentUser.roles.includes(role)) ) {
      fetchAbsentAllTeamEmployeeList(moment().format("YYYY-MM-DD"));
    }
  }, [currentUser]);

  const handleViewTeams = () => {
    navigate("/leave/manage-leave/employee-leave");
  };

  return (
    <div className="border rounded-md p-3 w-full h-full">
      <div className="flex justify-between font-medium">
        <p>Today's Employee Absent List</p>
      </div>
      <SimpleBar style={{ maxHeight: 317 }}>
        {absentEmployeeList.length === 0 ? (
          <div className="flex gap-2 justify-center py-12 max-h-full">
            <span className="flex items-center py-2 text-gray-800">No Absent List</span>
          </div>
        ) : (
          absentEmployeeList.map((employee, key) => (
            <AbsentEmployeeList employeeList={employee} key={key} />
          ))
        )}
        {(allowedRoles.find((role) => currentUser.roles.includes(role))) && absentEmployeeList.length > 1 && (
          <div className="flex justify-center py-2">
            <button onClick={handleViewTeams} className="text-blue-500 hover:underline">
              View more
            </button>
          </div>
        )}
      </SimpleBar>
    </div>
  );
};

export default AbsentEmployee;
