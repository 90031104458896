import React, { useEffect, useState, useRef } from "react";
import HolidayListTable from "../HolidayListTable/HolidayListTable";
import Search from "../../../Header/Search/Search";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Select from "react-select";
import Pagination from "../../../Pagination/Pagination";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import AddorEditHoliday from "../AddOrEditHoliday/AddorEditHoliday";
import { holidayTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import EmptyState from "../../../EmptyState";

const HolidayList = ({ policyMapping, setSelectedPolicy }) => {
  const [isAddNewHoliday, setIsAddNewHoliday] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [holidayList, setHolidayList] = useState(null);
  const [locationList, setLocationList] = useState({
    country: [],
    state: [],
    district: [],
  });
  const [location, setLocation] = useState({
    country: 0,
    state: 0,
    city: 0,
    date: "",
    search: "",
  }); // selected locaition id and date filtering
  const [searchValue, setSearchValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [makeApiCall, setMakeApiCall] = useState(false);
  const [holidayType, setHolidayType] = useState(null);

  const filterRef = useRef({
    country: "",
    state: "",
    date: "",
    holidayType: "",
  });
  const isFilterApplied =
    location.country ||
    location.state ||
    location.date ||
    location.search ||
    holidayType;

  const handleClearFilters = () => {
    setLocation({
      country: 0,
      state: 0,
      city: 0,
      date: "",
      search: "",
    });

    setHolidayType(null);

    if (filterRef.current.country) {
      filterRef.current.country.clearValue();
    }
    if (filterRef.current.state) {
      filterRef.current.state.clearValue();
    }
    if (filterRef.current.date) {
      filterRef.current.date.value = "";
    }
    if (filterRef.current.holiday_type) {
      filterRef.current.holiday_type.clearValue();
    }
  };

  const handleMakeApiCallToFetchHolidayList = () => {
    setMakeApiCall((prev) => !prev);
  };

  useEffect(() => {
    let baseUrl = `onboarding/services/location?`;
    let holidayListBaseUrl = `lms/holiday/holidayList?page_number=${pageNo}&page_limit=${pageSize}`;
    if (location.country) {
      baseUrl += `StatesFrom=${location.country}`;
      holidayListBaseUrl += `&country_id=${location.country}`;
    }
    if (location.state) {
      baseUrl += `&CityFrom=${location.state}`;
      holidayListBaseUrl += `&state_id=${location.state}`;
    }
    if (location.date) {
      holidayListBaseUrl += `&date=${location.date}`;
    }
    if (holidayType) {
      holidayListBaseUrl += `&holiday_type_id=${holidayType}`;
    }
    if (location.search) {
      holidayListBaseUrl += `&search=${location.search}`;
    }

    fetchLocationList(baseUrl);
    fetchHolidayList(holidayListBaseUrl);
  }, [location, pageNo, holidayType, makeApiCall, pageSize]);

  const fetchLocationList = async (baseUrl) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(baseUrl)
      .then((response) => {
        const res = response?.data;
        if (res.status || res.statusCode === 200) {
          if (res.country) {
            setLocationList((prev) => {
              return {
                ...prev,
                country: res.country,
              };
            });
          }
          if (res.state) {
            setLocationList((prev) => {
              return {
                ...prev,
                state: res.state,
              };
            });
          }
          if (res.city) {
            setLocationList((prev) => {
              return {
                ...prev,
                city: res.city,
              };
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchHolidayList = async (url) => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(url)
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          setTotalRecords(res.total);
          setHolidayList(res.holidays);
        } else {
          setTotalRecords(0);
          setHolidayList(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setTotalRecords(0);
        setHolidayList(null);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setLocation((prev) => ({ ...prev, search: searchValue }));
    }, 500);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [searchValue]);

  const getSearchValue = (e) => {
    setSearchValue(e);
  };

  const handleHolidayTypeChange = (selectedOption) => {
    setHolidayType(selectedOption?.value || null);
  };

  return (
    <div className="">
      {isAddNewHoliday ? (
        <AddorEditHoliday
          open={isAddNewHoliday}
          close={() => setIsAddNewHoliday(false)}
          handleMakeApiCallToFetchHolidayList={
            handleMakeApiCallToFetchHolidayList
          }
        />
      ) : null}

      <div className="flex flex-wrap justify-between  items-center px-10 py-6">
        <div>
          <h2 className="">Holiday Lists</h2>
          <p className="pb-4">
            View and manage all your organizations holidays
          </p>
        </div>
        <button
          className="btn text-[14px]"
          onClick={() => setIsAddNewHoliday(true)}
        >
          <span>
            <i className="ico-plus text-white mr-1"></i>
          </span>
          New Holiday
        </button>
      </div>
      <div className="flex justify-between flex-wrap gap-3 items-center px-10 mb-4">
        <div className="flex flex-wrap gap-3">
          <div>
            <input
              ref={(ele) => (filterRef.current.date = ele)}
              onChange={(e) =>
                setLocation((prev) => ({ ...prev, date: e.target.value }))
              }
              type="date"
              placeholder="Select a date"
              className="appearance-none bg-transparent border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-600"
            />
          </div>
          <Select
            ref={(ele) => (filterRef.current.country = ele)}
            components={{ DropdownIndicator }}
            className="w-[200px]"
            placeholder={"Country"}
            options={
              locationList.country &&
              locationList.country.map((item) => {
                return { label: item.flag, value: item.id };
              })
            }
            onChange={(e) =>
              setLocation((prev) => {
                return { ...prev, country: e?.value };
              })
            }
          />
          <Select
            ref={(ele) => (filterRef.current.state = ele)}
            components={{ DropdownIndicator }}
            className="w-[200px]"
            placeholder={"State"}
            options={
              locationList.state &&
              locationList.state.map((item) => {
                return { label: item.state_name, value: item.id };
              })
            }
            onChange={(e) => {
              setLocation((prev) => {
                return { ...prev, state: e?.value };
              });
            }}
          />
          <Select
            ref={(ele) => (filterRef.current.holiday_type = ele)}
            placeholder="Holiday Type"
            components={{ DropdownIndicator }}
            options={holidayTypeOptions}
            onChange={handleHolidayTypeChange}
            value={
              holidayTypeOptions.find(
                (option) => option.value === holidayType
              ) || null
            }
          />

          {isFilterApplied && (
            <button
              className="text-blue-600 font-medium"
              onClick={handleClearFilters}
            >
              Clear Filters
            </button>
          )}
        </div>
        <div className="flex flex-wrap items-center gap-3">
          <Search mx="0" onChange={getSearchValue} />
          <a href="" className="btn btn--border text-[14px]">
            <span>
              <i className="ico-download mr-1"></i>
            </span>
            Download
          </a>
        </div>
      </div>
      {Array.isArray(holidayList) && holidayList.length > 0 ? (
        <HolidayListTable
          holidayList={holidayList}
          policyMapping={policyMapping}
          setSelectedPolicy={setSelectedPolicy}
          handleMakeApiCallToFetchHolidayList={
            handleMakeApiCallToFetchHolidayList
          }
        />
      ) : (
        <EmptyState />
      )}
      {Array.isArray(holidayList) && holidayList.length > 0 && (
        <Pagination
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      )}
    </div>
  );
};

export default HolidayList;
