import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../../Icons/Icons";
import Select from "react-select";
import Avatar from "../../Avatar";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import formatAvatarLetter from "../../../utils/FormatAvatarLetter/formatAvatarLetters";

const AssignHRModal = ({ open, close, selectedEmployees }) => {
  const axiosPrivate = useAxiosPrivate();
  const [hrList, setHRList] = useState([]);
  const [selectedHR, setSelectedHR] = useState(null);

  const fetchHR = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "employee/search?pageNo=1&pageSize=100&sortOrder=DESC&role=Hr",
        {
          signal: controller.signal,
        }
      );
      setHRList(response?.data?.employee || []);
    } catch (err) {
      console.error(err);
    } finally {
      controller.abort();
    }
  };

  useEffect(() => {
    fetchHR();

    if (selectedEmployees.length === 1 && selectedEmployees[0]?.hr_name) {
      const currentHR = {
        employee_id: selectedEmployees[0].hr_id,
        first_name: selectedEmployees[0].hr_name.split(" ")[0],
        last_name: selectedEmployees[0].hr_name.split(" ")[1] || "",
      };
      setSelectedHR(currentHR);
    } else {
      setSelectedHR(null);
    }
  }, [selectedEmployees]);

  const assignHR = async (reqObj) => {
    try {
      const res = await axiosPrivate.put("employee/assignHR", { employeeList: reqObj });
      if (res?.data?.status) {
        toast.success(res?.data?.message || "HR updated for the selected employee(s)");
        close();
      } else {
        toast.info(res?.data?.message || "Something went wrong... try again");
      }
    } catch (err) {
      toast.error(err?.response?.data?.error || "Failed to assign HR(s)");
    }
  };

  const onSubmit = () => {
    if (!selectedHR) {
      return toast.info("Select an HR from the list");
    }
    const reqObj = selectedEmployees.map((employee) => ({
      employeeId: employee.employee_id,
      HRId: selectedHR.employee_id,
    }));
    assignHR(reqObj);
  };

  const isSelectDisabled = selectedEmployees.length > 1;

  return (
    <Modal open={open} onClose={close} center showCloseIcon={false}>
      <div className="w-[500px] flex flex-col">
        <div className="flex justify-between items-center gap-5">
          <h1 className="text-xl font-medium text-gray-800">
            Assign HR
          </h1>
          <button onClick={close}>{closeIcon}</button>
        </div>
        <div className="py-2">
          <label>Select HR</label>
          <Select
            options={hrList}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            value={selectedHR}
            getOptionValue={(option) => option.employee_id}
            onChange={(selectedOption) => {
              setSelectedHR(selectedOption);
            }}
            placeholder="Select HR"
           
          />
        </div>
        <div className="max-h-[400px] overflow-y-auto px-1">
          {selectedEmployees.map((i) => {
            const empShortName = `${i?.first_name} ${i?.last_name}`;
            const avatarLetter = formatAvatarLetter(empShortName);
            const empName = `${i.first_name || ""} ${i.last_name || ""}`;
            return (
              <div className="border rounded-3xl px-2 py-1 my-2 flex items-center gap-2 bg-gray-50">
                <Avatar width="8" height="8" title={avatarLetter} />
                <span className="text-gray-800">{empName}</span>
              </div>
            );
          })}
        </div>

        <div className="flex gap-3 justify-end">
          <button className="btn btn--border" onClick={close}>
            Close
          </button>
          <button className="btn" onClick={onSubmit}>
            Assign HR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignHRModal;
