import { Types } from "../constants/actionTypes";

const initialState = {
  isAsideBarExpanded: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TOGGLE_ASIDEBAR:
      return {
        ...state,
        isAsideBarExpanded: !state.isAsideBarExpanded,
      };
    default:
      return state;
  }
};

export default reducer;
