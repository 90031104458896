import React from "react";
import SimpleBar from "simplebar-react";
import NewJoinerData from "./NewJoinerData";

const joinerData = [
  {
    image: "/images/avatar.jpg",
    Name: "Dianne Russell",
    date: "Joined 22 March 2022"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ronald Richards",
    date: "Joined 22 March 2022"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Darlene Robertson",
    date: "Joined 22 March 2022"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Kristin Watson",
    date: "Joined 22 March 2022"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Shane Warne",
    date: "Joined 22 March 2022"
  },
  {
    image: "/images/avatar.jpg",
    Name: "Ricky Melson",
    date: "Joined 22 March 2022"
  }
];

const NewJoiner = () => {
  return (
    <div className="card flex flex-col">
      <div className="flex justify-between items-center text-gray-800 px-4 py-2">
        <h2>New Joiner</h2>
        <span className="text-xs leading-4 font-medium w-7 h-5 rounded-[10px] bg-gray-100 flex justify-center items-center">
          6
        </span>
      </div>
      <SimpleBar style={{ maxHeight: 317 }}>
        {joinerData.map((val, key) => {
          return <NewJoinerData requestdata={val} />;
        })}
      </SimpleBar>
    </div>
  );
};

export default NewJoiner;
