import React, { useEffect, useState } from "react";
import Search from "../../../../components/Header/Search/Search";
import LeaveTypeListTable from "../../../../components/Leaves/Settings/LeaveType/LeaveTypeListTable";
import AddOrEditLeaveTypeModal from "../../../../components/Leaves/Settings/LeaveType/AddOrEditLeaveTypeModal";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import PageLoader from "../../../../components/PageLoader/PageLoader";

const LeaveType = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isAddNewLeaveTypeModal, setIsAddNewLeaveTypeModal] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState(null);
  const [makeApiCall, setMakeApiCall] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [component, setComponent] = useState(<PageLoader />);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const fetchLeaveTypes = async () => {
    setComponent(<PageLoader />);
    setLeaveTypes(null);
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(`lms/leave/leaveTypesList?status=${toggleState}&fullDetails=1`)
      .then((response) => {
        // isMounted && console.log(response.data, "leaveTypes");
        const res = response?.data;
        if (res.status && Number(res.statusCode) === 200) {
          setTimeout(() => {
            setLeaveTypes(res.data);
            setComponent(null);
          }, 500);
        } else {
          setLeaveTypes(null);
          setComponent(<EmptyState />);
        }
      })
      .catch((err) => {
        console.error(err);
        setLeaveTypes(null);
        setComponent(<EmptyState />);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    fetchLeaveTypes();
  }, [makeApiCall, toggleState]);

  return (
    <>
      {isAddNewLeaveTypeModal && (
        <AddOrEditLeaveTypeModal
          openModal={isAddNewLeaveTypeModal}
          onCloseModal={() => setIsAddNewLeaveTypeModal(false)}
          makeApiCall={() => setMakeApiCall((prev) => !prev)}
        />
      )}

      <div className="w-full">
        <div className="px-10 pt-6 border-b border-gray-300">
          <div className="flex justify-between items-center">
            <div>
              <h2>Leave Type</h2>
              <p className="pb-4">All leave type list</p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setIsAddNewLeaveTypeModal(true)}
              >
                <span className="ico-plus"></span> &nbsp; Add New Leave Type
              </button>
            </div>
          </div>

          <div className="flex items-center  justify-between">
            <div className="flex items-center gap-12 pl-4 pt-6">
              <button
                className={
                  toggleState === 1
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(1)}
              >
                Active
              </button>
              <button
                className={
                  toggleState === 0
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(0)}
              >
                Inactive
              </button>
            </div>
            <div className="flex">
              <div>
                <button
                  type="button"
                  className="btn btn--border h-[38px]"
                  onClick={fetchLeaveTypes}
                >
                  <span className="material-symbols-outlined">refresh</span>
                </button>
              </div>
              <div>
                <Search />
              </div>
              {/* <a href="" className="btn btn--border text-[14px]">
                <span>
                  <i className="ico-download mr-1"></i>
                </span>
                Download
              </a> */}
            </div>
          </div>
        </div>
        {component}
        {leaveTypes && Array.isArray(leaveTypes) ? (
          <LeaveTypeListTable
            leaveTypes={leaveTypes}
            makeApiCall={() => setMakeApiCall((prev) => !prev)}
          />
        ) : null}
      </div>
    </>
  );
};

export default LeaveType;
