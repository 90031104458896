import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import { Link } from "react-router-dom";
import { personRoundedIcon, personOutlinedIcon } from "../../../Icons/Icons";
import userRoles from "../../../config/userRoles";
const MenuButtonForEmployeeListPage = (props) => {
  const btnFn = props.btnFunction;
  const user = useSelector((state) => state.user.auth);
  const isManager = userRoleAuth(user, userRoles.MANAGER);
  const isHr = userRoleAuth(user, userRoles.HR);
  const isAdmin = userRoleAuth(user, userRoles.TENANTADMIN);
  const isHrOrAdminRole = isAdmin || isHr;

  return (
    <Menu as="div" className="relative inline-block text-left select-none">
      <div>
        <Menu.Button className="inline-flex w-full justify-center py-2 text-sm  text-gray-700 hover:bg-gray-50">
          <i className="ico-more text-gray-500 cursor-pointer"></i>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none select-none">
          <div className="py-1">
            {isHrOrAdminRole ? (
              <Menu.Item>
                <div
                  onClick={
                    btnFn.handleOpenSendPasswordSetupLinkConfirmationModal
                  }
                  className={
                    "text-gray-700  flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  <img className="w-5 h-5" src="/images/mailIcon.svg" alt="" />
                  Send Password Setup Link
                </div>
              </Menu.Item>
            ) : null}
            {isHrOrAdminRole ? (
              <Menu.Item>
                <div
                  onClick={btnFn.assignLeave}
                  className={
                    "text-gray-700  flex cursor-pointer items-center hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  <i className="ico-clock1 font-medium text-xl"></i>
                  Assign Leave
                </div>
              </Menu.Item>
            ) : null}
            {isAdmin || isHr ? (
              <Menu.Item>
                <div
                  onClick={btnFn.employeeTeamAllocation}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  <img className="w-5 h-5" src="/images/teamIcon.svg" alt="" />
                  Team Allocation
                </div>
              </Menu.Item>
            ) : null}

            {isAdmin || isHr ? (
              <Menu.Item>
                <div
                  onClick={btnFn.editProfile}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  <img
                    className="w-5 h-5"
                    src="/images/edit-black-icon.svg"
                    alt=""
                  />{" "}
                  Edit Profile
                </div>
              </Menu.Item>
            ) : null}
            {isAdmin || isHr ? (
              <Menu.Item>
                <div
                  onClick={btnFn.lineManagerAllocation}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  {personRoundedIcon}
                  Manage Line Manager
                </div>
              </Menu.Item>
            ) : null}
            {isAdmin || isHr ? (
              <Menu.Item>
                <div
                  onClick={btnFn.hrAllocation}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  {personRoundedIcon}
                  Manage HR
                </div>
              </Menu.Item>
            ) : null}
            {isAdmin ? (
              <Menu.Item>
                <div
                  onClick={btnFn.handleOpenManageRoleModal}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                  }
                >
                  {personOutlinedIcon}
                  Manage Role
                </div>
              </Menu.Item>
            ) : null}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuButtonForEmployeeListPage;
