import React from "react";

const DependentCard = () => {
  return (
    <div className="bg-white px-8 py-6 rounded-md border border-gray-100 shadow-md shadow-[#F3F4F6] mt-6 mb-5">
      <div className="flex items-center justify-between pb-3">
        <div className="flex items-center gap-3">
          <img src="/images/basic-info-icon.svg" alt="" />
          <p className="text-base text-[#1F2937] font-semibold">Dependents</p>
        </div>
        <div>
          <button
            type="button"
            className="btn btn--border text-sm bg-indigo-50 text-[#2A52B9]"
          >
            <span className="text-[#2A52B9] ico-upload mr-1 w-3 h-3"></span> New
            Dependents
          </button>
        </div>
      </div>
      <div className="flex justify-end gap-3 mt-6">
        <div>
          <img className="m-auto" src="/images/delete-menu-icon.svg" alt="" />
        </div>
        <div>
          {" "}
          <img src="/images/editicon.svg" alt="" />
        </div>
      </div>
      <div className="flex justify-between employee-info-card">
        <table className="border-0">
          <tbody>
            <tr>
              <td className="title">Name </td>
              <td className="data">completed </td>
            </tr>
            <tr>
              <td className="title">Relationship </td>
              <td className="data">Jhon Cameron</td>
            </tr>
            <tr>
              <td className="title">Nationality</td>
              <td className="data">Jhon Cameron </td>
            </tr>
            <tr>
              <td className="title">Date of birth </td>
              <td className="data">27 Apr 2022 </td>
            </tr>
            <tr>
              <td className="title">Phone Number </td>
              <td className="data">+91 5600 500 600</td>
            </tr>
            <tr>
              <td className="title">Address </td>
              <td className="data">
                75, C, Kamdhenu, Lokhandwala Complex Mumbai, Maharashtra
                400051,India
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DependentCard;
