import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Line from "../../../Line/Line";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import LeaveTypesInPolicyListData from "./LeaveTypesInPolicyListData";
import { warningIcon, warningIcon2 } from "../../../../Icons/Icons";

const DeleteLeaveTypeModal = ({
  openModal,
  onCloseModal,
  leaveType,
  makeApiCall = { makeApiCall },
}) => {
  const axiosPrivate = useAxiosPrivate();
  const leaveName = leaveType?.leave_name;
  const numberOfDaysAllowed = leaveType?.number_days_allowed;
  const description = leaveType?.description;
  const leaveTypeId = leaveType?.id;
  const [forceAction, setForceAction] = useState(false);
  const [baseUrl, setBaseUrl] = useState(null);
  const [leaveTypeInPolicies, setLeaveTypeInPolicies] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const handleCloseModal = () => {
    setForceAction(false);
    setLeaveTypeInPolicies(null);
    setErrMsg("");
    onCloseModal();
  };

  useEffect(() => {
    let baseUrl = `lms/leave/deleteLeaveType?leaveTypeId=${Number(
      leaveTypeId
    )}`;
    if (forceAction) {
      baseUrl += `&forceAction=${forceAction}`;
    }
    setBaseUrl(baseUrl);
  }, [forceAction]);

  const handleSubmit = async () => {
    setErrMsg("");
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .delete(baseUrl)
      .then((response) => {
        // console.log(response.data, "delete leave type");
        const res = response?.data;
        if (res.status) {
          toast.success(res.message);
          makeApiCall();
          handleCloseModal();
        } else {
          setErrMsg(res.message);
          setLeaveTypeInPolicies(res.result);
          setForceAction(true);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something went wrong, try again");
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal} center>
        <div className="p-5 max-w-[350px] text-black flex flex-col gap-3">
          <div className="flex flex-col items-center">
            <span className="rounded-full bg-red-100 w-16 h-16 flex items-center justify-center">
              {warningIcon2}
            </span>
            <p className="font-medium text-lg text-center">
              Are you sure you want to delete {`"${leaveName}"`}
            </p>
            <p className="text-gray-500 text-center">
              Deleting leave type will remove all the active leave types of
              employee and from policy also do you want to continue?
            </p>
            <span className="text-red-600 py-3">{errMsg}</span>
            <div className="flex flex-col w-full gap-3 py-3">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn--red w-full"
                disabled={true} // remove this after confirming the leve type delete criteria
              >
                {forceAction ? "Force Delete" : "Yes, Delete"}
              </button>
              <button
                type="button"
                className="btn btn--border w-full"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteLeaveTypeModal;
