import React, { useState } from "react";
import ViewPolicyAttachment from "./ViewPolicyAttachment";

const PolicyAttachment = ({ data }) => {
  const isPdf = data.file_name.split(".").pop() === "pdf";
  const [isViewAttachmentModalOpen, setIsViewAttachmentModalOpen] =
    useState(false);
  return (
    <div className="w-[50px]">
      <ViewPolicyAttachment
          isModalOpen={isViewAttachmentModalOpen}
          handleCloseModal={() => setIsViewAttachmentModalOpen(false)}
          attachment={data}
        />
      <div className="w-[50px]  h-[63px]  border relative rounded-md">
        <img
          onClick={() => setIsViewAttachmentModalOpen(true)}
          className="object-cover cursor-pointer"
          src={
            isPdf ? "/images/pdf-thumbnail.svg" : "/images/jpg-thumbnail.svg"
          }
        />
      </div>
      <marquee className="mt-1" scrollamount={3}>{data.file_name}</marquee>
    </div>
  );
};

export default PolicyAttachment;
