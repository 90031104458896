import React from "react";
import { Outlet } from "react-router-dom";
import StarterHeader from "../../components/StarterHeader";

const StarterLayout = () => {
  return (
    <div>
      <StarterHeader />
      <Outlet />
    </div>
  );
};

export default StarterLayout;
